
import React, { useEffect, useState } from 'react';
import { Tab, Nav, Row, Col, Button, Table, OverlayTrigger } from "react-bootstrap";
import './CoTermPanelTabs.scss'; // Your custom CSS file for styling
import styles from '../CSPProductDetail.module.scss';
import { Tooltip } from 'react-bootstrap';
import { CSP_PROVISION_COTERM_DATA } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
// import { coTermPlansObj } from "./coterm";
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif";
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'

const CoTermAlignEndDate = (props) => {

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(props.selectedPlan);
    const [selectedBillingTerm, setSelectedBillingTerm] = useState(selectedPlan ? selectedPlan[0].billingTerm : null);

    const [selectedplanName, setSelectedPlanName] = useState(selectedPlan ? selectedPlan[0].planName : null);
    const [selectedPlanId, setSelectedPlanId] = useState(selectedPlan ? selectedPlan[0].planId : null);

    const [customTermEndDate, setCustomTermEndDate] = useState();
    const ColumnNameCoterm = ['Plan Name', 'Vendor Subscription ID', 'Commitment Term (Billing Frequency)'];
    const [coTermPlans, setCoTermPlans] = useState([]);

    const [cotermChosen, setCotermChosen] = useState(null);
    const [isCalenderDate, setIsCalenderDate] = useState(true);
    const [alignedCalendarDate, setAlignedCalendarDate] = useState(null);
    const [alignedDateArray, setAlignedDateArray] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);

    async function GetCoTermData() {
        setLoading(true);
        setCotermChosen("");
        await api.get(`${CSP_PROVISION_COTERM_DATA}?commitmentTerm=${selectedBillingTerm?.split(' (')[0]}&vendorAccountId=${props.vendorAccountId}&planName=${selectedplanName}`, config)
            .then(resp => {
                if (resp.status == 200) {
                    setCoTermPlans(resp.data.item);
                    // console.log(coTermPlans);
                } else if (resp.status == 204) {
                    // setCoTermPlans(resp.data[0].item);
                }
                setLoading(false);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
                setLoading(false);
            });
    }

    function toggleArrows(selectedPlanDate) {
        if(selectedDate === selectedPlanDate) {
            setSelectedDate(null);
        } else {
            setSelectedDate(selectedPlanDate); 
        }

    }


    useEffect(() => {
        const updatedPlans = selectedPlan.map(plan => ({
            planId: plan.planId,
            customTermEndDate: "",
            isAlignedDateFlag: false,
            alignedMonthEndDateFlag: false
        }));
        setAlignedDateArray(updatedPlans);
        // console.log("alignedDateArray", alignedDateArray);
    }, []);

    useEffect(() => {
        // if (props.step2Progress) {
        GetCoTermData();
        // }
    }, [selectedplanName, props.step2Progress, selectedPlanId]);

    // useEffect(() => {
    //     console.log("Data Coterm Date selection: ", alignedDateArray)
    // }, [alignedDateArray]);

    // Get Calendar Aligned Month Date
    useEffect(() => {
        const calendarMonthAlignedDate = coTermPlans.find(item => item.allowedCustomTermEndDateType === 'calendarMonthAligned');
        if (calendarMonthAlignedDate) {
            setAlignedCalendarDate(calendarMonthAlignedDate.allowedCustomTermEndDate);
        }
    }, [coTermPlans]);

    useEffect(() => {
        if (selectedPlan) {
            GetCoTermData();

            const filteredPlans = selectedPlan.filter(item => item.billingTerm !== "Trial");

            if (filteredPlans.length != 0) {
                setSelectedPlanName(filteredPlans[0].planName);
                setSelectedPlanId(filteredPlans[0].planId);
                setSelectedBillingTerm(filteredPlans[0].billingTerm);
            }
            // console.log("Data: ",selectedBillingTerm,selectedPlanId,selectedplanName)
        }
    }, [selectedPlan]);


    return (
        <div className="coTermPanel">
            <div className="align-end-date-tabs">
                <Tab.Container id="vertical-tabs"
                    // defaultActiveKey={selectedPlan.filter(item => item.billingTerm !== "Trial")[0].planId}>
                    defaultActiveKey={
                        selectedPlan.filter(item => item.billingTerm !== "Trial").length === 0
                            ? ""
                            : selectedPlan.filter(item => item.billingTerm !== "Trial")[0].planId
                    }>
                    <div className="d-flex coterm-data-tabs">
                        <Nav variant="pills" className="col-lg-3 col-md-4">
                            <div className='col-12 px-0 plans-wrapper'>
                            {
                                selectedPlan.filter(item => item.billingTerm !== "Trial").length === 0
                                    ? ""
                                    : <h3 className='col-12'>Plans</h3>
                            }
                            <div className='plans-list col-12'>
                            {selectedPlan.filter(item => item.billingTerm !== "Trial").length === 0 ? (
                                <div className={styles["no-data-cloud-invoice"]}>
                                    <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                </div>
                            ) : (
                                selectedPlan
                                    .filter(item => item.billingTerm !== "Trial")
                                    .map((plan, index) => (
                                        <Nav.Item key={index}>
                                            <Nav.Link eventKey={plan.planId}
                                                onClick={() => {
                                                    setSelectedBillingTerm(plan.billingTerm);
                                                    setSelectedPlanName(plan.planName);
                                                    setSelectedPlanId(plan.planId);
                                                    setSelectedDate(null);

                                                    // if (selectedBillingTerm && props.vendorAccountId && selectedPlanName) {
                                                    //     GetCoTermData();

                                                    //     // console.log(coTermPlans);
                                                    // }
                                                }}>
                                                {plan.planName}
                                                <span>Commitment Term (Billing Frequency):<br/>
                                                     {plan.billingTerm} </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))
                            )}
                            </div>
                            </div>
                        </Nav>

                        <Tab.Content className="flex-grow-1 col-lg-9 col-md-8">
                            {
                                selectedPlan.filter(item => item.billingTerm !== "Trial").length === 0
                                    ?
                                    <div className={styles["no-data-cloud-invoice"]}>
                                        <h3 className="mt-4 mb-3">Trial Plans are not eligible for Co-term. Click on Proceed Button for order confirmation.</h3>
                                    </div>
                                    : <h3>Here is the list of your existing subscription end dates.</h3>
                            }

                            {selectedPlan.map((plan, index) => (
                                <Tab.Pane key={index} eventKey={plan.planId}>
                                    {!loading &&
                                        <div className='px-3 py-3'>
                                            {/* plan.customTermEndDate - {alignedDateArray.find(item => plan.planId === item.planId)?.customTermEndDate} */}
                                            <div className='pb-5'>
                                                {coTermPlans.length > 0 && <>
                                                    {coTermPlans.map((option, index) => (

                                                        option.cotermSubscriptions &&

                                                        <div key={index} className="mr-5 mb-2">
                                                            {/* option.customTermEndDate - 
                                                            {alignedDateArray.find(item => plan.planId === item.planId)?.customTermEndDate == option.allowedCustomTermEndDate ? "Show" : "false"} */}
                                                            <div>
                                                                <input
                                                                    className="mr-2"
                                                                    id={`coterm-${plan.planId}-${index}`}
                                                                    key={index}
                                                                    value={option.allowedCustomTermEndDate}
                                                                    type="radio"
                                                                    name={`cotermOption-${selectedPlanId}-${plan.planId}`}
                                                                    // checked={alignedDateArray.find(item => plan.planId === item.planId)?.customTermEndDate === option.allowedCustomTermEndDate}
                                                                    checked={(cotermChosen === option.allowedCustomTermEndDate && isCalenderDate && plan.planId === selectedPlanId) || (alignedDateArray.find(item => plan.planId === item.planId)?.customTermEndDate === option.allowedCustomTermEndDate && alignedDateArray.find(item => plan.planId === item.planId)?.isAlignedDateFlag)}
                                                                    onClick={(e) => {
                                                                        if ((cotermChosen === option.allowedCustomTermEndDate && isCalenderDate && plan.planId === selectedPlanId) || (alignedDateArray.find(item => plan.planId === item.planId)?.customTermEndDate === option.allowedCustomTermEndDate && alignedDateArray.find(item => plan.planId === item.planId)?.isAlignedDateFlag)) {
                                                                            setCotermChosen(null);
                                                                            setCustomTermEndDate(null);
                                                                            setIsCalenderDate(false);

                                                                            // Update the particular plan's alignedMonthEndDateFlag
                                                                            setAlignedDateArray(prevPlans =>
                                                                                prevPlans.map(plan =>
                                                                                    plan.planId === selectedPlanId
                                                                                        ? {
                                                                                            ...plan,
                                                                                            customTermEndDate: "",
                                                                                            isAlignedDateFlag: false,
                                                                                            alignedMonthEndDateFlag: false
                                                                                        }
                                                                                        : plan
                                                                                )
                                                                            );
                                                                        }

                                                                        else {
                                                                            setCotermChosen(option.allowedCustomTermEndDate);
                                                                            setCustomTermEndDate(option.allowedCustomTermEndDate);
                                                                            setIsCalenderDate(true);

                                                                            // Update the particular plan's alignedMonthEndDateFlag
                                                                            setAlignedDateArray(prevPlans =>
                                                                                prevPlans.map(plan =>
                                                                                    plan.planId === selectedPlanId
                                                                                        ? {
                                                                                            ...plan,
                                                                                            customTermEndDate: e.target.value,
                                                                                            alignedMonthEndDateFlag: false,
                                                                                            isAlignedDateFlag: true
                                                                                        }
                                                                                        : plan
                                                                                )
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                <label htmlFor={`coterm-${selectedPlanId}-${index}`}>
                                                                    <span className="cpointer">{option.allowedCustomTermEndDate}</span>
                                                                </label>
                                                                <span onClick={() => toggleArrows(option.allowedCustomTermEndDate)} className={`ml-2 gdap-${selectedDate == option.allowedCustomTermEndDate ? "up" : "down"}-button`} />
                                                            </div>

                                                            {(selectedDate == option.allowedCustomTermEndDate) && (
                                                                <div className="mt-3 px-4">
                                                                    {<Table responsive="md">
                                                                        <thead className="sticky-top">
                                                                            <tr>
                                                                                {ColumnNameCoterm.map((th) => {
                                                                                    return (
                                                                                        <th className={(th === "Commitment Term (Billing Frequency)") ? `${styles['commitment-term-width']}` : "" } key={th}>{th}</th>
                                                                                    );
                                                                                })}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {option.cotermSubscriptions !== null && option.cotermSubscriptions !== undefined ?
                                                                                option.cotermSubscriptions.map((item, index) => (
                                                                                    <tr className={`${styles['vertical-align-middle']}`}>
                                                                                        <td>{item.planName}</td>
                                                                                        <td>{item.vendorSubscriptionId}</td>
                                                                                        <td>{item.billingTerm}</td>
                                                                                    </tr>
                                                                                )) : (
                                                                                    <tr className={`${styles['vertical-align-middle']}`}>
                                                                                        <td colspan="3" className='text-center'><p>No subscriptions found.</p></td>
                                                                                    </tr>
                                                                                )}
                                                                        </tbody>
                                                                    </Table>}
                                                                </div>
                                                            )}
                                                        </div>

                                                    ))}

                                                    {coTermPlans &&
                                                        <>
                                                            <div className="d-flex align-items-start">
                                                                <input
                                                                    key={index}
                                                                    value={alignedCalendarDate}
                                                                    type="radio"
                                                                    className="mr-2"
                                                                    id={`coterm-${plan.planId}-alignedDate`}
                                                                    name={`cotermOption-${selectedPlanId}-${plan.planId}`}
                                                                    checked={(cotermChosen === alignedCalendarDate && !isCalenderDate && plan.planId === selectedPlanId) || (alignedDateArray.find(item => plan.planId === item.planId)?.customTermEndDate === alignedCalendarDate && alignedDateArray.find(item => plan.planId === item.planId)?.alignedMonthEndDateFlag)}
                                                                    onClick={(e) => {
                                                                        if ((cotermChosen === alignedCalendarDate && !isCalenderDate && plan.planId === selectedPlanId) || (alignedDateArray.find(item => plan.planId === item.planId)?.customTermEndDate === alignedCalendarDate && alignedDateArray.find(item => plan.planId === item.planId)?.alignedMonthEndDateFlag)) {
                                                                            setCotermChosen(null);
                                                                            // setCustomTermEndDate(null);
                                                                            setIsCalenderDate(false);

                                                                            setAlignedDateArray(prevPlans =>
                                                                                prevPlans.map(plan =>
                                                                                    plan.planId === selectedPlanId
                                                                                        ? {
                                                                                            ...plan,
                                                                                            customTermEndDate: "",
                                                                                            alignedMonthEndDateFlag: false,
                                                                                            isAlignedDateFlag: false
                                                                                        }
                                                                                        : plan
                                                                                )
                                                                            );
                                                                        }

                                                                        else {
                                                                            setCotermChosen(alignedCalendarDate);
                                                                            setIsCalenderDate(false);
                                                                            setAlignedDateArray(prevPlans =>
                                                                                prevPlans.map(plan =>
                                                                                    plan.planId === selectedPlanId
                                                                                        ? {
                                                                                            ...plan,
                                                                                            customTermEndDate: alignedCalendarDate,
                                                                                            alignedMonthEndDateFlag: true,
                                                                                            isAlignedDateFlag: false
                                                                                        }
                                                                                        : plan
                                                                                )
                                                                            );

                                                                        }
                                                                    }}
                                                                // onChange={() => {
                                                                //     setCotermChosen(alignedCalendarDate);
                                                                //     setIsCalenderDate(false);
                                                                //     setAlignedDateArray(prevPlans =>
                                                                //         prevPlans.map(plan =>
                                                                //             plan.planId === selectedPlanId
                                                                //                 ? {
                                                                //                     ...plan,
                                                                //                     customTermEndDate: alignedCalendarDate,
                                                                //                     alignedMonthEndDateFlag: true,
                                                                //                     isAlignedDateFlag: false
                                                                //                 }
                                                                //                 : plan
                                                                //         )
                                                                //     );

                                                                // }}
                                                                />


                                                                <span>
                                                                    <label
                                                                        htmlFor={`coterm-${plan.planId}-alignedDate`}
                                                                        className='cpointer'
                                                                    >Align end date with calendar month<span className='ml-1'>({alignedCalendarDate})</span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                                }
                                                {coTermPlans.length < 1 && <>
                                                    <div className={styles["no-data-cloud-summary"]}>
                                                        <h2>No plans available.</h2>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img className="management-console-loading-icon" src={loadingGif} />
                                            <p className="text-medium text-strong management-console-loading-text">Loading...</p>
                                        </div>
                                    }

                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </div>
                </Tab.Container>
                <Row className='mt-5'>
                    <Col className="text-right">
                        <Col md={12}>
                            <div className="btn-wrapper text-right mb-1">
                                <Button
                                    variant="light"
                                    className="px-4 btn-border-light w-md-auto col-xs-12"
                                    onClick={() => {
                                        props.setIsProvisionsReviewDone(false);
                                        props.setIsCoTermSelection(false);
                                        setSelectedBillingTerm(null);
                                        setSelectedPlanName(null);
                                        setSelectedPlanId(null);
                                        props.setStep2Progress(false)
                                    }
                                    }
                                >
                                    Back
                                </Button>

                                <Button
                                    className="px-4 mx-2"
                                    onClick={() => {
                                        props.setIsCoTermSelection(true);
                                        props.setAlignedDateArrayData(alignedDateArray);
                                        props.setStep1Progress(false)
                                        props.setStep2Progress(false)
                                        props.setStep3Progress(true)
                                    }
                                    }
                                >
                                    Proceed
                                </Button>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default CoTermAlignEndDate;





