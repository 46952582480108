import { React, useState, useEffect } from 'react'
import { Col, Row, Button, Form, Card } from 'react-bootstrap'
import { INTEGRATION_CONNECTWISE_SETTINGS, POST_TEST_CREDENTIALS } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import { Check2Circle, XCircle } from 'react-bootstrap-icons';
import styles from './integrationSettings.module.scss';
import Toast from '../../../../../../../GlobalComponents/Toast';

const IntegrationSettingsManage = ({toggleDrawer, setSelectService, setSelectCategory, Logout }) => {

  const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Integration") ? true : false);
  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);                         // loading state for processing button
  const [errorSuccessMessage, setErrorSuccessMessage] = useState(true);                        // error or success message displayed in the top
  const [connectwiseCompany, setConnectwiseCompany] = useState("");                            // to store Connectwise Company entered by user
  const [connectwiseUrl, setConnectwiseUrl] = useState("");                                    // to store Connectwise URL entered by user
  const [publicKey, setPublicKey] = useState("");                                              // to store public key entered by user
  const [privateKey, setPrivateKey] = useState("");                                            // to store private key entered by user
  const [isEditAllowed, setIsEditAllowed] = useState(null);                                    // to check whether editing is allowed or not in form
  const [errorMessageTestCredential, setErrorMessageTestCredential] = useState("");            // error message to display from backend.
  const [integrationData, setIntegrationData] = useState(null);                                // to store data from get api
  const websiteURLReg = /^https:\/\/(?:www\.)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;   // website regex expression         
  const [connectwiseUrlErrorFe, setConnectwiseUrlErrorFe] = useState("");                      // to check whether website format is correct or not by frontend
  const [integrationSettingsId, setIntegrationSettingsId] = useState(null);                      // to store data ie.integration settings id from get api
  const [errorSuccessMessageNotEditable, setErrorSuccessMessageNotEditablee] = useState(true); // to show error and success message when integration is done prior and is not editable

  // Use State for Toasts
  const [toastError, setToastError] = useState(false);                                         // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                                        // toast message usestate
  const [toastSuccess, setToastSuccess] = useState(false);

  const defaultServiceName = "Connectwise";
  const defaultCategoryName = "Cloud Technical Support";

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // function for form validation of website
  const validateForm = () => {
    let valid = true;

    // Validate website URL
    if (!websiteURLReg.test(connectwiseUrl)) {
      setConnectwiseUrlErrorFe('Invalid website URL. Please use https instead of http with valid format.');
      valid = false;
    }
    if (valid) {
      // Proceed with form submission
      PostTestCredentials();
    }
  };

  // get api to check whether integration settings data for each row
  async function GetIntegrationListing() {
    await api.get(INTEGRATION_CONNECTWISE_SETTINGS, config)
      .then(resp => {
        if (resp.status == 200) {
          // console.log("Integration Listing :", resp.data.content);
          setIntegrationData(resp.data);
          setConnectwiseCompany(resp.data.connectwiseCompany);
          setConnectwiseUrl(resp.data.connectwiseURL);
          setPrivateKey(resp.data.privateKey);
          setPublicKey(resp.data.publicKey);
          setIsEditAllowed(resp.data.isEdit);
          if (resp.data.isEdit) { setErrorSuccessMessage(null); }
          setIntegrationSettingsId(resp.data.integrationSettingId);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
         Logout();
        }
        else {
          // console.log(error);
        }
      });
  };

  //post api for test credentials check
  async function PostTestCredentials() {
    setConfirmLoadingFlag(true);

    const data = {
      "connectwiseURL": connectwiseUrl,
      "connectwiseCompany": connectwiseCompany,
      "publicKey": publicKey,
      "privateKey": privateKey
    };
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(POST_TEST_CREDENTIALS, data, configFile)
      .then(resp => {
        if (resp.status = 200) {
          // console.log("resp", resp);
          setConfirmLoadingFlag(false);
          setErrorSuccessMessage(true);
          setErrorSuccessMessageNotEditablee(true);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
         }
        setConfirmLoadingFlag(false);
        setErrorSuccessMessage(false);
        setErrorSuccessMessageNotEditablee(false);
        setErrorMessageTestCredential(error.response.data.message);
      })
  };

  //post api for saving integration settings
  async function PostIntegrationListing() {
    setConfirmLoadingFlag(true);

    const data = {
      "connectwiseCompany": connectwiseCompany,
      "privateKey": privateKey,
      "publicKey": publicKey,
      "connectwiseURL": connectwiseUrl,
      "isEdit": false,
      "integrationSettingId": integrationSettingsId,
      "integrationId": 1
    };
    const configFile = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    await api.post(INTEGRATION_CONNECTWISE_SETTINGS, data, configFile)
      .then(resp => {
        if (resp.status = 200) {
          setConfirmLoadingFlag(false);
          GetIntegrationListing();
          setToastMessage("Integration settings saved successfully.");
          setToastSuccess(true);
          setTimeout(() => {
            setToastSuccess(false);
          }, 2000);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
         }
        setConfirmLoadingFlag(false);
        setToastMessage("We encountered an issue while submitting the request. Please try again later.");
        setToastError(true);
        setTimeout(() => {
          setToastError(false);
        }, 5000);
      })
  };

  //useEffect for calling get api on initial load
  useEffect(() => {
    GetIntegrationListing();
  }, [])

  return (
    <>
      <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
      <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />

      <Row className="details-box px-1 py-3">
        <h3>Synnex to Connectwise manage synchronisation agreement</h3>
        <p className='mb-0'>Please agree to enable synchronisation to connectwise manage first to be able to fill the details </p>
        <div className='text-small text-muted mb-3'>I agree to allow Synnex to pull and push the company, contract and product information that has been mapped to ConnectWise manage. I understand that by enabling Synnex synchronization settings, the mapped information may be altered by Synnex within ConnectWise manage. I and my company agree to these terms by selecting the enable synchronization checkbox below.</div>

        {/* when editing is not allowed */}
        {!isEditAllowed && <>
          <Row className='mb-3'>
            <Col md={6}>
              <Form.Check
                type="checkbox"
                className='mb-4 notes-confirmation'
                id={`default-check-box`}
                label={`Enable synchronisation to Connectwise Manage`}
                checked
                readOnly
              />
            </Col>
            <Col md={6}>
              <div className="btn-wrapper text-right">

                {!confirmLoadingFlag ? <Button

                  className={isreadOnly ? "d-none" : "px-3 mx-2 btn-border-light w-md-auto col-xs-12 d-none-mobile"}
                  variant="light"
                  onClick={() => {
                    PostTestCredentials();
                    setErrorSuccessMessage(null);
                  }}
                > Test credentials</Button> : <Button
                  className={isreadOnly ? "d-none" : "px-3 mx-2 btn-border-light w-md-auto col-xs-12 d-none-mobile"}
                  variant="light"
                  disabled
                > Processing ...</Button>}

                {
                  <Button
                    className={isreadOnly ? "d-none" : "px-3 mx-2 d-none-mobile"}
                    onClick={() => {
                      toggleDrawer(true)();
                      setSelectService(defaultServiceName);
                      setSelectCategory(defaultCategoryName);
                    }}
                  >Request Synnex to edit</Button>}
              </div>
            </Col>
            {/* error and success message at the end */}
            {!errorSuccessMessage && errorSuccessMessage != null && <Card className={`mb-4 mt-5 ml-3 ${styles['error-card']}`}>
              <Card.Body className={`${styles['error-card']}`}><XCircle size={22} color="#9C3B35" className='mr-2' />Authentication not successful. {errorMessageTestCredential}
                {/* <a href="#" >Click here</a> to raise a request. */}
              </Card.Body>
            </Card>}
            {errorSuccessMessage && errorSuccessMessage != null && <Card className={`mb-4 mt-5 ml-3 ${styles['success-card']}`}>
              <Card.Body className={`${styles['success-card']}`}> <Check2Circle size={24} color="#1D874C" /> Authentication successful</Card.Body>
            </Card>}
          </Row>

          <Row>
            <Col md={8}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Connectwise company<span className="red">*</span></Form.Label>
                  <Form.Control type="text" readOnly
                    value={'*'.repeat(connectwiseCompany.length)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Public Key<span className="red">*</span></Form.Label>
                  <Form.Control type="text" readOnly
                    value={'*'.repeat(publicKey.length)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Private Key<span className="red">*</span></Form.Label>
                  <Form.Control type="text" readOnly
                    value={'*'.repeat(privateKey.length)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Connectwise URL<span className="red">*</span></Form.Label>
                  <Form.Control type="text" readOnly
                    value={'*'.repeat(connectwiseUrl.length)}
                  />
                </Form.Group>
              </Form>
              <Form.Check // prettier-ignore
                type="checkbox"
                id="termsCheckBox"
                label=""
                readOnly
                checked
                className="d-inline-block w-auto"
              />
              <div className="d-inline-block text-small w-auto mb-4">
                I agree to the <a href="/terms-and-conditions" target='_blank'  >terms & conditions</a>
              </div>
            </Col>

          </Row>
        </>}

        {/* when editing is allowed */}
        {isEditAllowed &&
          <>
            <Row className='mb-3'>
              <Col>
                <Form.Check
                  type="checkbox"
                  className='mb-4 notes-confirmation'
                  id={`default-check-box`}
                  label={`Enable synchronisation to Connectwise Manage`}
                  checked
                  readOnly
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Connectwise company<span className="red">*</span></Form.Label>
                    <Form.Control type="text" value={connectwiseCompany}
                      onChange={(e) => {
                        if (e.target.value === '' || /^[a-zA-Z0-9_!@#$%^&*()-+=]+$/.test(e.target.value)) {
                          setConnectwiseCompany(e.target.value);
                          setErrorSuccessMessage(null);
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Public Key<span className="red">*</span></Form.Label>
                    <Form.Control type="text" value={publicKey}
                      onChange={(e) => {
                        if (e.target.value === '' || /^[a-zA-Z0-9]+$/.test(e.target.value)) {
                          setPublicKey(e.target.value);
                          setErrorSuccessMessage(null);
                        }
                      }} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Private Key<span className="red">*</span></Form.Label>
                    <Form.Control type="text" value={privateKey}
                      onChange={(e) => {
                        if (e.target.value === '' || /^[a-zA-Z0-9]+$/.test(e.target.value)) {
                          setPrivateKey(e.target.value);
                          setErrorSuccessMessage(null);
                        }
                      }} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Connectwise URL<span className="red">*</span></Form.Label>
                    <Form.Control type="text" value={connectwiseUrl}
                      onChange={(e) => {
                        setConnectwiseUrl(e.target.value);
                        setConnectwiseUrlErrorFe("");
                        setErrorSuccessMessage(null);
                      }} />
                    <span className="red text-small">{connectwiseUrlErrorFe}</span>

                  </Form.Group>
                </Form>
                <Form.Check // prettier-ignore
                  type="checkbox"
                  id="termsCheckBox"
                  label=""
                  readOnly
                  checked
                  className="d-inline-block w-auto"
                />
                <div className="d-inline-block text-small w-auto mb-4">
                  I agree to the <a href="/terms-and-conditions" target='_blank'  >terms & conditions</a>
                </div>
              </Col>

              <Row className='pr-0'>
                {/* error and success message at the end */}
                {!errorSuccessMessage && errorSuccessMessage != null && <Card className={`mb-4 mt-2 ml-3 ${styles['error-card']}`}>
                  <Card.Body className={`${styles['error-card']}`}><XCircle size={22} color="#9C3B35" className='mr-2' />Credential error. {errorMessageTestCredential}</Card.Body>
                </Card>}
                {errorSuccessMessage && errorSuccessMessage != null && <Card className={`mb-4 mt-2 ml-3 ${styles['success-card']}`}>
                  <Card.Body className={`${styles['success-card']}`}> <Check2Circle size={24} color="#1D874C" /> Authentication successful</Card.Body>
                </Card>}

                <div className="btn-wrapper text-right pr-0">
                  {/* case 1 :  */}
                  {!confirmLoadingFlag && (errorSuccessMessage === null || !errorSuccessMessage) && !(connectwiseCompany === integrationData.connectwiseCompany && publicKey === integrationData.publicKey && privateKey === integrationData.privateKey && connectwiseUrl === integrationData.connectwiseURL) &&
                    <>
                      {(connectwiseCompany != "" || connectwiseUrl != "" || publicKey != "" || privateKey != "") && <Button
                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                        variant="light"
                        onClick={() => {
                          setConnectwiseCompany(""); setConnectwiseUrl(""); setPublicKey(""); setPrivateKey(""); setConnectwiseUrlErrorFe(""); setErrorSuccessMessage(null);
                        }}
                      > Clear  </Button>}
                      {(connectwiseCompany === "" && connectwiseUrl === "" && publicKey === "" && privateKey === "") && <Button
                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                        variant="light"
                        disabled >
                        Clear </Button>}
                      {(connectwiseCompany != "" && connectwiseUrl != "" && publicKey != "" && privateKey != "") &&
                        <Button
                          className="px-4 mx-2"
                          onClick={() => {
                            validateForm();
                            setErrorSuccessMessage(null);
                          }}
                        >Test Credentials</Button>}
                      {(connectwiseCompany === "" || connectwiseUrl === "" || publicKey === "" || privateKey === "") &&
                        <Button
                          className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                          variant="light"
                          disabled  >Test Credentials</Button>}
                    </>}

                  {/* case 2 */}
                  {!confirmLoadingFlag && errorSuccessMessage === null && (connectwiseCompany === integrationData.connectwiseCompany && publicKey === integrationData.publicKey && privateKey === integrationData.privateKey && connectwiseUrl === integrationData.connectwiseURL) &&
                    <>
                      <Button
                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                        variant="light"
                        disabled
                        sx={{ mr: 1 }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                        variant="light"
                        disabled
                      >
                        Test Credentials
                      </Button>
                    </>}

                  {/* case 3: while calling api processing button should be displayed */}
                  {confirmLoadingFlag && (errorSuccessMessage === null || !errorSuccessMessage) &&
                    <>
                      <Button
                        variant="light"
                        disabled
                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                      >Clear
                      </Button>
                      <Button
                        disabled
                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                        variant="light"
                      >
                        Processing . . .
                      </Button>
                    </>
                  }

                  {/* case 4 : when post api is called and we are processing towards next step*/}
                  {errorSuccessMessage &&
                    <>
                      {!confirmLoadingFlag ? <Button
                        className="px-4 mx-2  w-md-auto col-xs-12"
                        onClick={() => {
                          PostIntegrationListing();
                        }}
                      >
                        Save and Proceed
                      </Button> : <Button
                        disabled
                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                        variant="light"
                      > Processing ...</Button>}
                    </>}
                </div>
              </Row>
            </Row>
          </>
        }
      </Row>
    </>
  )
}

export default IntegrationSettingsManage
