import React, { useState, useEffect } from 'react'
import { DOWNLOAD_BULK_UPLOAD_TEMPLATE, UPDATE_CUSTOMER_STATUS, CUSTOMER_LISTING_TABLE } from '../../../../Utils/GlobalConstants';
import { Button , Col, Container, Dropdown, Row } from 'react-bootstrap';
import { api } from "../../../../Utils/API";
import { useNavigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import RaiseTicketIllustration from '../../../../Assets/Images/Illustrations/raiseticketillustration.svg';
import EmptyDataTableFilterSearch from '../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import "./styles.scss";
import { useMsal } from '@azure/msal-react';
import Toast from '../../../../GlobalComponents/Toast';

const BulkUpload = (props) => {
  
    const [isMcaBulkUpload, setIsMcaBulkUpload] = useState(false);              // for downloading templates
    const navigate = useNavigate();

    const [searchValueTable, setSearchValueTable] = useState(null);             // storing searching value in the search input box

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                    // table active page number
    const [pageSize, setPageSize] = useState(5);                                // table active page size
    const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    let ColumnName = ["Organization", "Full Name",                              // table headers used to make object of react data table component headers
        "Email", "Creation Date", "Status", "Actions"];
     const [status, setStatus] = useState(null);                                 // getTableData() status

    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
    const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data

    const [isDescOrganization, setIsDescOrganization] = useState(true);         // sort state check for Organization column
    const [isDescEmail, setIsDescEmail] = useState(null);                       // sort state check for Email column
    const [isDescCreationDate, setIsDescCreationDate] = useState(null);         // sort state check for Creation Date column
    const [isDescStatus, setIsDescStatus] = useState(null);                     // sort state check for Status column

    // Get Table Data Function Parameters
    const [dateFrom, setDateFrom] = useState(null);                             // getTableData() from date
    const [dateTo, setDateTo] = useState(null);                                 // getTableData()  to date
    const [sortCol, setSortCol] = useState("organization");                     // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("DESC");                             // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending

    const [uploadedFile, setUploadedFile] = useState();                         // for uploading file 
    const [totalRecords, setTotalRecords] = useState(0);                        // for checking no. of records
    const [successfulRecords, setSuccessfulRecords] = useState(0);              // for checking no. of successfulRecords 
    const [failedRecords, setFailedRecords] = useState(0);                      // for checking no. of failedRecords
    const [warnings, setWarnings] = useState(0);                                // for checking no. of warnings
    const [showTable, setShowTable] = useState(false);                          // setting value of count table as false
    const [showTableLoading, setShowTableLoading] = useState("Loading Customer Details . . .");                          
 
 
    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

   // Header Authorization for uploading file API
    const configFile = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File template
    async function DownloadAction(isMcaBulkUpload, url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?isMcaBulkUpload=${isMcaBulkUpload}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                     
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                   
                
            });
    };

    // function for uploading file

    function FileUploaded(e) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        setUploadedFile(formData);
    }

 

    // api for uploading file 
    async function BulkUpload() {
        await api.post(DOWNLOAD_BULK_UPLOAD_TEMPLATE, uploadedFile, configFile)
            .then(resp => {
                // handle success
                
                setTotalRecords(resp.data.totalRecords);
                setSuccessfulRecords(resp.data.successRecords);
                setFailedRecords(resp.data.failedRecords);
                setWarnings(resp.data.warningRecords);
                if (resp.data.customers.length ===0){
                    setShowTableLoading("No Successfully Uploaded Records!")
                }
                else
                {
                    setShowTableLoading("Loading Customer Details . . .")
                }
                let f = [];
                (resp.data.customers).map((td) => {
                    f.push
                        (
                            {
                                "Organization": td.organizationName,
                                "Full Name": td.firstName + " " + td.lastName,
                                "Email": td.email,
                                "Creation Date": td.createdDate,
                                "Status": td.isActive ? "ACTIVE" : "DEACTIVE",
                                "customerCode": td.customerCode,
                                "Actions": (
                                    <Dropdown drop={"start"}>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title="">
                                            {td.isActive ? (
                                                <>
                                                    <Dropdown.Item onClick={(e) => { /* Edit logic */ }} id="1">Edit </Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => { /* CSP Audit logic */ }} id="1">CSP Audit</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => { CustomerStatusActivation(false, td.customerCode) }} id="1">Deactivate</Dropdown.Item>
                                                </>
                                            ) : (
                                                <Dropdown.Item onClick={(e) => { CustomerStatusActivation(true, td.customerCode) }} id="1">Activate</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ),
                            }
                        );
                })
                 
                setTimeout(() => {
                    setTable(f);         // Setting Table Data
                }, 50);

                
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                
            });
        setShowTable(true);
    };


    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className='threeDotAnchorTag'
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className='threedots' />
        </a>
    ));
 
    // status updation from active to deactive and vice-versa
    async function CustomerStatusActivation(isActive, customerCode) {
        // Define the request body for the PUT API
        const requestBody = {
            isActive: isActive,
            externalCustomerCompanyCode: customerCode,
        };

        await api.put(UPDATE_CUSTOMER_STATUS, requestBody, config)
            .then(resp => {
                // handle success
                GetTableData(pageNo, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                
            });
    }
    
    // Get Table Data with Filters, Pagination, Search and Sort Functionality
    async function GetTableData(page, newPerPage, search, dateFrom, dateTo, sortCol, sortDir, status) {
 
        (search === undefined || search === null) ? search = "" : search = search;
        (dateFrom === undefined || dateFrom === null) ? dateFrom = "" : dateFrom = dateFrom;
        (dateTo === undefined || dateTo === null) ? dateTo = "" : dateTo = dateTo;
        (status === undefined || status === null) ? status = "" : status = status;
        await api.get(`${CUSTOMER_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&organization=${search}&status=${status}&fromDate=${dateFrom}&toDate=${dateTo}&sortCol=${sortCol}&sortDir=${sortDir}`, config)
            .then(resp => {
    
                if (resp.status == 204) {
                    if (search === "" && dateFrom === "" && dateTo === "" && status === "") {
                        setEmptyCustomer(true);
                    }

                }
                // handle success
                else {
                    setServerErrorCustomer(false);
                    setEmptyCustomer(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Organization": td.organizationName,
                                    "Full Name": td.firstName + " " + td.lastName,
                                    "Email": td.email,
                                    "Creation Date": td.createdDate,
                                    "Status": td.isActive ? "ACTIVE" : "DEACTIVE",
                                    "customerCode": td.customerCode,
                                    "Actions": (
                                        <Dropdown drop={"start"}>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                {td.isActive ? (
                                                    <>
                                                        <Dropdown.Item onClick={(e) => { /* Edit logic */ }} id="1">Edit </Dropdown.Item>
                                                        <Dropdown.Item onClick={(e) => { /* CSP Audit logic */ }} id="1">CSP Audit</Dropdown.Item>
                                                        <Dropdown.Item onClick={(e) => { CustomerStatusActivation(false, td.customerCode) }} id="1">Deactivate</Dropdown.Item>
                                                    </>
                                                ) : (
                                                    <Dropdown.Item onClick={(e) => { CustomerStatusActivation(true, td.customerCode) }} id="1">Activate</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ),
                                }
                            );
                    })
                     
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Actions") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Organization") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescOrganization === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescOrganization(true); setSortCol("organization"); setIsDescEmail(null); setIsDescCreationDate(null); setIsDescStatus(null); }}></span></>}
                                    {isDescOrganization === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setSortDir("ASC"); setIsDescOrganization(false); setSortCol("organization"); }}></span></>}
                                    {isDescOrganization === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescOrganization(true); setSortCol("organization"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                // sortable: true,
                                // allowOverflow: true  
                            })
                        }
                        else if (td === "Email") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescEmail === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescEmail(true); setSortCol("email"); setIsDescOrganization(null); setIsDescCreationDate(null); setIsDescStatus(null); }} ></span></>}
                                    {isDescEmail === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setSortDir("ASC"); setIsDescEmail(false); setSortCol("email"); }}></span></>}
                                    {isDescEmail === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescEmail(true); setSortCol("email"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1,
                                // sortable: true,
                                // allowOverflow: true  
                            })
                        }
                        else if (td === "Creation Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescCreationDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescCreationDate(true); setSortCol("createdDate"); setIsDescOrganization(null); setIsDescEmail(null); setIsDescStatus(null); }}></span></>}
                                    {isDescCreationDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setSortDir("ASC"); setIsDescCreationDate(false); setSortCol("createdDate"); }}></span></>}
                                    {isDescCreationDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescCreationDate(true); setSortCol("createdDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                grow: 0.8,
                                })
                        }
                        else if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescStatus === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescStatus(true); setSortCol("isActive"); setIsDescOrganization(null); setIsDescCreationDate(null); setIsDescEmail(null); }}></span></>}
                                    {isDescStatus === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setSortDir("ASC"); setIsDescStatus(false); setSortCol("isActive"); }}></span></>}
                                    {isDescStatus === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setSortDir("DESC"); setIsDescStatus(true); setSortCol("isActive"); }}></span></>}
                                </div>,
                                selector: row => <div className="">{row[`${td}`]}</div>,
                                center: true,
                                grow: 0.7,
                                // sortable: true,
                                // allowOverflow: true  
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                    
                }
            })
            .catch(error => {
                
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setServerErrorCustomer(true);
                   
                }

            });
    };


    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        GetTableData(pageNo, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status);
    }, [isDescCreationDate, isDescEmail, isDescOrganization, isDescStatus])

    return (
       
            <Container fluid className="main-content px-0">
                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
                <Container fluid className="my-3">
                    <Row>
                        <Col className="justify-content-between d-flex align-items-center">
                            <label  className="cpointer">
                                <span className="back" onClick={() => navigate('/my-customers')}>&nbsp;</span>&nbsp;<strong>Back</strong>
                            </label>
                            <div>
                            </div>
                        </Col>
                    </Row>
                    <div className="details-box px-3 row">
                        <Col md={12} className="text-center pt-3">
                            <div className="my-0 table-heading align-items-center row">
                                <div className="pl-1  col">
                                    <h5 className="py-1 mb-0 subscription-heading-table-header">Customer Bulk Upload</h5></div></div>
                            <div className="row m-0 d-flex justify-content-between align-items-center">
                                <div className="col-2">
                                    <p className="mb-0 fs-16 fw-5 text-center ff-r"><strong>How to Upload</strong></p>
                                </div>
                                <div className="col-10">
                                    <div className="row m-0 my-4">
                                        <div className="col-4 align-items-start">
                                            <p className="bulk-circle text-primary fs-16 mb-2">1</p>
                                            <p className="mb-0 text-center">Click on Download Template button</p>
                                            <p className="mb-0 fs-12 text-grey text-center">&nbsp;</p>
                                        </div>
                                        <div className="col-4 align-items-start">
                                            <p className="bulk-circle text-primary fs-16 mb-2">2</p>
                                            <p className="mb-0 text-center">Add your data to template file </p>
                                            <p className="mb-0 fs-11 text-grey2 text-center"> Make sure to save in .csv format</p>
                                        </div>
                                        <div className="col-4 align-items-start">
                                            <p className="bulk-circle text-primary fs-16 mb-2">3</p>
                                            <p className="mb-0 text-center">Upload it below for processing</p>
                                            <p className="mb-0 fs-12 text-grey text-center">&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 border-top">

                            </div>
                        </Col>
                        <Row>
                            <Col>
                                &nbsp;
                                <div className="grid-container border-end">
                                    <div className="select-container">
                                        <select onChange={(e) => { setIsMcaBulkUpload(e.target.value) }}>
                                            <option value="false">Customer Bulk Upload Template</option>
                                            <option value="true"> Customer MCA Bulk Upload Template</option>
                                        </select>
                                    </div>
                                    <div className="download-container">
                                        <Button className="ml-3 px-4 mr-3" onClick={(e) => { DownloadAction(isMcaBulkUpload, DOWNLOAD_BULK_UPLOAD_TEMPLATE, isMcaBulkUpload?`Customer_MCA_Bulk_Upload_Template.csv`:`Customer_Bulk_Upload_Template.csv`, "csv") }}>Download Template </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                &nbsp;
                                <div >
                                    <div className="select-container">
                                        <form>
                                            <input type={"file"} accept={".csv"} onChange={(e) => { FileUploaded(e) }} />
                                            <Button className="ml-3 px-4 mr-3" onClick={(e) => { BulkUpload() }}>Upload</Button>
                                        </form>
                                    </div>
                                </div> &nbsp;</Col>
                        </Row>
                        &nbsp; </div>
                    &nbsp;
                    {/* showing count table */}
                    {showTable && (
                    
                        <DataTable
                            noHeader
                            columns={[
                                {
                                    name: 'Total Records',
                                    selector: 'totalRecords',
                                },
                                {
                                    name: 'Successful',
                                    selector: 'successfulRecords',
                                },
                                {
                                    name: 'Failed',
                                    selector: 'failedRecords',
                                },
                                {
                                    name: 'Warnings',
                                    selector: 'warnings',
                                },
                            ]}
                            data={[
                                {
                                    totalRecords: totalRecords,
                                    successfulRecords: successfulRecords,
                                    failedRecords: failedRecords,
                                    warnings: warnings
                                }
                            ]}
                        />)}  &nbsp;
                    <h2>{totalRows} Customers Found</h2>
                    <div className="details-box px-3 row">
                        &nbsp;
                        <Container fluid className="mb-5 p-0">

                            <Row>
                                <Col>
                                    <div className='table-responsive customers-history-table'>
                                        {serverErrorCustomer &&
                                            <div className="no-data-customers">
                                                <img alt="Empty DataTable Filter Search" src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                            </div>
                                        }
                                        {emptyCustomer &&
                                            <div className="no-data-customers">
                                                <img alt="Raise Ticket" src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                                <h2 className="mt-4 mb-3">No Customer Found.</h2>
                                            </div>
                                        }
                                        {!serverErrorCustomer && !emptyCustomer &&
                                            <DataTable
                                                columns={columns}
                                                data={table}
                                                theme="solarized"
                                                customStyles={customStyles}
                                                // striped={true}
                                                persistTableHead={false}                                                noDataComponent={<div className="loading-customers"><p><b>{showTableLoading}</b></p></div>}
                                                width="100%"
                                                fixedHeaderScrollHeight="60vh"
                                                // progressPending={loading}
                                                fixedHeader
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                paginationPerPage={pageSize}
                                                paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                onChangePage={(pageNo) => handlePageChange(pageNo, totalRows)}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                            // paginationResetDefaultPage={resetPaginationToggle}
                                            />}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </Container>

            </Container>
 
    )
}
export default BulkUpload