import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom"
import styles from './creditNotes.module.scss'
import { createTheme } from 'react-data-table-component';
import { api } from "../../../../Utils/API";
import Dropdown from "react-bootstrap/Dropdown";
import Form from 'react-bootstrap/Form';
import 'reactjs-popup/dist/index.css';
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import {
  GET_SERVICES_ALL, GET_CUSTOMERS_LIST, GET_CREDIT_NOTE_STATUS_LIST,
  CLOUD_CREDIT_NOTE_LISTING_TABLE, CLOUD_CREDIT_NOTE_DOWNLOAD_CSV_SUMMARY, CLOUD_CREDIT_NOTE_DOWNLOAD_PDF_SUMMARY,
  RESELLER_API
} from '../../../../Utils/GlobalConstants';
import CreditNotesFilterBox from './LocalComponents/FilterBox';
import Toast from '../../../../GlobalComponents/Toast';
import AccessDenied from '../../../../GlobalComponents/EmptyStates/AccessDenied';
import LazyTable from './LocalComponents/LazyTable';
import ResellerNavdropdown from '../../../../GlobalComponents/ResellerNavdropdown';


const CreditNotesListing = (props) => {

  const navigate = useNavigate();                                                           // to use navigate function from react router dom    
  const date = new Date();                                                                  // for js date time function
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Billing") ? true : false);
  const searchParams = new URLSearchParams(location.search);

  const [searchIconFlag, setSearchIconFlag] = useState(false);                              // to open and close search box
  const [searchValueTable, setSearchValueTable] = useState((searchParams.get('searchValueTable') && searchParams.get('searchValueTable') !== "null") ? searchParams.get('searchValueTable') : null);                           // storing searching value in the search input box
  const [filterFlag, setFilterFlag] = useState(false);                                      // to show active and deactive filter states 
  const [showFilterBox, setShowFilterBox] = useState(false);                                // showing filter box
  const searchInputRef = useRef();                                                          // Search Input Box

  const [customerId, setCustomerId] = useState(null);                                 // getTableData() customer id


  const [serviceData, setServiceData] = useState(null);                                    // data in service dropdown
  const [statusData, setStatusData] = useState(null);
  // Table Parameters
  const [pageNo, setPageNo] = useState(parseInt(searchParams.get('pageNo')) || 1);                                                  // table active page number
  const [pageSize, setPageSize] = useState(parseInt(searchParams.get('pageSize')) || 15);                                              // table active page size
  const [totalRows, setTotalRows] = useState(0);                                            // table total count of data from api 
  const [columns, setColumns] = useState([]);                                               // columns used to display column headers in the react data table component
  const [table, setTable] = useState([]);                                                   // data state used to display data in react data table component
  let ColumnName = ["Credit Note No.", "Cloud Service",                                     // table headers used to make object of react data table component headers
    "Customer",
    "Credit Note Date", "Amount", "Balance Amount", "Status", "Actions"];
  const [initialLoading, setInitialLoading] = useState(true);                               // loader for table

  const [serverErrorCloudCreditNote, setServerErrorCloudCreditNote] = useState(false);      // server error check state in table during api call to load data
  const [emptyCloudCreditNote, setEmptyCloudCreditNote] = useState(false);                  // empty table check state in table during api call to load data
  const [emptyCloudCreditNoteFilters, setEmptyCloudCreditNoteFilters] = useState(false);    // empty filter response check state in table during api call to load data
  const [emptyCloudCreditNoteSearch, setEmptyCloudCreditNoteSearch] = useState(false);      // empty search response check state in table during api call to load data
  const [emptyCloudSummaryReseller, setEmptyCloudSummaryReseller] = useState(false);                  // empty table check state in table during api call to load data for reseller dropdown

  const [isDescCreditNoteNumber, setIsDescCreditNoteNumber] = useState(true);               // sort state check for Credit Note number column
  const [isDescCreditNoteDate, setIsDescCreditNoteDate] = useState(null);                   // sort state check for Credit Note date column
  const [isDescCloudService, setIsDescCloudService] = useState(null);                       // sort state check for cloud service column
  const [isDescCustomer, setIsDescCustomer] = useState(null);                               // sort state check for customer column
  const [isDescStatus, setIsDescStatus] = useState(null);                                   // sort state check for Status column

  //lazy loading
  const [scrollToDivTop, setScrollToDivTop] = useState(false);
  const [infinityLoading, setInfinityLoading] = useState(false);                     // loading state while table loads for lazy loading

  // Get Table Data Function Parameters
  const [serviceId, setServiceId] = useState(searchParams.get('serviceId') || null);                                         // getTableData() service id
  const [creditNoteDateFrom, setCreditNoteDateFrom] = useState(searchParams.get('creditNoteDateFrom') || null);                       // getTableData() Credit Note from date
  const [creditNoteDateTo, setCreditNoteDateTo] = useState(searchParams.get('creditNoteDateTo') || null);                           // getTableData() Credit Note to date
  const [sortCol, setSortCol] = useState(searchParams.get('sortCol') || "CreditNoteNumber");                               // getTableData() sorting column name
  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || "DESC");                                           // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending
  const [customerName, setCustomerName] = useState(searchParams.get('customerName') || null);                                       // getTableData() customer id
  const [status, setStatus] = useState(searchParams.get('status') || null);                                               // getTableData() status column

  const [creditNoteServiceLastUsed, setCreditNoteServiceLastUsed] = useState(searchParams.get('creditNoteServiceLastUsed') || "default");    // service dropdown saved data after applying filters
  const [creditNoteDateLastUsedDate, setCreditNoteDateLastUsedDate] = useState(searchParams.get('creditNoteDateLastUsedDate') || "default");  // Credit Note date dropdown saved data after applying filters
  const [customerLastUsed, setCustomerLastUsed] = useState(searchParams.get('customerLastUsed') || "default");                      // customer dropdown saved data after applying filters
  const [statusLastUsed, setStatusLastUsed] = useState(searchParams.get('statusLastUsed') || "default");                          // status dropdown saved data after applying filters  
  const [customerData, setCustomerData] = useState(null);                                   // data in customer dropdown in filter box

  // if custom was selected in Credit Note date in filter box
  const [creditNoteDateCustomFromLast, setCreditNoteDateCustomFromLast]
    = useState(searchParams.get('creditNoteDateCustomFromLast') || date.toISOString().slice(0, 10));                                       // CreditNote from date saved data after applying filter having custom selection
  const [creditNoteDateCustomToLast, setCreditNoteDateCustomToLast]
    = useState(searchParams.get('creditNoteDateCustomToLast') || date.toISOString().slice(0, 10));                                       // Credit Note to date saved data after applying filter having custom selection

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);                                  // toast message displaying success message
  const [toastError, setToastError] = useState(false);                                      // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                                     // toast message usestate

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // reseller dropdown for  staff users
  const [resellerData, setResellerData] = useState([]);                            // data in customer dropdown
  const [resellerNameDefaultValue, setResellerNameDefaultValue] = useState(searchParams.get('resellerNameDefaultValue') || "Resellers");
  const [resellerNameSearchValue, setResellerNameSearchValue] = useState();
  const [resellerName, setResellerName] = useState([]);
  const [resellerNameFiltered, setResellerNameFiltered] = useState(resellerName);
  const updatedResellerName = [];
  const [resellerId, setResellerId] = useState(searchParams.get('resellerId') || null);
  const [resellerIdChangedFlag, setResellerIdChangedFlag] = useState(false);
  const [isCloudAdmin, setIsCloudAdmin] = useState(localStorage.getItem("curentRole") && localStorage.getItem("curentRole").toLowerCase().includes("cloud admin view") ? true : false);

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Three Dot Actions Functionality
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      className={styles['threeDotAnchorTag']}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className={styles["threedots"]} />
    </a>
  ));

  // Header Authorization for Download PDF API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/pdf") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  // Download PDF and CSV File from actions Dropdown
  async function DownloadAction(id, url, name, fileType) {
    let configuration;
    if (fileType === "pdf") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }
    await api.get(`${url + id}?requestedResellerCode=${resellerId}`, configuration)
      .then(resp => {
        // handle success
        if (resp.status === 204) {
          setToastMessage("No Data available to Download !");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 2000);
        }
        else {
          // console.log("Response : ", resp.data);
          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });
          // // console.log(d);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  // Function to call all dropdown values in Reseller Section in Filter Box
  async function GetAllResellers() {
    await api.get(RESELLER_API, config)
      .then(resp => {
        setResellerData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  // Filter Box APIS
  // Function to call all dropdown values in Service Section in Filter Box
  async function GetAllServices() {
    await api.get(GET_SERVICES_ALL, config)
      .then(resp => {
        setServiceData([{ id: "0", name: 'All Services', integrationCode: 'default' }, ...resp.data]);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }

      });
  };

  // Function to call all dropdown values in Status Section in Filter Box
  async function GetAllStatuses() {
    await api.get(GET_CREDIT_NOTE_STATUS_LIST, config)
      .then(resp => {
        setStatusData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }

      });
  };

  // Function to call all dropdown values in Customer Section in Filter Box
  async function GetAllCustomers() {
    await api.get(`${GET_CUSTOMERS_LIST}?requestedResellerCode=${resellerId}`, config)
      .then(resp => {
        setCustomerData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }

      });
  };


  // Get Table Data with Filters, Pagination, Search and Sort Functionality
  async function GetTableData(page, newPerPage, search, serviceId, creditNoteDateFrom, creditNoteDateTo, sortCol, sortDir, customerName, status, appendData, isFilter) {
    setInitialLoading(false);
    (search === undefined || search === null || search === "null") ? search = "" : search = search;
    (serviceId === undefined || serviceId === null || serviceId === "null") ? serviceId = "" : serviceId = serviceId;
    (creditNoteDateFrom === undefined || creditNoteDateFrom === null || creditNoteDateFrom === "null") ? creditNoteDateFrom = "" : creditNoteDateFrom = creditNoteDateFrom;
    (creditNoteDateTo === undefined || creditNoteDateTo === null || creditNoteDateTo === "null") ? creditNoteDateTo = "" : creditNoteDateTo = creditNoteDateTo;
    (customerName === undefined || customerName === null || customerName === "null") ? customerName = "" : customerName = customerName;
    (status === undefined || status === null || status === "null") ? status = "" : status = status;
    isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;
    await api.get(`${CLOUD_CREDIT_NOTE_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&creditNoteNumber=${search}&serviceId=${serviceId}&customerCode=${customerName}&status=${status}&startDate=${creditNoteDateFrom}&endDate=${creditNoteDateTo}&sortCol=${sortCol}&sortDir=${sortDir}&requestedResellerCode=${resellerId}`, config)
      .then(resp => {
        setInitialLoading(true);
        setInfinityLoading(false);

        if (resp.status == 204) {
          if (search === "" && serviceId === "" && creditNoteDateFrom === "" && creditNoteDateTo === "" && customerName === "" && status === "" && resellerId === "") {
            setEmptyCloudCreditNote(true);
          } else if ((serviceId != "" || creditNoteDateFrom != "" || creditNoteDateTo != "" || customerName != "" || status != "" || resellerId != "") && isFilter) {
            setEmptyCloudCreditNoteFilters(true);
          } else if (search != "" && !isFilter) {
            setEmptyCloudCreditNoteSearch(true);
          } else {
            setEmptyCloudSummaryReseller(true);
          }
        }
        // handle success
        else {
          setServerErrorCloudCreditNote(false);
          setEmptyCloudCreditNote(false);
          setEmptyCloudCreditNoteFilters(false);
          setEmptyCloudCreditNoteSearch(false);
          setEmptyCloudSummaryReseller(false);

          let f = [];
          setTotalRows(resp.data.totalCount);
          (resp.data.content).map((td) => {
            f.push
              (
                {
                  "Credit Note No.": <span className={styles["cloud-creditNote-color-underline"]} onClick={() => { navigate("/billing/cloud-creditNote/creditNote-detail", { state: { Id: td.creditNoteId, creditNoteNumber: td.creditNoteNumber, resellerId: resellerId }, }); }}>{td.creditNoteNumber}</span>, //"PICT00000235",
                  "Cloud Service": `${td.serviceName}`,
                  "Customer": td.customerName,
                  "Credit Note Date": td.creditNoteDate,
                  "Amount": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`,
                  "Balance Amount": td.balanceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.balanceAmount === null ? "0.00" : td.balanceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.balanceAmount === null ? 0.00 : td.balanceAmount)).toFixed(2))}`,
                  "Status": td.status === 'PAID' ? (
                    <div className={`${styles['status-creditnote']}`}>
                      <span className="status-paid-new"></span>
                      <div className="py-1 text-muted text-small">Paid</div>
                    </div>
                  ) : td.status === 'OUTSTANDING' ? (
                    <div className={`${styles['status-creditnote']}`}>
                      <span className="status-outstanding-new"></span>
                      <div className="py-1 text-muted text-small">Outstanding</div>
                    </div>
                  ) : td.status === 'OVERDUE' ? (
                    <div className={`${styles['status-creditnote']}`}>
                      <span className="status-overdue"></span>
                      <div className="py-1 text-muted text-small">Overdue</div>
                    </div>
                  ) :
                    (
                      td.status
                    ),
                  "Actions": <Dropdown drop={"start"}>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu size="sm" title="">
                      <Dropdown.Item onClick={(e) => { DownloadAction(td.creditNoteNumber, CLOUD_CREDIT_NOTE_DOWNLOAD_PDF_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.creditNoteNumber}_creditNote.pdf`, "pdf") }} id="1">Download PDF</Dropdown.Item>
                      <Dropdown.Item onClick={(e) => { DownloadAction(td.creditNoteNumber, CLOUD_CREDIT_NOTE_DOWNLOAD_CSV_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.creditNoteNumber}_creditNote.csv`, "csv") }} id="1">Download CSV</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                }
              );
          })
          setTimeout(() => {
            if (appendData) {
              setTable(table => [...table, ...f])            // Setting Table Data
            } else {
              setTable(f)
            }
          }, 50);
          let d = [];
          ColumnName.map((td) => {
            if (td === "Actions") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
              })
            }
            else if (td === "Credit Note No.") {
              d.push({
                id: `${td}`,
                name: <div>
                  {(isDescCreditNoteNumber === null && sortCol !== "creditNoteNumber") && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCreditNoteNumber(true); setSortCol("creditNoteNumber"); setIsDescCreditNoteDate(null); setIsDescCustomer(null); setIsDescCloudService(null); setIsDescStatus(null); }}></span></>}
                  {((isDescCreditNoteNumber === null && sortCol === "creditNoteNumber" && sortDir === "DESC") || isDescCreditNoteNumber === true) && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescCreditNoteNumber(false); setSortCol("creditNoteNumber"); }}></span></>}
                  {isDescCreditNoteNumber === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCreditNoteNumber(true); setSortCol("creditNoteNumber"); }}></span></>}
                </div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
              })
            }
            else if (td === "Credit Note Date") {
              d.push({
                id: `${td}`,
                name: <div>
                  {isDescCreditNoteDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCreditNoteDate(true); setSortCol("creditNoteDate"); setIsDescCreditNoteNumber(null); setIsDescCustomer(null); setIsDescCloudService(null); setIsDescStatus(null); }}></span></>}
                  {isDescCreditNoteDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescCreditNoteDate(false); setSortCol("creditNoteDate"); }}></span></>}
                  {isDescCreditNoteDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCreditNoteDate(true); setSortCol("creditNoteDate"); }}></span></>}
                </div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
              })
            }
            else if (td === "Cloud Service") {
              d.push({
                id: `${td}`,
                name: <div>
                  {isDescCloudService === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCloudService(true); setSortCol("serviceName"); setIsDescCreditNoteDate(null); setIsDescCustomer(null); setIsDescCreditNoteNumber(null); setIsDescStatus(null); }}></span></>}
                  {isDescCloudService === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescCloudService(false); setSortCol("serviceName"); }}></span></>}
                  {isDescCloudService === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCloudService(true); setSortCol("serviceName"); }}></span></>}
                </div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
              })
            }
            else if (td === "Status") {
              d.push({
                id: `${td}`,
                name: <div>
                  {isDescStatus === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStatus(true); setSortCol("status"); setIsDescCreditNoteNumber(null); setIsDescCloudService(null); setIsDescCreditNoteDate(null); setIsDescCustomer(null); }}></span></>}
                  {isDescStatus === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescStatus(false); setSortCol("status"); }}></span></>}
                  {isDescStatus === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStatus(true); setSortCol("status"); }}></span></>}
                </div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                center: true,
              })
            }
            else if (td === "Customer") {
              d.push({
                id: `${td}`,
                name: <div>
                  {isDescCustomer === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCustomer(true); setSortCol("customerName"); setIsDescCreditNoteNumber(null); setIsDescCreditNoteDate(null); setIsDescCloudService(null); setIsDescStatus(null); }}></span></>}
                  {isDescCustomer === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescCustomer(false); setSortCol("customerName"); }}></span></>}
                  {isDescCustomer === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCustomer(true); setSortCol("customerName"); }}></span></>}
                </div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
              })
            }
            else if (td === "Amount") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                right: true,
                grow: 0.7,
              })
            }
            else {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                right: true,
              })
            }

          })
          setColumns(d);     // Setting Column Data
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
          setServerErrorCloudCreditNote(true);
        }

      });
  };

  // On search field value we trigger this function having react debugging after every 800 ms
  useEffect(() => {
    if (searchValueTable === "null") {
      setSearchValueTable(null);
    }
    if (searchValueTable !== undefined && searchValueTable !== null) {
      const getData = setTimeout(() => {
        if (searchValueTable.length > 0) {
          setScrollToDivTop(true);
          setPageNo(1);
          setResetPaginationToggle(true);
          GetTableData(1, pageSize, searchValueTable, serviceId, creditNoteDateFrom, creditNoteDateTo, sortCol, sortDir, customerName, status);
          setResetPaginationToggle(false);
        } else {
          setScrollToDivTop(true);
          setPageNo(1);
          setResetPaginationToggle(true);
          GetTableData(1, pageSize, searchValueTable, serviceId, creditNoteDateFrom, creditNoteDateTo, sortCol, sortDir, customerName, status);
          setResetPaginationToggle(false);
        }
      }, 800);
      return () => clearTimeout(getData);
    }
  }, [searchValueTable])

  // Function to handle the lazy loading calls invoked from the Lazy Table
  const handleLazyCalls = async () => {
    setInfinityLoading(true);
    setPageNo((prev) => {
      const newPageNumber = prev + 1;
      (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, serviceId, creditNoteDateFrom, creditNoteDateTo, sortCol, sortDir, customerName, status, true))();

      return newPageNumber;
    });
  }

  // React Data Table Custom Theme
  createTheme('solarized', {
    text: {
      primary: '#000000',
    },
    background: {
      default: '#FFFFFF',
    },
    divider: {
      default: '#d7dadf',
    },
    striped: {
      default: '#f5f5f5',
    },
  });

  // React Data Table Custom Styles
  const customStyles = {
    tableWrapper: {
      style: {
        minHeight: '400px'
      },
    },
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        textAlign: 'center',
        fontSize: '0.9rem',
        fontWeight: '400',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '10px', // override the cell padding for head cells
        paddingRight: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#fafafa',
        fontWeight: '500',
        fontSize: '0.875rem',
        color: '#6A6A6A !important',
        textTransform: 'capitalize',
      },
    },
  };

  // Function Triggers when Page Number is Changed by Customer
  const handlePageChange = (pageNo) => {
    GetTableData(pageNo, pageSize, searchValueTable, serviceId, creditNoteDateFrom, creditNoteDateTo, sortCol, sortDir, customerName, status);
    setPageNo(pageNo);
  };

  // Function Triggers when Rows Per Page is Changed by Customer
  const handlePerRowsChange = async (newPerPage, page) => {
    setPageNo(page);
    setPageSize(newPerPage);
    GetTableData(page, newPerPage, searchValueTable, serviceId, creditNoteDateFrom, creditNoteDateTo, sortCol, sortDir, customerName, status);
  };

  // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
  useEffect(() => {
    setPageNo(1);
    GetTableData(1, pageSize, searchValueTable, serviceId, creditNoteDateFrom, creditNoteDateTo, sortCol, sortDir, customerName, status);
  }, [isDescCreditNoteNumber, isDescCreditNoteDate, isDescCloudService, isDescCustomer, isDescStatus])

  // Search Box Focus on Open
  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();
  }, [searchIconFlag]);

  // Use Effect to get all the services in filter service dropdown
  useEffect(() => {
    localStorage.getItem("navigation_link") ? localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link", "/billing/credit-notes");
    GetAllServices();
    GetAllStatuses();
    { isCloudAdmin && GetAllResellers(); }
    if (customerData === null) {
      GetAllCustomers();
    }
  }, []);

  // useEffct to select reseller for staff user
  useEffect(() => {
    if(resellerId!=="null" && resellerId && resellerIdChangedFlag){setSearchValueTable(null);
      setServiceId(null);
      if (creditNoteDateLastUsedDate !== "default") {
        setCreditNoteDateLastUsedDate("default");
        const summaryElement = document.getElementById(creditNoteDateLastUsedDate);
        if (summaryElement) {
            summaryElement.checked = false; // Uncheck the checkbox if it exists
        }
    }
      setCreditNoteDateFrom(null);
      setCreditNoteDateTo(null);
      setFilterFlag(false);
      setCustomerName(null);
      setStatus(null);
      setPageNo(1);
      setResellerIdChangedFlag(false);
      GetTableData(1, pageSize, null, null, null, null, sortCol, sortDir, null, null, false);
      GetAllCustomers();
    }
  }, [resellerId]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('pageNo', pageNo.toString());
    newSearchParams.set('pageSize', pageSize.toString());
    newSearchParams.set('sortCol', sortCol);
    newSearchParams.set('sortDir', sortDir);
    newSearchParams.set('searchValueTable', searchValueTable);
    newSearchParams.set('serviceId', serviceId);
    newSearchParams.set('creditNoteDateFrom', creditNoteDateFrom);
    newSearchParams.set('creditNoteDateTo', creditNoteDateTo);
    newSearchParams.set('customerName', customerName);
    newSearchParams.set('status', status);
    newSearchParams.set('resellerId', resellerId);
    newSearchParams.set('resellerNameDefaultValue', resellerNameDefaultValue);
    newSearchParams.set('creditNoteServiceLastUsed', creditNoteServiceLastUsed);
    newSearchParams.set('creditNoteDateLastUsedDate', creditNoteDateLastUsedDate);
    newSearchParams.set('customerLastUsed', customerLastUsed);
    newSearchParams.set('statusLastUsed', statusLastUsed);
    newSearchParams.set('creditNoteDateCustomFromLast', creditNoteDateCustomFromLast);
    newSearchParams.set('creditNoteDateCustomToLast', creditNoteDateCustomToLast);

    window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
  }, [pageNo, pageSize, sortDir, sortCol, searchValueTable, serviceId, creditNoteDateFrom, creditNoteDateTo, customerName, status,
    creditNoteServiceLastUsed, creditNoteDateLastUsedDate, customerLastUsed, statusLastUsed, creditNoteDateCustomFromLast, creditNoteDateCustomToLast, resellerId]);

  useEffect(() => {
    (searchParams.get('searchValueTable') && searchParams.get('searchValueTable').length > 0 && searchParams.get('searchValueTable') !== "null") ? setSearchIconFlag(true) : setSearchIconFlag(false);
    (
      (searchParams.get('serviceId') || searchParams.get('creditNoteDateFrom') || searchParams.get('creditNoteDateTo') || searchParams.get('customerName') || searchParams.get('status')) &&
        (searchParams.get('serviceId') !== "null" || searchParams.get('creditNoteDateFrom') !== "null" || searchParams.get('creditNoteDateTo') !== "null" || searchParams.get('customerName') !== "null" || searchParams.get('status') !== "null") &&
        (searchParams.get('serviceId').length > 0 || searchParams.get('creditNoteDateFrom').length > 0 || searchParams.get('creditNoteDateTo').length > 0 || searchParams.get('customerName').length > 0 || searchParams.get('status').length > 0)
        ? setFilterFlag(true) : setShowFilterBox(false)
    );
  }, [])

  return (
    <>
      <div className="main-content">
        <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
        {!isDisabled && <div className="customer-management-console-main-div mb-5">
          <div className="mgmt-console-table-row">
            <div className="my-3 d-flex">
              <Col className={`py-0 overflow-auto ${styles['cloud-creditNote-table']}`}>
                {isCloudAdmin && <Col md={3}>
                  <ResellerNavdropdown
                    formGroupId="customerNameId"
                    placeholder="Enter Reseller Code"
                    selectDefaultValue={resellerNameDefaultValue}
                    setSelectDefaultValue={setResellerNameDefaultValue}
                    setOptionId={setResellerId}
                    selectOptions={resellerData}
                    selectOptionsFiltered={resellerNameFiltered}
                    setSelectOptionsFiltered={setResellerNameFiltered}
                    selectSearchValue={resellerNameSearchValue}
                    setSelectSearchValue={setResellerNameSearchValue}
                    updatedOptions={updatedResellerName}
                    setResellerIdChangedFlag={setResellerIdChangedFlag}
                  />
                </Col>}
                <div className={`${styles['cloud-creditNote-table-inner']} ${styles['table-details']} details-box`}>
                  <div className="my-0 table-heading align-items-center row">
                    <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                      <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header">Credit Notes {!emptyCloudCreditNoteSearch && !emptyCloudCreditNoteFilters && !emptyCloudCreditNote && !emptyCloudSummaryReseller && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h5>
                    </Col>
                    {/* desktop and tab view search and filter */}
                    {<Col className="px-1 pr-4 d-none d-md-block">
                      <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                        {!serverErrorCloudCreditNote && !emptyCloudCreditNote && !emptyCloudSummaryReseller && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                          {!searchIconFlag &&
                            <span className="mx-3">&nbsp;</span>
                          }
                          {!searchIconFlag && !emptyCloudCreditNoteFilters &&
                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                          }
                          {searchIconFlag && !emptyCloudCreditNoteFilters && !emptyCloudSummaryReseller &&
                            <InputGroup className="search-input-box search-input-box-large">
                              <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                <span className="searchgrey cpointer"
                                >
                                  &nbsp;
                                </span>
                              </InputGroup.Text>
                              <Form.Control
                                placeholder="Search by Credit Note No."
                                aria-label="Search by Credit Note No."
                                aria-describedby="basic-addon2"
                                className="search-input-box-input"
                                value={searchValueTable}
                                onChange={(e) => setSearchValueTable(e.target.value)}
                                ref={searchInputRef}
                              />
                              <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                onClick={() => {
                                  setSearchValueTable("");
                                  setSearchIconFlag(!searchIconFlag);
                                }}>
                                <span className="closebtn">
                                  &nbsp;
                                </span>
                              </InputGroup.Text>
                            </InputGroup>
                          }
                          {!filterFlag && !emptyCloudCreditNoteSearch && !emptyCloudSummaryReseller && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                            setShowFilterBox(!showFilterBox);
                          }}></span>}
                          {initialLoading && filterFlag && !emptyCloudCreditNoteSearch && !emptyCloudSummaryReseller && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                        </Col>}
                      </Row>
                    </Col>}
                    {/* mobile view search and filter */}
                    {<Col className="px-1 d-block d-md-none" md={12}>
                      <Row className="justify-content-end align-items-center mobile-padding-search-open-sub px-2">
                        {!serverErrorCloudCreditNote && !emptyCloudCreditNote && !emptyCloudSummaryReseller && <Col className="d-flex align-items-center justify-items-center position-relative">
                          {!emptyCloudCreditNoteFilters && !emptyCloudSummaryReseller &&
                            <InputGroup className="search-input-box">
                              <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                <span className="searchgrey cpointer"
                                >
                                  &nbsp;
                                </span>
                              </InputGroup.Text>
                              <Form.Control
                                placeholder="Search by Credit Note No."
                                aria-label="Search by Credit Note No."
                                aria-describedby="basic-addon2"
                                className="search-input-box-input"
                                value={searchValueTable}
                                onChange={(e) => setSearchValueTable(e.target.value)}
                              />
                              <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                onClick={() => {
                                  setSearchValueTable("");
                                  setSearchIconFlag(!searchIconFlag);
                                }}>
                                <span className="closebtn">
                                  &nbsp;
                                </span>
                              </InputGroup.Text>
                            </InputGroup>
                          }

                          {!filterFlag && !emptyCloudCreditNoteSearch && !emptyCloudSummaryReseller && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                            setShowFilterBox(!showFilterBox);
                          }}></span>}
                          {initialLoading && filterFlag && !emptyCloudCreditNoteSearch && !emptyCloudSummaryReseller && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                        </Col>}
                      </Row>
                    </Col>}
                  </div>
                  {
                    !emptyCloudCreditNoteSearch && !emptyCloudSummaryReseller && showFilterBox &&
                    <CreditNotesFilterBox
                      serviceId={serviceId} setServiceId={setServiceId} GetTableData={GetTableData}
                      pageNo={pageNo} pageSize={pageSize} searchValueTable={searchValueTable}
                      creditNoteDateFrom={creditNoteDateFrom} creditNoteDateTo={creditNoteDateTo}
                      setCreditNoteDateFrom={setCreditNoteDateFrom} setCreditNoteDateTo={setCreditNoteDateTo}
                      sortCol={sortCol} sortDir={sortDir} setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag}
                      creditNoteServiceLastUsed={creditNoteServiceLastUsed} setCreditNoteServiceLastUsed={setCreditNoteServiceLastUsed}
                      creditNoteDateLastUsedDate={creditNoteDateLastUsedDate} status={status} setStatus={setStatus}
                      setCreditNoteDateLastUsedDate={setCreditNoteDateLastUsedDate} creditNoteDateCustomFromLast={creditNoteDateCustomFromLast}
                      setCreditNoteDateCustomFromLast={setCreditNoteDateCustomFromLast} creditNoteDateCustomToLast={creditNoteDateCustomToLast}
                      setCreditNoteDateCustomToLast={setCreditNoteDateCustomToLast} customerLastUsed={customerLastUsed} setCustomerLastUsed={setCustomerLastUsed}
                      customerName={customerName} setCustomerName={setCustomerName} customerData={customerData} setCustomerData={setCustomerData}
                      statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed} customerIsAuthenticated={props.customerIsAuthenticated}
                      setCustomerIsAuthenticated={props.setAuthentication} customerId={customerId} setCustomerId={setCustomerId}
                      statusData={statusData} setPageNo={setPageNo} setResetPaginationToggle={setResetPaginationToggle}
                      serviceData={serviceData} setScrollToDivTop={setScrollToDivTop}
                    />
                  }
                  <Container fluid className="mb-5 p-0">
                    <Row>
                      <Col>
                        <div className={`table-responsive ${styles['cloud-creditNote-history-table']}`}>
                          {initialLoading && serverErrorCloudCreditNote &&
                            <div className={styles["no-data-cloud-creditNote"]}>
                              <img src={EmptyDataTableFilterSearch} className="empty-cloud-creditNote-table-svg" />
                              <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                            </div>
                          }
                          {initialLoading && emptyCloudCreditNoteSearch &&
                            <div className={styles["no-data-cloud-creditNote"]}>
                              <img src={EmptyDataTableFilterSearch} className="empty-cloud-creditNote-table-svg" />
                              <h2 className="mt-4 mb-3">No Credit Notes Found</h2>
                            </div>
                          }
                          {initialLoading && emptyCloudCreditNoteFilters && !emptyCloudCreditNoteSearch &&
                            <div className={styles["no-data-cloud-creditNote"]}>
                              <img src={EmptyDataTableFilterSearch} className="empty-cloud-creditNote-table-svg" />
                              <h2 className="mt-4 mb-3">No Credit Notes Found</h2>
                            </div>
                          }
                          {initialLoading && emptyCloudSummaryReseller && !emptyCloudCreditNoteFilters && !emptyCloudCreditNoteSearch && !emptyCloudCreditNote &&
                            <div className={styles["no-data-cloud-creditNote"]}>
                              <img src={EmptyDataTableFilterSearch} className="empty-cloud-creditNote-table-svg" />
                              <h2 className="mt-4 mb-3">No Credit Notes Found</h2>
                            </div>
                          }
                          {initialLoading && emptyCloudCreditNote &&
                            <div className={styles["no-data-cloud-creditNote"]}>
                              <img src={RaiseTicketIllustration} className="empty-cloud-creditNote-table-svg" />
                              <h2 className="mt-4 mb-3">No Credit Notes Found.</h2>
                            </div>
                          }
                          {!serverErrorCloudCreditNote && !emptyCloudCreditNoteFilters && !emptyCloudCreditNote && !emptyCloudCreditNoteSearch &&
                            <LazyTable
                              d={columns}
                              table={table}
                              customStyles={customStyles}
                              loading={infinityLoading}
                              pageNo={pageNo}
                              totalRows={totalRows}
                              handlePerRowsChange={handlePerRowsChange}
                              handlePageChange={handlePageChange}
                              styles={styles}
                              handleLazyCalls={handleLazyCalls}
                              serverErrorUser={serverErrorCloudCreditNote}
                              emptyUser={emptyCloudCreditNote}
                              emptyUserFilters={emptyCloudCreditNoteFilters}
                              emptyUserSearch={emptyCloudCreditNoteSearch}
                              emptyCloudSummaryReseller={emptyCloudSummaryReseller}
                              setScrollToDivTop={setScrollToDivTop}
                              scrollToDivTop={scrollToDivTop}
                            />}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </div>
          </div>
        </div>}
        {
          isDisabled && <AccessDenied />
        }
      </div>
    </>
  )
}
export default CreditNotesListing