import { useEffect, useState, useRef } from "react";
import "./styles.scss";

const QuantityEditor = ({
    editedQuantity,
    editQuantityRef,
    setEditedQuantity,
    handleIncrease,
    handleDecrease,
    setIsEditingQuantity,
    maxQuantity,
    minQuantity,
    updatedQuanity
}) => {

    const [initQuantity, setInitQuantity] = useState(editedQuantity);

    const qunatityRefBox = useRef();
    useEffect(() => {
        // console.log("initQuantity", initQuantity);
        if (updatedQuanity == editedQuantity) {
            qunatityRefBox.current.className = "number-input";
            setIsEditingQuantity(false)
        } else {
            qunatityRefBox.current.className = "number-input active";
        }
    }, [editedQuantity]);

    return (
        <>
            <div class="number-input" ref={qunatityRefBox}>
                
                <input
                    type="number"
                    className="no-arrows"
                    value={editedQuantity}
                    ref={editQuantityRef}
                    min={minQuantity}
                    max={maxQuantity}
                    onChange={(e) => {
                        // Capture input as a string
                        const inputValue = e.target.value;

                        // Remove leading zeros and convert to number
                        const value = Number(inputValue.replace(/^0+/, '') || 0); // Handle empty string

                        // Update the state with cleaned value
                        setEditedQuantity(value);

                        // Set editing state based on the input
                        if (value === updatedQuanity) {
                            setIsEditingQuantity(false);
                        } else if (value > maxQuantity) {
                            setEditedQuantity(maxQuantity);
                        } else {
                            setIsEditingQuantity(true);
                        }
                    }}
                    onBlur={() => {
                        // When the input loses focus, check for boundaries
                        if (editedQuantity > maxQuantity) {
                            setEditedQuantity(maxQuantity);
                        } else if (editedQuantity < minQuantity) {
                            setEditedQuantity(minQuantity);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.code === 'Minus') {
                            e.preventDefault();
                        }
                    }}
                />

                <div className="btn-wrapper">
                    {maxQuantity && editedQuantity < maxQuantity && editedQuantity >= minQuantity ?
                        <button className="plus" onClick={() => handleIncrease(editedQuantity)
                        }>+</button> :
                        <button className="plus disabled" disabled>+</button>
                    }

                    {minQuantity && editedQuantity > minQuantity ?
                        <button className="minus" onClick={
                            () => handleDecrease(editedQuantity)
                        }> <span>&nbsp;</span> </button>
                        :
                        <button className="minus disabled" disabled><span>&nbsp;</span></button>}

                </div>

            </div>
        </>
    );
}

export default QuantityEditor;