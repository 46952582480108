import React, { useState, useEffect } from 'react';
import './styles.scss'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { COOKIE_POLICY_AUTHENTICATED, COOKIE_POLICY_UNAUTHENTICATED } from '../../../Utils/GlobalConstants';
import { api } from '../../../Utils/API';

const CookiePopup = ({setCookieAccepted,customerIsAuthenticated,Logout}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);

  const userAgent = navigator.userAgent;
  const browserName = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera|Internet Explorer)\//);
  const browserVersion = userAgent.match(/\((.*?)\)/);

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  useEffect(() => {
    const cookie = getCookie('cookieAccepted');
    // console.log("Cookie :",cookie);
    if (cookie) {
      setCookieAccepted(true);
      setShowPopup(false);
    } else {
      setShowPopup(true);
      fetch("https://api.ipify.org?format=json")
        .then(response => response.json())
        .then(data => setIpAddress(data.ip));
      }
      setCookieAccepted(false);
      
      
  }, []);

    // accept Cookie Policy
    async function AcceptCookiePolicy(browser, ipAddress, selectedCookieType ) {
      (ipAddress === undefined || ipAddress === null || ipAddress === "null") ? ipAddress = "" : ipAddress = ipAddress;
      let body = {
        "browserName": browser,
        "ipAddress": ipAddress,
        "selectedCookieType": selectedCookieType,
      }
      if(customerIsAuthenticated===false){
        await api.post(COOKIE_POLICY_UNAUTHENTICATED, body).then((resp) => {
          handleAccept(selectedCookieType);
          // handle success
        })
          .catch((error) => {
            if (error?.status == 401 || error?.response?.status == 401) {
              Logout();
            } else {
    
            }
          });
      }
      else{
        await api.post(COOKIE_POLICY_AUTHENTICATED, body, config).then((resp) => {
          // handle success
          handleAccept(selectedCookieType);
        })
          .catch((error) => {
            if (error?.status == 401 || error?.response?.status == 401) {
              Logout();
            } else {
    
            }
          });
      }
      
    }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const handleAccept = (cookieValue) => {
    document.cookie = `cookieAccepted=${cookieValue}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    setShowPopup(false);
    setCookieAccepted(true);
  };

  const handleDecline = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup && (
        <div className="cookie-popup">
            <Container fluid>
                <Row>
                    <Col md={8}>
                        <h2>Cookie Policy</h2>
                        <p className="mb-0">We use cookies to improve your experience on our websites. By continuing you are giving consent to cookies being used. <span className='text-decoration-underline cpointer text-primary' onClick={()=>window.open("/cookie-policy", '_blank')}>Cookie Policy</span></p>
                    </Col>
                    <Col md={4} className="text-right d-flex align-items-center justify-content-end">

                        <Button onClick={()=>{
                          let browser = browserName[1] + " " + browserVersion[1];
                          AcceptCookiePolicy(browser, ipAddress, "all" );
                          // handleAccept("all");
                        }}
                            className="btn-lg mr-3 mx-3 btn-border-light"
                            variant="light"
                        >
                            Accept All Cookies
                        </Button>
                        <Button onClick={() => {
                          let browser = browserName[1] + " " + browserVersion[1];
                          AcceptCookiePolicy(browser, ipAddress, "required" );
                        }}>Accept Required Cookies Only
                        </Button>
                    </Col>
                </Row>
            </Container>
          {/* <h2>Cookie Policy</h2>
          <p>We use cookies to improve your experience on our websites. By continuing you are giving consent to cookies being used. Cookie Policy</p>
          <button onClick={handleAccept}>Accept All</button>
          <button onClick={() => setCookieType('required')}>Accept Only Required</button>
          <button onClick={handleDecline}>Decline All</button> */}
        </div>
      )}
    </div>
  );
};

export default CookiePopup;

