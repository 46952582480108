import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import styles from './customerSummary.module.scss'
import SubscriptionsRenewal from "./LocalComponents/SubscriptionsRenewal";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PayOutstandingInvoice from "./LocalComponents/Pay Outstanding Invoices";
import SubscriptionWidget from "./LocalComponents/Subscriptions";
import AddWidgets from "./LocalComponents/AddWidgets";
import MonthlySummary from "./LocalComponents/MonthlySummary";
import QuickActions from "./LocalComponents/QuickActions";
import BusinessContact from "./LocalComponents/BusinessContact";
import SubscriptionsOverview from "./LocalComponents/SubscriptionsOverview";
import { GET_SERVICES } from "../../../../../Utils/GlobalConstants";
import { api } from "../../../../../Utils/API";

const CustomerSummary = (props) => {

    const [widgetPositionData, setWidgetPositionData] = useState([               // widgets position 
        {
            "id": "1",
            "widgetName": "Unpaid Invoice Summary"
        },
        {
            "id": "2",
            "widgetName": "Subscriptions"
        },
        {
            "id": "3",
            "widgetName": "Subscriptions Renewal"
        },
        {
            "id": "4",
            "widgetName": "Subscriptions Overview"
        }
    ]);
    const [masterWidget, setMasterWidget] = useState([]);
    const [addWidgetPopup, setAddWidgetPopup] = useState(false);
    const [serviceData, setServiceData] = useState([null])

    const ItemTypes = {
        CARD: 'card',
    };

    const Card = ({ id, widget, index, moveItem }) => {
        const ref = React.useRef(null);
        const [, drop] = useDrop({
            accept: ItemTypes.CARD,
            hover(item, monitor) {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }
                moveItem(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        });

        const [{ isDragging }, drag] = useDrag({
            type: ItemTypes.CARD,
            item: { id, index },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        });

        drag(drop(ref));

        if (widget === "Subscriptions Renewal") {
            return (
                <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }} key={id} className={styles["widget-body"]}><SubscriptionsRenewal serviceData={serviceData} /></div>
            );
        }
        else if (widget === "Unpaid Invoice Summary") {
            return (
                <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                    <div key={id} className={styles["widget-body"]}> <PayOutstandingInvoice customerCode={props.customerCode} Logout={props.Logout} serviceData={serviceData} /></div>
                </div>
            );
        }
        else if (widget === "Subscriptions") {
            return (
                <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                    <div key={id} className={styles["widget-body"]}> <SubscriptionWidget Logout={props.Logout} serviceData={serviceData} /></div>
                </div>
            );
        }
        else if (widget === "Subscriptions Overview") {
            return (
                <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
                    <div key={id} className={styles["widget-body"]}> <SubscriptionsOverview Logout={props.Logout} serviceData={serviceData} /></div>
                </div>
            );
        }
        else {
            return (
                <div style={{ opacity: isDragging ? 0.5 : 1, cursor: 'pointer' }}>
                    <div key={id} className={styles["widget-body"]}>Development in Progress . . .</div>
                </div>
            );
        }

    };

    const moveItem = (dragIndex, hoverIndex) => {
        const draggedItem = widgetPositionData[dragIndex];
        const newwItems = [...widgetPositionData];
        newwItems.splice(dragIndex, 1);
        newwItems.splice(hoverIndex, 0, draggedItem);
        setWidgetPositionData(newwItems);
    };

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    async function GetAllServices() {
        await api.get(`${GET_SERVICES}`, config)
            .then(resp => {
                setServiceData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    useEffect(() => {
        GetAllServices();
    }, []);

    return (
        <>
            <>
                <AddWidgets addWidgetPopup={addWidgetPopup} setAddWidgetPopup={setAddWidgetPopup} masterWidget={masterWidget} widgetPositionData={widgetPositionData} />
                <Row className="mt-4">
                    <Col lg={3} md={4} className={`${styles['left-widgets-ipad-pro']}`}>
                        <MonthlySummary Logout={props.Logout} />
                        <QuickActions setOpen={props.setOpen} customerName={props.customerName} />
                        <BusinessContact Logout={props.Logout} />

                    </Col>
                    <Col lg={9} md={8} className={`${styles['right-widgets-ipad-pro']}`}>
                        <DndProvider backend={HTML5Backend}>
                            {widgetPositionData.reduce((rows, item, index) => {
                                if (index % 2 === 0) {
                                    rows.push([]);
                                }
                                rows[rows.length - 1].push(
                                    <Col lg={6} md={12} key={item.id} className={`${styles['individual-widgets-ipad-pro']}`}>
                                        <Card id={item.id} widget={item.widgetName} index={index} moveItem={moveItem} />
                                    </Col>
                                );
                                return rows;
                            }, []).map((row, rowIndex) => (
                                <Row key={rowIndex}>
                                    {row}
                                </Row>
                            ))}
                        </DndProvider>
                    </Col>
                </Row>
            </>
        </>
    );
}

export default CustomerSummary;