import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { api } from '../../../Utils/API';
import { DASHBOARD_SUBS_RENEW_DOWNLOAD } from '../../../Utils/GlobalConstants';
import RenewalSettingsTab from './LocalComponents/RenewalSettingsTab';
import SendNotificationsTab from './LocalComponents/SendNotifications';
import styles from './subscriptionRenewal.module.scss';

const SubscriptionsRenewal = ({ Logout }) => {
    const [tabName, setTabName] = useState("Renewal Settings");
    const [selectedItem2, setSelectedItem2] = useState('Total'); 
    const [serviceId, setServiceId] = useState('');
    const [integrationCode, setIntegrationCode] = useState('');
    const [isDisabledRenewalSettings, setIsRenewalSettings] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Subscriptions Renewal") ? true : false);
    const [isReadRenewalSettings, setIsReadRenewalSettings] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Subscriptions Renewal") ? true : false);
    const date = new Date();

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });
        const a = document.createElement('a');
        a.download = fileName;
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data);
        } else {
            a.href = window.URL.createObjectURL(blob);
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };


    const handleStateChange = (values) => {
        setSelectedItem2(values.selectedItem2);
        setServiceId(values.serviceId);
        setIntegrationCode(values.integrationCode);
    };

    // Download CSV File 
    const handleDownloadReport = async () => {
        try {
            const response = await api.get(
                `${DASHBOARD_SUBS_RENEW_DOWNLOAD}?serviceId=${serviceId}&type=${selectedItem2}&integrationCode=${integrationCode}`, 
                configBlob
            );

            if (response.status === 204) {
                // Handle no data case if needed
                console.log("No data available to download");
            } else {
                downloadFile({
                    data: response.data,
                    fileName: `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_subscriptions-renewal.csv`,
                    fileType: "application/csv",
                });
            }
        } catch (error) {
            if (error?.status === 401 || error?.response?.status === 401) {
                Logout();
            }
            console.error("Download failed:", error);
        }
    };

    return (
        <div className='main-content'>
            <Tab.Container id="main-tab-bar" defaultActiveKey="Agreement Mapping">
                <div className={`mgmt-console-table-row mobile-padding ${styles['integrations-mobile-padding']}`}>
                    <div className="mb-3 d-flex">
                        <Col className={`py-0 overflow-auto ${styles['profile-table']}`}>
                            <Tab.Container activeKey={tabName}>
                                <Row>
                                    {!isDisabledRenewalSettings && <Col sm={9}>
                                        <Nav variant="pills">
                                            <Nav.Item>
                                                <Nav.Link 
                                                    eventKey="Renewal Settings" 
                                                    className='addons-core-gws-manage pl-6 pr-6 pt-3 pb-3'
                                                    onClick={() => { setTabName("Renewal Settings") }}
                                                >
                                                    Renewal Settings
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link 
                                                    eventKey="Send Notifications" 
                                                    className='addons-core-gws-manage pl-6 pr-6 pt-3 pb-3'
                                                    onClick={() => { setTabName("Send Notifications") }}
                                                >
                                                    Send Notifications
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>}
                                    {/* <Col sm={3}>
                                        <div className='text-right'>
                                            <Button
                                                variant="light"
                                                className="mr-1 px-4 btn-border-light w-md-auto col-xs-12"
                                                onClick={handleDownloadReport}
                                            >
                                                Download Report
                                            </Button>
                                        </div>
                                    </Col> */}
                                    <Col lg={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Renewal Settings">
                                                <RenewalSettingsTab
                                                    Logout={Logout}
                                                    onStateChange={handleStateChange}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Send Notifications">
                                                <SendNotificationsTab />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </div>
                </div>
            </Tab.Container>
        </div>
    );
};

export default SubscriptionsRenewal;