import React, { useState, useEffect } from 'react'
import { Col, Form, NavDropdown, Row } from 'react-bootstrap';
import styles from './invoiceAmount.module.scss'
import { DASHBOARD_INVOICE_AMOUNT, DASHBOARD_INVOICE_AMOUNT_DOWNLOAD_REPORT, GET_SERVICES } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Toast from '../../../../../GlobalComponents/Toast';
import { LineChart } from '@mui/x-charts';
import Summary from './LocalComponents/Summary';

const InvoiceAmmount = ({ SaveDashboardWidget, id, widgetPositionData, Logout }) => {
    const date = new Date();                                                    // for js date time function

    const [lineChartData, setLineChartData] = useState(null)
    const [summaryPopup, setSummaryPopup] = useState(false);                    // summary popup
    const [tabName, setTabName] = useState("Summary");
    const [filterType, setFilterType] = useState('This Year');                  // dropdown filter filter type
    const [serviceSelected, setServiceSelected] = useState('All Services');                  // dropdown filter filter type
    const [lineChartDataSeries, setLineChartDataSeries] = useState(null);             // used for widgets data
    const [serviceId, setServiceId] = useState("");                                   // getTableData() service id
    const [invoiceDateFrom, setInvoiceDateFrom] = useState(null);                       // getTableData() invoice from date
    const [invoiceDateTo, setInvoiceDateTo] = useState(null);                            // getTableData() invoice to date

    // Use State for Toasts
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        let urls = '';
        if (serviceId == "")
            urls = `${url}?duration=${filterType}`
        else
            urls = `${url}/${serviceId}?duration=${filterType}`

        await api.get(urls, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    async function GetAllServices(selectedServiceName) {
        await api.get(`${GET_SERVICES}`, config)
            .then(resp => {
                resp.data.map((service) => {
                    if (service.name === selectedServiceName) {
                        setServiceSelected(service.name);
                        setServiceId(service.id);
                    }
                })
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // function for dropdown data
    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (eventKey === "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            setInvoiceDateFrom("01/01/" + date.getFullYear());
            setInvoiceDateTo(fromDate);
        }
        else {
            // console.log("Event Key : ", eventKey, `01/01/${date.getFullYear() - 1}`, `31/12/${date.getFullYear() - 1}`);
            setInvoiceDateFrom(`01/01/${date.getFullYear() - 1}`);
            setInvoiceDateTo(`31/12/${date.getFullYear() - 1}`);
        }
    };

    // Define a function to handle selecting a service from the dropdown
    const handleSelectService = (selectedServiceName) => {
        if (selectedServiceName === "All Services") {
            // If "All Services" is selected, set service id to empty and integration code to empty string
            setServiceSelected("All Services");
            setServiceId("");
        } else {
            // If a specific service is selected, fetch its details
            GetAllServices(selectedServiceName);
        }
    };

    // Function to delete widget in dashboard
    async function DeleteWidget() {
        let body = [];
        widgetPositionData.map((wd) => {
            if (wd.widgetName !== "add" && wd.id !== id) {
                body.push(wd);
            }
        })
        SaveDashboardWidget(body, true);
    };


    // Function to call invoice amount widget data
    async function GetInvoiceAmountData() {
        await api.get(`${DASHBOARD_INVOICE_AMOUNT}?duration=${filterType}&service=${serviceSelected}`, config)
            .then(resp => {
                setLineChartData(resp.data.graphData)
                setLineChartDataSeries(resp.data.xAxisData)
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    useEffect(() => {
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (filterType === "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            setInvoiceDateFrom("01/01/" + date.getFullYear());
            setInvoiceDateTo(fromDate);
        }
        else {
            setInvoiceDateFrom(`01/01/${date.getFullYear() - 1}`);
            setInvoiceDateTo(`31/12/${date.getFullYear() - 1}`);
        }
        GetInvoiceAmountData();
    }, [serviceSelected, filterType]);

    return (
        <>
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
            <Row className={`${styles["widget-header"]} align-items-center pt-0`}>
                <Col md={6} xs={6}>
                    <h3 className='mb-0'>Invoice Amount</h3>
                </Col>
                <Col md={6} xs={6} className="text-right">
                    <span className="dashboard-arrow mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Summary"); }}></span>
                    <span className="dashboard-circle-dot mx-1 cpointer d-none-mobile" onClick={() => { setSummaryPopup(true); setTabName("Details") }}></span>
                    <span className="dashboard-download mx-1 cpointer d-none-mobile"
                        onClick={() => { DownloadAction(DASHBOARD_INVOICE_AMOUNT_DOWNLOAD_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_invoice-amount.csv`, "csv") }}>
                    </span>
                    <span className="dashboard-cross mx-1 cpointer" onClick={() => DeleteWidget()}></span>
                </Col>
                {
                    summaryPopup &&
                    <Summary setSummaryPopup={setSummaryPopup} summaryPopup={summaryPopup} tabName={tabName} setTabName={setTabName} filterType={filterType} setFilterType={setFilterType} serviceSelected={serviceSelected} setServiceSelected={setServiceSelected}
                        serviceId={serviceId} setServiceId={setServiceId} invoiceDateFrom={invoiceDateFrom} setInvoiceDateFrom={setInvoiceDateFrom} invoiceDateTo={invoiceDateTo} setInvoiceDateTo={setInvoiceDateTo} Logout={Logout}
                    />
                }
            </Row>
            <Row className={`pt-2 pb-0 ${styles["widget-header"]} `}>
                <Col lg={2} md={4} xs={1}>
                </Col>
                <Col lg={4} md={4} xs={4}>
                    <Form.Group>
                        <NavDropdown title={filterType} id="cloud-service-dropdown-2" className={`servcies-dropdown ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setFilterType)}>
                            <NavDropdown.Item
                                eventKey="Last Year"
                                className={filterType === "Last Year" ? "selected" : ""}
                            >Last Year</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="This Year"
                                className={filterType === "This Year" ? "selected" : ""}
                            >This Year</NavDropdown.Item>
                        </NavDropdown>
                    </Form.Group>
                </Col>

                <Col lg={6} md={4} xs={7}>
                    <Form.Group>
                        <NavDropdown title={serviceSelected} id="cloud-service-dropdown-3" className={`servcies-dropdown-sm services-dropdown-sm cpointer ${styles['dropdown-disabled']}`} onSelect={handleSelectService}>
                            <NavDropdown.Item
                                eventKey="All Services"
                                className={serviceSelected === "All Services" ? "selected" : ""}
                            >All Services</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="Microsoft CSP"
                                className={serviceSelected === "Microsoft CSP" ? "selected" : ""}
                            >Microsoft CSP</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="Microsoft CSP - Azure"
                                className={serviceSelected === "Microsoft CSP - Azure" ? "selected" : ""}
                            >Microsoft CSP - Azure</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="Google Workspace"
                                className={serviceSelected === "Google Workspace" ? "selected" : ""}
                            >Google Workspace</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="Google Cloud"
                                className={serviceSelected === "Google Cloud" ? "selected" : ""}
                            >Google Cloud</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="Acronis Cyber Cloud"
                                className={serviceSelected === "Acronis Cyber Cloud" ? "selected" : ""}
                            >Acronis Cyber Cloud</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="Avast CloudCare"
                                className={serviceSelected === "Avast CloudCare" ? "selected" : ""}
                            >Avast CloudCare</NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey="IBM Cloud"
                                className={serviceSelected === "IBM Cloud" ? "selected" : ""}
                            >IBM Cloud</NavDropdown.Item>
                        </NavDropdown>
                    </Form.Group>
                </Col>
            </Row>
            {lineChartData &&
                <LineChart
                    series={lineChartData}
                    grid={{ horizontal: true, vertical: true }}
                    labelRotation={45}
                    slotProps={{
                        legend: {
                            direction: 'row',
                            position: { vertical: 'bottom', horizontal: 'middle' },
                            padding: 0,
                            labelStyle: {
                                fill: '#6a6a6a'
                            }
                        },
                    }}
                    colors={['hsla(204, 69%, 53%, 0.17)', '#F9A932']}
                    sx={{
                        [`.MuiAreaElement-root`]: {
                            opacity: 0.5,
                        }
                    }}
                    xAxis={[{
                        data: lineChartDataSeries,
                        scaleType: 'band',
                        tickPlacement: 'middle',
                        categoryGapRatio: 0.6,
                        tickLabelPlacement: 'middle',
                    }]}
                    yAxis={[{
                        valueFormatter: (value) => value > 1000 ? `$${(value / 1000).toLocaleString()}K` : `$${value}`,
                    }]}
                    margin={{ top: 10, bottom: 50, left: 50, right: 50 }}
                />}
        </>
    )
}

export default InvoiceAmmount
