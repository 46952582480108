import React, { useEffect, useRef, useLayoutEffect, useState } from 'react'
import { api } from '../../../../../../Utils/API';
import { CSP_PRODUCT_PLANS, CSP_BILLING_TERMS_TYPE, GET_FLAVOR_CATEGORY, CSP_PRODUCT_ORDERING, GET_GST_RATES, CSP_SERVICE_MANAGEMENT_ACCOUNT_DETAILS, ACCEPT_LOGIN_TERMS_AND_CONDITIONS, CSP_LEGACY_BILLING_TERM } from '../../../../../../Utils/GlobalConstants';
import { Col, Container, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip, Button } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import styles from './CSPProductDetail.module.scss';
import EmptyDataTableFilterSearch from '../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import SearchDropdown from '../../../SearchDropdown';
import loadingGif from "../../../../../../Assets/Images/Animations/loading-management-console.gif";
import { alphanumericRegexWithHyphen } from '../../../../../../GlobalComponents/RegExPatterns';
import { useLocation, useNavigate } from 'react-router-dom';
import CSPOrderConfirmationModalHeader from './LocalComponents/modalHeader';
import CoTermCloudProvsion from './LocalComponents/CoTermCloudProvision';
import CoTermAlignEndDate from './LocalComponents/CotermAlignEndDate';
import SearchBoxLegacyPlanDropdown from './LocalComponents/SearchBoxLegacyPlanDropdown';

const CSPProductDetail = ({ productId, serviceId, isNce, categoryIdCsp, isPrerpectual, customerId, customerName, setSelectCustomerErrorState, customerPrerequisite, checkResellerPrereqisiteFlag, checkResellerPrereqisiteExpiryFlag, setPlanSelectedFlag, setToastMessage, setToastError, isreadOnly, isMCACompleted, billingPaymentGatewayData, setBillingPaymentGatewayErrorMessage, setBillingPaymentGatewayData, billingPaymentCardExpiryFlag, billingPaymentDirecrDebitExpiryFlag, setBillingPaymentGatewayErrorPopup, Logout, proceedToProvisionFlag }) => {
  const navigate = useNavigate();                                                 // to use navigate function from react router dom
  let CSPProductDetailColumnName = ["Plan", "SKU ID", "Commitment Term (Billing Frequency)", "Category", "RRP (Inc GST)", "Price (Ex GST)", "Add Quantity"]; // Normal Product Columns
  let ReadOnlyCSPProductDetailColumnName = ["Plan", "SKU ID", "Commitment Term (Billing Frequency)", "Category", "RRP (Inc GST)", "Price (Ex GST)"]; // Normal Product Columns
  let CSPProductDetailColumnNamePerpetual = ["Plan", "SKU ID", "Category", "RRP (Inc GST)", "Price (Ex GST)", "Add Quantity"];  // Perpetua Columns
  let ReadOnlyCSPProductDetailColumnNamePerpetual = ["Plan", "SKU ID", "Category", "RRP (Inc GST)", "Price (Ex GST)"];  // Perpetua Columns
  const [productDetailsData, setProductDetailsData] = useState([]); // Product Details keeps udpated on every action
  const [initProductDetailsData, setInitProductDetailsData] = useState([]); // Iitial Product Details to reset data
  const [loading, setLoading] = useState(false);  // Set Loading State
  const [emptyData, setEmptyData] = useState(false);  // Empty Data
  const [serverError, setServerError] = useState(false);  // Server Error 
  const [initialSubscriptionTableCall, setInitialSubscriptionTableCall] = useState(null); // Used for Search Dropdowns
  const [searchValueTable, setSearchValueTable] = useState(null);                     // storing searching value in the search input box
  const [searchIconFlag, setSearchIconFlag] = useState(false);                        // to open and close search box
  const searchInputRef = useRef();                                                    // Search Input Box

  // Billnig Term Dropdown Values
  const [billingTermDefaultValue, setBillingTermDefaultValue] = useState("");
  const [billingTermSearchValue, setBillingTermSearchValue] = useState();
  const [billingTerm, setBillingTerm] = useState([]);
  const [billingTermFiltered, setBillingTermFiltered] = useState(billingTerm);
  const updatedBillingTerm = [];
  const [billingTermId, setBillingTermId] = useState(null);

  // Category Dropdown Values
  const [categoryDefaultValue, setCategoryDefaultValue] = useState(categoryIdCsp);
  const [categorySearchValue, setCategorySearchValue] = useState();
  const [category, setCategory] = useState([]);
  const [categoryFiltered, setCategoryFiltered] = useState(category);
  const updatedCategory = [];
  const [categoryId, setCategoryId] = useState(null);

  /* ******************  Quantity Editor Block ********************** */
  const [selectedAddOn, setSelectedAddOn] = useState();                       // Selected Row
  const [activeQtyCheckFlag, setActiveQtyCheckFlag] = useState(false);        // Shows green/grey tick if it's truw
  const [newQuantity, setNewQuantity] = useState(1);  // On click set Quantity to 1 or minimum quantity
  const [selectedRowId, setSelectedRowId] = useState([]); // All Selected Row ID s stored here
  const [selectedPlan, setSelectedPlan] = useState([]); // All selected plans stored here and can be displayed in Confirmation Table 

  // Min and Max Quantity of a particualr row. Will be set on doing edit quantity
  const [minQuantity, setMinQuantity] = useState(null);
  const [maxQuantity, setMaxQuantity] = useState(null);

  const [cspProductDetailsPopupModalOpen, setCSPProductDetailsPopupModalOpen] = useState(false);  // Order Confirmation Model Opens
  const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);  // Order Confirmation Model Terms check
  const [poNumber, setPONumber] = useState(""); // PO Number
  const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false) // Subscription is Loading Popup
  const [basePrice, setBasePrice] = useState(0);  // Order Confirmation Model Base Price
  const [gstAmount, setGstAmount] = useState(0);  // Order Confirmation Model GST
  const [totalWithGST, setTotalWithGST] = useState(0);  // Order Confirmation Model Total With GST
  const [gstPercentage, setGstPercentage] = useState(null); // Order Confirmation GST Percentage
  const [orderConfirmationSuccess, setOrderConfirmationSuccess] = useState(false);  // Order Confirmation Model Success
  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);  // Order Processing status

  // CoTerm 
  const [isProvisionsReviewDone, setIsProvisionsReviewDone] = useState(false);
  const [alignedDateArrayData, setAlignedDateArrayData] = useState([]);
  const [isCoTermAlignEndateDone, setIsCoTermAlignEndateDone] = useState(false);
  const [isCoTermSelection, setIsCoTermSelection] = useState(false);
  const [vendorAccountId, setVendorAccountId] = useState(null);

  const [step1Progress, setStep1Progress] = useState(true);
  const [step2Progress, setStep2Progress] = useState(false);
  const [step3Progress, setStep3Progress] = useState(false);

  const [cspCOTermCompleted, setCspCOTermCompleted] = useState(false);

  const [poPlans, setPOPlans] = useState([]); // Purchase Order Object for Post. Contains only plan Id and Quantity
  const [isProcessing, setIsProcessing] = useState(false);    // Is Processing for Order Confirmation Button

  // Order Confirmation Model Success Popup Details
  const [poConfirmation, setPOConfirmation] = useState({
    isSuccess: true,
    message: "",
    orderCode: "",
    orderId: 0,
    errorMessages: []
  });


  // Reseller and Customer Prereuisites Modal Details
  const [customerPrerequisiteModalUpen, setCustomerPrerequisiteModalUpen] = useState(false);
  const [resellerPrerequisiteModalUpen, setResellerPrerequisiteModalUpen] = useState(false);

  const [ipAddress, setIpAddress] = useState(null);
  const userAgent = navigator.userAgent;
  const browserName = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera|Internet Explorer)\//);
  const browserVersion = userAgent.match(/\((.*?)\)/);

  // Edit Quantity Increase 
  const handleIncrease = (qty) => {
    if (parseInt(newQuantity) <= maxQuantity) {
      setNewQuantity(parseInt(qty) + 1);
    } else {
      setNewQuantity(qty);
    }
  }

  // Edit Quantity Decrease
  const handleDecrease = (qty) => {
    if (parseInt(newQuantity) >= minQuantity) {
      setNewQuantity(parseInt(qty) - 1);
    } else {
      setNewQuantity(qty);
    }
  }

  // *******************  Quantity Editor Block ************************ //

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  const [isPlanSelectedSticky, setIsPlanSelectedSticky] = useState(false);
  const [tableWidth, setTableWidth] = useState();
  const tableRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const tableElement = tableRef.current;
      if (tableElement) {
        const tableRect = tableElement.getBoundingClientRect();
        const isTablePartiallyVisible =
          tableRect.top < window.innerHeight && tableRect.bottom > window.innerHeight;
        setIsPlanSelectedSticky(isTablePartiallyVisible);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [selectedPlan]);

  useEffect(() => {
    if (selectedRowId.length > 0) {
      setTimeout(() => {
        const element = document.getElementById('csp-plan-selected-component');
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 10);
    }
  }, [selectedRowId]);

  useLayoutEffect(() => {
    const calculateTableWidth = () => {
      if (tableRef.current) {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 380) {
          setTableWidth('83%');
        } else if (screenWidth <= 480) {
          setTableWidth('86%');
        } else if (screenWidth <= 580) {
          setTableWidth('88%');
        } else if (screenWidth <= 680) {
          setTableWidth('91%');
        } else if (screenWidth <= 992) {
          setTableWidth('68%');
        } else {
          setTableWidth(`${tableRef.current.offsetWidth}px`);
        }
      }
    };

    calculateTableWidth();
  }, [selectedPlan]);

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 380) {
          setTableWidth('83%');
        } else if (screenWidth <= 480) {
          setTableWidth('86%');
        } else if (screenWidth <= 580) {
          setTableWidth('88%');
        } else if (screenWidth <= 680) {
          setTableWidth('91%');
        } else if (screenWidth <= 992) {
          setTableWidth('68%');
        } else {
          setTableWidth(`${tableRef.current.offsetWidth}px`);
        }
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // function to call all additional details 
  async function GetAdditionalDetails() {
    await api.get(`${CSP_SERVICE_MANAGEMENT_ACCOUNT_DETAILS}?customerId=${customerId}`, config)
      .then(resp => {
        // console.log("Data : ", resp.data);
        //  setAdditionalDetails(resp.data);
        setVendorAccountId(resp.data.vendorAccountId)

      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          //   setInitialSubscriptionTableCall(false);
          // console.log(error);
        }

      });
  };


  // Get Table Data with Pagination and Filter and Search Functionality
  async function GetTableData(productId, searchValueTable, billingTermId, categoryId
    // , pageNo, pageSize, sortCol, sortDir
  ) {
    setLoading(true);
    (productId === undefined || productId === null) ? productId = "" : productId = productId;
    (billingTermId === undefined || billingTermId === null) ? billingTermId = "" : billingTermId = billingTermId;
    (searchValueTable === undefined || searchValueTable === null) ? searchValueTable = "" : searchValueTable = searchValueTable;
    (categoryId === undefined || categoryId === null) ? categoryId = "" : categoryId = categoryId;
    await api.get(`${CSP_PRODUCT_PLANS}?productId=${productId}&searchText=${searchValueTable}&billingTerm=${billingTermId}&category=${categoryId}`, config)
      .then(resp => {
        setLoading(false);
        if (resp.status == 204) {
          setEmptyData(true);
        }
        // handle success
        else {
          setEmptyData(false);
          // console.log("listing:", resp.data);
          setProductDetailsData(resp.data);
          setInitProductDetailsData(resp.data);
        }
      })
      .catch(error => {
        // console.log(error);
        setLoading(false);
        setEmptyData(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          setServerError(true);
        }
      });
  };

  // Function to call all dropdown values in Commitment Term (Billing Frequency) NCE Section in Filter Box
  async function GetAllBillingTermNce() {
    await api.get(CSP_BILLING_TERMS_TYPE, config)
      .then(resp => {
        // console.log("Data : ", resp.data);
                  // .filter(item => item.id !== "Trial (Monthly)")

        let intermediateBillingTerm = [];
        if (isNce) {
          resp.data.map((billingItem) => {    
            if (billingItem.id.includes("-"))
              {
                // if (billingItem.id === "Trial-Monthly") {
                //   billingItem.name = "Trial (Monthly)";         // Remove this Change once provisioning label Commitment Term (Billing Frequency) is changed (Temporary Chnage)
                // }  
                // if (billingItem.id === "Monthly-Yearly"){
                //     billingItem.name = "Monthly (Yearly)";         // Remove this Change once provisioning label Commitment Term (Billing Frequency) is changed (Temporary Chnage)
                //   }  
              intermediateBillingTerm.push(billingItem);
            }
          })
        }
        // else {
        //   resp.data.map((billingItem) => {
        //     if (!(billingItem.id.includes("-"))) {
        //       intermediateBillingTerm.push(billingItem);
        //     }
        //   })
        // }
        // console.log(intermediateBillingTerm)

        setBillingTerm(intermediateBillingTerm);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

   // Function to call all dropdown values in Commitment Term (Billing Frequency)Legacy Section in Filter Box
   async function GetAllBillingTermLegacy() {
    await api.get(CSP_LEGACY_BILLING_TERM, config)
      .then(resp => {
        let intermediateBillingTerm = [];
        
        resp.data.map((billingItem) => {
          if (billingItem.id === "Monthly") {
            intermediateBillingTerm.push({ ...billingItem, id: "Yearly (Monthly)" });
          } else {
            intermediateBillingTerm.push({ ...billingItem, id: "Yearly (Yearly)" });
          }
        });       

        setBillingTerm(intermediateBillingTerm);
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          // console.log(error);
        }
      });
  };

  // Function to call all dropdown values in Category Section in Filter Box
  async function GetAllCategory() {
    await api.get(`${GET_FLAVOR_CATEGORY}?productId=${productId}&serviceId=${serviceId}`, config)
      .then(resp => {
        // console.log("Data : ", resp.data);
        setCategory(resp.data);
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          // console.log(error);
        }
      });
  };


  // Delete the row from Order Confirmation Box and Updated Quantity to '0' in main table
  const handleDeleteRow = (index) => {
    // console.log("Plan Id", selectedPlan[index].planId);
    const updatedPlan = [...selectedPlan];

    updatedPlan.splice(index, 1);
    setSelectedPlan(updatedPlan);
    // console.log(selectedPlan);

    const updatedPOConfirmationBody = [...alignedDateArrayData];
    updatedPOConfirmationBody.splice(index, 1);
    setAlignedDateArrayData(updatedPOConfirmationBody);

    // console.log("Po", poConfirmationBody);

    setSelectedRowId(selectedRowId.filter(id => id != selectedPlan[index].planId));


    // Find the index of the object with planId = "68167"
    const indexToUpdate = productDetailsData.findIndex(item => item.planId === selectedPlan[index].planId);

    // Make sure the index is valid before updating
    if (indexToUpdate !== -1) {
      // Create a copy of the productDetailsData array
      const updatedProductDetails = [...productDetailsData];

      // Update the quantity of the object at the specified index
      updatedProductDetails[indexToUpdate] = {
        ...updatedProductDetails[indexToUpdate],
        quantity: 0
      };

      // Set the updated array using setProductDetailsData
      setProductDetailsData(updatedProductDetails);
    } else {
      console.error("Object not found with planId");
    }
  };

  function generateRandomIp() {
    // Generate random IP address components
    const octets = [];
    for (let i = 0; i < 4; i++) {
      octets.push(Math.floor(Math.random() * 256).toString());
    }

    // Join the octets with dots to form the IP address
    const ipAddress = octets.join(".");

    return ipAddress;
  }

  // Terms and Condition accept Popup Data
  async function TermsAndConditionAcceptFunction(browser, ipAddress) {
    (ipAddress === undefined || ipAddress === null || ipAddress === "null") ? ipAddress = generateRandomIp() : ipAddress = ipAddress;
    let body = {
      "browser": browser,
      "ipAddress": ipAddress,
      "module": "Cloud",
    }
    await api.post(ACCEPT_LOGIN_TERMS_AND_CONDITIONS, body, config).then((resp) => {
      // handle success
      // console.log(resp.data);
      localStorage.setItem("cloud_terms_and_conditions", "true");
      setIsTermsChecked(true);
    })
      .catch((error) => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
      });
  }

  useEffect(() => {
    if (!isTermsChecked) {
      fetch("https://api.ipify.org?format=json")
        .then(response => response.json())
        .then(data => setIpAddress(data.ip));
    }
  }, [isTermsChecked])


  // Replace Array from existing seletcedPlans to Production Details Array while changing category dropdown
  useEffect(() => {
    if (productDetailsData.length > 0) {
      // Make a copy of the productDetailsData array
      let updatedProductDetailsData = [...productDetailsData];

      // Iterate through the selectedPlan array
      selectedPlan.forEach(selected => {
        // Find the index of the selected plan in productDetailsData
        const index = updatedProductDetailsData.findIndex(item => item.planId === selected.planId);

        // If the planId exists in productDetailsData, update its quantity
        if (index !== -1) {
          updatedProductDetailsData[index].quantity = selected.quantity;
        } else {
          // If the planId doesn't exist, add it to the productDetailsData array
          updatedProductDetailsData.push(selected);
        }
      });

      // Now updatedProductDetailsData contains updated quantities and new plans if they didn't exist before
      setProductDetailsData(updatedProductDetailsData);
    }

  }, [initProductDetailsData]);

  // Get Updated Table Details if Product or Category is Updated
  useEffect(() => {
    if (categoryIdCsp) {
      if (productId) {
        setCategoryId(categoryIdCsp);
        if (isPrerpectual) {
          GetTableData(productId, searchValueTable, null, categoryIdCsp);
        }
        else {
          if (isNce) {
            setBillingTermId("Monthly-Monthly");
            setBillingTermDefaultValue("Monthly (Monthly)");
            GetTableData(productId, searchValueTable, "Monthly-Monthly", categoryIdCsp);
            GetAllBillingTermNce();
          }
          else {
            setBillingTermId("Monthly");
            setBillingTermDefaultValue("Yearly (Monthly)");
            GetTableData(productId, searchValueTable, "Monthly", categoryIdCsp);
            GetAllBillingTermLegacy();
          }
        }
      }
    }
  }, [productId, categoryIdCsp]);

  // Get All Category 

  useEffect(() => {
    if (productId && serviceId) {
      GetAllCategory();
    }
  }, [productId, serviceId])

  // Search Box Focus on Open
  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();
  }, [searchIconFlag]);

  // dropdown data call
  useEffect(() => {
    if (billingTermId || categoryId) {
      GetTableData(productId, searchValueTable, billingTermId, categoryId)
    }
  }, [billingTermId, categoryId]);

  // On search field value we trigger this function having react debugging after every 800 ms
  useEffect(() => {
    if (searchValueTable !== undefined && searchValueTable !== null) {
      const getData = setTimeout(() => {
        GetTableData(productId, searchValueTable, billingTermId, categoryId)
      }, 800);
      return () => clearTimeout(getData);
    }
  }, [searchValueTable]);



  // Order Confirmation Popup Base Price, GST and Total updated
  useEffect(() => {
    if (gstPercentage) {
      const gstPercent = parseInt(gstPercentage) / 100;
      // console.log("GST PERCENTAGE : ", gstPercent);
      // Calculate base price by multiplying quantity and price for each item in selectedPlan
      const totalBasePrice = selectedPlan.reduce((accumulator, item) => {
        return accumulator + (item.quantity * item.price);
      }, 0);

      setBasePrice(totalBasePrice.toFixed(2));

      // Calculate GST (assume 10% GST rate)
      const gst = totalBasePrice * gstPercent;
      setGstAmount(gst.toFixed(2));

      // Calculate total with GST
      const total = totalBasePrice + gst;
      setTotalWithGST(total.toFixed(2));
    }
  }, [selectedPlan, productDetailsData, cspProductDetailsPopupModalOpen, gstPercentage]);

  // Function to get GST RATE
  async function GetGST() {
    await api.get(GET_GST_RATES, config)
      .then(resp => {
        // console.log("Data : ", resp.data.gst);
        setGstPercentage(resp.data.gst);
      })
      .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
          // console.log(error);
        }
      });
  };



  const [resetData, setResetData] = useState(false);  // Clear button sets this to "true"

  // 
  const resetTableData = () => {
    const updatedAddOnTable = productDetailsData.map(obj => ({ ...obj, quantity: 0 }));
    setProductDetailsData(updatedAddOnTable);
    setSelectedRowId([]);
    setSelectedPlan([]);
    setCSPProductDetailsPopupModalOpen(false);

    setPONumber("");
    setNewQuantity(1);
    setSelectedAddOn("");
    setResetData(true);
    setIsCoTermSelection(false);
    setStep1Progress(false)
    setStep2Progress(false)
    setStep3Progress(false);
    setIsProvisionsReviewDone(false);
    setPoPlansError([{
      plandId: "",
      errorMessage: ""
    }])
  }

  // Activates if resetData sets to "True"
  useEffect(() => {
    resetData === true && (
      <>
        {resetTableData()}
        {setResetData(false)}
      </>

    )

  }, [resetData]);

  useEffect(() => {
    // console.log("initProductDetailsData", initProductDetailsData);
    // console.log("productDetailsData", productDetailsData);

  }, []);

  useEffect(() => {
    // console.log("isNce", isNce);
  }, [isNce])



  useEffect(() => {


    // const updatedPoPlans = selectedPlan.map(({ planId, quantity }) => ({ planId, quantity }));

    let updatedPoPlans = selectedPlan.map(poPlan => {
      return {
        planId: poPlan.planId,
        quantity: poPlan.quantity
      }
    });
    // console.log("updatedPoPlans", updatedPoPlans);
    setPOPlans(updatedPoPlans);
    // console.log("poPlans", poPlans);
  }, [selectedPlan]);


  const [poPlansError, setPoPlansError] = useState([{
    plandId: "",
    errorMessage: ""
  }]);



  const [poConfirmationBody, setPoConfirmationBody] = useState([]);

  useEffect(() => {

    if (isNce) {

      let selectedFeilds = selectedPlan.map(({ planId, quantity }) => ({ planId, quantity }));



      // Create a map from selectedFeilds based on planId
      const mapSelectedFields = {};
      selectedFeilds.forEach(item => {
        mapSelectedFields[item.planId] = item.quantity;
      });

      // Combine the arrays
      const combinedArray = alignedDateArrayData.map(item => {
        return {
          ...item,
          quantity: mapSelectedFields[item.planId] !== undefined ? mapSelectedFields[item.planId] : null
        };
      });

      setPoConfirmationBody(combinedArray);

      // console.log(combinedArray);
    }

  }, [selectedPlan, alignedDateArrayData, poNumber, step3Progress])


  // Transition Domain
  async function postOrderConfirmation() {
    setIsProcessing(true);
    setConfirmLoadingFlag(true);
    const body = {
      purchaseOrderNumber: poNumber,
      isPerpetualSoftware: isPrerpectual,
      isNcePlan: isNce,
      customerCode: customerId,
      plans: isNce ? poConfirmationBody : selectedPlan.map(({ planId, quantity }) => ({ planId, quantity }))
      //  plans: selectedPlan.map(({ planId, quantity }) => ({ planId, quantity }))
    };

    await api.post(CSP_PRODUCT_ORDERING, body, config)
      .then(resp => {
        if (resp.status == 200) {
          setConfirmLoadingFlag(false);
          setPOConfirmation(resp.data);
          setOrderConfirmationSuccess(true);
        }

      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        if (error.status == 500 || error.status == 400) {
          // console.log("Error : ", error);
          setConfirmLoadingFlag(false);
          setToastMessage("Difficult in reaching servers, try again later.");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 20000);
        } else {
          // console.log("Error : ", error);
          setConfirmLoadingFlag(false);
          setToastMessage("CSP Product Provision Failed at the moment, try again later.");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 20000);
        }
        setConfirmLoadingFlag(false);
        !error.response.data.errors ? setPoPlansError(error.response.data) : setPoPlansError("")
      });
    setIsProcessing(false);
  }


  // Order Success Details Resets to initial empty values 
  const resetCspPO = () => {
    setPOConfirmation({
      isSuccess: true,
      message: "",
      orderCode: "",
      orderId: 0,
      errorMessages: []
    });

    setProductDetailsData(initProductDetailsData);
    setSelectedRowId([]);
  }

  useEffect(() => {
    if (customerId) {
      setSelectCustomerErrorState(false);
    }
  }, [customerId]);



  useEffect(() => {
    GetAdditionalDetails();
    if (selectedPlan.length > 0) {
      setPlanSelectedFlag(true);
    }
    else {
      setPlanSelectedFlag(false);
    }
  }, [selectedPlan]);

  useEffect(() => {
    // console.log("alignedDateArrayData", alignedDateArrayData);
  }, [alignedDateArrayData, selectedPlan]);


  useEffect(() => {
    setStep1Progress(true);
    setStep2Progress(false);
    setStep3Progress(false);
    GetAdditionalDetails();
  }, [])

  return (
    <>
      <div className={`mt-4 ${styles['productlisting-main-table']}`}>
        {/* content when server error comes */}
        {!loading && serverError &&
          <div className={styles["no-data-cloud-invoice"]}>
            <img src={EmptyDataTableFilterSearch} className="empty-cloud-summary-table-svg" />
            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
          </div>
        }
        {!serverError &&
          <>
            <Row className={`mb-4 px-3 ${styles['product-plan-ipad-pro']}`}>
              <Col md={1} lg={2} xs={4}>
                <h2 className='notes-confirmation mt-7'>Plans</h2>
              </Col>
              <Col md={3} lg={4} xs={8}>
                {
                  <InputGroup className="search-input-box search-input-box-xl mt-4 notes-confirmation">
                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                      <span className="searchgrey cpointer"
                      >
                        &nbsp;
                      </span>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search for plan and SKU ID"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      className="search-input-box-input"
                      value={searchValueTable}
                      onChange={(e) => setSearchValueTable(e.target.value)}
                      ref={searchInputRef}
                    />
                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                      onClick={() => {
                        setSearchValueTable("");
                        setSearchIconFlag(!searchIconFlag);
                      }}
                    >
                      <span className="closebtn">
                        &nbsp;
                      </span>
                    </InputGroup.Text>
                  </InputGroup>
                }
              </Col>
              <Col md={5} lg={3} xs={7} className={`${styles['product-plan-top-right-card']}`}>
                {!isPrerpectual && <SearchBoxLegacyPlanDropdown
                  dropdownLabel="Commitment Term (Billing Frequency)"
                  formGroupId="billingTermId"
                  placeholder="Enter Commitment Term"
                  selectDefaultValue={billingTermDefaultValue}
                  setSelectDefaultValue={setBillingTermDefaultValue}
                  setOptionId={setBillingTermId}
                  selectOptions={billingTerm}
                  selectOptionsFiltered={billingTermFiltered}
                  setSelectOptionsFiltered={setBillingTermFiltered}
                  selectSearchValue={billingTermSearchValue}
                  setSelectSearchValue={setBillingTermSearchValue}
                  updatedOptions={updatedBillingTerm}
                  hasStaticOptions={false}
                  setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                />}
              </Col>
              <Col md={3} lg={3} xs={5} className={`${styles['product-plan-top-right-card']}`}>
                <SearchDropdown
                  dropdownLabel="Category"
                  formGroupId="categoryId"
                  placeholder="Enter Category"
                  selectDefaultValue={categoryDefaultValue}
                  setSelectDefaultValue={setCategoryDefaultValue}
                  setOptionId={setCategoryId}
                  selectOptions={category}
                  selectOptionsFiltered={categoryFiltered}
                  setSelectOptionsFiltered={setCategoryFiltered}
                  selectSearchValue={categorySearchValue}
                  setSelectSearchValue={setCategorySearchValue}
                  updatedOptions={updatedCategory}
                  hasStaticOptions={false}
                  setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                />
              </Col>
            </Row>
            {productDetailsData && !emptyData && !serverError && !loading &&
              <div className={`${styles['table-with-scroll']}`}>
                <Table className={isreadOnly ? `${styles['csp-change-status-core-table-read-only']}` : `${styles['csp-change-status-core-table']}`}
                  responsive ref={tableRef}>
                  <thead className="sticky-top">
                    <tr>
                      {!isPrerpectual && (isreadOnly ? ReadOnlyCSPProductDetailColumnName : CSPProductDetailColumnName).map(th => {
                       return (<th 
                          className={ 
                            (th === "Commitment Term (Billing Frequency)") ? `${styles['commitment-term-width']}` : 
                            (th === "Price (Ex GST)" || th === "RRP (Inc GST)" || th === "Add Quantity") ? "text-right" : 
                            "" 
                        }
                          >{th}</th>)
                      })
                      }
                      {productDetailsData && isPrerpectual && (isreadOnly ? ReadOnlyCSPProductDetailColumnNamePerpetual : CSPProductDetailColumnNamePerpetual).map(th => {
                        return (<th className={(th === "Price (Ex GST)" || th === "RRP (Inc GST)" || th === "Add Quantity") ? "text-right" : ""}>{th}</th>)
                      })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      productDetailsData &&
                      productDetailsData.map((td, index) => {

                        if (td.category === categoryId && (!isPrerpectual ? (td.billingTerm == billingTermDefaultValue) : true)) {
                          return (
                            <tr key={index} id={'row-' + index} className={selectedRowId.includes(td.planId) ? styles["selected-row"] : ""}>
                              <td className={td.isPerpetualSoftware === false ? styles['big-width'] : styles['small-width']}>{td.planName}</td>
                              <td >{td.skuId}</td>
                              {td.isPerpetualSoftware === false && <td className={styles['big-width']}>{td.billingTerm}</td>}
                              <td className={td.isPerpetualSoftware === true ? styles['big-width'] : ""}>{td.category}</td>
                              <td className='text-right'>${td.strRRP}</td>
                              <td className='text-right'>${td.strPrice}</td>
                              <td className={isreadOnly ? 'd-none' : `text-right ${styles['mobile-display']} ${styles['qty-wrapper-cell']}`}>
                                <div className={`${styles['qty-wrapper']}`}>
                                  <div className={isreadOnly ? 'd-none' : 'd-flex flex-wrap  align-items-center justify-content-start'}>

                                    {selectedAddOn != td.planId &&
                                      <div className={`${styles['show-qty-btn-wrapper']}`} onClick={(e) => {
                                        e.preventDefault();
                                        setMaxQuantity(parseInt(td.maxQuantity));
                                        setMinQuantity(parseInt(td.minQuantity));
                                        setNewQuantity(td.quantity == 0 ? 1 : td.quantity);
                                        setSelectedAddOn(e.target.id);
                                        setActiveQtyCheckFlag(true);
                                      }}>
                                        <label
                                          id={td.planId}
                                          className={`${styles['show-qty-btn']}`}>
                                          {td.quantity}
                                        </label>

                                        <div className={`${styles['btn-wrapper']}`}>
                                          <button className={`${styles['plus']}`}
                                            id={td.planId}
                                          >+</button>
                                          <button className={`${styles['minus']}`}
                                            id={td.planId}
                                          ><span>&nbsp;</span></button>
                                        </div>
                                      </div>
                                    }


                                    {(selectedAddOn == td.planId) &&
                                      <div className="d-flex flex-wrap  align-items-center justify-content-end">
                                        <>
                                          <div className="number-input">
                                            <input
                                              type="number"
                                              id={td.planId}
                                              className="no-arrows"
                                              defaultValue={td.quantity}
                                              value={
                                                (td.billingTerm == "Trial") ? td.maxQuantity : newQuantity !== null ? parseInt(newQuantity) : parseInt(td.quantity)}
                                              min={td.minQuantity}
                                              max={td.maxQuantity}
                                              onChange={(e) => {
                                                setNewQuantity(e.target.value);
                                                // if ((e.target.value) == (td.quantity)) {
                                                //   // setNewQuantity(false);
                                                // }

                                                if ((e.target.value) > td.maxQuantity) {
                                                  setNewQuantity(td.maxQuantity)
                                                }

                                                if ((e.target.value) < td.minQuantity) {
                                                  setNewQuantity(td.minQuantity)
                                                }

                                                if ((e.target.value) === td.quantity) {
                                                  setNewQuantity(td.quantity)
                                                }

                                              }}
                                              onKeyDown={(e) => {
                                                if (e.code === 'Minus') {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />

                                            <div className="btn-wrapper">
                                              {parseInt(newQuantity) < parseInt(td.maxQuantity) &&
                                                (td.billingTerm != "Trial") ?
                                                <button className="plus" onClick={() => handleIncrease(parseInt(newQuantity))
                                                }>+</button> :
                                                <button className="plus disabled" disabled>+</button>
                                              }

                                              {parseInt(newQuantity) > td.minQuantity ?
                                                <button className="minus" onClick={
                                                  () => handleDecrease(parseInt(newQuantity))
                                                }> <span>&nbsp;</span> </button>
                                                :
                                                <button className="minus disabled" disabled>
                                                  <span>&nbsp;</span>
                                                </button>}

                                            </div>

                                          </div>
                                          {parseInt(newQuantity) !== parseInt(td.quantity) &&
                                            <div className='mx-1'
                                              onClick={(e) => {
                                                td.quantity = (td.billingTerm == "Trial") ? td.maxQuantity : parseInt(newQuantity);

                                                // Plan is already selected
                                                if (selectedRowId.includes(td.planId)) {

                                                  // Update Quantity Corresponding in Main Table if we change here
                                                  const indexToUpdate = selectedPlan.findIndex(item => item.planId === td.planId);

                                                  // If the object with planId exists, update its quantity to newQuantity
                                                  if (indexToUpdate !== -1) {
                                                    const updatedProductDetailsData = selectedPlan.map((item, index) => {
                                                      if (index === indexToUpdate) {
                                                        return {
                                                          ...item,
                                                          quantity: (td.billingTerm == "Trial") ? td.maxQuantity : newQuantity
                                                        };
                                                      }
                                                      return item;
                                                    });

                                                    // Update the state with the new array
                                                    setSelectedPlan(updatedProductDetailsData);
                                                  }


                                                } else {
                                                  setSelectedRowId([...selectedRowId, td.planId]);
                                                  setSelectedPlan([...selectedPlan, td]);
                                                  // console.log("Qty Added", selectedPlan);
                                                }

                                                setNewQuantity((td.billingTerm != "Trial") ? td.maxQuantity : td.quantity);
                                                setSelectedAddOn("");
                                                setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                              }
                                              }>
                                              <span className="quantity-tick" />
                                            </div>
                                          }

                                          {parseInt(newQuantity) == td.quantity &&
                                            <div className='mx-1'>
                                              <span className="tick-grey" />
                                            </div>
                                          }


                                          <div className='mx-1' onClick={(e) => {
                                            setNewQuantity(td.quantity);
                                            setSelectedAddOn("");
                                            setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                            // console.log(activeQtyCheckFlag);
                                          }
                                          }>
                                            <span className="quantity-cross">&nbsp;</span>
                                          </div>
                                        </>
                                      </div>

                                    }

                                    {/* {selectedAddOn == td.planId &&
<>
<span className="text-small text-disabled">Min: {td.minQuantity}, </span>
<span className="text-small text-disabled">Max: {td.maxQuantity}</span>
</>
} */}
                                  </div>

                                </div>

                              </td>
                            </tr>
                          )
                        }

                      })
                    }
                  </tbody>
                </Table>
              </div>
            }
            <div>
              {
                selectedRowId.length > 0 && !loading &&
                <>
                  <div id='csp-plan-selected-component' className={`table ${styles['csp-purchase-addon-count-row']}  ${isPlanSelectedSticky ? styles['sticky-bottom'] : ''}`} style={isPlanSelectedSticky ? { maxWidth: tableWidth } : {}}>
                    <div>
                      <Row>
                        <Col md={6} className="d-flex align-items-center">
                          <p className="m-0 p-0 text-small">{selectedRowId.length} {selectedRowId.length < 2 ? "Plan" : "Plans"} selected</p>
                        </Col>
                        <Col md={6}>
                          <div className="btn-wrapper justify-content-end d-flex m-0 p-0">
                            <Button
                              variant="light"
                              className="px-4 btn-border-light w-md-auto col-xs-12"
                              onClick={() => setResetData(true)}>
                              Clear
                            </Button>

                            <Button
                              className="px-4 ml-5"
                              onClick={() => {
                                if (billingPaymentGatewayData.isDefaultPaymentMethodValid) {
                                  if (billingPaymentGatewayData.accountHoldStatus === "No Hold" || proceedToProvisionFlag) {
                                    if (billingPaymentCardExpiryFlag || billingPaymentDirecrDebitExpiryFlag) {
                                      if (billingPaymentCardExpiryFlag) {
                                        setBillingPaymentGatewayErrorMessage("Your default payment method of credit card has an expired primary card, please proceed to add a new card")
                                        setBillingPaymentGatewayErrorPopup(true)
                                      }
                                      else {
                                        setBillingPaymentGatewayErrorMessage("Your default payment method of Direct Debit has all expired statuses, please proceed to add a new application")
                                        setBillingPaymentGatewayErrorPopup(true)
                                      }
                                    }
                                    else {
                                      if (checkResellerPrereqisiteFlag === false) {
                                        setResellerPrerequisiteModalUpen(true);
                                      }
                                      else {
                                        if (checkResellerPrereqisiteExpiryFlag === true) {
                                          setResellerPrerequisiteModalUpen(true);
                                        }
                                        else {
                                          if (customerId) {
                                            if (customerPrerequisite.length > 0) {

                                              if (!isMCACompleted) {
                                                window.scrollTo(0, 0);
                                              }
                                              else {

                                                GetGST();
                                                setSelectCustomerErrorState(false);
                                                setCSPProductDetailsPopupModalOpen(true)
                                              }



                                            }
                                            else {
                                              setCustomerPrerequisiteModalUpen(true);
                                            }
                                          }
                                          else {
                                            setSelectCustomerErrorState(true);
                                            window.scrollTo(0, 0);
                                          }
                                        }
                                      }
                                    }
                                  }
                                  if (billingPaymentGatewayData.accountHoldStatus === "System Hold" && !proceedToProvisionFlag) {
                                    setBillingPaymentGatewayErrorMessage("There is an issue with your account. If you choose to proceed to provision the order, please contact Synnex account team to complete the provisioning of this order.")
                                    setBillingPaymentGatewayErrorPopup(true)
                                  }
                                  if (billingPaymentGatewayData.accountHoldStatus === "Manual Hold") {
                                    setBillingPaymentGatewayErrorMessage("We are unable to process your request at this time. Please contact Synnex Accounts team at 'accounts@au.synnex-grp.com' or '0385408888' for further instructions")
                                    setBillingPaymentGatewayErrorPopup(true)
                                  }

                                }
                                else {
                                  if (!(billingPaymentGatewayData.isDefaultPaymentMethodValid)) {
                                    setBillingPaymentGatewayErrorMessage("No payment method is chosen by default, click on proceed to add payment method.")
                                    setBillingPaymentGatewayErrorPopup(true)
                                  }
                                }
                              }}>
                              Proceed
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              }
            </div>
          </>}
        {!loading && emptyData && !serverError &&
          <div className={styles["no-data-cloud-invoice"]}>
            <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
            <h2 className="mt-4 mb-3">No Data Found</h2>
          </div>
        }
        {
          loading &&
          <div className={styles["no-data-cloud-invoice"]}>
            <img className="management-console-loading-icon" src={loadingGif} />
            <p className="text-medium text-strong management-console-loading-text">Loading...</p>
          </div>
        }
      </div>

      <Popup
        className={`custom-modal  ${!orderConfirmationSuccess ? "custom-modal-xl" : "custom-modal-md"}`}
        open={cspProductDetailsPopupModalOpen}
        onClose={() => {
          setSelectedAddOn("");
          setNewQuantity(null);
          setActiveQtyCheckFlag(false);
          setGstPercentage(null);
          setPONumber("");
          // setResetData(true)
          setStep1Progress(true);
          setStep2Progress(false);
          setStep3Progress(false);
          setIsProvisionsReviewDone(false);
          setIsCoTermSelection(false);
          setAlignedDateArrayData(null);
          setIsCoTermSelection(false);
          setAlignedDateArrayData([]);
          setCSPProductDetailsPopupModalOpen(false);

          if (orderConfirmationSuccess) {
            resetCspPO();
            setResetData(true);
            GetTableData(productId, searchValueTable, billingTermId, categoryId);
            setOrderConfirmationSuccess(false);
          }
          else {

          }

        }}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            {!subscriptionDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2>
                    {step1Progress && <>Cloud Provision</>}
                    {step2Progress && <>Co-term - Align End Date</>}
                    {step3Progress && <>Order Confirmation - Cloud Provision</>}</h2>


                  {!isProcessing &&
                    <XLg
                      size={18}
                      className="cpointer text-strong"
                      onClick={close}
                      color="#6A6A6A"
                    />
                  }

                  {isProcessing &&
                    <XLg
                      size={18}
                      className="text-strong"
                      color="#DDDDDD"
                      disabled
                    />
                  }
                </div>



                {!cspCOTermCompleted &&
                  <>

                    {!orderConfirmationSuccess &&
                      <Container className={`action-modal-height ${styles['edit-quantity-width']}`}>

                        <div className={`${styles["csp-purchase-addon-table-outer"]}`}>

                          {!step2Progress &&
                            <CSPOrderConfirmationModalHeader customerName={customerName} serviceName={"Microsoft CSP"} />
                          }

                          {isNce && <>
                            {!isProvisionsReviewDone &&
                              <CoTermCloudProvsion
                                selectedPlan={selectedPlan}
                                isPrerpectual={isPrerpectual}
                                setIsProvisionsReviewDone={setIsProvisionsReviewDone}
                                setCSPProductDetailsPopupModalOpen={setCSPProductDetailsPopupModalOpen}
                                setStep1Progress={setStep1Progress}
                                setStep2Progress={setStep2Progress}
                                setStep3Progress={setStep3Progress}
                                handleDeleteRow={handleDeleteRow}
                                setAlignedDateArrayData={setAlignedDateArrayData}
                                setIsCoTermSelection={setIsCoTermSelection}
                              />
                            }

                            {/* {isProvisionsReviewDone && !isCoTermSelection &&
                              <>
                                <CoTermAlignEndDate
                                  selectedPlan={selectedPlan}
                                  setIsCoTermSelection={setIsCoTermSelection}
                                  setIsProvisionsReviewDone={setIsProvisionsReviewDone}
                                  customerId={customerId}
                                  vendorAccountId={vendorAccountId}
                                  setAlignedDateArrayData={setAlignedDateArrayData}
                                  setStep1Progress={setStep1Progress}
                                  setStep2Progress={setStep2Progress}
                                  setStep3Progress={setStep3Progress}
                                />
                              </>
                            } */}
                            {isProvisionsReviewDone && !isCoTermSelection &&
                              <>
                                <CoTermAlignEndDate
                                  selectedPlan={selectedPlan}
                                  setIsCoTermSelection={setIsCoTermSelection}
                                  setIsProvisionsReviewDone={setIsProvisionsReviewDone}
                                  customerId={customerId}
                                  vendorAccountId={vendorAccountId}
                                  setAlignedDateArrayData={setAlignedDateArrayData}
                                  setStep1Progress={setStep1Progress}
                                  setStep2Progress={setStep2Progress}
                                  setStep3Progress={setStep3Progress} 
                                />
                              </>
                            }
                          </>
                          }
                          {/* {
!isCoTermAlignEndateDone &&
<>

</>
} */}

                          {((isProvisionsReviewDone && isCoTermSelection) || !isNce) &&
                            <>
                              <div className={`table-responsive col-12 my-3 ${styles['csp-popup-table']}`}>
                                <Table className={`table w-100 ${styles['csp-change-status-core-table']}`}>
                                  <thead className="sticky-top">
                                    <tr>
                                      <th>Plan</th>
                                      {!isPrerpectual &&
                                        <th className={`${styles['commitment-term-width']}`}>Commitment Term (Billing Frequency)</th>
                                      }
                                      <th>Category</th>
                                      <th className="text-right">RRP (Inc GST)</th>
                                      <th className="text-right">Price (Ex GST)</th>
                                      <th className="text-center">Quantity</th>
                                      <th className="text-right">Sub-Total Price</th>
                                      <th className="text-center px-0">Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(selectedPlan && selectedPlan.length > 0) &&
                                      selectedPlan.map((td, index) => {
                                        return (
                                          <>
                                            <tr key={index}
                                              className={poPlansError?.some(error => error.plandId === td.planId && error.errorMessage !== null) ? styles["smb-error"] : ""}
                                            >
                                              <td><span>{td.planName}</span>
                                                <p className='text-disabled text-small'>{td.skuId}</p>

                                                {td.isNcePlan === true &&
                                                  <>
                                                    {
                                                      poPlansError?.map(error => {
                                                        if (error.plandId === td.planId && error.errorMessage !== null) {
                                                          return (
                                                            <div key={`${index}-error`} className={`${styles["smb-error-message"]}`}>
                                                              <span className="text-small">{error.errorMessage}</span>
                                                            </div>
                                                          );
                                                        }
                                                        return null;
                                                      })
                                                    }
                                                  </>
                                                }

                                              </td>
                                              {!isPrerpectual && <td><span>{td.billingTerm}</span></td>}
                                              <td><span>{td.category}</span></td>
                                              <td className="text-right"><span>${td.strRRP}</span></td>
                                              <td className="text-right"><span>${td.strPrice}</span></td>
                                              <td className="text-right">
                                                <div className={styles['qty-wrapper']}>
                                                  {selectedAddOn != td.planId &&
                                                    <div className={`${styles['show-qty-btn-wrapper']}`}  
                                                    id={td.planId}
                                                    onClick={(e) => {
                                                      if (!activeQtyCheckFlag) {
                                                        e.preventDefault();
                                                        setMaxQuantity(parseInt(td.maxQuantity));
                                                        setMinQuantity(parseInt(td.minQuantity));
                                                        setNewQuantity(parseInt(td.quantity));
                                                        setSelectedAddOn(td.planId);
                                                        setActiveQtyCheckFlag(true);
                                                      }
                                                    }
                                                    }>
                                                      <label
                                                        
                                                        className={`${styles['show-qty-btn']}`}
                                                       >{td.quantity}</label>

                                                      <div className={`${styles['btn-wrapper']}`}>
                                                        <button className={`${styles['plus']}`}
                                                          
                                                           
                                                        >+</button>
                                                        <button className={`${styles['minus']}`}
                                                          
                                                          >
                                                          <span>&nbsp;</span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  }


                                                  {(selectedAddOn == td.planId) &&
                                                    <div className="d-flex flex-wrap  align-items-center justify-content-end">
                                                      <>
                                                        <div class="number-input">
                                                          <input
                                                            type="number"
                                                            id={td.planId}
                                                            className="no-arrows"
                                                            defaultValue={td.quantity}
                                                            value={parseInt(newQuantity)}
                                                            min={td.minQuantity}
                                                            max={td.maxQuantity}
                                                            onChange={(e) => {
                                                              setNewQuantity(e.target.value);
                                                              // if ((e.target.value) == (td.quantity)) {
                                                              //   // setNewQuantity(false);
                                                              // }

                                                              if ((e.target.value) > td.maxQuantity) {
                                                                setNewQuantity(td.maxQuantity)
                                                              }

                                                              if ((e.target.value) < td.minQuantity) {
                                                                setNewQuantity(td.minQuantity)
                                                              }

                                                              if ((e.target.value) === td.quantity) {
                                                                setNewQuantity(td.quantity)
                                                              }

                                                            }}
                                                            onKeyDown={(e) => {
                                                              if (e.code === 'Minus') {
                                                                e.preventDefault();
                                                              }
                                                            }}
                                                          />

                                                          <div className="btn-wrapper">
                                                            {parseInt(newQuantity) < parseInt(td.maxQuantity) ?
                                                              <button className="plus" onClick={() => handleIncrease(parseInt(newQuantity))
                                                              }>+</button> :
                                                              <button className="plus disabled" disabled>+</button>
                                                            }

                                                            {parseInt(newQuantity) > td.minQuantity ?
                                                              <button className="minus" onClick={
                                                                () => handleDecrease(parseInt(newQuantity))
                                                              }> <span>&nbsp;</span> </button>
                                                              :
                                                              <button className="minus disabled" disabled>
                                                                <span>&nbsp;</span>
                                                              </button>}

                                                          </div>

                                                        </div>
                                                        {parseInt(newQuantity) !== parseInt(td.quantity) &&
                                                          <div className='mx-1'
                                                            onClick={(e) => {
                                                              td.quantity = parseInt(newQuantity);
                                                              if (!selectedRowId.includes(td.planId)) {
                                                                setSelectedRowId([...selectedRowId, td.planId]);
                                                                setSelectedPlan([
                                                                  {
                                                                    billingTerm: td.billingTerm,
                                                                    category: td.category,
                                                                    flavorDetailJson: td.flavorDetailJson,
                                                                    flavorId: td.flavorId,
                                                                    isNcePlan: td.isNcePlan,
                                                                    isPerpetualSoftware: td.isPerpetualSoftware,
                                                                    maxQuantity: td.maxQuantity,
                                                                    minQuantity: td.minQuantity,
                                                                    planId: td.planId,
                                                                    planName: td.planName,
                                                                    price: td.price,
                                                                    quantity: td.quantity,
                                                                    rrp: td.rrp,
                                                                    skuId: td.skuId,
                                                                    total: td.quantity * td.price
                                                                  },
                                                                  ...selectedPlan
                                                                ]);
                                                              }


                                                              setNewQuantity(td.quantity);
                                                              setSelectedAddOn("");
                                                              setActiveQtyCheckFlag(!activeQtyCheckFlag);

                                                              // Update Quantity Corresponding in Main Table if we change here
                                                              const indexToUpdate = productDetailsData.findIndex(item => item.planId === td.planId);
                                                              const updatedProductDetails = [...productDetailsData];

                                                              // Update the quantity of the object at the specified index
                                                              updatedProductDetails[indexToUpdate] = {
                                                                ...updatedProductDetails[indexToUpdate],
                                                                quantity: newQuantity// Update the quantity to 4
                                                              };

                                                              setProductDetailsData(updatedProductDetails);
                                                              // Update Quantity Corresponding in Main Table if we change here
                                                              const indexToUpdateSelectedPlan = selectedPlan.findIndex(item => item.planId === td.planId);
                                                              const updatedProductDetailsSelectedPlan = [...selectedPlan];

                                                              // Update the quantity of the object at the specified index
                                                              updatedProductDetailsSelectedPlan[indexToUpdateSelectedPlan] = {
                                                                ...updatedProductDetailsSelectedPlan[indexToUpdateSelectedPlan],
                                                                quantity: newQuantity// Update the quantity
                                                              };
                                                              setSelectedPlan(updatedProductDetailsSelectedPlan)


                                                            }
                                                            }>
                                                            <span className="quantity-tick" />
                                                          </div>
                                                        }

                                                        {parseInt(newQuantity) == td.quantity &&
                                                          <div className='mx-1'>
                                                            <span className="tick-grey" />
                                                          </div>
                                                        }


                                                        <div className='mx-1' onClick={(e) => {
                                                          setNewQuantity(td.quantity);
                                                          setSelectedAddOn("");
                                                          setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                                        }
                                                        }>
                                                          <span className="quantity-cross">&nbsp;</span>
                                                        </div>
                                                      </>
                                                    </div>

                                                  }

                                                  {/* {selectedAddOn == td.planId &&
<>
<span className="text-small text-disabled">Min: {td.minQuantity}, </span>
<span className="text-small text-disabled">Max: {td.maxQuantity}</span>
</>
} */}
                                                </div>

                                              </td>
                                              <td className="text-right">
                                                <span>
                                                  {
                                                    (selectedAddOn == td.planId) ?
                                                      "$" + parseFloat((td.price == 0.0 ? "0.00" : (Math.round((((td.price) * parseInt(newQuantity)) + Number.EPSILON) * 100) / 100))).toFixed(2) :
                                                      "$" + parseFloat((td.price === 0.0 ? "0.00" : Math.round((((td.price) * parseInt(td.quantity)) + Number.EPSILON) * 100) / 100)).toFixed(2)
                                                  }

                                                </span>
                                              </td>
                                              <td className={`text-center ${styles['delete-column']}`}>
                                                <span className="trash" onClick={() => handleDeleteRow(index)}>&nbsp;</span>
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      })
                                    }


                                    {selectedPlan.length < 1 &&
                                      <tr className="no-bdr-row no-hover-row">
                                        <td colSpan={8} className="text-center">
                                          <p className="mb-0 py-3">No plans in the list. Please go back to provision module to buy subscriptions.</p>
                                        </td>
                                      </tr>
                                    }

                                    {/* {selectedPlan.length < 1 &&
<tr>
<td className="text-center" colSpan="7"><p className="mb-0">Loading...</p></td>
</tr>
} */}
                                  </tbody>
                                </Table>



                              </div>
                              {selectedPlan.length > 0 &&
                                <div className={`table-responsive col-12 my-3 ${styles['csp-popup-table-total']}`}>
                                  <Table className={`table w-100 ${styles['csp-change-status-core-table']}`}>
                                    <tr>
                                      <td colSpan={6} className={`text-right`}>
                                        <div>
                                          <span>Base price (Ex. GST):</span>
                                          <span className={`text-right ${styles['total-block-right']}`}>${basePrice}</span><br />

                                          <span>GST ({gstPercentage && (parseFloat(gstPercentage).toFixed(2) + "%")}):</span>
                                          <span className={`text-right ${styles['total-block-right']}`}>${gstAmount}</span><br />
                                        </div>
                                        <span className={`${styles['total-block-row']}`}>
                                          <span className="text-strong">Total (Inc. GST):</span>
                                          <span className={`text-right text-strong ${styles['total-block-right']}`}>${totalWithGST}</span>
                                        </span>

                                      </td>
                                      <td className={`${styles['delete-column']}`}></td>
                                    </tr>
                                  </Table>
                                </div>
                              }


                              <>
                                {selectedPlan.length > 0 &&
                                  <Row>
                                    <Col>
                                      <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="w-auto d-inline ml-3"
                                        id="inputPONumber"
                                        aria-describedby="inputPONumber"
                                        placeholder='Add PO Number'
                                        value={poNumber}
                                        maxlength="50"
                                        onChange={(e) => {
                                          alphanumericRegexWithHyphen.test(e.target.value)
                                            ? setPONumber(e.target.value)
                                            : console.log("")
                                        }}
                                      />
                                      <Form.Text id="poNumber" />
                                    </Col>
                                  </Row>
                                }

                                <Row className="mt-2">

                                  <Col md={6}>
                                    {selectedPlan.length > 0 &&
                                      <>
                                        <Form.Check // prettier-ignore
                                          type="checkbox"
                                          id="termsCheckBox"
                                          label=""
                                          disabled
                                          checked={isTermsChecked}
                                          className="d-inline-block w-auto"
                                        />
                                        <p className="d-inline-block  w-auto mb-0">

                                          I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => { if (!isTermsChecked) { let browser = browserName[1] + " " + browserVersion[1]; TermsAndConditionAcceptFunction(browser, ipAddress); } }}>terms & conditions</a> of Synnex cloud platform

                                        </p>
                                      </>
                                    }
                                  </Col>

                                  <Col md={6}>
                                    <div className="btn-wrapper text-right mb-1">
                                      {!confirmLoadingFlag &&
                                        <Button
                                          variant="light"
                                          className="px-4 btn-border-light w-md-auto col-xs-12"
                                          onClick={() => {
                                            setCSPProductDetailsPopupModalOpen(false);
                                            setIsProvisionsReviewDone(false);
                                            setPONumber("");
                                            setIsProvisionsReviewDone(false);
                                            setIsCoTermSelection(false);
                                          }}>
                                          Cancel
                                        </Button>
                                      }

                                      {confirmLoadingFlag &&
                                        <Button
                                          variant="light"
                                          className="px-4 mx-2"
                                          disabled>
                                          Cancel
                                        </Button>
                                      }
                                      {!confirmLoadingFlag &&
                                        <>
                                          {(isTermsChecked && selectedPlan.length > 0) ? <>
                                            <Button
                                              className="px-4 mx-2"
                                              onClick={postOrderConfirmation}>
                                              Confirm
                                            </Button>
                                          </>
                                            :
                                            <>
                                              <Button
                                                variant="light"
                                                className="px-4 mx-2"
                                                disabled
                                              >
                                                Confirm
                                              </Button>
                                            </>
                                          }
                                        </>
                                      }

                                      {confirmLoadingFlag &&
                                        <>

                                          <Button
                                            disabled
                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                            variant="light"
                                          >
                                            Processing . . .
                                          </Button>
                                        </>
                                      }
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            </>
                          }
                        </div>

                      </Container>
                    }
                  </>
                }
                {orderConfirmationSuccess &&
                  <>
                    <Container className='text-center'>
                      <p>
                        <div className='success-page'></div>
                      </p>
                      <p className='text-regular'>{poConfirmation.message}</p>
                      <p className='text-regular'><strong>Provision Order ID:</strong> {poConfirmation.orderCode}</p>
                      <div>
                        <Button
                          className="px-4 mx-2"
                          onClick={() => { close(); setCSPProductDetailsPopupModalOpen(false); resetCspPO() }}>
                          Ok
                        </Button>
                      </div>
                    </Container>
                  </>
                }
              </>
            }
            {
              subscriptionDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2 className="mb-0">Edit Quantity</h2>
                  <XLg
                    size={18}
                    className="cpointer text-strong"
                    onClick={close}
                    color="#6A6A6A"
                  />
                </div>
                <Container>
                  <div className="empty-subscription-detail">
                    <center><h2> Loading . . .</h2></center>
                  </div>
                </Container>
              </>
            }
          </div>
        )}
      </Popup>

      <Popup
        className="custom-modal custom-modal-sm"
        open={customerPrerequisiteModalUpen}
        onClose={() => setCustomerPrerequisiteModalUpen(false)}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header p-4 py-3">
              <h2 className="mb-0 px-3">Customer Prerequisite</h2>
              <XLg
                size={18}
                className="cpointer text-strong close-icon-popup-sm"
                onClick={close}
                color="#6A6A6A"
              />
            </div>
            <Container className="p-5">
              <p className='pt-0 px-3 pb-5 mb-0'>
                <strong>Please add customer pre-requisites to provision subscriptions.</strong>
              </p>
              <div className="actions">
                <div className="d-flex justify-content-end px-3 pb-3 pt-2">
                  <Button
                    className="btn btn-lg mx-3 btn-border-light"
                    variant="light"
                    onClick={() => {
                      // console.log("modal closed ");
                      close();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-md"
                    onClick={() => {
                      if (customerName !== "Select Customer") {
                        navigate("/cloud-provision/customer/prerequisite/microsoft", {
                          state: { serviceId: serviceId, customerId: customerId, customerName: customerName }
                        }
                        );
                        close();
                      }
                    }}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        )}
      </Popup>
      <Popup
        className="custom-modal custom-modal-sm"
        open={resellerPrerequisiteModalUpen}
        onClose={() => setResellerPrerequisiteModalUpen(false)}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header p-4 py-3">
              <h2 className="mb-0 px-3">Vendor Requirement</h2>
              <XLg
                size={18}
                className="cpointer text-strong close-icon-popup-sm"
                onClick={close}
                color="#6A6A6A"
              />
            </div>
            <Container className="p-5">
              <p className='pt-0 px-3 pb-5 mb-0'>
                {checkResellerPrereqisiteExpiryFlag === true ? <strong>Please click on proceed to reset your MPNID.</strong> : <strong>Please add vendor requirements to provision subscriptions.</strong>}
              </p>
              <div className="actions">
                <div className="d-flex justify-content-end px-3 pb-3 pt-2">
                  <Button
                    className="btn btn-lg mx-3 btn-border-light"
                    variant="light"
                    onClick={() => {
                      // console.log("modal closed ");
                      close();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-md"
                    onClick={() => {
                      if (checkResellerPrereqisiteExpiryFlag === true) {
                        navigate("/profile", {
                          state: { serviceId: serviceId }
                        }
                        );
                      }
                      else {
                        navigate("/cloud-provision/reseller/prerequisite/microsoft", {
                          state: { serviceId: serviceId }
                        }
                        );

                      }
                      close();
                    }}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        )}
      </Popup>

    </>
  )
}

export default CSPProductDetail