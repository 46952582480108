import React, { useState, useEffect } from 'react'
import { Accordion } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Toast from '../../../../../../GlobalComponents/Toast';

import { api } from '../../../../../../Utils/API';
import { CSP_CHECK_DOMAIN_AVAILABILITY, CSP_CHECK_TENANT_AVAILABILITY, PROVISIONING_GET_CUSTOMER_DOMAIN, CSP_DOMAIN_TRANSITION, CSP_GET_GDAP_DETAILS, CSP_GDAP_TRANSITION_STATUS, CSP_MCA_AGREEMENT_DETAILS, CSP_TRANSITION_ONBOARD_STATUS, MCA_AGREEMENT_DOWNLOAD } from '../../../../../../Utils/GlobalConstants';

import { alphanumericRegex, emailRegEx, phoneRegex } from '../../../../../../GlobalComponents/RegExPatterns';
import DomainCheck from './LocalComponents/DomainCheck';
import TransitionDomain from './LocalComponents/TransitionDomain';
import MCAAgreement from './LocalComponents/MCAAgreement';
import GDAPAssignment from './LocalComponents/GDPADetails';
import BuyDomainSuccess from './LocalComponents/BuyDomain';

import CSPDomainProvisioned from './LocalComponents/DomainProvisioned';

const CSPCustomerPrerequisite = (props) => {
  const location = useLocation();                                             // get Location
  const integrationCode = "microsoftsaas"                                     // CSP Integration Code        
  // to use navigate function
  const domainPattern = /^(.*)\.onmicrosoft\.com$/;                           // Domain Pattern to check "onmicrosoft.com"
  const fullDomainPatern = /^[a-zA-Z0-9]+\.onmicrosoft\.com$/                // Domain Pattern to check Alpha Numeric.
  const tenantIdRegex = /^[a-zA-Z0-9\-]{36}$/;                                // Validation of TenantId

  const newCustomerOnBoard = "New Customer (Tenant) Creation";  // New Customer (Tenant) Creation Option
  const transitionDomain = "Existing Customer (Tenant) Onboarding";              // Transition Domain Option

  const [updatedToastMessage, setUpdatedToastMessage] = useState(false);      // To check Toast Message is active
  const [toastMessage, setToastMessage] = useState("");                       // Set Toast Message


  const [expandedAll, setExpandedAll] = useState(false);                      // Expand All Accordions
  const [activeAccordion, setActiveAccordion] = useState(["domainCheck"]);              // Default Active Accordion Id is "0"
  const [isDomainBuySuccess, setIsDomainBuySuccess] = useState(false);          // Display Success Message

  const [customerData, setCustomerData] = useState([]);                               // data in status dropdown
  const [submittingMCA, setSubmittingMCA] = useState(false);
  const [mcaStatus, setMCAStatus] = useState(true);

  // Search Value for Customer Name
  const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState(location.state !== null ? location.state.customerName : props.customerName);




  const [isCheckingDomain, setIsCheckingDomain] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);
  const [domainCheckBlockErrors, setDomainCheckBlockErrors] = useState(false);
  const [transitionErrorBlock, setTransitionErrorBlock] = useState(false);
  const [transitionErrorMessage, setTransitionErrorMessage] = useState("");
  const [isMCAFirstTime, setIsMCAFirstTime] = useState(location.state?.isMCACompleted ? location.state?.isMCACompleted : false);
  const [isCompletinTtansaction, setIsCompletinTtansaction] = useState(false);

  const defaultServiceName = "Microsoft CSP";
  const defaultCategoryName = "Cloud Technical Support";

  // Initial Status of Form and Buttons
  const initStatus = {
    domainName: "",
    updatedDomainName: "",
    isCheckDomain: false,
    domainValidationFE: {
      isValid: "",
      message: "Customer domain is not valid. Please enter a valid domain."
    },

    isDomainType: {
      isDomainTransition: false,
      isDomainProceed: props.isMCACompleted ? props.isMCACompleted : false,
    },

    domainCheckStatusBE: {
      isExistsInSynnex: "",
      isSuccess: "",
      message: ""
    },

    btnChecks: {
      btnCheckAvailability: false,
      btnClear: false,
      btnProceed: false,
      btnBuySubscription: false,
      btnTransition: false,
      btnGDAP: false
    },

    stepsCompletedCheck: {
      isDomainCheckCompleted: props.isMCACompleted ? props.isMCACompleted : false, // Domain check completed to Buy or Transition
      isDomainTransitionCompleted: false, // Domain Transition status
      isMCACompleted: props.isMCACompleted ? props.isMCACompleted : false,  // MCA Agreement completed
      isGDAPCompleted: false, // GDAP completed
      isDomainFlowCompleted: false, // Domain (Buy or Transition) Flow Completed
    },

    mcaAgreementDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      mcaAcceptanceDate: "",
      customerCode: "",
      domainName: ""
    },

    transitionDomainChecks: {
      organizationProfileChecked: false,
      acceptInvitationChecked: false
    },

    getDomainTrasnitionUri: {
      microsoftCspUri: "",
      tenantUri: ""
    },
    gdapDetails: {
      gdapUri: "",
      delegateAdminRelationshipId: "",
      message: "",
      isSuccess: true,
      gdapStatus: "",
      domainEnable: true,
      assignedRoles: ""
    },
    onBoardDetails: {
      status: "",
      message: "",
      gdapUrl: "",
      delegateRelationshipId: "",
      assignedRoles: ""
    },
    delegateAdminRelationshipApproved: ""
  };

  const [assignedRoles, setAssignedRoles] = useState([]);
  const [delegateAdminRelationshipId, setDelegateAdminRelationshipId] = useState(null);
  const [gdapURI, setGDAPUri] = useState(null);



  const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false);
  const [isDomainProvisioned, setIsDomainProvisioned] = useState(false);
  const [domainName, setDomainName] = useState(initStatus.domainName);                                  // Domain Name
  const [domainCheckFE, setDomainCheckFE] = useState(initStatus.domainValidationFE);                    // Domain validations from Front End
  const [isDomainAvailable, setIsDomainAvailable] = useState(initStatus.domainCheckStatusBE);           // Checks the domain availability
  const [isDomainType, setIsDomainType] = useState(initStatus.isDomainType);                            // Proceed Domain or Transition Domain
  const [btnChecks, setBtnChecks] = useState(initStatus.btnChecks);                                     // Keeps all button states
  const [stepsCompletedCheck, setStepsCompletedCheck] = useState(initStatus.stepsCompletedCheck);       // Check all steps
  const [delegateAdminRelationshipApproved, setDelegateAdminRelationshipApproved] = useState(initStatus.delegateAdminRelationshipApproved); // Get Delegate Admin Details
  const [mcaAgreementDetails, setMCAAgreementDetails] = useState(props.mcaAgreementDetails ? props.mcaAgreementDetails : initStatus.mcaAgreementDetails);        // MCA Agreement Form
  const [mcaFirstTimeDate, setMCAFirstimeDate] = useState('');
  const [customerId, setCustomerId] = useState(location.state?.customerId ? location.state?.customerId : props.customerId); // Get it from PreRequsite Screen
  const [isMCATermsAgreed, setIsMCATermsAgreed] = useState(false);
  const [microsoftID, setMicrosoftID] = useState();  // Microsoft Tenent ID from Partner Center 
  const [domainError, setDomainError] = useState(null);
  const [selectDomainCheckOption, setSelectDomainCheckOption] = useState("New Customer (Tenant) Creation");
  const [ismcaFormHasNoErros, setIsmcaFormHasNoErros] = useState(false);
  const [domainChecked, setDomainChecked] = useState(false);
  const [mcaCompleted, setMCACompleted] = useState(location.state?.mcaCompleted ? location.state?.mcaCompleted : false);

  // MCA Form Errors
  const [mcaFormErrors, setMCAFormErrors] = useState({
    firstNameError: null,
    lastNameError: null,
    emailError: null,
    phoneNumberError: null,
    dateError: null
  });

  const [validDate, setValidDate] = useState(false);
  const [isGDAPTermsChecked, setIsGDAPTermsChecked] = useState(false);
  const [mcaErrorMessage, setMCAErrorMessage] = useState();
  const [mcaErrorMessageFlag, setMCAErrorMessageFlag] = useState(false);   // Dispay Error flag MCA Submission fails

  // Transition Domain Checks
  const [transitionDomainChecks, setTransitionDomainChecks] = useState(initStatus.transitionDomainChecks);
  const [getDomainTrasnitionUri, setGetDomainTrasnitionUri] = useState(initStatus.getDomainTrasnitionUri);
  const [gdapDetails, setGDAPDetails] = useState(initStatus.gdapDetails);
  const [onBoardDetails, setOnBoardDetails] = useState(initStatus.onBoardDetails); // GET GDAP onboard Status

  let intervalId;

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Header Authorization for Download PDF API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };

  // Download MCA Agreement

  const [mcaDisplayUri, setMCAdisplayUri] = useState("");
  const [mcaDownloadUri, setMCADownloadUri] = useState("");


  async function GetMCAAgreement() {

    await api.get(`${MCA_AGREEMENT_DOWNLOAD}`, config)
      .then(resp => {


        setMCAdisplayUri(resp.data.displayUri);
        setMCADownloadUri(resp.data.displayUri);
      })
      .catch(error => {

        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }

      });
  }


  useEffect(() => {
    GetMCAAgreement();
  }, [])

  // Download File using Js blobs

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/docx") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }


  async function DownloadMCAAgreement(url, name, fileType) {
    let configuration;
    if (fileType === "docx") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }
    await api.get(`${url}`, configuration)
      .then(resp => {
        // handle success
        if (resp.status === 200) {

          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });

        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }

      });
  };

  // Check Domain Availability API
  async function getDomainAvailability(e) {
    setIsDomainAvailable(initStatus.domainCheckStatusBE);
    setIsCheckingDomain(true);

    e.preventDefault();

    setDomainCheckFE({
      ...domainCheckFE,
      isValid: true
    });

    await api.get(`${CSP_CHECK_DOMAIN_AVAILABILITY}?domainName=${domainName}&isTransitionCustomerFlag=${selectDomainCheckOption == transitionDomain}`, config)
      .then(resp => {
        setIsDomainAvailable(resp.data);
        setMicrosoftID(resp?.data?.tenantId);
        if (resp.status == 200) {
          if (resp.data.isSuccess === false && resp.data.isExistsInSynnex === false) {
            setIsDomainType({
              ...isDomainType,
              isDomainTransition: true,
              isDomainProceed: false
            });
            setDomainChecked(true);
          } else if (resp.data.isSuccess === true && resp.data.isExistsInSynnex === false) {
            setIsDomainType({
              ...isDomainType,
              isDomainTransition: false,
              isDomainProceed: true
            });
            setDomainChecked(true);
          } else {
            setIsDomainType({
              ...isDomainType,
              isDomainTransition: false,
              isDomainProceed: false
            })
          }
        }
      })
      .catch(error => {

        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }

      });


    setIsCheckingDomain(false);
  }

  // Check Tenant Availability
  async function getTenantAvailability(e) {
    setIsDomainAvailable(initStatus.domainCheckStatusBE);
    setIsCheckingDomain(true);

    e.preventDefault();

    setDomainCheckFE({
      ...domainCheckFE,
      isValid: true
    });

    if (tenantIdRegex.test(microsoftID)) {
      setDomainError(null);
      await api.get(`${CSP_CHECK_TENANT_AVAILABILITY}?tenantId=${microsoftID}`, config)
        .then(resp => {
          setIsDomainAvailable(resp.data);

          if (resp.status == 200) {
            if (resp.data.isSuccess === true) {
              setIsDomainType({
                ...isDomainType,
                isDomainTransition: true,
                isDomainProceed: false
              });
              setDomainChecked(true);
            } else {
              setIsDomainType({
                ...isDomainType,
                isDomainTransition: false,
                isDomainProceed: false
              })
            }
          }
        })
        .catch(error => {

          if (error?.status == 401 || error?.response?.status == 401) {
            props.Logout();
          }

        });
    } else {
      setDomainError("Invalid Microsoft ID (Tenant ID)");
    }


    setIsCheckingDomain(false);
  }


  // Check Domain Availability
  const checkDomainAvailability = (e) => {
    if (selectDomainCheckOption == "New Customer (Tenant) Creation") {
      if (alphanumericRegex.test(e.target.value)) {
        setDomainName(e.target.value);
        setDomainError(null);
      } else {
        setDomainName(e.target.value);
        setDomainError("Domain names can only contain letters and numbers.");
      }
    } else {
      setMicrosoftID(e.target.value);
      setDomainError(null);
    }

    if (e.target.value.length > 0) {
      setBtnChecks({
        ...btnChecks,
        btnCheckAvailability: true,
        btnClear: true
      })
    } else {
      setBtnChecks({
        ...btnChecks,
        btnCheckAvailability: false,
        btnBuySubscription: false,
        btnClear: false
      })
    }
  }

  // Reset all to Initial state
  const clearDomainCheck = () => {
    setDomainError("");
    setSelectDomainCheckOption(newCustomerOnBoard)
    setDomainCheckFE(initStatus.domainValidationFE);
    setIsDomainAvailable(initStatus.domainCheckStatusBE);
    setIsDomainType(initStatus.isDomainType);
    setDomainChecked(false);
    setBtnChecks(initStatus.btnChecks);
    setIsCheckingDomain(false);
    setStepsCompletedCheck({
      ...stepsCompletedCheck,
      isDomainCheckCompleted: false,
      isDomainTransitionCompleted: false,
      isMCACompleted: false,
      isGDAPCompleted: false,
      isDomainFlowCompleted: false,
    });
    setDomainName(initStatus.domainName);
    setMicrosoftID("");
    setMCAAgreementDetails(initStatus.mcaAgreementDetails);
    setIsMCATermsAgreed(false);
    setTransitionDomainChecks(initStatus.transitionDomainChecks);
    setGetDomainTrasnitionUri(initStatus.getDomainTrasnitionUri);
    setMCAFormErrors({
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      phoneNumberError: null,
      dateError: null
    });
    setValidDate(false);
    setMCAErrorMessage("");
    setMCAErrorMessageFlag(false);
    setTransitionErrorBlock(false);
  }

  // Proceed Flow 
  const proceedDomainFlow = () => {
    setStepsCompletedCheck({
      ...stepsCompletedCheck,
      isDomainCheckCompleted: true
    });

    setActiveAccordion(["mcaDetails"]);


  }

  // Transition Flow
  async function getTransitionDomain() {
    await api.get(CSP_DOMAIN_TRANSITION, config)
      .then(resp => {

        if (resp.status == 200) {
          setGetDomainTrasnitionUri(resp.data);
          setTransitionErrorBlock(false);
          setGetDomainTrasnitionUri({
            ...getDomainTrasnitionUri,
            microsoftCspUri: resp.data.microsoftCspUri,
            tenantUri: resp.data.tenantUri

          });
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        setTransitionErrorBlock(true);
      })
    setStepsCompletedCheck({
      ...stepsCompletedCheck,
      isDomainCheckCompleted: true
    });

    setActiveAccordion(["transitionDomain"]);

  }



  const submitDomain = () => {
    setIsAllStepsCompleted(true);
  }

  function submitMCAForm() {
    let mcaFormValid = true;


    if (!emailRegEx.test(mcaAgreementDetails.email)) {
      setMCAFormErrors({
        ...mcaFormErrors,
        emailError: true
      });
      mcaFormValid = false
    } else {
      setMCAFormErrors({
        ...mcaFormErrors,
        emailError: false
      });

      mcaFormValid = true
    }

    if (mcaFormValid) {
      saveMCADetails()
    }
  }

  // Post MCA Details 
  async function saveMCADetails() {
    setSubmittingMCA(true);
    setMCAErrorMessage("");
    setMCAErrorMessageFlag(false);
    const body = {

      firstName: mcaAgreementDetails.firstName,
      lastName: mcaAgreementDetails.lastName,
      email: mcaAgreementDetails.email,
      phoneNumber: mcaAgreementDetails.phoneNumber,
      mcaAcceptanceDate: `${mcaAgreementDetails.mcaAcceptanceDate[2]}/${mcaAgreementDetails.mcaAcceptanceDate[1]}/${mcaAgreementDetails.mcaAcceptanceDate[0]}`,
      customerCode: customerId,
      serviceId: props.cspServiceId,
      domainName: domainName.includes(".") ? domainName.split(".")[0] : domainName // Send only one word i.e; before ".onmicrosoft.com"
    };

    await api.post(CSP_MCA_AGREEMENT_DETAILS, body, config)
      .then(resp => {
        if (resp.status == 200) {

          if (resp.data.isSuccess == true) {
            setIsMCAFirstTime(true);
            props.GetMCADetails();
            setMCACompleted(true);
            setIsDomainBuySuccess(true);
            setMCAStatus(true);
            props.setIscomingFromProvision(false);
            setDomainName(domainName);
            setIsDomainBuySuccess(true);
            setIsDomainProvisioned(true);
            setStepsCompletedCheck({
              ...stepsCompletedCheck,
              isMCACompleted: true
            });
          }
        }
      })
      .catch(error => {
        setSubmittingMCA(false);
        setMCAErrorMessageFlag(true);
        setMCAErrorMessage(error.response.data.errors[0]);
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      })
  }

  // Get GDAP Details
  async function getGDAPDetails() {
    setIsProceeding(true);
    setTransitionErrorBlock(false);

    const body = {
      customerCode: customerId,
      vendorAccountId: microsoftID,
    };

    try {
      const response = await api.post(CSP_GET_GDAP_DETAILS, body, config);
      const data = response.data;

      if (response.status === 200) {
        setGDAPDetails({
          gdapUri: data.gdapUri,
          delegateAdminRelationshipId: data.delegateAdminRelationshipId,
          message: data.message,
          isSuccess: data.isSuccess,
          assignedRoles: data.assignedRoles,
        });

        if ((data.message === '504' && !data.isSuccess) ||
          (data.gdapUri === null || data.assignedRoles.length < 1)) {
          intervalId = setInterval(getTransitionDomainStatusRoles, 20000);
          setTimeout(() => {
            clearInterval(intervalId);
            setDelegateAdminRelationshipApproved(false);
          }, 600000);
        } else {
          setAssignedRoles(data.assignedRoles || []);
          setDelegateAdminRelationshipId(data.delegateAdminRelationshipId);
          setGDAPUri(data.gdapUri);
        }
      }

      if (response.data.isSuccess) {
        setStepsCompletedCheck({
          ...stepsCompletedCheck,
          isDomainTransitionCompleted: true
        });
        setActiveAccordion(["gdap"]);
        setTransitionErrorBlock(false);
        setTransitionErrorMessage("")
      } else {
        setTransitionErrorBlock(true);
        setTransitionErrorMessage(response.data.message)
      }

    } catch (error) {
      console.error('error', error);
      if (error?.status == 401 || error?.response?.status == 401) {
        props.Logout();
      }
    } finally {

    }
    setIsProceeding(false);
  }

  // Function to get GDAP details incase of delay from Microsoft. This is Backup GET API
  async function getTransitionDomainStatusRoles() {
     
      await api.get(`${CSP_TRANSITION_ONBOARD_STATUS}?vendorAccountId=${microsoftID}`, config)
        .then((resp) => {

          if (resp.status === 200 && resp.data.status === 'Success') {
            setOnBoardDetails({
              ...onBoardDetails,
              status: resp.data.status,
              gdapUri: resp.data.gdapUrl,
              delegateAdminRelationshipId: resp.data.delegateRelationshipId,
              message: resp.data.message,
              isSuccess: resp.data.isSuccess,
              assignedRoles: resp.data.assignedRoles,
            });
            clearInterval(intervalId);
            setAssignedRoles(resp.data.assignedRoles);
            setDelegateAdminRelationshipId(resp.data.delegateRelationshipId);
            setGDAPUri(resp.data.gdapUrl);
          }
        })
        .catch((error) => {

          if (error?.status == 401 || error?.response?.status == 401) {
            props.Logout();
          }
        });
 
    setTimeout(() => {
      clearInterval(intervalId);
    }, 200000);
  }



  async function GDAPTransitionStatusPOST() {
    setIsCompletinTtansaction(true);
    const body = {
      delegateAdminRelationshipId: gdapDetails.delegateAdminRelationshipId
    };

    await api.post(`${CSP_GDAP_TRANSITION_STATUS}?delegateAdminRelationshipId=${delegateAdminRelationshipId}`, body, config)
      .then(resp => {
        setDelegateAdminRelationshipApproved(true);
        setIsMCAFirstTime(true);
        setStepsCompletedCheck({
          ...stepsCompletedCheck,
          isMCACompleted: props.isMCACompleted
        });
        props.GetMCADetails();
        props.setIscomingFromProvision(false);
        setDomainName(domainName);
        setIsDomainBuySuccess(true);
        setIsDomainProvisioned(true);

      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        } else {
          setDelegateAdminRelationshipApproved(true);
          setIsMCAFirstTime(true);
          setStepsCompletedCheck({
            ...stepsCompletedCheck,
            isMCACompleted: props.isMCACompleted
          });
          props.GetMCADetails();
          props.setIscomingFromProvision(false);
          setDomainName(domainName);
          setIsDomainBuySuccess(true);
          setIsDomainProvisioned(true);
        }
      });

    setIsCompletinTtansaction(false)
  }

  // Get Customer Domain to display Domain in Transition Flow. We are not taking domain name any where in transition flow.
  async function GetCustomerDomain() {

    await api.get(`${PROVISIONING_GET_CUSTOMER_DOMAIN}?customerCode=${customerId}&serviceId=${props.serviceId}&integrationCode=${integrationCode}`, config)
      .then(resp => {
        if (resp.status == 200) {
          setDomainName(resp?.data.domainName);
          setIsDomainProvisioned(resp?.data.domainName ? true : false);
        }

        if (resp.status == 204) {
          setDomainName("");
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
      });

  }

  const handleMCAFormChange = (e) => {
    const { name, value } = e.target;

    setMCAAgreementDetails(prev => {
      return { ...prev, [name]: value }
    })
  }

  useEffect(() => {
    let todayDate = new Date();


    if (
      (mcaAgreementDetails.firstName.length > 0 && mcaAgreementDetails.firstName.length <= 30 && /^[a-zA-Z\s]*$/.test(mcaAgreementDetails.firstName)) &&
      (mcaAgreementDetails.lastName.length > 0 && mcaAgreementDetails.lastName.length <= 30 && /^[a-zA-Z\s]*$/.test(mcaAgreementDetails.lastName)) &&
      (mcaAgreementDetails.email?.length > 0) &&
     // (phoneRegex.test(mcaAgreementDetails.phoneNumber)) &&
      (mcaAgreementDetails.mcaAcceptanceDate?.length > 1 && mcaAgreementDetails.mcaAcceptanceDate <= todayDate.toISOString().split('T')[0])  &&
      (validDate === true)
    ) {
      setIsmcaFormHasNoErros(true);
    } else {
      setIsmcaFormHasNoErros(false);
    }
  }, [mcaAgreementDetails]);


  useEffect(() => {





    if (
      (props.mcaDomainName !== "" || isDomainProvisioned) && props.isMCACompleted !== true) {
      setStepsCompletedCheck({
        ...stepsCompletedCheck,
        isDomainCheckCompleted: true,
      });

      setIsDomainType({
        ...isDomainType,
        isDomainTransition: false
      });

      setDomainName(props.mcaDomainName);
      setActiveAccordion("mcaDetails");
    }

  }, [props.selectedOption, props.mcaDomainName, props.serviceId]);



  return (
    <>
      <Toast toastCheckUseState={updatedToastMessage} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
      <div className="main-content pb-5">

        {!props.isLoading &&
          <>
            {(props.mcaDomainName !== "" || isDomainProvisioned) &&
              <>
                {props.isMCACompleted &&

                  <div className="accordion-panel">
                    <Accordion
                      className="pre-requisite-panels mb-5"
                      activeKey={activeAccordion}
                      onSelect={(e) => { setActiveAccordion(e) }}
                      alwaysOpen>

                      <CSPDomainProvisioned
                        domainName={props.mcaDomainName ? props.mcaDomainName : domainName}
                        mcaDetails={mcaAgreementDetails}
                        customerId={customerId}
                        customerName={customerNameDefaultValue}
                        serviceId={props.serviceId}
                        comingFromProvision={props.comingFromProvision}
                        isMCAFirstTime={isMCAFirstTime}
                        mcaFirstTimeDate={mcaFirstTimeDate}
                        mcaCompleted={mcaCompleted}
                        setActiveLocalKey={props.setActiveLocalKey}
                      />
                    </Accordion>
                  </div>

                }

                {(!props.isMCACompleted || mcaStatus == false) &&
                  <>

                    {(isDomainType.isDomainTransition != true) &&

                      <div className="accordion-panel">
                        <Accordion
                          className="pre-requisite-panels"
                          activeKey={activeAccordion}
                          onSelect={(e) => { setActiveAccordion(e); setExpandedAll(false) }}
                        >

                          <DomainCheck
                            domainCheckBlockErrors={domainCheckBlockErrors}
                            stepsCompletedCheck={stepsCompletedCheck}
                            getDomainAvailability={getDomainAvailability}
                            getTenantAvailability={getTenantAvailability}
                            isDomainType={isDomainType}
                            domainName={props.mcaDomainName}
                            mcaDomainName={domainName}
                            isDomainAvailable={isDomainAvailable}
                            domainCheckFE={domainCheckFE}
                            btnChecks={btnChecks}
                            checkDomainAvailability={checkDomainAvailability}
                            getTransitionDomain={getTransitionDomain}
                            clearDomainCheck={clearDomainCheck}
                            proceedDomainFlow={proceedDomainFlow}
                            isCheckingDomain={isCheckingDomain}
                            domainChecked={domainChecked}
                            microsoftId={microsoftID}
                            selectDomainCheckOption={selectDomainCheckOption}
                            setSelectDomainCheckOption={setSelectDomainCheckOption}
                            setDomainError={setDomainError}
                            isDomainProvisioned={isDomainProvisioned}
                            showDomainExtension={props.showDomainExtension}
                          />

                          <MCAAgreement
                            domainCheckBlockErrors={domainCheckBlockErrors}
                            isDomainType={isDomainType}
                            stepsCompletedCheck={stepsCompletedCheck}
                            mcaAgreementDetails={mcaAgreementDetails}
                            setMCAAgreementDetails={setMCAAgreementDetails}
                            handleMCAFormChange={handleMCAFormChange}
                            setIsMCATermsAgreed={setIsMCATermsAgreed}
                            isMCATermsAgreed={isMCATermsAgreed}
                            ismcaFormHasNoErros={ismcaFormHasNoErros}
                            setActiveAccordion={setActiveAccordion}
                            submittingMCA={submittingMCA}
                            setMCAFormErrors={setMCAFormErrors}
                            mcaFormErrors={mcaFormErrors}
                            mcaErrorMessageFlag={mcaErrorMessageFlag}
                            submitMCAForm={submitMCAForm}
                            setValidDate={setValidDate}
                            DownloadMCAAgreement={DownloadMCAAgreement}
                            mcaErrorMessage={mcaErrorMessage}
                            MCA_AGREEMENT_DOWNLOAD={MCA_AGREEMENT_DOWNLOAD}
                            mcaDownloadUri={mcaDownloadUri}
                            GetMCAAgreement={GetMCAAgreement}
                            mcadisplayUri={mcaDisplayUri}
                            setMCAFirstimeDate={setMCAFirstimeDate}
                          />
                        </Accordion>
                      </div>
                    }

                    {isDomainBuySuccess == true && isDomainType.isDomainTransition == true &&
                      <BuyDomainSuccess
                        isDomainType={isDomainType}
                        setIsDomainType={setIsDomainType}
                        customerId={customerId || props.customerId}
                        customerName={customerNameDefaultValue}
                        domainName={domainName}
                        serviceId={props.serviceId}
                        setActiveAccordion={setActiveAccordion}
                        setDomainName={setDomainName}
                        setMCAStatus={setMCAStatus}
                        setIsDomainProvisioned={setIsDomainProvisioned}
                        setActiveLocalKey={props.setActiveLocalKey}
                        GetCustomerDomain={GetCustomerDomain}
                        setSelectDomainCheckOption={setSelectDomainCheckOption}
                      />
                    }

                  </>
                }
              </>
            }
          </>
        }

        {!props.isLoading &&
          <>
            {(props.mcaDomainName == "" && !isDomainProvisioned) &&
              <>
                {customerId && <>
                  {isAllStepsCompleted == false &&
                    <div className="accordion-panel">
                      <Accordion
                        className="pre-requisite-panels"
                        activeKey={activeAccordion}
                        onSelect={(e) => { setActiveAccordion(e); setExpandedAll(false) }}
                        alwaysOpen>
                        <DomainCheck
                          domainCheckBlockErrors={domainCheckBlockErrors}
                          stepsCompletedCheck={stepsCompletedCheck}
                          getDomainAvailability={getDomainAvailability}
                          getTenantAvailability={getTenantAvailability}
                          isDomainType={isDomainType}
                          domainName={domainName}
                          isDomainAvailable={isDomainAvailable}
                          domainCheckFE={domainCheckFE}
                          btnChecks={btnChecks}
                          checkDomainAvailability={checkDomainAvailability}
                          getTransitionDomain={getTransitionDomain}
                          clearDomainCheck={clearDomainCheck}
                          proceedDomainFlow={proceedDomainFlow}
                          isCheckingDomain={isCheckingDomain}
                          domainChecked={domainChecked}
                          domainError={domainError}
                          setDomainError={setDomainError}
                          selectDomainCheckOption={selectDomainCheckOption}
                          setSelectDomainCheckOption={setSelectDomainCheckOption}
                          microsoftId={microsoftID}
                          isDomainProvisioned={isDomainProvisioned}

                        />

                        {(isDomainType.isDomainProceed == true && isDomainAvailable.isExistsInSynnex !== true && selectDomainCheckOption !== transitionDomain) &&
                          <MCAAgreement
                            domainCheckBlockErrors={domainCheckBlockErrors}
                            isDomainType={isDomainType}
                            stepsCompletedCheck={stepsCompletedCheck}
                            mcaAgreementDetails={mcaAgreementDetails}
                            setMCAAgreementDetails={setMCAAgreementDetails}
                            handleMCAFormChange={handleMCAFormChange}
                            setIsMCATermsAgreed={setIsMCATermsAgreed}
                            isMCATermsAgreed={isMCATermsAgreed}
                            ismcaFormHasNoErros={ismcaFormHasNoErros}
                            setActiveAccordion={setActiveAccordion}
                            submittingMCA={submittingMCA}
                            setMCAFormErrors={setMCAFormErrors}
                            mcaFormErrors={mcaFormErrors}
                            submitMCAForm={submitMCAForm}
                            setValidDate={setValidDate}
                            DownloadMCAAgreement={DownloadMCAAgreement}
                            mcaErrorMessage={mcaErrorMessage}
                            mcaErrorMessageFlag={mcaErrorMessageFlag}
                            MCA_AGREEMENT_DOWNLOAD={MCA_AGREEMENT_DOWNLOAD}
                            GetMCAAgreement={GetMCAAgreement}
                            mcadisplayUri={mcaDisplayUri}
                            mcaDownloadUri={mcaDownloadUri}
                            setMCAFirstimeDate={setMCAFirstimeDate}
                          />
                        }

                        {(isDomainType.isDomainTransition == true && isDomainAvailable.isExistsInSynnex !== true && selectDomainCheckOption == transitionDomain) &&
                          <TransitionDomain
                            domainCheckBlockErrors={domainCheckBlockErrors}
                            stepsCompletedCheck={stepsCompletedCheck}
                            domainName={domainName}
                            getDomainTrasnitionUri={getDomainTrasnitionUri}
                            transitionDomainChecks={transitionDomainChecks}
                            setTransitionDomainChecks={setTransitionDomainChecks}
                            setActiveAccordion={setActiveAccordion}
                            getGDAPDetails={getGDAPDetails}
                            getTransitionDomainStatusRoles={getTransitionDomainStatusRoles}
                            transitionErrorBlock={transitionErrorBlock}
                            transitionErrorMessage={transitionErrorMessage}
                            isProceeding={isProceeding}
                            toggleDrawer={props.toggleDrawer}
                            setSelectService={props.setSelectService}
                            setSelectCategory={props.setSelectCategory}
                            defaultServiceName={defaultServiceName}
                            defaultCategoryName={defaultCategoryName}
                            setMicrosoftID={setMicrosoftID}
                            microsoftID={microsoftID}
                          />
                        }

                        {stepsCompletedCheck.isDomainTransitionCompleted &&
                          <GDAPAssignment
                            domainCheckBlockErrors={domainCheckBlockErrors}
                            stepsCompletedCheck={stepsCompletedCheck}
                            setActiveAccordion={setActiveAccordion}
                            isGDAPTermsChecked={isGDAPTermsChecked}
                            setIsGDAPTermsChecked={setIsGDAPTermsChecked}
                            setIsAllStepsCompleted={setIsAllStepsCompleted}
                            isDomainType={isDomainType}
                            gdapDetails={gdapDetails}
                            GDAPTransitionStatusPOST={GDAPTransitionStatusPOST}
                            assignedRoles={assignedRoles}
                            delegateAdminRelationshipApproved={delegateAdminRelationshipApproved}
                            gdapURI={gdapURI}
                            isCompletinTtansaction={isCompletinTtansaction}
                            GetCustomerDomain={GetCustomerDomain}
                          />
                        }

                        <p className="red text-small mt-2 d-none">
                          Note: Organization Details section is not complete. Please fill all the details to proceed.
                        </p>
                      </Accordion>
                    </div>
                  }

                  {isDomainBuySuccess == true && isDomainType.isDomainTransition == true &&
                    <BuyDomainSuccess
                      isDomainType={isDomainType}
                      setIsDomainType={setIsDomainType}
                      customerId={customerId || props.customerId}
                      customerName={customerNameDefaultValue}
                      domainName={domainName}
                      serviceId={props.serviceId}
                      setActiveAccordion={setActiveAccordion}
                      setDomainName={setDomainName}
                      setMCAStatus={setMCAStatus}
                      setIsDomainProvisioned={setIsDomainProvisioned}
                      setActiveLocalKey={props.setActiveLocalKey}
                      GetCustomerDomain={GetCustomerDomain}
                      setSelectDomainCheckOption={setSelectDomainCheckOption}
                    />
                  }
                </>}
              </>
            }
          </>
        }
      </div >
    </>
  )
}

export default CSPCustomerPrerequisite