import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Container, Col, Nav, Row, Tab, Button } from 'react-bootstrap';
import styles from './mcaBulkUpload.module.scss';

import { api } from '../../../../../Utils/API';
import FileUpload from '../../../../../GlobalComponents/FileUpload';
import Toast from '../../../../../GlobalComponents/Toast';
import DataTable from 'react-data-table-component';
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif";
import NoDataFound from "../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg";
import { DOWNLOAD_BULK_UPLOAD_TEMPLATE } from '../../../../../Utils/GlobalConstants';


const MCABulkUpload = (props) => {

    // get Location
    const navigate = useNavigate();



    const [tabName, setTabName] = useState("all");
    const [uploadedFile, setUploadedFile] = useState();                         // for uploading file 
    const [isMcaBulkUpload, setIsMcaBulkUpload] = useState(true);              // for downloading templates
    const [showTable, setShowTable] = useState(false);                          // setting value of count table as false
    const [loadingState, setLoadingState] = useState(false);                           // loading state while api is called
    const [columns, setColumns] = useState([]);                                         // columns used to display column headers in the react data table component

    const mappingTableColumns = ["Company Name", "Contact Name", "MCA Domain", "Audit Id", "MCA Agreement", "Status", "Message"]; // Column Names

    const [processing, setProcessing] = useState(false);                        // Uplaoding process
    const [isDownloadProcessing, setIsDownloadProcessing] = useState(false);    // Downloading process
    const [isDownloadReportProcessing, setIsDownloadReportProcessing] = useState(false);    // Downloading process
    const [emptyFileError, setEmptyFileError] = useState(null);                 // Error for empty state


    const [allTabCount, setAllTabCount] = useState(0);      // All Tab Count to display above Table
    const [failedCount, setFailedCount] = useState(0);      // Failed Tab Count to display above Table
    const [successCount, setSuccessCount] = useState(0);    // Success Tab Count to display above Table

    const [bulkmapingTable, setBulkmapingTable] = useState([]); // All Table
    const [bulkmapingTableFail, setBulkmapingTableFail] = useState([]); // Failed Table
    const [bulkmapingTableSuccess, setBulkmapingTableSuccess] = useState([]); // Success Table
    const [auditTrackId, setAuditTrackId] = useState(null);     // Audit Track ID

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");


    const [selectedFile, setSelectedFile] = useState(null);


    // Logout Function on error 410
    // function Logout() {
    //     props.setCustomerIsAuthenticated(false);
    //     if (props.customerIsAuthenticated) { navigate("/") };
    //     localStorage.clear();
    //     instance.logoutRedirect();
    // }

    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };


    // Header Authorization for uploading file API
    const configFile = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Download CSV File template
    async function DownloadAction(isMcaBulkUpload, url, name, fileType) {
        let configuration;
        setIsDownloadProcessing(true);
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}/download-template?isMcaBulkUpload=${isMcaBulkUpload}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {

                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });

                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }

            });
        setIsDownloadProcessing(false);
    };

    // Download CSV File template
    async function DownloadReport(url, name, fileType) {
        setIsDownloadReportProcessing(true);
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}/download-mca-bulk-upload-report/${auditTrackId}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {

                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });

                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }

            });
        setIsDownloadReportProcessing(false);
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // api for uploading file 
    async function BulkUpload() {
        setProcessing(true);
        setEmptyFileError(null);
        await api.post(DOWNLOAD_BULK_UPLOAD_TEMPLATE + "/mca-import", uploadedFile, configFile)
            .then(resp => {
                if (resp.status == 200) {

                    setLoadingState(true);
                    setProcessing(false);
                    setShowTable(true);


                    // setBulkmapingTable(resp.data.response);
                    setToastMessage("File uploaded successfully!");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 2000);
                    setAuditTrackId(resp.data.auditTrackId);
                    let f = [];       // All Tab
                    let ff = [];       // Failed Tab
                    let fs = [];       // Success Tab

                    (resp.data.mcaCustomers).map((obj) => {
                        f.push
                            (//let ColumnName = ["Company  Name", "Contact Name", "MCA Domain", "Audit Id", "MCA Agreement","Status", "Messsage"];
                                {
                                    "Company Name": obj.organisationName,
                                    "Contact Name": obj.contactName != null ? obj.contactName : "",
                                    "MCA Domain": obj.mcaDomain != null ? obj.mcaDomain : "",
                                    "Audit Id": obj.auditId != null ? obj.auditId : "",
                                    "MCA Agreement": obj.mcaAgreementDate,
                                    "Status": obj.status == "Failed" ?

                                        <span className='d-block text-center mt-1'>
                                            <span className="status-error">&nbsp;</span><br />
                                            <span className="red text-small">Failed</span>
                                        </span>

                                        :

                                        <span className='d-block text-center mt-1'>
                                            <span className="status-success">&nbsp;</span><br />
                                            <span className="green text-small">Success</span>
                                        </span>

                                    ,
                                    "Message": obj.message
                                }
                            );
                    });

                    // Setting All Table Data
                    setAllTabCount(f.length);
                    setTimeout(() => {
                        setBulkmapingTable(f);
                    }, 50);

                    (resp.data.mcaCustomers).map((obj) => {
                        if (obj.status == "Failed") {
                            ff.push
                                (
                                    {
                                        "Company Name": obj.organisationName,
                                        "Contact Name": obj.contactName != null ? obj.contactName : "",
                                        "MCA Domain": obj.mcaDomain != null ? obj.mcaDomain : "",
                                        "Audit Id": obj.auditId != null ? obj.auditId : "",
                                        "MCA Agreement": obj.mcaAgreementDate,
                                        "Status": obj.status == "Failed" ?

                                            <span className='d-block text-center mt-1'>
                                                <span className="status-error">&nbsp;</span><br />
                                                <span className="red text-small">Failed</span>
                                            </span>

                                            :

                                            <span className='d-block text-center mt-1'>
                                                <span className="status-success">&nbsp;</span><br />
                                                <span className="green text-small">Success</span>
                                            </span>

                                        ,
                                        "Message": obj.message
                                    }
                                );
                        }
                    });


                    // Setting Fail Table Data
                    setFailedCount(ff.length);
                    setTimeout(() => {
                        setBulkmapingTableFail(ff);

                    }, 50);

                    (resp.data.mcaCustomers).map((obj) => {
                        if (obj.status == "Success") {
                            fs.push
                                (
                                    {
                                        "Company Name": obj.organisationName,
                                        "Contact Name": obj.contactName != null ? obj.contactName : "",
                                        "MCA Domain": obj.mcaDomain != null ? obj.mcaDomain : "",
                                        "Audit Id": obj.auditId != null ? obj.auditId : "",
                                        "MCA Agreement": obj.mcaAgreementDate,
                                        "Status": obj.status == "Failed" ?

                                            <span className='d-block text-center mt-1'>
                                                <span className="status-error">&nbsp;</span><br />
                                                <span className="red text-small">Failed</span>
                                            </span>

                                            :

                                            <span className='d-block text-center mt-1'>
                                                <span className="status-success">&nbsp;</span><br />
                                                <span className="green text-small">Success</span>
                                            </span>
                                        ,
                                        "Message": obj.message
                                    }
                                );
                        }
                    });

                    setBulkmapingTableSuccess(fs);

                    // Setting Success Table Data
                    setSuccessCount(fs.length);
                    setTimeout(() => {
                        setBulkmapingTableSuccess(fs);

                    }, 50);


                    let d = [];
                    mappingTableColumns.map((td) => {
                        if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                grow: 1.2,
                            })
                        } else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1.2,
                            })
                        }

                    })
                    setColumns(d);       // Setting Column Data
                }
            })
            .catch(error => {

                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                } else if (error.response.status == 500) {

                    setEmptyFileError("Facing issues in reaching servers, Try again later.");
                } else if (error.response.status == 400) {
                    setEmptyFileError(error.response?.data?.errors?.[0] || "Please enter data in file");

                }
            });
        setProcessing(false);
        // setSelectedFile(null);
        document.getElementById('file-upload').value = '';
        // setUploadedFile(null);
        setTabName("all");
    };

    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'left',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                textAlign: 'left',
            },
        },
    };



    return (
        <Container fluid className="main-content px-0 cloud-summery-details-table">


            <Container fluid className="my-3">

                <Row className="align-items-center">

                    <Col className="gotoback col-md-6" onClick={() => navigate(-1)}>
                        <span className="back">&nbsp;</span>
                        <span>MCA bulk upload</span>
                    </Col>
                </Row>
                <Row className="details-box px-3 py-3">

                    <>
                        <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                        <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />



                        <div className={`py-3 ${styles["upload-block"]}`}>
                            <Row>
                                <Col className={`${styles["number-block"]}`}>
                                    <span>1</span>
                                </Col>
                                <Col>
                                    <p>Click on Download button for "MCA Bulk Upload Template". </p>
                                    <p>
                                        {!isDownloadProcessing &&
                                            <Button className="ml-3 px-4 mr-3" onClick={(e) => { DownloadAction(isMcaBulkUpload, DOWNLOAD_BULK_UPLOAD_TEMPLATE, isMcaBulkUpload ? `Customer_MCA_Bulk_Upload_Template.csv` : `Customer_Bulk_Upload_Template.csv`, "csv") }}>Download Template</Button>
                                        }

                                        {isDownloadProcessing &&
                                            <Button
                                                variant="light"
                                                className="btn btn-lg px-3"
                                                disabled
                                            >Downloading Template...</Button>
                                        }
                                    </p>


                                </Col>
                            </Row>
                            <Row>
                                <Col className={`${styles["number-block"]}`}>
                                    <span>2</span>
                                </Col>
                                <Col>
                                    <p>Complete the details in the template.</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className={`${styles["number-block"]}`}>
                                    <span>3</span>
                                </Col>
                                <Col>
                                    <p>Upload the MCA bulk upload template</p>
                                    <form>
                                        <Row>
                                            <Col lg={4} md={6}>
                                                <FileUpload fileFormat=".csv"
                                                    setUploadedFile={setUploadedFile}
                                                    selectedFile={selectedFile}
                                                    setSelectedFile={setSelectedFile}
                                                    setEmptyFileError={setEmptyFileError}
                                                />
                                            </Col>
                                            <Col md={6} className="text-left">
                                                {(!processing && !selectedFile) &&
                                                    <Button
                                                        variant="light"
                                                        className="px-4 mx-2"
                                                        disabled
                                                    >
                                                        Clear
                                                    </Button>
                                                }
                                                {!processing && selectedFile && <Button
                                                    variant="light"
                                                    className="px-4 btn-border-light w-md-auto col-xs-12 mx-2"
                                                    onClick={() => {
                                                        setProcessing(false);
                                                        setSelectedFile(null);
                                                        document.getElementById('file-upload').value = ''; setUploadedFile(null)
                                                        setBulkmapingTable([]);
                                                        setBulkmapingTableFail([]);
                                                        setBulkmapingTableSuccess([]);
                                                        setShowTable(false);
                                                        setTabName("all");
                                                        setEmptyFileError(null);
                                                    }}>
                                                    Clear
                                                </Button>
                                                }
                                                {!processing && <>
                                                    {!selectedFile &&
                                                        <Button
                                                            variant="light"
                                                            className="px-4 mx-2"
                                                            disabled
                                                        >
                                                            Upload
                                                        </Button>
                                                    }
                                                    {selectedFile && !processing &&
                                                        <Button
                                                            className="btn btn-lg px-3 mx-2"
                                                            variant="primary"
                                                            onClick={(e) => { BulkUpload() }}>
                                                            Upload
                                                        </Button>
                                                    }
                                                </>}

                                                {processing &&
                                                    <>
                                                        <Button
                                                            variant="light"
                                                            className="px-4 mx-2"
                                                            disabled
                                                        >
                                                            Clear
                                                        </Button>
                                                        <Button
                                                            variant="light"
                                                            className="px-4 mx-2"
                                                            disabled
                                                        >
                                                            Uploading...
                                                        </Button>
                                                    </>
                                                }
                                            </Col>

                                            <Col md={12}>
                                                <p className='text-small red'> {emptyFileError && <>{emptyFileError}</>}</p>
                                            </Col>
                                        </Row>
                                    </form>

                                </Col>
                            </Row>
                            {showTable &&
                                <Row>
                                    <Col lg={12}>
                                        <h2>MCA Bulk Upload - Confirmation</h2>
                                    </Col>
                                    <Col lg={12}>
                                        <Tab.Container id="main-tab-bar" defaultActiveKey="all">
                                            <div className="mgmt-console-table-row">
                                                <div className="my-3 d-flex">

                                                    <Col className={`py-0 px-0 ${styles['profile-table']}`}>
                                                        <Tab.Container activeKey={tabName}>
                                                            <Row>
                                                                <Col md={8}>
                                                                    <Nav variant="pills" className='mb-4'>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="all" className={`bulk-mapping-tabs pl-6 pr-6 pt-3 pb-3`}
                                                                                onClick={() => { setTabName("all") }}
                                                                            >
                                                                                All ({allTabCount})</Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="failed" className={`bulk-mapping-tabs pl-6 pr-6 pt-3 pb-3`}
                                                                                onClick={() => { setTabName("failed") }}
                                                                            >
                                                                                Failed ({failedCount})</Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="success" className={`bulk-mapping-tabs pl-6 pr-6 pt-3 pb-3`}
                                                                                onClick={() => { setTabName("success") }}
                                                                            >
                                                                                Successful ({successCount})</Nav.Link>
                                                                        </Nav.Item>
                                                                    </Nav>
                                                                </Col>
                                                                <Col md={4} className="d-flex justify-content-md-end justify-content-start">
                                                                    {isDownloadReportProcessing &&
                                                                        <Col md={12} className="d-flex justify-content-md-end mb-3 disabled">
                                                                            <span className={`${styles['download-report']}`}><span className="download">&nbsp;</span>Downloading Report...</span>
                                                                        </Col>
                                                                    }
                                                                    {!isDownloadReportProcessing &&


                                                                        <Col md={12} className="d-flex justify-content-md-end mb-3">
                                                                            <span className={`${styles['download-report']}`} onClick={(e) => { DownloadReport(DOWNLOAD_BULK_UPLOAD_TEMPLATE, "MCA_Bulk_Report.csv") }}><span className="download">&nbsp;</span>Download Report</span>
                                                                        </Col>
                                                                    }
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <Tab.Content className={styles['bulk-mapping-tab-pane']}>
                                                                        <Tab.Pane eventKey="all">
                                                                            <div className='p-0'>
                                                                                <div className={`table-responsive bulk-upload-table ${styles['bulk-mapping-table']}`}>
                                                                                    <DataTable
                                                                                        columns={columns}
                                                                                        data={bulkmapingTable}
                                                                                        theme="solarized"
                                                                                        customStyles={customStyles}
                                                                                        persistTableHead={false}
                                                                                        noDataComponent={<div className={styles["no-data-found"]}><img className="" src={NoDataFound} /><p className="text-medium text-strong management-console-loading-text">No Data Found</p></div>}
                                                                                        width="100%"
                                                                                        progressComponent={<div className={styles["no-data-cloud-invoice"]}>
                                                                                            <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                                                                                        </div>}
                                                                                        fixedHeaderScrollHeight="50vh"
                                                                                        fixedHeader
                                                                                        pagination={false}
                                                                                        paginationServer
                                                                                        paginationRowsPerPageOptions={[25, 50, 75, 100, 125, 150]}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Tab.Pane>
                                                                        <Tab.Pane eventKey="failed">
                                                                            {bulkmapingTableFail.length > 0 &&
                                                                                <div className={`table-responsive bulk-upload-table ${styles['bulk-mapping-table']}`}>
                                                                                    <DataTable
                                                                                        columns={columns}
                                                                                        data={bulkmapingTableFail}
                                                                                        theme="solarized"
                                                                                        customStyles={customStyles}
                                                                                        persistTableHead={false}
                                                                                        width="100%"
                                                                                        // noDataComponent={<div className={styles["no-data-found"]}><img className="" src={NoDataFound} /><p className="text-medium text-strong management-console-loading-text">No Data Found</p></div>}
                                                                                        noDataComponent={<p>No records</p>}
                                                                                        progressComponent={<div className={styles["no-data-cloud-invoice"]}>
                                                                                            <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                                                                                        </div>}
                                                                                        fixedHeaderScrollHeight="50vh"
                                                                                        fixedHeader
                                                                                        pagination={false}
                                                                                        paginationServer
                                                                                        paginationRowsPerPageOptions={[25, 50, 75, 100, 125, 150]}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            {bulkmapingTableFail.length < 1 &&
                                                                                <>No data found</>
                                                                            }
                                                                        </Tab.Pane>
                                                                        <Tab.Pane eventKey="success">
                                                                            {bulkmapingTableSuccess.length > 0 &&
                                                                                <div className={`table-responsive bulk-upload-table ${styles['bulk-mapping-table']}`}>
                                                                                    <DataTable
                                                                                        columns={columns}
                                                                                        data={bulkmapingTableSuccess}
                                                                                        theme="solarized"
                                                                                        customStyles={customStyles}
                                                                                        persistTableHead={false}
                                                                                        noDataComponent={<div className={styles["no-data-found"]}><img className="" src={NoDataFound} /><p className="text-medium text-strong management-console-loading-text">No Data Found</p></div>}
                                                                                        progressComponent={<div className={styles["no-data-cloud-invoice"]}>
                                                                                            <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                                                                                        </div>}
                                                                                        fixedHeaderScrollHeight="50vh"
                                                                                        fixedHeader
                                                                                        pagination={false}
                                                                                        paginationServer
                                                                                        paginationRowsPerPageOptions={[25, 50, 75, 100, 125, 150]}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            {bulkmapingTableSuccess.length < 1 &&
                                                                                <div className={styles["no-data-cloud-invoice"]}>
                                                                                    <img src={NoDataFound} className="no-data-found" />
                                                                                    <h2 className="mt-4 mb-3">No Data Found</h2>
                                                                                </div>
                                                                            }
                                                                        </Tab.Pane>
                                                                    </Tab.Content>
                                                                </Col>
                                                            </Row>
                                                        </Tab.Container>
                                                    </Col>
                                                </div >

                                            </div >
                                        </Tab.Container>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </>
                </Row>
            </Container>
        </Container>
    );
}

export default MCABulkUpload;