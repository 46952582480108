import { Row, Col, Button, Accordion, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../../../Utils/API";
import { CUSTOMER_LISTING_TABLE } from '../../../../../../Utils/GlobalConstants';
import { emailRegEx, numberRegEx, websiteURLRegEx, phoneOnChangeRegex, phoneRegex } from "../../../../../../GlobalComponents/RegExPatterns";
import { useEffect, useRef, useState } from "react";
import Toast from "../../../../../../GlobalComponents/Toast";
import PhoneNumber from "../../../../../../GlobalComponents/PhoneNumber";
import { customerDetailsErros } from "../../../Utils";
import { useMsal } from '@azure/msal-react';

const CustomerDetails = (props) => {
    const location = useLocation();                                                                     // get Location
    const navigate = useNavigate();                                                                     // to use navigate function

    const phoneNumberValidationMsg = "Phone number should between 8 to 15 digits";

    const [customerId, setCustomerId] = useState(location.state !== null ? location.state.customerCode : "");     // Get Customer ID
    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);                              // server error check state in details during api call to load data
    const [enabledSaveBtn, setEnabledSaveBtn] = useState(false);                                        // Save Button Enables once all the conditions are true
    const [updatedToastMessage, setUpdatedToastMessage] = useState(false);                              // To check Toast Message is active
    const [toastError, setToastError] = useState(false);                                                // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                                               // Set Toast Message

    // Expand All Accordions
    const [expandedAll, setExpandedAll] = useState(false);                                              // Expand All Accordions
    const [activeAccordion, setActiveAccordion] = useState(['0']);                                      // Default Active Accordion Id is "0"

    // Expands all accordions
    const handleExpandAll = () => {
        setExpandedAll(!expandedAll);
        setActiveAccordion(expandedAll ? ['0', '1', '2', '3', '4'] : []);
    };

    // Errors display after validation block level
    const [organisationBlockErrors, setOrganisationBlockErrors] = useState(false);                      // Organization Block Errors
    const [addressBlockErrors, setAddressBlockErrors] = useState(false);                                // Address Block Errors
    const [itContactBlockErrors, setItContactBlockErrors] = useState(false);                            // Contact Block Errors
    const [businessContactBlockErrors, setBusinessContactBlockErrors] = useState(false);                // BUsiness Contact Block Errors
    const [billToBlockErrors, setBillToBlockErrors] = useState(false);                                  // Bill To Block Errors

    const selectedSpecialQulifications = ["Microsoft CSP Academic", "CloudCare Academic", "CloudCare Government"]; // Special Qualifications Selected
    const [specialQulifications, setSpecialQulifications] = useState(["Microsoft CSP Academic"]); // Special Qualifications Default

    // To access input elements with useRef Starts. 
    const addressLine1 = useRef();
    const addressLine2 = useRef();
    const city = useRef();
    const state = useRef();
    const zipcode = useRef();
    const organization = useRef();
    const abnNumber = useRef();
    const acnNumber = useRef();

    const websiteUrl = useRef();

    const itContactDetailsEmail = useRef();
    const itContactDetailsFirstName = useRef();
    const itContactDetailsLastName = useRef();
    const itContactDetailsPhoneNumber = useRef();
    const businessContactDetailsEmail = useRef();
    const businessContactDetailsFirstName = useRef();
    const businessContactDetailsLastName = useRef();
    const businessContactDetailsPhoneNumber = useRef();
    const billToDetailsEmail = useRef();
    const billToDetailsFirstName = useRef();
    const billToDetailsLastName = useRef();
    const billToDetailsPhoneNumber = useRef();

    // To access input elements with useRef Ends.

    // States 
    let ausStates = ["WA", "SA", "ACT", "QLD", "TAS", "NSW", "NT", "VIC"];

    // ****************** Front End Validations Starts ****************** //
    const [organizationErrorFe, setOrganizationErrorFe] = useState(null);
    const [websiteURLErrorFe, setWebsiteURLErrorFe] = useState(null);
    const [abnNumberErrorFe, setAbnNumberErrorFe] = useState(null);
    const [acnNumberErrorFe, setAcnNumberErrorFe] = useState(null);
    const [addressLine1ErrorFe, setAddressLine1ErrorFe] = useState("");
    const [addressLine2ErrorFe, setAddressLine2ErrorFe] = useState("");
    const [cityErrorFe, setCityErrorFe] = useState("");
    const [zipCodeErrorFe, setZipCodeErrorFe] = useState("");
    const [stateErrorFe, setStateErrorFe] = useState("");

    // IT Contact Details Error FE
    const [itContactDetailsFirstNameErrorFe, setITContactDetailsFirstNameErrorFe] = useState("");
    const [itContactDetailsLastNameErrorFe, setITContactDetailsLastNameErrorFe] = useState("");
    const [itContactDetailsEmailErrorFe, setITContactDetailsEmailErrorFe] = useState("");
    const [itContactDetailsPhoneNumberErrorFe, setITContactDetailsPhoneNumberErrorFe] = useState("");

    // Business Contact Details Error FE
    const [businessContactDetailsFirstNameErrorFe, setBusinessContactDetailsFirstNameErrorFe] = useState("");
    const [businessContactDetailsLastNameErrorFe, setBusinessContactDetailsLastNameErrorFe] = useState("");
    const [businessContactDetailsEmailErrorFe, setBusinessContactDetailsEmailErrorFe] = useState("");
    const [businessContactDetailsPhoneNumberErrorFe, setBusinessContactDetailsPhoneNumberErrorFe] = useState("");

    // Bill To Details Error FE
    const [billToDetailsFirstNameErrorFe, setBillToDetailsFirstNameErrorFe] = useState("");
    const [billToDetailsLastNameErrorFe, setBillToDetailsLastNameErrorFe] = useState("");
    const [billToDetailsEmailErrorFe, setBillToDetailsEmailErrorFe] = useState("");
    const [billToDetailsPhoneNumberErrorFe, setBillToDetailsPhoneNumberErrorFe] = useState("");
    // ****************** Front End Validations Ends ****************** //

    const [duplicateOrganisationNameCheck, setDuplicateOrganisationNameCheck] = useState(null);

    // Customer Details All Fields 
    const [customerDetails, setCustomerDetails] = useState({
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "",
        "zipcode": "",
        "organization": "",
        "abnNumber": "",
        "acnNumber": "",
        "customerCompanyName": "PIVITAL",
        "customerCode": "",
        "websiteUrl": "",
        "isAcademicApproved": true,
        "isCloudCareEDUApproved": true,
        "isCloudCareGOVTApproved": true,
        "resellerCompanyCode": "",
        "isActive": true,
        "itContactDetails": {
            "email": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": ""
        },
        "businessContactDetails": {
            "email": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": ""
        },
        "billToDetails": {
            "email": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": ""
        },
        "mcaDetails": {
            "mcaEmail": "",
            "mcaFirstName": "",
            "mcaIPAddress": "",
            "mcaLastName": "",
            "mcaPhone": "",
            "mcaAgreementDate": "",
            "mcaDomainFlag": true
        }
    });

    const [customerDetailsUpdated, setCustomerDetailsUpdated] = useState(customerDetails); // Initial Customer Details

    // Reset Customer Details When Click on Reset to initialCustomerDetails
    const resetEditCustomerDetails = () => {
        setActiveAccordion(["0"]);
        navigate('/my-customers');
        setCustomerDetailsUpdated(customerDetails);
        setEnabledSaveBtn(false);

        // Set Address and Organziation Details to null
        setOrganizationErrorFe(null);
        setWebsiteURLErrorFe(null);
        setAbnNumberErrorFe(null);
        setAcnNumberErrorFe(null);
        setAddressLine1ErrorFe("");
        setAddressLine2ErrorFe("");
        setCityErrorFe("");
        setZipCodeErrorFe("");
        setStateErrorFe("");

        // IT Contact Details Error FE
        setITContactDetailsFirstNameErrorFe("");
        setITContactDetailsLastNameErrorFe("");
        setITContactDetailsEmailErrorFe("");
        setITContactDetailsPhoneNumberErrorFe("");

        // Business Contact Details Error FE
        setBusinessContactDetailsFirstNameErrorFe("");
        setBusinessContactDetailsLastNameErrorFe("");
        setBusinessContactDetailsEmailErrorFe("");
        setBusinessContactDetailsPhoneNumberErrorFe("");

        // Bill To Details Error FE
        setBillToDetailsFirstNameErrorFe("");
        setBillToDetailsLastNameErrorFe("");
        setBillToDetailsEmailErrorFe("");
        setBillToDetailsPhoneNumberErrorFe("");
    }

    // Error Response. Default values are empty
    const [errorObject, setErrorObject] = useState({
        "City": "",
        "Zipcode": "",
        "AbnNumber": "",
        "AcnNumber": "",
        "AddressLine1": "",
        "AddressLine2": "",
        "CustomerCompanyName": "",
        "BillToDetails.Email": "",
        "BillToDetails.LastName": "",
        "BillToDetails.FirstName": "",
        "BillToDetails.PhoneNumber": "",
        "ITContactDetails.Email": "",
        "ITContactDetails.LastName": "",
        "ITContactDetails.FirstName": "",
        "ITContactDetails.PhoneNumber": "",
        "BusinessContactDetails.Email": "",
        "BusinessContactDetails.LastName": "",
        "BusinessContactDetails.FirstName": "",
        "BusinessContactDetails.PhoneNumber": "",
    });

    // Header Authorization for General API's
    const config = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Get Customer Details Based on Customer ID
    async function getCustomerDetails() {
        await api.get(CUSTOMER_LISTING_TABLE + "/" + customerId, config)
            .then(resp => {
                if (resp.status === 200) {

                    setCustomerDetails(resp.data);
                    setCustomerDetailsUpdated(resp.data);
                } else {
                    setServerErrorCustomer(false);
                }

            })
            .catch(error => {

                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setServerErrorCustomer(true);

                }

            });
    }

    // useEffect to Load Customer Details On Initial Loading
    useEffect(() => {
        getCustomerDetails();
    }, []);

    //Code for Special Qualifications Available
    const handleCheckboxChange = (option) => {
        if (specialQulifications.includes(option)) {
            setSpecialQulifications(specialQulifications.filter((item) => item !== option));
        } else {
            setSpecialQulifications([...specialQulifications, option]);
        }
    };


    // Data sent to server from here
    const updatedCustomerData = {
        "addressLine1": customerDetailsUpdated.addressLine1,
        "addressLine2": customerDetailsUpdated.addressLine2,
        "city": customerDetailsUpdated.city,
        "state": customerDetailsUpdated.state,
        "zipcode": customerDetailsUpdated.zipcode,
        "abnNumber": customerDetailsUpdated.abnNumber,
        "acnNumber": customerDetailsUpdated.acnNumber,
        "customerCompanyName": customerDetailsUpdated.organization,
        "websiteUrl": customerDetailsUpdated.websiteUrl,
        "externalCustomerCompanyCode": customerDetailsUpdated.customerCode,
        "externalResellerCompanyCode": customerDetailsUpdated.resellerCompanyCode,
        "isActive": true,
        "itContactDetails": {
            "email": customerDetailsUpdated.itContactDetails.email,
            "firstName": customerDetailsUpdated.itContactDetails.firstName,
            "lastName": customerDetailsUpdated.itContactDetails.lastName,
            "phoneNumber": customerDetailsUpdated.itContactDetails.phoneNumber
        },
        "businessContactDetails": {
            "email": customerDetailsUpdated.businessContactDetails.email,
            "firstName": customerDetailsUpdated.businessContactDetails.firstName,
            "lastName": customerDetailsUpdated.businessContactDetails.lastName,
            "phoneNumber": customerDetailsUpdated.businessContactDetails.phoneNumber
        },
        "billToDetails": {
            "email": customerDetailsUpdated.billToDetails.email,
            "firstName": customerDetailsUpdated.billToDetails.firstName,
            "lastName": customerDetailsUpdated.billToDetails.lastName,
            "phoneNumber": customerDetailsUpdated.billToDetails.phoneNumber
        },
    };

    // Check duplicate values in Accordion Array
    const removeAccordion = (arr, myItem) => {
        if (!arr.includes(myItem)) {
            arr.push(myItem);
        }


        return arr;
    }

    // ********************* Front End Validations *********************//
    // Organisation Validation
    function organisationValidation() {
        if (organization.current.value === '') {
            setOrganizationErrorFe(customerDetailsErros.organisationEmpty);
        } else {
            if (organization.current.value.length > 100) {
                setOrganizationErrorFe(customerDetailsErros.organisationMaxChars);
            } else {
                setOrganizationErrorFe("");
                setErrorObject({
                    ...errorObject, "CustomerCompanyName": ""
                })
            }
        }
    }

    // Website URL Validation
    function websiteURLValidation() {
        if (websiteUrl.current.value === '' || websiteURLRegEx.test(websiteUrl.current.value)) {
            setWebsiteURLErrorFe("");
        } else {
            setWebsiteURLErrorFe(customerDetailsErros.websiteURLFormat);
        }
    }

    // ABN Validation   
    function abnValidation(abnNumberLocal) {
        if (abnNumberLocal && abnNumberLocal === '') {
            setAbnNumberErrorFe("The ABN number field is required.");
        } else {
            if (abnNumberLocal && abnNumberLocal.length !== 11) {
                setAbnNumberErrorFe("The ABN number should be 11 digits");
            } else {
                setAbnNumberErrorFe("");
                setErrorObject({
                    ...errorObject, "AbnNumber": ""
                })
            }
        }
    }

    // ACN Validation
    function acnValidation() {
        if (acnNumber.current.value.length !== 0 && acnNumber.current.value.length !== 9) {
            setAcnNumberErrorFe("The ACN number should be 9 digits");
        } else {
            setAcnNumberErrorFe("");
            setErrorObject({
                ...errorObject, "AcnNumber": ""
            })
        }
    }

    // Address Line 1 Validation
    function addressLine1Validation() {
        if (addressLine1.current.value === '') {
            setAddressLine1ErrorFe("Level/unit/street no field is required");
        } else {
            if (addressLine1.current.value.length > 50) {
                setAddressLine1ErrorFe("Level/unit/street no field should not be greater than 50 characters");
            } else {
                setAddressLine1ErrorFe("");
                setErrorObject({
                    ...errorObject, "AddressLine1": ""
                })
            }
        }
    }

    // Address Line 2 Validation
    function addressLine2Validation() {
        if (addressLine2.current.value === '') {
            setAddressLine2ErrorFe("Street name no field is required");
        } else {
            if (addressLine2.current.value.length > 50) {
                setAddressLine2ErrorFe("Street name no field should not be greater than 50 characters");
            } else {
                setAddressLine2ErrorFe("");
                setErrorObject({
                    ...errorObject, "AddressLine2": ""
                })
            }
        }
    }

    // Suburb Validation
    function suburbValidation() {
        if (city.current.value === '') {
            setCityErrorFe("City field is required");
        } else {
            if (city.current.value.length > 50) {
                setCityErrorFe("City field should not be greater than 50 characters");
            } else {
                setCityErrorFe("");
                setErrorObject({
                    ...errorObject, "City": ""
                })
            }
        }
    }

    // Zip Code Validation
    function zipCodeValidation() {
        if (zipcode.current.value === '') {
            setZipCodeErrorFe("The Zipcode field is required.");
        } else {
            if (zipcode.current.value.length !== 4) {
                setZipCodeErrorFe("The zipcode should be 4 digits");
            } else {
                setZipCodeErrorFe("");
                setErrorObject({
                    ...errorObject, "Zipcode": ""
                })
            }
        }
    }

    // State Validation
    function stateValidation() {
        if (state.current.value === "selectstate") {
            setStateErrorFe("Please select state");
        } else {
            setStateErrorFe("");
        }
    }
    // IT Contact Details First Name
    function itContactDetailsFirstNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const firstName = itContactDetailsFirstName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the first name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return firstName === name || firstName.includes(name) || firstName.includes(" " + name) || firstName.includes(name + " ");
        });

        if (firstName === '') {
            setITContactDetailsFirstNameErrorFe("First name field is required");
        } else if (firstName.length > 40) {
            setITContactDetailsFirstNameErrorFe("First name field should not be greater than 40 characters");
        } else if (firstName.length < 2) {
            setITContactDetailsFirstNameErrorFe("First name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setITContactDetailsFirstNameErrorFe("Please enter a valid first name");
        }
        else {
            setITContactDetailsFirstNameErrorFe("");
            setErrorObject({
                ...errorObject,
                "ITContactDetails.FirstName": ""
            });
        }
    }

    // IT Contact Details Last Name
    function itContactDetailsLastNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const lastName = itContactDetailsLastName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the last name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return lastName === name || lastName.includes(name) || lastName.includes(" " + name) || lastName.includes(name + " ");
        });

        if (lastName === '') {
            setITContactDetailsLastNameErrorFe("Last name field is required");
        } else if (lastName.length > 40) {
            setITContactDetailsLastNameErrorFe("Last name field should not be greater than 40 characters");
        } else if (lastName.length < 2) {
            setITContactDetailsLastNameErrorFe("Last name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setITContactDetailsLastNameErrorFe("Please enter a valid last name");
        }
        else {
            setITContactDetailsLastNameErrorFe("");
            setErrorObject({
                ...errorObject, "ITContactDetails.LastName": ""
            })
        }
    }

    // IT Contact Details EMail
    function itContactDetailsEmailValidation() {
        if (itContactDetailsEmail.current.value === '') {
            setITContactDetailsEmailErrorFe("Email is required");
        } else {
            if (emailRegEx.test(itContactDetailsEmail.current.value)) {
                setITContactDetailsEmailErrorFe("");
                setErrorObject({
                    ...errorObject, "ITContactDetails.Email": ""
                })
            } else {
                setITContactDetailsEmailErrorFe("Email should be in required format");
            }
        }
    }

    // IT Contact Details Phone Number
    function itContactDetailsPhoneNumberValidation() {
        if (itContactDetailsPhoneNumber.current.value === '') {
            setITContactDetailsPhoneNumberErrorFe("Phone number is required.");
        } else {
            if (itContactDetailsPhoneNumber.current.value.length < 8 || itContactDetailsPhoneNumber.current.value.length > 15) {
                setITContactDetailsPhoneNumberErrorFe(phoneNumberValidationMsg);
            } else {
                setITContactDetailsPhoneNumberErrorFe("");
                setErrorObject({
                    ...errorObject, "ITContactDetails.PhoneNumber": ""
                })
            }
        }
    }

    // Business Contact Details First Name
    function businessContactDetailsFirstNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const firstName = businessContactDetailsFirstName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the first name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return firstName === name || firstName.includes(name) || firstName.includes(" " + name) || firstName.includes(name + " ");
        });

        if (firstName === '') {
            setBusinessContactDetailsFirstNameErrorFe("First name field is required");
        } else if (firstName.length > 40) {
            setBusinessContactDetailsFirstNameErrorFe("First name field should not be greater than 40 characters");
        } else if (firstName.length < 2) {
            setBusinessContactDetailsFirstNameErrorFe("First name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setBusinessContactDetailsFirstNameErrorFe("Please enter a valid first name");
        }
        else {
            setBusinessContactDetailsFirstNameErrorFe("");
            setErrorObject({
                ...errorObject, "BusinessContactDetails.FirstName": ""
            })
        }
    }

    // Business Contact Details Last Name
    function businessContactDetailsLastNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const lastName = businessContactDetailsLastName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the last name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return lastName === name || lastName.includes(name) || lastName.includes(" " + name) || lastName.includes(name + " ");
        });

        if (lastName === '') {
            setBusinessContactDetailsLastNameErrorFe("Last name field is required");
        } else if (lastName.length > 40) {
            setBusinessContactDetailsLastNameErrorFe("Last name field should not be greater than 40 characters");
        } else if (lastName.length < 2) {
            setBusinessContactDetailsLastNameErrorFe("Last name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setBusinessContactDetailsLastNameErrorFe("Please enter a valid last name");
        }
        else {
            setBusinessContactDetailsLastNameErrorFe("");
            setErrorObject({
                ...errorObject, "BusinessContactDetails.LastName": ""
            })
        }
    }

    // Business Contact Details Email
    function businessContactDetailsEmailValidation() {
        if (businessContactDetailsEmail.current.value === '') {
            setBusinessContactDetailsEmailErrorFe("Email is required");
        } else {
            if (emailRegEx.test(businessContactDetailsEmail.current.value)) {
                setBusinessContactDetailsEmailErrorFe("");
                setErrorObject({
                    ...errorObject, "BusinessContactDetails.Email": ""
                })
            } else {
                setBusinessContactDetailsEmailErrorFe("Email should be in required format");
            }
        }
    }

    // Business Contact Details Phone Number
    function businessContactDetailsPhoneNumberValidation() {
        if (businessContactDetailsPhoneNumber.current.value === '') {
            setBusinessContactDetailsPhoneNumberErrorFe("Phone number is required.");
        } else {
            if (businessContactDetailsPhoneNumber.current.value.length < 8 || businessContactDetailsPhoneNumber.current.value.length > 15) {
                setBusinessContactDetailsPhoneNumberErrorFe(phoneNumberValidationMsg);
            } else {
                setBusinessContactDetailsPhoneNumberErrorFe("");
                setErrorObject({
                    ...errorObject, "BusinessContactDetails.PhoneNumber": ""
                })
            }
        }
    }

    // Bill To Details First Name
    function billToDetailsFirstNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const firstName = billToDetailsFirstName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the first name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return firstName === name || firstName.includes(name) || firstName.includes(" " + name) || firstName.includes(name + " ");
        });

        if (firstName === '') {
            setBillToDetailsFirstNameErrorFe("First name field is required");
        } else if (firstName.length > 40) {
            setBillToDetailsFirstNameErrorFe("First name field should not be greater than 40 characters");
        } else if (firstName.length < 2) {
            setBillToDetailsFirstNameErrorFe("First name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setBillToDetailsFirstNameErrorFe("Please enter a valid first name");
        }
        else {
            setBillToDetailsFirstNameErrorFe("");
            setErrorObject({
                ...errorObject, "BillToDetails.FirstName": ""
            })
        }
    }

    // Bill To Details Last Name
    function billToDetailsLastNameValidation() {
        const forbiddenNames = ['test', 'admin', 'nobody', 'administrator', 'testing', 'placeholder'];
        const lastName = billToDetailsLastName.current.value.trim().toLowerCase();
        // Check if the forbidden names appear in the last name
        const hasForbiddenName = forbiddenNames.some(name => {
            // Check if the forbidden name is an exact match or appears after a space
            return lastName === name || lastName.includes(name) || lastName.includes(" " + name) || lastName.includes(name + " ");
        });

        if (lastName === '') {
            setBillToDetailsLastNameErrorFe("Last name field is required");
        } else if (lastName.length > 40) {
            setBillToDetailsLastNameErrorFe("Last name field should not be greater than 40 characters");
        } else if (lastName.length < 2) {
            setBillToDetailsLastNameErrorFe("Last name field should be greater than 1 character");
        } else if (hasForbiddenName) {
            setBillToDetailsLastNameErrorFe("Please enter a valid last name");
        } else {
            setBillToDetailsLastNameErrorFe("");
            setErrorObject({
                ...errorObject, "BillToDetails.LastName": ""
            })
        }
    }

    // Bill To Details Email
    function billToDetailsEmailValidation() {
        if (billToDetailsEmail.current.value === '') {
            setBillToDetailsEmailErrorFe("Email is required");
        } else {
            if (emailRegEx.test(billToDetailsEmail.current.value)) {
                setBillToDetailsEmailErrorFe("");
                setErrorObject({
                    ...errorObject, "BillToDetails.Email": ""
                })
            } else {
                setBillToDetailsEmailErrorFe("Email should be in required format");
            }
        }
    }

    // Bill To Details Phone Number
    function billToDetailsPhoneNumberValidation() {
        if (billToDetailsPhoneNumber.current.value === '') {
            setBillToDetailsPhoneNumberErrorFe("Phone number is required.");
        } else {
            if (billToDetailsPhoneNumber.current.value.length < 8 || billToDetailsPhoneNumber.current.value.length > 15) {
                setBillToDetailsPhoneNumberErrorFe(phoneNumberValidationMsg);
            } else {
                setBillToDetailsPhoneNumberErrorFe("");
                setErrorObject({
                    ...errorObject, "BillToDetails.PhoneNumber": ""
                })
            }
        }
    }
    // ********************* Front End Validations *********************//

    // Validation Errors. Call from "Front End Validations"
    const validateFrontEndErrors = () => {
        organisationValidation();
        websiteURLValidation();
        abnValidation();
        acnValidation();
        addressLine1Validation();
        addressLine2Validation();
        suburbValidation();
        zipCodeValidation();
        stateValidation();
        itContactDetailsFirstNameValidation();
        itContactDetailsLastNameValidation();
        itContactDetailsEmailValidation();
        itContactDetailsPhoneNumberValidation();
        businessContactDetailsFirstNameValidation();
        businessContactDetailsLastNameValidation();
        businessContactDetailsEmailValidation();
        businessContactDetailsPhoneNumberValidation();
        billToDetailsFirstNameValidation();
        billToDetailsLastNameValidation();
        billToDetailsEmailValidation();
        billToDetailsPhoneNumberValidation();
    }

    // Get Updated Organization Value
    function getOrganisationValue() {
        setCustomerDetailsUpdated({ ...customerDetailsUpdated, organization: organization.current.value.replace(/\s+/g, ' ').replace(/,/g, '') });
        organisationValidation();
    }

    // Get Updated Website URL
    function getWebsiteURLValue() {
        setCustomerDetailsUpdated({ ...customerDetailsUpdated, websiteUrl: websiteUrl.current.value });
        websiteURLValidation();
    }

    // Get Updated ABN Value
    function getABNValue(e) {
        let abnNumberLocal = ((e.target.value).replaceAll(" ","")).trim();
        if(numberRegEx.test(abnNumberLocal) && abnNumberLocal.length<=11 ){
            setCustomerDetailsUpdated({ ...customerDetailsUpdated, abnNumber: abnNumberLocal })
            abnValidation(abnNumberLocal)
        }
        else{

        }
        
    }

    // Get Updated ACN Value
    function getACNValue() {
        setCustomerDetailsUpdated({ ...customerDetailsUpdated, acnNumber: (numberRegEx.test(acnNumber.current.value.trim()) ? acnNumber.current.value.trimStart() : "") })
        acnValidation()
    }

    // useEffect to check Organisation Block value changes
    useEffect(() => {
        if (
            (organizationErrorFe === null || organizationErrorFe === "") &&
            (websiteURLErrorFe == null || websiteURLErrorFe == "") &&
            (abnNumberErrorFe == null || abnNumberErrorFe == "") &&
            (acnNumberErrorFe == null || acnNumberErrorFe == "")
        ) {
            setOrganisationBlockErrors(false)
        } else {
            setOrganisationBlockErrors(true)
        }
    }, [organizationErrorFe, websiteURLErrorFe, abnNumberErrorFe, acnNumberErrorFe])


    // Address Details Block

    // Get Updated Address Details Line1 
    function getAddressLine1Value() {
        setCustomerDetailsUpdated({ ...customerDetailsUpdated, addressLine1: addressLine1.current.value.replace(/\s+/g, ' ') })
        addressLine1Validation();
    }

    // Get Updated Address Details Line2 
    function getAddressLine2Value() {
        setCustomerDetailsUpdated({ ...customerDetailsUpdated, addressLine2: addressLine2.current.value.replace(/\s+/g, ' ') })
        addressLine2Validation();
    }

    // Get Updated SubUrb
    function getSuburbValue() {
        setCustomerDetailsUpdated({ ...customerDetailsUpdated, city: city.current.value })
        suburbValidation();
    }

    // Get Updated Zip Code
    function getZipCodeValue() {
        setCustomerDetailsUpdated({ ...customerDetailsUpdated, zipcode: (numberRegEx.test(zipcode.current.value) ? zipcode.current.value : customerDetails.zipcode) })
        zipCodeValidation();
    }

    // Get Updated State
    function getStateValue() {
        setCustomerDetailsUpdated({ ...customerDetailsUpdated, state: state.current.value })
        stateValidation();
    }

    // useEffect to check Address Block value changes
    useEffect(() => {
        if (addressLine1ErrorFe !== "" || addressLine2ErrorFe !== "" || cityErrorFe !== "" || zipCodeErrorFe !== "" || stateErrorFe !== "") {
            setAddressBlockErrors(true)
        } else {
            setAddressBlockErrors(false)
        }
    }, [addressLine1ErrorFe, addressLine2ErrorFe, cityErrorFe, zipCodeErrorFe, stateErrorFe])


    // IT Contact Details

    // Get Updated IT Contact Details First Name
    function getITContactDetailsFirstNameValue(e) {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, itContactDetails: {
                ...prevState.itContactDetails,
                firstName: itContactDetailsFirstName.current.value
            }
        }))
        itContactDetailsFirstNameValidation();
    }

    // Get Updated IT Contact Details Last Name
    function getITContactDetailsLastNameValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, itContactDetails: {
                ...prevState.itContactDetails,
                lastName: itContactDetailsLastName.current.value
            }
        }))
        itContactDetailsLastNameValidation();
    }

    // Get Updated IT Contact Details Email
    function getITContactDetailsEmailValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, itContactDetails: {
                ...prevState.itContactDetails,
                email: itContactDetailsEmail.current.value
            }
        }))
        itContactDetailsEmailValidation();
    }

    // Get Updated IT Contact Details Phone Number
    function getITContactDetailsPhoneNumberValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, itContactDetails: {
                ...prevState.itContactDetails,
                phoneNumber: (phoneOnChangeRegex.test(itContactDetailsPhoneNumber.current.value) ? itContactDetailsPhoneNumber.current.value : "")
            }
        }))
        itContactDetailsPhoneNumberValidation()
    }

    // useEffect to check IT Contact Details Block value changes
    useEffect(() => {
        if (itContactDetailsFirstNameErrorFe !== "" || itContactDetailsLastNameErrorFe !== "" || itContactDetailsEmailErrorFe !== "" || itContactDetailsPhoneNumberErrorFe !== "") {
            setItContactBlockErrors(true)
        } else {
            setItContactBlockErrors(false)
        }
    }, [itContactDetailsFirstNameErrorFe, itContactDetailsLastNameErrorFe, itContactDetailsEmailErrorFe, itContactDetailsPhoneNumberErrorFe])

    // Business Contact Details

    // Get Updated Business Contact Details First Name
    function getBusinessContactDetailsFirstNameValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, businessContactDetails: {
                ...prevState.businessContactDetails,
                firstName: businessContactDetailsFirstName.current.value
            }
        }))
        businessContactDetailsFirstNameValidation();
    }

    // Get Updated Business Contact Details Last Name
    function getBusinessContactDetailsLastNameValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, businessContactDetails: {
                ...prevState.businessContactDetails,
                lastName: businessContactDetailsLastName.current.value
            }
        }))
        businessContactDetailsLastNameValidation();
    }

    // Get Updated Business Contact Details Email
    function getBusinessContactDetailsEmailValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, businessContactDetails: {
                ...prevState.businessContactDetails,
                email: businessContactDetailsEmail.current.value,
            }
        }))
        businessContactDetailsEmailValidation();
    }

    // Get Updated Business Contact Details Phone Number
    function getBusinessContactDetailsPhoneNumberValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, businessContactDetails: {
                ...prevState.businessContactDetails,
                phoneNumber: (phoneOnChangeRegex.test(businessContactDetailsPhoneNumber.current.value) ? businessContactDetailsPhoneNumber.current.value : "")
            }
        }))

        businessContactDetailsPhoneNumberValidation()
    }

    // useEffect to check  Business Contact Details Block value changes
    useEffect(() => {
        if (businessContactDetailsFirstNameErrorFe !== "" || businessContactDetailsLastNameErrorFe !== "" || businessContactDetailsEmailErrorFe !== "" || businessContactDetailsPhoneNumberErrorFe !== "") {
            setBusinessContactBlockErrors(true)
        } else {
            setBusinessContactBlockErrors(false)
        }
    }, [businessContactDetailsFirstNameErrorFe, businessContactDetailsLastNameErrorFe, businessContactDetailsEmailErrorFe, businessContactDetailsPhoneNumberErrorFe])

    // Bill To Details

    // Get Updated Business Contact Details First Name
    function getBillToDetailsFirstNameValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, billToDetails: {
                ...prevState.billToDetails,
                firstName: billToDetailsFirstName.current.value
            }
        }))
        billToDetailsFirstNameValidation();
    }

    // Get Updated Business Contact Details Last Name
    function getBillToDetailsLastNameValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, billToDetails: {
                ...prevState.billToDetails,
                lastName: billToDetailsLastName.current.value
            }
        }))
        billToDetailsLastNameValidation();
    }

    // Get Updated Business Contact Details Email
    function getBillToDetailsEmailValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, billToDetails: {
                ...prevState.billToDetails,
                email: billToDetailsEmail.current.value,
            }
        }))
        billToDetailsEmailValidation();
    }

    // Get Updated Business Contact Details Phone Number
    function getBillToDetailsPhoneNumberValue() {
        setCustomerDetailsUpdated((prevState) => ({
            ...customerDetailsUpdated, billToDetails: {
                ...prevState.billToDetails,
                phoneNumber: (phoneOnChangeRegex.test(billToDetailsPhoneNumber.current.value) ? billToDetailsPhoneNumber.current.value : "")
            }
        }))
        billToDetailsPhoneNumberValidation()
    }

    // useEffect to check Bill To Details Block value changes 
    useEffect(() => {
        if (billToDetailsFirstNameErrorFe !== "" || billToDetailsLastNameErrorFe !== "" || billToDetailsEmailErrorFe !== "" || billToDetailsPhoneNumberErrorFe !== "") {
            setBillToBlockErrors(true)
        } else {
            setBillToBlockErrors(false)
        }
    }, [billToDetailsFirstNameErrorFe, billToDetailsLastNameErrorFe, billToDetailsEmailErrorFe, billToDetailsPhoneNumberErrorFe])


    // useEffct to check All the validations are done to enable save button
    useEffect(() => {

        if (customerDetailsUpdated.organization !== customerDetails.organization ||
            customerDetailsUpdated.websiteUrl !== customerDetails.websiteUrl ||
            customerDetailsUpdated.abnNumber !== customerDetails.abnNumber ||
            customerDetailsUpdated.acnNumber !== customerDetails.acnNumber ||
            customerDetailsUpdated.addressLine1 !== customerDetails.addressLine1 ||
            customerDetailsUpdated.addressLine2 !== customerDetails.addressLine2 ||
            customerDetailsUpdated.city !== customerDetails.city ||
            customerDetailsUpdated.zipcode !== customerDetails.zipcode ||
            customerDetailsUpdated.state !== customerDetails.state ||
            customerDetailsUpdated.itContactDetails.firstName !== customerDetails.itContactDetails.firstName ||
            customerDetailsUpdated.itContactDetails.lastName !== customerDetails.itContactDetails.lastName ||
            customerDetailsUpdated.itContactDetails.email !== customerDetails.itContactDetails.email ||
            customerDetailsUpdated.itContactDetails.phoneNumber !== customerDetails.itContactDetails.phoneNumber ||
            customerDetailsUpdated.businessContactDetails.firstName !== customerDetails.businessContactDetails.firstName ||
            customerDetailsUpdated.businessContactDetails.lastName !== customerDetails.businessContactDetails.lastName ||
            customerDetailsUpdated.businessContactDetails.email !== customerDetails.businessContactDetails.email ||
            customerDetailsUpdated.businessContactDetails.phoneNumber !== customerDetails.businessContactDetails.phoneNumber ||
            customerDetailsUpdated.billToDetails.firstName !== customerDetails.billToDetails.firstName ||
            customerDetailsUpdated.billToDetails.lastName !== customerDetails.billToDetails.lastName ||
            customerDetailsUpdated.billToDetails.email !== customerDetails.billToDetails.email ||
            customerDetailsUpdated.billToDetails.phoneNumber !== customerDetails.billToDetails.phoneNumber
        ) {
            setEnabledSaveBtn(true)
        } else {
            setEnabledSaveBtn(false);
        }
    }, [customerDetailsUpdated])

    // Block Level Validations

    const blockLevelErrorValidations = () => {
        // Block Level Errors

        // ****************** Organization Block ****************** //
        if (
            (organization.current.value === "" || organization.current.value.length > 100) ||
            (websiteUrl.current.value !== "" && !websiteURLRegEx.test(websiteUrl.current.value)) ||
            (abnNumber.current.value === "" || abnNumber.current.value.length !== 11) ||
            (errorObject.AcnNumber || (acnNumber.current.value !== "" && acnNumber.current.value.length !== 9))
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '0'));
            setOrganisationBlockErrors(true);
            setExpandedAll(false);
        } else {
            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf('0')));
            setOrganisationBlockErrors(false);
        }

        // ****************** Address Block ****************** //
        if
            (
            (addressLine1.current.value === "" || addressLine1.current.value.length > 50) ||
            (addressLine2.current.value === "" || addressLine2.current.value.length > 50) ||
            (city.current.value === "" || city.current.value.length > 50) ||
            (zipcode.current.value === "" || zipcode.current.value.length !== 4 || !numberRegEx.test(zipcode.current.value)) ||
            (state.current.value === "selectstate")
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '1'));
            setAddressBlockErrors(true);
            setExpandedAll(false);
        } else {
            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf('1')));
            setAddressBlockErrors(false);
        }

        // ****************** IT Contact Details Block ****************** //
        if (
            (itContactDetailsFirstName.current.value === "" || itContactDetailsFirstName.current.value.length > 50) ||
            (itContactDetailsLastName.current.value === "" || itContactDetailsLastName.current.value.length > 50) ||
            (itContactDetailsEmail.current.value === "" || !emailRegEx.test(itContactDetailsEmail.current.value)) ||
            (itContactDetailsPhoneNumber.current.value === "" || !phoneRegex.test(itContactDetailsPhoneNumber.current.value) || (itContactDetailsPhoneNumber.current.value.length < 8 || itContactDetailsPhoneNumber.current.value.length > 15)
            )
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '2'));
            setItContactBlockErrors(true);
            setExpandedAll(false);
        } else {
            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf('2')));
            setItContactBlockErrors(false);
        }


        // ****************** Business Contact Details Block ****************** //
        if (
            (businessContactDetailsFirstName.current.value === "" || businessContactDetailsFirstName.current.value.length > 50) ||
            (businessContactDetailsLastName.current.value === "" || businessContactDetailsLastName.current.value.length > 50) ||
            (businessContactDetailsEmail.current.value === "" || !emailRegEx.test(businessContactDetailsEmail.current.value)) ||
            (businessContactDetailsPhoneNumber.current.value === "" || !phoneRegex.test(businessContactDetailsPhoneNumber.current.value) || (businessContactDetailsPhoneNumber.current.value.length < 8 || businessContactDetailsPhoneNumber.current.value.length > 15)
            )
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '3'));
            setBusinessContactBlockErrors(true);
            setExpandedAll(false);
        } else {
            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf('3')));
            setBusinessContactBlockErrors(false);
        }

        // ****************** Bill To Details Block ****************** //
        if (
            (billToDetailsFirstName.current.value === "" || billToDetailsFirstName.current.value.length > 50) ||
            (billToDetailsLastName.current.value === "" || billToDetailsLastName.current.value.length > 50) ||
            (billToDetailsEmail.current.value === "" || !emailRegEx.test(billToDetailsEmail.current.value)) ||
            (billToDetailsPhoneNumber.current.value === "" || !phoneRegex.test(billToDetailsPhoneNumber.current.value) || (billToDetailsPhoneNumber.current.value.length < 8 || billToDetailsPhoneNumber.current.value.length > 15)
            )
        ) {
            setActiveAccordion(removeAccordion(activeAccordion, '4'));
            setBillToBlockErrors(true);
            setExpandedAll(false);
        } else {
            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf('4')));
            setBillToBlockErrors(false);
        }

        return activeAccordion;
    }

    // Function to Update a Customer It POST API once all the validations are working fine
    async function UpdateCustomer() {
        validateFrontEndErrors();
        blockLevelErrorValidations();



        if (
            organisationBlockErrors == false &&
            addressBlockErrors == false &&
            itContactBlockErrors == false &&
            businessContactBlockErrors == false &&
            billToBlockErrors == false
        ) {
            await api.put(CUSTOMER_LISTING_TABLE, updatedCustomerData, config)
                .then(resp => {
                    if (resp.status == 200) {

                        setToastMessage("Customer details updated successfully.");
                        setUpdatedToastMessage(true);
                        setTimeout(() => {
                            setUpdatedToastMessage(false);
                            navigate("/my-customers");
                        }, 2000);
                        setEnabledSaveBtn(false);
                    }
                })
                .catch(error => {
                    if (error?.status == 401 || error?.response?.status == 401) {
                        props.Logout();
                    }
                    if (error.response.data.errors) {
                        setErrorObject(error.response.data.errors);

                    }
                    if(!error.response.data.isSuccess && !error.response.data.errors){
                        setDuplicateOrganisationNameCheck(error.response.data.message);
                        setToastError(true);
                        setTimeout(() => {
                            setToastError(false);
                        }, 3000);
                    }

                    if (error.response.data.errors) {
                        if (error.response.data.errors.AbnNumber ||
                            error.response.data.errors.CustomerCompanyName ||
                            error.response.data.errors.AcnNumber
                        ) {
                            setActiveAccordion(removeAccordion(activeAccordion, '0'));
                            setOrganisationBlockErrors(true);
                            setExpandedAll(false);
                            return activeAccordion;
                        } else {

                            setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf('0')));
                            setOrganisationBlockErrors(false);
                        }
                    }

                    if (error.response.data.errors.AddressLine1 ||
                        error.response.data.errors.AddressLine2 ||
                        error.response.data.errors.city ||
                        error.response.data.errors.Zipcode ||
                        error.response.data.errors.State
                    ) {
                        setActiveAccordion(removeAccordion(activeAccordion, '1'));
                        setAddressBlockErrors(true);
                        return activeAccordion;
                    } else {
                        setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf('1')));
                        setAddressBlockErrors(false);
                    }

                    if (error.response.data.errors["ITContactDetails.FirstName"] ||
                        error.response.data.errors["ITContactDetails.LastName"] ||
                        error.response.data.errors["ITContactDetails.Email"] ||
                        error.response.data.errors["ITContactDetails.PhoneNumber"]
                    ) {

                        setActiveAccordion(removeAccordion(activeAccordion, '2'));
                        setItContactBlockErrors(true);
                        return activeAccordion;
                    } else {
                        setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf("2")));
                        setItContactBlockErrors(false);
                    }

                    if (error.response.data.errors["BusinessContactDetails.FirstName"] ||
                        error.response.data.errors["BusinessContactDetails.LastName"] ||
                        error.response.data.errors["BusinessContactDetails.Email"] ||
                        error.response.data.errors["BusinessContactDetails.PhoneNumber"]
                    ) {
                        setActiveAccordion(removeAccordion(activeAccordion, '3'));
                        setBusinessContactBlockErrors(true);
                        return activeAccordion;
                    } else {
                        setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf("3")));
                        setBusinessContactBlockErrors(false);
                    }

                    if (error.response.data.errors["BillToDetails.FirstName"] ||
                        error.response.data.errors["BillToDetails.LastName"] ||
                        error.response.data.errors["BillToDetails.Email"] ||
                        error.response.data.errors["BillToDetails.PhoneNumber"]
                    ) {
                        setActiveAccordion(removeAccordion(activeAccordion, '4'));
                        setBillToBlockErrors(true);
                        return activeAccordion;
                    } else {
                        setActiveAccordion(activeAccordion.splice(activeAccordion.indexOf("4")));
                        setBillToBlockErrors(false);
                    }
                })
        } else {
            validateFrontEndErrors();
        }
    }

    return (
        <>
            <Toast toastCheckUseState={updatedToastMessage} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastCheckUseState={toastError} toastType="danger" toastMessage={duplicateOrganisationNameCheck} />
            <Row>
                <Col sm={12} className="mt-3">

                    <Row>
                        <Col className="justify-content-end d-flex align-items-center">

                            <div>
                                <Button
                                    className="px-4 mr-3 btn-xl btn-border-light expand-collapse-button"
                                    variant="light"
                                    onClick={handleExpandAll}>
                                    {expandedAll ? "Expand all" : "Collapse all"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="accordion-panel">
                        <Form>
                            <Accordion
                                activeKey={activeAccordion}
                                onSelect={(e) => { setActiveAccordion(e); setExpandedAll(false) }}
                                alwaysOpen>
                                <Accordion.Item className={`mt-5 ${organisationBlockErrors ? "accordionErrors" : ""}`} eventKey="0">
                                    <Accordion.Header>Organization Details</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={8} md={12}>
                                                <Form.Group className="mb-3" controlId="formOrganaisation">
                                                    <Form.Label>Organization <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.organization}
                                                        // placeholder="Enter Organisation"
                                                        maxlength="100"
                                                        ref={organization}
                                                        onChange={getOrganisationValue}
                                                    />
                                                    {errorObject.CustomerCompanyName && <span className="red text-small">{errorObject.CustomerCompanyName}</span>}
                                                    <span className="red text-small">{organizationErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formWebsite">
                                                    <Form.Label>Website url</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.websiteUrl}
                                                        // placeholder="Enter Website URL"
                                                        ref={websiteUrl}
                                                        onChange={getWebsiteURLValue}
                                                    />
                                                    <span className="red text-small">{websiteURLErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={2} md={3}>
                                                <Form.Group className="mb-3" controlId="formABNNumber">
                                                    <Form.Label>ABN number<span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={(customerDetailsUpdated.abnNumber).replace(/\s+/g, "")}
                                                        // placeholder="Enter ABN Number"
                                                        ref={abnNumber}
                                                        // maxlength="11"
                                                        onChange={(e)=>getABNValue(e)}
                                                    />
                                                    {errorObject.AbnNumber && <span className="red text-small">{errorObject.AbnNumber}</span>}
                                                    <span className="red text-small">{abnNumberErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={2} md={3}>
                                                <Form.Group className="mb-3" controlId="formACNNumber">
                                                    <Form.Label>ACN number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.acnNumber}
                                                        // placeholder="Enter ACN Number"
                                                        ref={acnNumber}
                                                        maxlength="9"
                                                        onChange={getACNValue}
                                                    />
                                                    {errorObject.AcnNumber && <span className="red text-small">{errorObject.AcnNumber}</span>}
                                                    <span className="red text-small">{acnNumberErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    organisationBlockErrors && <p className="red text-small mt-2">
                                        Organization details section is not complete. Please fill all the details to proceed.
                                    </p>
                                }

                                <Accordion.Item className={`mt-5 ${addressBlockErrors ? "accordionErrors" : ""}`} eventKey="1">
                                    <Accordion.Header>Address details</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formLevelNumber">
                                                    <Form.Label>Level/unit/street no <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.addressLine1}
                                                        // placeholder="Enter Level/Unit/Street No"
                                                        ref={addressLine1}
                                                        maxlength="50"
                                                        onChange={getAddressLine1Value}
                                                    />
                                                    {errorObject.AddressLine1 && <span className="red text-small">{errorObject.AddressLine1}</span>}
                                                    <span className="red text-small">{addressLine1ErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formStreetNumber">
                                                    <Form.Label>Street name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.addressLine2}
                                                        // placeholder="Enter Street Name"
                                                        ref={addressLine2}
                                                        maxlength="50"
                                                        onChange={getAddressLine2Value}
                                                    />
                                                    {errorObject.AddressLine2 && <span className="red text-small">{errorObject.AddressLine2}</span>}
                                                    <span className="red text-small">{addressLine2ErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formSuburb">
                                                    <Form.Label>Suburb <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.city}
                                                        // placeholder="Enter Suburb"
                                                        ref={city}
                                                        maxlength="50"
                                                        onChange={getSuburbValue}
                                                    />
                                                    {errorObject.City && <span className="red text-small">{errorObject.City}</span>}
                                                    <span className="red text-small">{cityErrorFe}</span>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={2} md={3}>
                                                <Form.Group className="mb-3" controlId="formPostalCode">
                                                    <Form.Label>Postal code <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.zipcode}
                                                        // placeholder="Enter Postal Code"
                                                        ref={zipcode}
                                                        maxlength="4"
                                                        onChange={getZipCodeValue}
                                                    />
                                                    {errorObject.Zipcode && <span className="red text-small">{errorObject.Zipcode}</span>}
                                                    <span className="red text-small">{zipCodeErrorFe}</span>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={2} md={3}>
                                                <Form.Group className="mb-3" controlId="formState">
                                                    <Form.Label>State <span className="red">*</span></Form.Label>
                                                    <Form.Select
                                                        ref={state}
                                                        onChange={getStateValue}
                                                    >
                                                        <option value="selectstate">Select state</option>
                                                        {
                                                            ausStates.map(state => {
                                                                return (
                                                                    <option
                                                                        key={state}
                                                                        id={state}
                                                                        value={state}
                                                                        selected={state === customerDetails.state ? true : false}
                                                                    >
                                                                        {state}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    {errorObject.state && <span className="red text-small">{errorObject.state}</span>}
                                                    <span className="red text-small">{stateErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    addressBlockErrors && <p className="red text-small mt-2">
                                        Address details section is not complete. Please fill all the details to proceed.
                                    </p>
                                }

                                <Accordion.Item eventKey="2" className={`mt-5 ${itContactBlockErrors ? "accordionErrors" : ""}`}>
                                    <Accordion.Header>IT contact details</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formITFirstName">
                                                    <Form.Label>First name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.itContactDetails.firstName}
                                                        // placeholder="Enter First Name"
                                                        ref={itContactDetailsFirstName}
                                                        maxlength="40"
                                                        onChange={getITContactDetailsFirstNameValue}
                                                    />
                                                    {errorObject["ITContactDetails.FirstName"] && <span className="red text-small">{errorObject["ITContactDetails.FirstName"]}</span>}
                                                    <span className="red text-small">{itContactDetailsFirstNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formITLastName">
                                                    <Form.Label>Last name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.itContactDetails.lastName}
                                                        // placeholder="Enter Last Name"
                                                        ref={itContactDetailsLastName}
                                                        maxlength="40"
                                                        onChange={getITContactDetailsLastNameValue}
                                                    />
                                                    {errorObject["ITContactDetails.LastName"] && <span className="red text-small">{errorObject["ITContactDetails.LastName"]}</span>}
                                                    <span className="red text-small">{itContactDetailsLastNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formITEmail">
                                                    <Form.Label>Email <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.itContactDetails.email}
                                                        // placeholder="Enter Email"
                                                        ref={itContactDetailsEmail}
                                                        maxlength="50"
                                                        onChange={getITContactDetailsEmailValue}
                                                    />
                                                    {errorObject["ITContactDetails.Email"] && <span className="red text-small">{errorObject["ITContactDetails.Email"]}</span>}
                                                    <span className="red text-small">{itContactDetailsEmailErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <PhoneNumber
                                                    phoneNumber={customerDetailsUpdated.itContactDetails.phoneNumber}
                                                    // placeholder="Enter Phone Number"
                                                    ref={itContactDetailsPhoneNumber}
                                                    updatePhoneNumber={getITContactDetailsPhoneNumberValue}
                                                    errorMessage={errorObject["ITContactDetails.PhoneNumber"]}
                                                    errorMessageFE={itContactDetailsPhoneNumberErrorFe}
                                                ></PhoneNumber>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    itContactBlockErrors && <p className="red text-small mt-2">
                                        IT contact details section is not complete. Please fill all the details to proceed.
                                    </p>
                                }

                                <Accordion.Item eventKey="3" className={`mt-5 ${businessContactBlockErrors ? "accordionErrors" : ""}`}>
                                    <Accordion.Header>Business contact details</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBusinessFirstName">
                                                    <Form.Label>First name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.businessContactDetails.firstName}
                                                        // placeholder="Enter First Name"
                                                        ref={businessContactDetailsFirstName}
                                                        maxlength="40"
                                                        onChange={getBusinessContactDetailsFirstNameValue}
                                                    />
                                                    {errorObject["BusinessContactDetails.FirstName"] && <span className="red text-small">{errorObject["BusinessContactDetails.FirstName"]}</span>}
                                                    <span className="red text-small">{businessContactDetailsFirstNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBusinessLastName">
                                                    <Form.Label>Last name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.businessContactDetails.lastName}
                                                        // placeholder="Enter Last Name"
                                                        ref={businessContactDetailsLastName}
                                                        maxlength="40"
                                                        onChange={getBusinessContactDetailsLastNameValue}
                                                    />
                                                    {errorObject["BusinessContactDetails.LastName"] && <span className="red text-small">{errorObject["BusinessContactDetails.LastName"]}</span>}
                                                    <span className="red text-small">{businessContactDetailsLastNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBusinessEmail">
                                                    <Form.Label>Email <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.businessContactDetails.email}
                                                        // placeholder="Enter Email"
                                                        ref={businessContactDetailsEmail}
                                                        maxlength="50"
                                                        onChange={getBusinessContactDetailsEmailValue}
                                                    />
                                                    {errorObject["BusinessContactDetails.Email"] && <span className="red text-small">{errorObject["BusinessContactDetails.Email"]}</span>}
                                                    <span className="red text-small">{businessContactDetailsEmailErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <PhoneNumber
                                                    phoneNumber={customerDetailsUpdated.businessContactDetails.phoneNumber}
                                                    // placeholder="Enter Phone Number"
                                                    ref={businessContactDetailsPhoneNumber}
                                                    updatePhoneNumber={getBusinessContactDetailsPhoneNumberValue}
                                                    errorMessage={errorObject["BusinessContactDetails.PhoneNumber"]}
                                                    errorMessageFE={businessContactDetailsPhoneNumberErrorFe}
                                                ></PhoneNumber>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    businessContactBlockErrors && <p className="red text-small mt-2">
                                        Business contact details section is not complete. Please fill all the details to proceed.
                                    </p>
                                }

                                <Accordion.Item eventKey="4" className={`mt-5 ${billToBlockErrors ? "accordionErrors" : ""}`}>
                                    <Accordion.Header>Bill to information (end customer)</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBillToFirstName">
                                                    <Form.Label>First name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.billToDetails.firstName}
                                                        // placeholder="Enter First Name"
                                                        ref={billToDetailsFirstName}
                                                        maxlength="40"
                                                        onChange={getBillToDetailsFirstNameValue}
                                                    />
                                                    {errorObject["BillToDetails.FirstName"] && <span className="red text-small">{errorObject["BillToDetails.FirstName"]}</span>}
                                                    <span className="red text-small">{billToDetailsFirstNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBillToLastName">
                                                    <Form.Label>Last name <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.billToDetails.lastName}
                                                        // placeholder="Enter Last Name"
                                                        ref={billToDetailsLastName}
                                                        maxlength="40"
                                                        onChange={getBillToDetailsLastNameValue}
                                                    />
                                                    {errorObject["BillToDetails.LastName"] && <span className="red text-small">{errorObject["BillToDetails.LastName"]}</span>}
                                                    <span className="red text-small">{billToDetailsLastNameErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}></Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group className="mb-3" controlId="formBillToEmail">
                                                    <Form.Label>Email <span className="red">*</span></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={customerDetailsUpdated.billToDetails.email}
                                                        // placeholder="Enter Email"
                                                        ref={billToDetailsEmail}
                                                        maxlength="50"
                                                        onChange={getBillToDetailsEmailValue}
                                                    />
                                                    {errorObject["BillToDetails.Email"] && <span className="red text-small">{errorObject["BillToDetails.Email"]}</span>}
                                                    <span className="red text-small">{billToDetailsEmailErrorFe}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <PhoneNumber
                                                    phoneNumber={customerDetailsUpdated.billToDetails.phoneNumber}
                                                    // placeholder="Enter Phone Number"
                                                    ref={billToDetailsPhoneNumber}
                                                    updatePhoneNumber={getBillToDetailsPhoneNumberValue}
                                                    errorMessage={errorObject["BillToDetails.PhoneNumber"]}
                                                    errorMessageFE={billToDetailsPhoneNumberErrorFe}
                                                ></PhoneNumber>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {
                                    billToBlockErrors && <p className="red text-small mt-2">
                                        Bill to information (end customer) section is not complete. Please fill all the details to proceed.
                                    </p>
                                }

                                {/* MCA Details or Special Qualification */}
                                {
                                    customerDetailsUpdated.mcaDetails !== null &&

                                    <Accordion.Item eventKey="5" className="mt-5">
                                        <Accordion.Header>Special qualification</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                {selectedSpecialQulifications.map((option) => (
                                                    <Col lg={4} md={6}>
                                                        <Form.Check
                                                            key={option}
                                                            type="checkbox"
                                                            label={option}
                                                            checked={specialQulifications.includes(option)}
                                                            onChange={() => handleCheckboxChange(option)}
                                                        />
                                                    </Col>
                                                ))}

                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                }

                            </Accordion>

                            <Row>
                                <Col className="my-3 text-right btn-wrapper-outer">
                                    <Button
                                        className="btn-border-light w-md-auto col-xs-12 mb-3"
                                        variant="light"
                                        onClick={() => resetEditCustomerDetails()}
                                    >
                                        Cancel
                                    </Button>

                                    {enabledSaveBtn &&
                                        <Button
                                            className="ml-3 w-md-auto col-xs-12 mb-3"
                                            onClick={() => UpdateCustomer()}
                                        >
                                            Save
                                        </Button>

                                    }
                                    {!enabledSaveBtn &&
                                        <Button
                                            className="ml-3 w-md-auto col-xs-12 mb-3"
                                            variant="disabled"
                                            disabled
                                        >
                                            Save
                                        </Button>
                                    }
                                </Col>
                            </Row>

                        </Form>
                    </div>

                </Col>
            </Row>
        </>
    );
}

export default CustomerDetails;