import React, { useState , useEffect } from 'react'
import { Accordion, Button, Col, Container, Form, Row } from 'react-bootstrap';
import styles from './paymentMethods.module.scss';
import EmptyDataTable from '../../../../Assets/Images/Illustrations/no-credit-card-data.svg'
import CreditCards from './CreditCards';
import Others from './Others';
import Toast from '../../../../GlobalComponents/Toast';
import { GET_CREDIT_CARD_DETAILS, GET_DIRECT_DEBITS, GET_PAYMENT_GATEWAY_BILLING_ADDRESS, UPDATE_PAYMENT_METHOD } from '../../../../Utils/GlobalConstants';
import { api } from '../../../../Utils/API';
import DirectDebit from './DirectDebit';
import DeleteCardImage from '../../../../Assets/Images/Illustrations/delete-card-img.svg';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';

const PaymentMethods = (props) => {

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    const [expandedAll, setExpandedAll] = useState(false);                                  // Expand All Accordions
    const [activeAccordion, setActiveAccordion] = useState(["0"]);                          // Default Active Accordion Id is "0"

    const [isRead, setIsRead] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Billing") ? true : false); 

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                        // toast message displaying success message
    const [toastError, setToastError] = useState(false);                            // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                           // toast message
    
    const [paymentData, setPaymentData] = useState(null)                            // Payment methods Data

    // Payment methods permissions usesStates and storing active payment method
    const [isDirectDebitAllowed, setIsDirectDebitAllowed] = useState(false);
    const [isCreditCardAllowed, setIsCreditCardAllowed] = useState(false);
    const [switchingPaymentMethodPopup, setSwitchingPaymentMethodPopup] = useState(false);
    const [switchingPaymentMethodPopupMessage, setSwitchingPaymentMethodPopupMessage] = useState("");
    const [activeMethodPrimaryCard, setActiveMethodPrimaryCard] = useState(null);
    const [activeMethodPrimaryBank, setActiveMethodPrimaryBank] = useState(null);
    const [activeMethod, setActiveMethod] = useState("");
    const [isDirectDebitBlocked, setIsDirectDebitBlocked] = useState(false);
    const [isCreditCardActive, setIsCreditCardActive] = useState(false);
    const [othersToCreditCardPaymentMethodFlag, setOthersToCreditCardPaymentMethodFlag] = useState(false);

    // Function to call payment methods data
    async function GetPaymentGatewayBillingAddress() {
        await api.get(GET_PAYMENT_GATEWAY_BILLING_ADDRESS, config)
            .then(resp => {
                // console.log("Data : ",resp.data);
                setPaymentData(resp.data);
                resp.data.paymentMethods.map((data)=>{
                    if(data.paymentMethod==="Direct Debit" && data.isBlocked=== true){
                        setIsDirectDebitBlocked(true);
                    }
                    if(data.paymentMethod==="Direct Debit" && data.isBlocked=== false){
                        setIsDirectDebitBlocked(false);
                    }
                    if(data.isDefault===true){
                       if(data.paymentMethod=="Credit Card"){
                        setActiveMethod("Credit Card");
                        GetDirectDebitDetails();
                        setIsCreditCardActive(true);
                        setIsCreditCardAllowed(true);
                        }
                        if(data.paymentMethod=="Direct Debit"){
                            setActiveMethod("Direct Debit");
                            GetCreditCardDetails();
                            setIsCreditCardActive(false);
                            setIsDirectDebitAllowed(true);
                        }
                        if(data.paymentMethod=="Others"){
                            setActiveMethod("Others");
                            GetCreditCardDetailsActive();
                            GetDirectDebitDetailsActive();
                            setIsCreditCardActive(false);
                            GetCreditCardDetails();
                            GetDirectDebitDetails();
                        } 
                    }
                })
            })
            .catch(error => {
                // console.log(error)
                setIsCreditCardActive(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                    // console.log(error);
                }

            });
    };
      // Function to call Direct Debit details 
    async function GetDirectDebitDetails() {
        await api.get(GET_DIRECT_DEBITS, config)
        .then(resp => {
            // console.log(resp.data)
            if(resp.status==200){
                resp.data.map((data)=>{
                    if(data.status === 'Approved'){
                        setActiveMethodPrimaryBank(data.accountNumber);
                        setIsDirectDebitAllowed(true);
                    }
                })
            }
            else{
                setIsDirectDebitAllowed(false);
            }
            
        })
        .catch(error => {
            // console.log(error)
            if (error?.status == 401 || error?.response?.status == 401) {
                // console.log("Error 401");
                props.Logout();
            }
            else {
            // console.log(error);
            }

        });
    };

    // Get Credit Cards Data
    async function GetCreditCardDetails() {
    await api.get(GET_CREDIT_CARD_DETAILS, config)
        .then(resp => {
        if(resp.status===200){
            setIsCreditCardAllowed(true);
            resp.data.map((data)=>{
                if(data.isPrimary){
                   setActiveMethodPrimaryCard(data.cardNumber); 
                //    console.log("Dataaaaaaaaaa",data.cardNumber)
                }
            })
            
        }
        else{
            setIsCreditCardAllowed(false);
        }
        })
        .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response?.status == 401) {
            // console.log("Error 401");
            props.Logout();
        }
        else {
            // console.log(error);
        }

        });
    };

    // Function to call Direct Debit information to allow reseller to activate the direct debit option 
    async function GetDirectDebitDetailsActive() {
        await api.get(GET_DIRECT_DEBITS, config)
        .then(resp => {
            // console.log(resp.data)
            if(resp.status==200){
                resp.data.map((data)=>{
                    if(data.status === 'Approved'){
                        setActiveMethodPrimaryBank(data.accountNumber);
                    }
                })
            }
            else{
            }
            
        })
        .catch(error => {
            // console.log(error)
            if (error?.status == 401 || error?.response?.status == 401) {
                // console.log("Error 401");
                props.Logout();
            }
            else {
            // console.log(error);
            }

        });
    };

    // Function to call Credit Card information to allow reseller to activate the credit card option 
    async function GetCreditCardDetailsActive() {
    await api.get(GET_CREDIT_CARD_DETAILS, config)
        .then(resp => {
        if(resp.status===200){
            resp.data.map((data)=>{
                if(data.isPrimary){
                    setActiveMethodPrimaryCard(data.cardNumber); 
                }
            })
            
        }
        })
        .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response?.status == 401) {
            // console.log("Error 401");
            props.Logout();
        }
        else {
            // console.log(error);
        }

        });
    };

    // Get Credit Cards Data
    async function UpdatePaymentMethod(paymentType) {
    await api.put(`${UPDATE_PAYMENT_METHOD}?paymentType=${paymentType}`,null, config)
        .then(resp => {
        if(resp.status===200){
            if(resp.data.isSuccess){
                setToastMessage("Payment method updated successfully");
                setToastSuccess(true);
                if(activeMethod==="Direct Debit"){setIsDirectDebitAllowed(false);}
                GetPaymentGatewayBillingAddress();
                setSwitchingPaymentMethodPopup(false);
                setTimeout(() => {
                    setToastSuccess(false);
                }, 3000);
                
            }
            else{
                setToastMessage("We encountered an issue while trying to update your payment method. Please try again later.");
                setToastError(true);
                GetPaymentGatewayBillingAddress();
                setTimeout(() => {
                    setToastError(false);
                }, 3000);
            }
        }
        })
        .catch(error => {
        // console.log(error)
        if (error?.status == 401 || error?.response?.status == 401) {
            // console.log("Error 401");
            props.Logout();
        }
        else {
            // console.log(error);
            setToastMessage("We encountered an issue while trying to update your payment method. Please try again later.");
                setToastError(true);
                GetPaymentGatewayBillingAddress();
                setTimeout(() => {
                    setToastError(false);
                }, 3000);
        }

        });
    };

    useEffect(() => {
        localStorage.getItem("navigation_link") ?  localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link","/billing/payment-methods");
        GetPaymentGatewayBillingAddress();
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
    // console.log("isDirectDebitAllowed",isDirectDebitAllowed)
    // console.log("isCreditCardAllowed",isCreditCardAllowed)
    }, [isDirectDebitAllowed,isCreditCardAllowed])

    return (
       <div className="main-content">
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
            <Popup
                className="custom-modal custom-modal-sm"
                open={switchingPaymentMethodPopup}
                onClose={() =>{ setSwitchingPaymentMethodPopup(false);setOthersToCreditCardPaymentMethodFlag(false);}}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Switching Payment Method</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container>
                            <div className={styles["no-data-cloud-delete"]}>
                                <img src={DeleteCardImage} className="empty-cloud-invoice-table-svg mx-auto" />
                                {switchingPaymentMethodPopupMessage=="Credit Card" ? <div className='my-3 text-center text-medium'>
                                    All your future and pending invoices will be processed with credit card {activeMethodPrimaryCard ? activeMethodPrimaryCard : ""}.<br></br><br></br>
                                    Please ensure you have sufficient clear funds with credit card {activeMethodPrimaryCard ? activeMethodPrimaryCard : ""} to meet your pending and future payment dates to avoid dishonour and surcharge fees.<br></br><br></br>
                                    {!othersToCreditCardPaymentMethodFlag && <><span className='text-muted'>Note : Your current approved bank account will be expired and you will need to submit a new Direct Debit - Bank Account Application.</span><br></br><br></br></>}
                                    <span className='text-strong'>Would you like to proceed?</span>
                                </div>:
                                (switchingPaymentMethodPopupMessage=="Others" ?<div className='my-3 text-center text-medium'>
                                    Are you sure you would like to make cloud payments using Others payment method?<br></br><br></br>
                                    <span className='text-muted'>Note : Your current approved bank account will be expired and you will need to submit a new Direct Debit - Bank Account Application.</span><br></br><br></br>
                                    <span className='text-strong'>Would you like to proceed?</span>
                                </div> : <div className='my-3 text-center text-medium'>
                                    All your future and pending invoices will be processed with Bank Account {activeMethodPrimaryBank ? activeMethodPrimaryBank : ""}.<br></br><br></br>
                                    Please ensure you have sufficient clear funds with Bank Account {activeMethodPrimaryBank ? activeMethodPrimaryBank : ""} to meet your pending and future payment dates to avoid dishonour and surcharge fees.<br></br><br></br>
                                    <span className='text-strong'>Would you like to proceed?</span>
                                </div>)}

                                <div> <Button
                                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                    variant="light"
                                    onClick={close}>No</Button>
                                    <Button onClick={()=>{
                                        UpdatePaymentMethod(switchingPaymentMethodPopupMessage);
                                    } }>Yes</Button></div>

                            </div>
                        </Container>
                    </div>
                )}
            </Popup>
           {paymentData &&  <Container fluid>
                <div className="accordion-panel mb-4">
                    <Accordion
                        className="pre-requisite-panels"
                        activeKey={activeAccordion}
                        onSelect={(e) => { if(!isRead){setActiveAccordion(e); setExpandedAll(false)} }}>
                        <Accordion.Item className={`mt-5`} eventKey="0">
                            <Accordion.Header>
                                <h3 className={`${styles['accordion-header-wrapper']}`}>
                                    <label>
                                        <span className={`${styles['accordion-name']}`}>Billing Address</span>
                                    </label>
                                </h3>
                            </Accordion.Header>
                            <Accordion.Body className='pt-0'>
                                <div>
                                    <Row>
                                        <Col md={4}>
                                            <label className='text-muted text-regular'>
                                                Address
                                            </label>
                                            <div className='text-strong my-1'>
                                                {paymentData.address}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <label className='text-muted text-regular'>
                                                Phone Number
                                            </label>
                                            <div className='text-strong my-1'>
                                                {paymentData.phoneNumber}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <label className='text-muted text-regular'>
                                                Fax
                                            </label>
                                            <div className='text-strong my-1'>
                                                {paymentData.fax}
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        {
                            paymentData && paymentData.paymentMethods.map((data,index)=>{
                                return(
                                    <Accordion.Item className={`mt-5 `} eventKey={index+1}>
                                        <Accordion.Header>
                                            <h3 className={`${styles['accordion-header-wrapper']}`}>
                                                <label>
                                                    {data.paymentMethod==="Credit Card" && 
                                                        <>
                                                            <Form>
                                                                <Form.Check // prettier-ignore
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    checked={data.isDefault}
                                                                    onClick={()=>{if(!(data.isDefault)){
                                                                        // console.log("data.paymentMethod ::",data.paymentMethod)
                                                                        if(activeMethod!=="Others"){
                                                                            setSwitchingPaymentMethodPopupMessage(data.paymentMethod);
                                                                            setSwitchingPaymentMethodPopup(true);
                                                                        }
                                                                        else{
                                                                            // UpdatePaymentMethod(data.paymentMethod);
                                                                            setOthersToCreditCardPaymentMethodFlag(true);
                                                                            setSwitchingPaymentMethodPopupMessage("Credit Card");
                                                                            setSwitchingPaymentMethodPopup(true);
                                                                        }  
                                                                    }}}
                                                                    disabled={!isCreditCardAllowed || isRead}
                                                                />
                                                            </Form>
                                                        </>
                                                    }
                                                    {data.paymentMethod==="Direct Debit" && 
                                                        <>
                                                            <Form>
                                                                <Form.Check // prettier-ignore
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    checked={data.isDefault}
                                                                    onClick={()=>{if(!(data.isDefault) && !isDirectDebitBlocked){
                                                                        if(activeMethod!=="Others"){
                                                                            setSwitchingPaymentMethodPopupMessage(data.paymentMethod);
                                                                            setSwitchingPaymentMethodPopup(true);
                                                                        }
                                                                        else{
                                                                            // UpdatePaymentMethod(data.paymentMethod);
                                                                            setSwitchingPaymentMethodPopupMessage("Direct Debit");
                                                                            setSwitchingPaymentMethodPopup(true);
                                                                        }  
                                                                    }}}
                                                                    disabled={!isDirectDebitAllowed || isRead}
                                                                />
                                                            </Form>
                                                        </>
                                                    }
                                                    {data.paymentMethod==="Others" && 
                                                        <>
                                                            <Form>
                                                                <Form.Check // prettier-ignore
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    checked={data.isDefault}
                                                                    disabled={isRead}
                                                                    onClick={()=>{if(!(data.isDefault)){
                                                                        if(activeMethod=="Direct Debit"){
                                                                            setSwitchingPaymentMethodPopupMessage("Others");
                                                                            setSwitchingPaymentMethodPopup(true);
                                                                        }
                                                                        else{
                                                                            UpdatePaymentMethod(data.paymentMethod);
                                                                        }
                                                                             
                                                                    }}}
                                                                />
                                                            </Form>
                                                        </>
                                                    }
                                                </label>
                                                <label className="pl-2">
                                                    <span className={(data.paymentMethod==="Direct Debit" && isDirectDebitBlocked) ? `text-muted ${styles['accordion-name']}` : `${styles['accordion-name']}`}>{data.paymentMethod}</span>
                                                </label>
                                            </h3>
                                        </Accordion.Header>
                                        {<Accordion.Body className={`${styles['credit-card-accordion']}`}>
                                                {data.paymentMethod === "Credit Card" ? <CreditCards Logout={props.Logout} activeAccordion={activeAccordion} setIsDirectDebitAllowed={setIsDirectDebitAllowed} UpdatePaymentMethod={UpdatePaymentMethod} setIsCreditCardAllowed={setIsCreditCardAllowed} isCreditCardActive={isCreditCardActive} /> : (data.paymentMethod === "Direct Debit" ? <DirectDebit Logout={props.Logout} activeAccordion={activeAccordion} setIsDirectDebitAllowed={setIsDirectDebitAllowed} isDirectDebitBlocked={isDirectDebitBlocked} /> :  <Others Logout={props.Logout} activeAccordion={activeAccordion} /> )}
                                        </Accordion.Body>}
                                    </Accordion.Item>
                                )
                            })
                        }
                        
                    </Accordion>
                </div>
            </Container>}
        </div>
    )
}

export default PaymentMethods
