import { Container, Row, Col, Button } from "react-bootstrap";
import styles from './styles.scss';
import { useEffect, useState } from "react";
import TableBlock from "../../../../../GlobalComponents/TableBlock";
import { api } from "../../../../../Utils/API";
import { CLOUD_SUMMARY_LISTING_TABLE, CLOUD_SUMMARY_DOWNLOAD_PDF_SLIPS, CLOUD_SUMMARY_DOWNLOAD_PDF_SUMMARY, DOWNLOAD_ACRONIS_USAGE_REPORT } from "../../../../../Utils/GlobalConstants";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import Toast from "../../../../../GlobalComponents/Toast";
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif"

const CloudSummaryDetails = (props) => {

    const location = useLocation();                                                                                     // get Location
    const navigate = useNavigate();                                                                                     // to use navigate function from react router dom
    const [loading, setLoading] = useState(false);                                                                      // loading state when api is called
    const [summeryId, setSummeryId] = useState(location.state !== null ? location.state.Id : "");                       // Get Summery ID From Listing Table
    const [summaryNumber, setSummaryNumber] = useState(location.state !== null ? location.state.summaryNumber : "");    // Get Summery Number From Listing Table
    const [resellerId, setResellerId] = useState(location.state !== null ? location.state.resellerId : "");             // Get Summery Number From Listing Table

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                                                            // toast message displaying success message
    const [toastError, setToastError] = useState(false);                                                                // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                                                               // toast message usestate

    const [isShowNotes, setIsShowNotes] = useState(false);                                                              // Show notes only for cloudcare

    // CLOUD Summery Details TABLE DATA
    const [invoiceColumnsData, setInvoiceColumnsData] = useState([]);                                                   // Invoice Table Columns Data
    const [invoiceTable, setInvoiceTable] = useState([]);                                                               // Invoice Table
    const [creditNotesColumnsData, setCreditNotesColumnsData] = useState([]);                                           // Credit Notes Table Columns Data
    const [creditNotesTable, setCreditNotesTable] = useState([]);                                                       // Credit Notes Table
    const [isCreditNotesTable, setIsCreditNotesTable] = useState(false)                                                 // Checks Is the Credit Notes Data Available
    const date = new Date();                                                                                            // for js date time function

    const [summaryDetailsDummy, setSummaryDetailsDummy] = useState(null);
    // Summery Details Table and All the Fields
    const [summeryDetails, setSummeryDetails] = useState(
        {
            "summaryDate": "",
            "currency": "",
            "soldTo": {
                "companyName": "",
                "terriCode": "",
                "abnNumber": "",
                "salesPerson": "",
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "country": "",
                "zipCode": "",
                "email": ""
            },
            "totalInvoiceAmount": 0,
            "integrationCode": "",
            "totalCreditNoteAmount": 0,
            "balanceAmount": 0,
            "dueDate": "",
            "paymentTerm": "",
            "creditNotes": [{
                "serviceName": "",
                "creditNoteNumber": "",
                "customerName": "",
                "creditNoteDate": "",
                "amount": ""
            }],
            "invoices": [{
                "invoiceNumber": "",
                "invoiceAmount": "",
                "invoiceDate": "",
                "serviceName": "",
                "customerName": "",
                "domainName": ""
            }],
            "distributor": {
                "companyName": "",
                "addressLine1": "",
                "addressLine2": "",
                "city": "",
                "state": "",
                "country": "",
                "zipCode": "",
                "phone": "",
                "fax": "",
                "abn": "",
                "acn": ""
            }
        }
    );

    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    const invoiceCreditNotesColumnName = ['No.', 'Brand', 'Invoice No', 'Ship To', 'Domain', 'Link', 'Invoice Date', 'Invoice Amount']; // Invoice and Credit Notes Table Columns

    // Get Summery Details based on Summery ID
    async function SummeryDetails() {
        setLoading(true);

        await api.get(`${CLOUD_SUMMARY_LISTING_TABLE + "/" + summeryId}?requestedResellerCode=${resellerId}`, config)  
            .then(resp => {
                // console.log("Summery Invoice", resp.data);
                if (resp.status == 200) {
                    setSummeryDetails(resp.data);
                    setSummaryDetailsDummy(resp.data);
                    if (resp.data.integrationCode === 'cloudcare') {
                        setIsShowNotes(true);
                    } else {
                        setIsShowNotes(false);
                    }
                  
                    let f = [];
                    let cf = [];

                    let sNo = 0;
                    let cSno = 0;
                    (resp.data.invoices).map((td) => {
                        sNo = sNo + 1;
                        f.push
                            (
                                {
                                    "No.": sNo,
                                    "Brand": td.serviceName,
                                    "Ship To": td.customerName,
                                    "Domain": td.domainName,
                                    "Invoice No": <span className="cloud-summary-color-underline"
                                        onClick={() => { navigate("/billing/cloud-invoice/invoice-detail", { state: { Id: td.invoiceNumber, summaryNumber: summaryNumber, resellerId: resellerId }, }); }}>{td.invoiceNumber}</span>,
                                    "Link": <span className="text-primary cpointer" onClick={() => { DownloadAction(summaryNumber, DOWNLOAD_ACRONIS_USAGE_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${summaryNumber}_Download_Acronis_Usage_Report.zip`, "zip") }}>Download Usage Report</span>,
                                    "Invoice Date": td.invoiceDate,
                                    "Invoice Amount": td.invoiceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.invoiceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.invoiceAmount)).toFixed(2))}`,
                                }
                            );
                    });



                    f.push
                        (
                            {
                                "No.": "",
                                "Brand": "",
                                "Ship To": "",
                                "Invoice No": "",
                                "Link": "",
                                "Invoice Date": "Total",
                                "Invoice Amount": resp.data.totalInvoiceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((resp.data.totalInvoiceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(resp.data.totalInvoiceAmount)).toFixed(2))}`
                            }
                        );



                    setTimeout(() => {
                        setInvoiceTable(f);         // Setting Invoice Table Data
                    }, 50);


                    if (resp.data.creditNotes.length > 0) {
                        setIsCreditNotesTable(true);
                    } else {
                        setIsCreditNotesTable(false);
                    }
                    // Credit Notes Table
                    (resp.data.creditNotes).map((td) => {
                        cSno = cSno + 1;
                        cf.push
                            (
                                {
                                    "No.": cSno,
                                    "Brand": td.serviceName,
                                    "Ship To": td.customerName,
                                    "Invoice No": <span className="cloud-summary-color-underline"
                                        onClick={() => { navigate("/billing/cloud-creditNote/creditNote-detail", { state: { Id: td.creditNoteId, }, }); }}>{td.creditNoteNumber}</span>,
                                    "Link": "",
                                    "Invoice Date": td.creditNoteDate,
                                    "Invoice Amount": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`,
                                }
                            );
                    });

                    cf.push
                        (
                            {
                                "No.": "",
                                "Brand": "",
                                "Ship To": "",
                                "Domain": "",
                                "Invoice No": "",
                                "Link": "",
                                "Invoice Date": "Total",
                                "Invoice Amount": resp.data.totalCreditNoteAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((resp.data.totalCreditNoteAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(resp.data.totalCreditNoteAmount)).toFixed(2))}`
                            }
                        );

                    setTimeout(() => {
                        setCreditNotesTable(cf);         // Setting Credit Notes Table Data
                    }, 50);

                    let d = [];


                    invoiceCreditNotesColumnName.map((td) => {
                        if (td === "Invoice Amount") {
                            d.push({
                                id: `${td}`,
                                name: <div className="text-right">{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                            });
                        } else if (td === 'Domain' && resp.data.integrationCode !== "GCP") {
                            d.push({
                                omit: true
                            });
                        }
                        else if (td === "Invoice Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 0.6,
                            });
                        } else if (resp.data.integrationCode == "acronis1" && td === "Link") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 0.6,
                            });
                        } else if (resp.data.integrationCode !== "acronis1" && td === "Link") {
                            d.push({
                                omit: true
                            });
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: false,
                            });
                        }

                    });

                    // console.log(d);
                    setInvoiceColumnsData(d);
                    setCreditNotesColumnsData(d);

                }

            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                    // console.log(error);
                }
            });
    }

    // Get Summery Details on Initial Loading
    useEffect(() => {
        SummeryDetails();
    }, []);

    // Summery Details Table 
    const SummeryDetailsColumnName = [
        {
            name: 'Summary Date',
            selector: row => <div className="text-wrap"> {row.summaryDate}</div>,
            grow: 0.5,
        },
        {
            name: 'Customer Code',
            selector: row => <div className="text-wrap"> {row.customerCode}</div>,
            left: true,
            grow: 1,
        },
        {
            name: 'TERR. Code',
            selector: row => <div className="text-wrap"> {row.terrCode}</div>,
            left: true,
        },
        {
            name: 'ABN Number',
            selector: row => <div className="text-wrap"> {row.abnNumber}</div>,
        },
        {
            name: 'Sales Person',
            selector: row => <div className="text-wrap"> {row.salesPerson}</div>,
        },
        {
            name: 'Currency',
            selector: row => <div className="text-wrap"> {row.currency}</div>,
            left: true,
        },
    ];

    // // Sample Data till API Ready
    const summeryDetilsData = [
        {
            summaryDate: summeryDetails.summaryDate,
            customerCode: summeryDetails.soldTo.companyName,
            terrCode: summeryDetails.soldTo.terriCode,
            abnNumber: summeryDetails.soldTo.abnNumber,
            salesPerson: summeryDetails.soldTo.salesPerson,
            currency: summeryDetails.currency
        }
    ];

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF and CSV File from actions Dropdown
    async function DownloadAction(id, url, name, fileType) {
        let configuration;
        if (fileType === "pdf" || fileType === "zip") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url + id}?requestedResellerCode=${resellerId}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    // console.log("Response : ", resp.data);
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                    // // console.log(d);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                    // console.log(error);
                }
            });
    };

    // Custom Styles for React Data Table
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                textAlign: 'center',
            },
        },
    };

    return (
        <Container fluid className="main-content px-0 cloud-summery-details-table">
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
            {
                !summaryDetailsDummy && 
                    <div className="no-data-cloud-summary-details">
                        <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading summary details...</p>
                    </div>
            }
            {summaryDetailsDummy && <Container fluid className="my-3">

                <Row className="align-items-center">

                    <Col className="gotoback col-md-6" onClick={() => navigate(-1)}>
                        <span className="back">&nbsp;</span>
                        <span>Cloud Bill Summary No.: </span>
                        <span className="text-normal">{summaryNumber}</span>
                    </Col>

                    <div className="col-md-6 pt-3">
                        <div className="btn-wrapper">
                            <Button className="px-4 mx-2" onClick={(e) => { DownloadAction(summaryNumber, CLOUD_SUMMARY_DOWNLOAD_PDF_SLIPS, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${summaryNumber}_summaryslip.pdf`, "pdf") }}>Download Slips</Button>
                            <Button className="px-4 mx-2" onClick={() => { DownloadAction(summaryNumber, CLOUD_SUMMARY_DOWNLOAD_PDF_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${summaryNumber}_summary.pdf`, "pdf") }}>Download Summary</Button>
                        </div>
                    </div>

                </Row>
                <Row className="details-box px-1">

                    <Col md={4} className="mb-3 p-3 mt-3">
                        <div>
                            <h3 className="text-primary mb-5 text-uppercase">Details</h3>
                            <p>
                                <span className="text-strong">{summeryDetails.distributor.companyName}</span><br />
                                {summeryDetails.distributor.addressLine1}<br />
                                {summeryDetails.distributor.addressLine2 && <>{summeryDetails.distributor.addressLine2}<br /></>}
                                {summeryDetails.distributor.city}<br />
                                {summeryDetails.distributor.state},&nbsp;{summeryDetails.distributor.country} - {summeryDetails.distributor.zipCode}<br />

                                ABN:{summeryDetails.distributor.abn}<br />
                                ACN:{summeryDetails.distributor.acn}<br />
                                TEL:{summeryDetails.distributor.phone}<br />
                                FAX:{summeryDetails.distributor.fax}
                            </p>
                        </div>
                    </Col>
                    <Col md={4} className="mb-3 p-3 mt-3 d-flex justify-content-md-center">
                        <div>
                            <h3 className="text-primary mb-5 text-uppercase">Sold To</h3>
                            <p>
                                <span className="text-strong">{summeryDetails.soldTo.companyName}</span><br />
                                {summeryDetails.soldTo.addressLine1}<br />
                                {summeryDetails.soldTo.addressLine2 && <>{summeryDetails.soldTo.addressLine2}<br /></>}
                                {summeryDetails.soldTo.city && <>{summeryDetails.soldTo.city}<br /></>}
                                {summeryDetails.soldTo.state},&nbsp;{summeryDetails.soldTo.country} - {summeryDetails.soldTo.zipCode}<br />
                                {/* {summeryDetails.soldTo.terriCode}<br /> */}
                                {summeryDetails.soldTo.email}<br />
                            </p>
                        </div>
                    </Col>
                </Row>
                <div className="summary-detail-first-table">
                    <TableBlock
                        tableName=""
                        programName=""
                        domainName=""
                        loadingMessage=""
                        columnNames={SummeryDetailsColumnName}
                        customStyles={customStyles}
                        columnData={summeryDetilsData}
                    />
                </div>
                <div className="cloud-summery-invoice">
                    <TableBlock
                        tableName="Invoices"
                        domainName=""
                        loadingMessage=""
                        columnNames={invoiceColumnsData}
                        customStyles={customStyles}
                        columnData={invoiceTable}
                    />
                </div>

                {isCreditNotesTable &&
                    <div className="cloud-summery-invoice">

                        <TableBlock
                            tableName="Credit Notes"
                            domainName=""
                            loadingMessage=""
                            columnNames={creditNotesColumnsData}
                            customStyles={customStyles}
                            columnData={creditNotesTable}
                        />
                    </div>
                }
                <Row className="details-box py-4 px-1 pb-2">
                    <Col md={6}>
                        <p className="text-regular mb-1">Payment Terms<br /></p>
                        <h2 className="text-strong">{summeryDetails.paymentTerm}</h2>

                        <p className="text-regular mb-1">Due Date<br /></p>
                        <h2 className="text-strong">{summeryDetails.dueDate}</h2>
                    </Col>
                    <Col md={6}>
                        <table className="text-right float-right mobile-table">
                            <tr>
                                <td><label className="text-strong">Total Invoice Amount:</label></td>
                                <td><label className="text-strong ml-5 pl-5">{summeryDetails.totalInvoiceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((summeryDetails.totalInvoiceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(summeryDetails.totalInvoiceAmount)).toFixed(2))}`}</label></td>
                            </tr>


                            <tr>
                                <td><label className="text-strong">Total Credit Note Amount:</label></td>
                                <td><label className="text-strong ml-5 pl-5">{summeryDetails.totalCreditNoteAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((summeryDetails.totalCreditNoteAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(summeryDetails.totalCreditNoteAmount)).toFixed(2))}`}</label></td>
                            </tr>

                            <tr>
                                <td colSpan="2"><hr /></td>
                            </tr>
                            <tr>
                                <td><label><h2 className="text-strong">Balance Amount:</h2></label></td>
                                <td><label><h2 className="text-primary ml-5 pl-5 text-strong">{summeryDetails.balanceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((summeryDetails.balanceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(summeryDetails.balanceAmount)).toFixed(2))}`}</h2></label></td>
                            </tr>
                        </table>
                    </Col>
                </Row>
                <Row>
                    {isShowNotes &&
                        <Col md={12}>
                            <p><span className="text-strong">Note:</span> Unit prices shown are based on the total amount of quantity used across cloud care offers.</p>
                        </Col>
                    }
                </Row>
            </Container>}
        </Container>
    );
}

export default CloudSummaryDetails;