import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";

const CustomNumberInput = ({ defaultValue, min, max, setUpdatedQuanity, value, setValue, setAdditionalNextStepFlag }) => {
  const [quantityEditedCheck, setQuantityEditedCheck] = useState(false);
  const qunatityRefBox = useRef();
  // Functions to increase and decrease the value
  const increaseValue = () => {
    setValue(parseInt(value) + 1);
  };

  const decreaseValue = () => {
    setValue(parseInt(value) - 1);
  };

  useEffect(() => {
    if (value !== defaultValue) {
      qunatityRefBox.current.className = "number-input active";
      setQuantityEditedCheck(true);
    } else {
      qunatityRefBox.current.className = "number-input";
      setQuantityEditedCheck(false);
    }
  }, [value]);

  return (
    <>
      <div className="d-flex">
        <div class="number-input" ref={qunatityRefBox}>
          <input
            type="number"
            min={min}
            max={max}
            defaultValue={value}
            value={value}
            onChange={(e) => {
              // Capture input as a string
              const inputValue = e.target.value;

              // Remove leading zeros and convert to number
              const data = Number(inputValue.replace(/^0+/, '') || 0);

              // Update the state with cleaned value
              setValue(data);

              // Set editing state based on the input
              if (data === value) {
                setQuantityEditedCheck(false);
              } else if (data > max) {
                setValue(max);
              } else {
                setQuantityEditedCheck(true);
              }
            }}
            onBlur={() => {
              // When the input loses focus, check for boundaries
              if (value > max) {
                setValue(max);
              } else if (value < min) {
                setValue(min);
              }
            }}
            onKeyDown={(e) => {
              if (e.code === "Minus") e.preventDefault();
            }}
          />
          <div className="btn-wrapper">
            {max && value < max && value >= min ? (
              <button className="plus" onClick={() => increaseValue()}>
                +
              </button>
            ) : (
              <button className="plus disabled" disabled>
                +
              </button>
            )}

            {value > min ? (
              <button className="minus" onClick={() => decreaseValue()}>
                <span>&nbsp;</span>
              </button>
            ) : (
              <button className="minus disabled" disabled>
                <span>&nbsp;</span>
              </button>
            )}
          </div>
        </div>

        <div className="d-flex d-flex align-items-center">
          {quantityEditedCheck ? (
            value <= max && value >= min ? (
              <span
                className="mx-2 quantity-tick"
                onClick={() => {
                  setUpdatedQuanity(value);
                  setAdditionalNextStepFlag(true);
                }}
              />
            ) : (
              <span
                className="mx-2 tick-grey"
              />
            )
          ) : (
            <span
              className="mx-2 tick-grey"
            />
          )}
          {/* {quantityEditedCheck ? (
            <span
              className="mx-2 quantity-tick"
              onClick={() => {
                setUpdatedQuanity(value);
                setAdditionalNextStepFlag(true);
              }}
            />
          ) : (
            <span
            className="mx-2 tick-grey"
          />
          )} */}
          <span
            className="quantity-cross"
            onClick={() => {
              setUpdatedQuanity(defaultValue);
              setValue(defaultValue);
            }}
          >
            &nbsp;
          </span>
        </div>
      </div>

      <div className="text-disabled col-12">
        <span className="text-small">Min {min}, </span>
        <span className="text-small">Max {max}</span>
      </div>
    </>
  );
};

export default CustomNumberInput;