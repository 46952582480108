import { Button, Col, Container, Form, NavDropdown, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import styles from './dashboard.module.scss'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DASHBOARD_VIEWS_WIDGET, GET_DASHBOARD_WIDGETS, SAVE_DASHBOARD_WIDGETS } from "../../../Utils/GlobalConstants";
import { api } from "../../../Utils/API";
import AddWidgets from "./LocalComponents/AddWidgets";
import MonthlySummary from "./LocalComponents/MonthlySummary";
import RecentCustomers from "./LocalComponents/RecentCustomers";
import LoginTermsAndCondition from "./LocalComponents/LoginTermsAndConditions";
import Card from "./LocalComponents/Card";

const DashBoard = (props) => {

    const [widgetPositionData, setWidgetPositionData] = useState([]);
    const [masterWidget, setMasterWidget] = useState([]);
    const [addWidgetPopup, setAddWidgetPopup] = useState(false);
    const [duplicatewidgetPositionData, setDuplicateWidgetPositionData] = useState([]);
    const [viewId, setViewId] = useState(null);
    const [viewData, setViewData] = useState(null);
    const [viewNameDefault, setViewNameDefault] = useState(null);
    const [noAddWidgetAvailable, setNoAddWidgetAvailable] = useState(false);

    const ItemTypes = {
        CARD: 'card',
    };

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetDashboardWidget() {
        await api.get(GET_DASHBOARD_WIDGETS + "/" + viewId, config)
            .then(resp => {
                let dataWidget = [];
                if ((resp.data.widgets).length === (resp.data.master).length) {
                    setNoAddWidgetAvailable(true);
                    dataWidget = resp.data.widgets;
                }
                else {
                    setNoAddWidgetAvailable(false);
                    dataWidget = resp.data.widgets;
                    if (dataWidget.length % 2 == 0) {
                        dataWidget.push({ id: "9999", widgetName: "add" })
                        dataWidget.push({ id: "9999", widgetName: "add" })
                    }
                    else {
                        dataWidget.push({ id: "9999", widgetName: "add" })
                    }
                }
                setWidgetPositionData(dataWidget);
                setDuplicateWidgetPositionData(dataWidget);
                setMasterWidget(resp.data.master);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Function to save widget in dashboard
    async function SaveDashboardWidget(widgetData, isRefreshPage) {
        let body = widgetData;
        await api.post(SAVE_DASHBOARD_WIDGETS + "/" + viewId, body, config)
            .then(resp => {
                if (isRefreshPage) {
                    setWidgetPositionData([]);
                    GetDashboardWidget();
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    async function GetAllViews() {
        await api.get(`${DASHBOARD_VIEWS_WIDGET}`, config)
            .then(resp => {
                setViewData(resp.data);
                if (viewNameDefault === null) {
                    (resp.data).map((view) => {
                        if (view.isDefaultView) {
                            setViewId(view.viewId);
                            setViewNameDefault(view.viewName);
                        }
                    })
                }

            })
            .catch(error => {

                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                }
            });
    };

    const moveItem = (dragIndex, hoverIndex) => {
        const draggedItem = widgetPositionData[dragIndex];
        const newwItems = [...widgetPositionData];
        newwItems.splice(dragIndex, 1);
        newwItems.splice(hoverIndex, 0, draggedItem);
        setWidgetPositionData(newwItems);
    };



    useEffect(() => {
        // GetDashboardWidget();
        GetAllViews();
    }, [])

    useEffect(() => {
        if (viewId) {
            GetDashboardWidget();
        }
    }, [viewId])

    useEffect(() => {
        if (widgetPositionData.length > 0 && duplicatewidgetPositionData.length > 0 && duplicatewidgetPositionData !== widgetPositionData) {
            // console.log("widgetPositionData :", widgetPositionData);
            let postDashboardPositionChange = [];
            widgetPositionData.map((widget) => {
                if (widget.widgetName !== "add") {
                    postDashboardPositionChange.push(widget);
                }
            })
            // console.log("postDashboardPositionChange :", postDashboardPositionChange);
            SaveDashboardWidget(postDashboardPositionChange, false);
        }

    }, [widgetPositionData])


    return (
        <>
            <LoginTermsAndCondition termsAndConditionPopupFlag={props.termsAndConditionPopupFlag} setTermsAndConditionPopupFlag={props.setTermsAndConditionPopupFlag} Logout={props.Logout} />
            <Container fluid className="main-content">
                {widgetPositionData.length > 0 && <>
                    <AddWidgets addWidgetPopup={addWidgetPopup} setAddWidgetPopup={setAddWidgetPopup} masterWidget={masterWidget} widgetPositionData={widgetPositionData} SaveDashboardWidget={SaveDashboardWidget} />
                    <Row className="align-items-center">
                        <Col md={6} lg={6} className={`${styles['top-heading-ipad-pro']}`}>
                            <h2 className="mb-0">Welcome {localStorage.getItem("profile_name")} !</h2>
                        </Col>
                        <Col md={3} lg={3} className="d-lg-block d-none"></Col>
                        <Col md={4} lg={2} xs={8}>
                            <Form.Group
                                className={`form-group col-md-12 col-lg-11 ml-3 mb-0 download-btn ${styles['left-button']}`}
                                controlId="downloadId"
                            >
                                {viewNameDefault && <NavDropdown title={viewNameDefault} id="cloud-service-dropdown"
                                    className={"servcies-dropdown"}
                                // disabled={emptyCustomerFlag}
                                >
                                    {
                                        viewData && viewData.map((view) => {
                                            return (
                                                <NavDropdown.Item
                                                    key={view.viewId}
                                                    id={view.viewId}
                                                    value={view.viewName}
                                                    className={viewNameDefault === view.viewName ? "selected" : ""}
                                                    onClick={() => {
                                                        if (view.viewId !== viewId) {
                                                            setWidgetPositionData([]);
                                                            setDuplicateWidgetPositionData([]);
                                                            setViewNameDefault(view.viewName);
                                                            setViewId(view.viewId)
                                                        }
                                                    }}
                                                >
                                                    {view.viewName}
                                                </NavDropdown.Item>
                                            )
                                        })
                                    }
                                </NavDropdown>}
                            </Form.Group>
                        </Col>
                        <Col md={2} lg={1} xs={3} className={`${styles['button-mobile']}`}>
                            {!noAddWidgetAvailable ? <Button className={`px-2 cpointer ${styles["button-tab-view"]}`} onClick={() => { setAddWidgetPopup(true); }}>Add Widget</Button> : <Button className={`btn btn-border-light btn-lg px-2 cnotallowed ${styles["button-tab-view"]}`} variant="light" disabled >Add Widget</Button>}
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col lg={3} md={5} className={`${styles['left-widgets-ipad-pro']}`}>
                            <RecentCustomers Logout={props.Logout} />
                            <MonthlySummary Logout={props.Logout} />
                        </Col>
                        <Col lg={9} md={7} className={`${styles['right-widgets-ipad-pro']}`}>
                            <DndProvider backend={HTML5Backend}>
                                {widgetPositionData && widgetPositionData.reduce((rows, item, index) => {
                                    if (index % 2 === 0) {
                                        rows.push([]);
                                    }
                                    rows[rows.length - 1].push(
                                        <Col lg={6} md={12} key={item.id} className={`${styles['individual-widgets-ipad-pro']}`}>
                                            <Card id={item.id} widget={item.widgetName} index={index} moveItem={moveItem} styles={styles} SaveDashboardWidget={SaveDashboardWidget} widgetPositionData={widgetPositionData} useDrop={useDrop} ItemTypes={ItemTypes} useDrag={useDrag} setAddWidgetPopup={setAddWidgetPopup} Logout={props.Logout} />
                                        </Col>
                                    );
                                    return rows;
                                }, []).map((row, rowIndex) => (
                                    <Row key={rowIndex}>
                                        {row}
                                    </Row>
                                ))}
                            </DndProvider>
                        </Col>
                    </Row>
                </>}
            </Container>
        </>
    );
}

export default DashBoard;