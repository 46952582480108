import React, { useState, useEffect, useRef } from 'react';
import { Drawer } from "@mui/material";
import { Button, Col, Form, Nav, NavDropdown, Row, Tab } from "react-bootstrap";
import './styles.scss';
import { XLg } from 'react-bootstrap-icons';
import { api } from '../../Utils/API';
import { PROFILE_DETAILS, GET_SERVICES_ALL, GET_DEPARTMENT_CATEGORIES, SUBMIT_CONTACT_FORM, INTEGRATION_CONTACT_FORM, CONTACT_LIST } from '../../Utils/GlobalConstants';
import { emailRegEx, phoneOnChangeRegex, phoneRegex } from '../../GlobalComponents/RegExPatterns';
import Toast from "../../GlobalComponents/Toast";

const ContactUsDrawer = ({ open, toggleDrawer, setOpen, selectService, setSelectService, selectCategory, setSelectCategory, setIntegrationContactUsFormFlag, comingFromIntegration, Logout }) => {
    const formRef = useRef();
    const [serviceData, setServiceData] = useState([]);                             // Data in Service dropdown
    const [categoryData, setCategoryData] = useState([]);                           // Data in Category dropdown
    const [tabName, setTabName] = useState("Query");
    const [comments, setComments] = useState("");

    // profile detail fields 
    const [profileDetails, setProfileDetails] = useState({
        "firstName": "",
        "lastName": "",
        "userName": "",
        "password": "",
        "phoneNumber": "",
        "email": "",
        "passwordExpiry": "",
        "cloudEmail": ""
    });

    // updated profile details information 
    const [updatedProfileDetails, setUpdatedProfileDetails] = useState(profileDetails);

    // Form Body Post Request
    const [formBody, setFormBody] = useState({
        name: "",
        email: "",
        phoneNumber: null,
        category: null,
        connectWisDomainUrl: null,
        connectWiseDeployment: null,
        subject: "",
        referenceId: "",
        message: "",
        serviceName: null,
    });

    // Form Body Initial While from API
    const [initFormBody, setInitFormBody] = useState({
        name: "",
        email: "",
        phoneNumber: null,
        category: null,
        connectWisDomainUrl: null,
        connectWiseDeployment: null,
        subject: "",
        referenceId: "",
        message: "",
        serviceName: null,
    });

    useEffect(() => {

        // Set Initial and Form Body for the first time and updates only formBody later
        setFormBody({
            ...formBody,
            "name": updatedProfileDetails.firstName + " " + updatedProfileDetails.lastName,
            "email": updatedProfileDetails.email ? updatedProfileDetails.email : "",
            "phoneNumber": updatedProfileDetails.phoneNumber ? updatedProfileDetails.phoneNumber : "",
            "serviceName": selectService,
            "category": selectCategory
        });
        setInitFormBody({
            ...initFormBody,
            "name": profileDetails.firstName + " " + profileDetails.lastName,
            "email": profileDetails.email ? profileDetails.email : "",
            "phoneNumber": profileDetails.phoneNumber ? profileDetails.phoneNumber : "",
            "serviceName": selectService,
            "category": selectCategory
        });

    }, [updatedProfileDetails, profileDetails]);

    const [enableSubmit, setEnableSubmit] = useState(false);            // Submit Button
    const [enableClear, setEnableClear] = useState(false);              // Clear Button
    const [formSubmitting, setFormSubmitting] = useState(false);        // Button state while form submitting 

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [isErrorMessage, setIsErrorMessage] = useState("");
    const [contactList, setContactList] = useState("");

    // Form Errors 
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [phoneNumberError, setPhoneNumberError] = useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [serviceError, setServiceError] = useState(null);
    const [subjectError, setSubjectError] = useState(false);
    const [connectwiseDomainError, setConnectwiseDomainError] = useState(false);
    const [connectwiseOptionsError, setConnectwiseOptionsError] = useState(false);
    const [commentsError, setCommentError] = useState(false);


    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call account details (profile details)
    async function GetContactList() {
        await api.get(CONTACT_LIST, config)
            .then(resp => {
                setContactList(resp.data.contactListHtml);
            })
            .catch(error => {

                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {

                }

            });
    };




    // Function to call account details (profile details)
    async function GetProfileDetails() {
        await api.get(PROFILE_DETAILS, config)
            .then(resp => {
                setProfileDetails(resp.data);
                setUpdatedProfileDetails(resp.data);
            })
            .catch(error => {

                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {

                }

            });
    };

    let connectWiseServiceDetails = {
        "id": "",
        "name": "Connectwise",
        "integrationCode": "Connectwise",
        "serviceType": "ONLINE",
        "hasMultiProducts": false
    }


    // Function to call all dropdown values in Service Section in Filter Box
    async function GetAllServices() {
        await api.get(GET_SERVICES_ALL, config)
            .then(resp => {
                setServiceData([connectWiseServiceDetails, ...resp.data]);


            })
            .catch(error => {

                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {

                }
            });
    };

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetCategories() {
        await api.get(GET_DEPARTMENT_CATEGORIES, config)
            .then(resp => {
                setCategoryData(resp.data);

            })
            .catch(error => {

                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {

                }
            });
    };

    const validateFormErrors = () => {
        if (!emailRegEx.test(updatedProfileDetails.email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }

        if (updatedProfileDetails.phoneNumber == "") {
            setPhoneNumberError(true)
        } else {
            setPhoneNumberError(false);
        }

        if (!phoneRegex.test(updatedProfileDetails.phoneNumber)) {
            setPhoneNumberError(true)
        } else {
            setPhoneNumberError(false);
        }

        if (selectCategory == "Select Category") {
            setCategoryError(true);
        } else {
            setCategoryError(false);
        }

        if (selectService == "Select Service") {
            setServiceError(true);
        } else {
            setServiceError(false);
        }

        if (formBody.subject.length < 1) {
            setSubjectError(true)
        } else {
            setSubjectError(false)
        }

        if ((comingFromIntegration === true && selectService === "Connectwise") && formBody.connectWisDomainUrl.length < 1) {
            setConnectwiseDomainError(true);
        } else {
            setConnectwiseDomainError(false);
        }

        if ((comingFromIntegration === true && selectService === "Connectwise") && formBody.connectWiseDeployment.length < 1) {
            setConnectwiseOptionsError(true);
        } else {
            setConnectwiseOptionsError(false);
        }

        if (comments.length < 1) {
            setCommentError(true);
        } else {
            setCommentError(false);
        }
    }

    // Post Agreement Mapping
    async function SubmitContactForm() {
        validateFormErrors();

        if (!firstNameError &&
            !lastNameError &&
            !emailError &&
            emailRegEx.test(updatedProfileDetails.email) &&
            !phoneNumberError &&
            !categoryError &&
            !serviceError &&
            !connectwiseDomainError &&
            !connectwiseOptionsError &&
            !subjectError &&
            !commentsError
        ) {
            setFormSubmitting(true);
            if (comingFromIntegration == true && selectService === "Connectwise") {
                await api.post(INTEGRATION_CONTACT_FORM, formBody, config)
                    .then(resp => {


                        if (resp.request.status == 200) {



                            setToastSuccess(true);
                            setToastMessage("Contact Form submitted successfully!");

                            setTimeout(() => {
                                setToastSuccess(false);
                                setToastMessage("");
                            }, 3000);

                            setTimeout(() => {
                                setOpen(false);
                                setUpdatedProfileDetails(profileDetails);
                                resetForm();
                                setFormSubmitting(false);
                                setFormBody(initFormBody);
                                setIntegrationContactUsFormFlag(true);
                            }, 4000);
                        }
                    })
                    .catch(error => {
                        setToastError(true);
                        setToastMessage("Contact Form not submitted!");

                        setTimeout(() => {
                            setToastError(false);
                            setToastMessage("");
                        }, 3000);



                        if (error?.status == 401 || error?.response?.status == 401) {
                            Logout();
                        }
                        else {

                            setIsErrorMessage(error?.response?.data?.errors);

                        }

                    });
            } else {
                await api.post(SUBMIT_CONTACT_FORM, formBody, config)
                    .then(resp => {


                        if (resp.request.status == 200) {



                            setToastSuccess(true);
                            setToastMessage("Contact Form submitted successfully!");

                            setTimeout(() => {
                                setToastSuccess(false);
                                setToastMessage("");
                            }, 3000);

                            setTimeout(() => {
                                setOpen(false);
                                setUpdatedProfileDetails(profileDetails);
                                resetForm();
                                setFormSubmitting(false);
                                setFormBody(initFormBody);
                            }, 4000);
                        }
                    })
                    .catch(error => {
                        setToastError(true);
                        setToastMessage("Contact Form not submitted!");

                        setTimeout(() => {
                            setToastError(false);
                            setToastMessage("");
                        }, 3000);



                        if (error.status == 401) {
                            Logout();
                        }
                        else {

                            setIsErrorMessage(error.response.data.errors);

                        }

                        setFormSubmitting(false);
                    });
            }

        }
    };

    useEffect(() => {
        if (open) {
            GetContactList();
            GetProfileDetails();
            GetAllServices();
            GetCategories();
        }
    }, [open])

    const resetForm = () => {
        setFormBody(initFormBody);
        setUpdatedProfileDetails(profileDetails);
        setSelectCategory("Select Category");
        setSelectService("Select Service");
        setComments("");
        setFirstNameError(false);
        setLastNameError(false);
        setEmailError(false);
        setCategoryError(false);
        setServiceError(false)
        setSubjectError(false);
        setCommentError(false);

        if (formRef.current) {
            formRef.current.reset();
        }
    }

    useEffect(() => {
        setFormBody(prevFormBody => ({ ...prevFormBody, message: comments }));
    }, [comments]);

    const handleChange = (e) => {
        if (e.target.value.length <= 2000) {
            setComments(e.target.value);
        }
    };

    useEffect(() => {



        if (
            updatedProfileDetails.firstName?.length > 0 &&
            updatedProfileDetails.lastName?.length > 0 &&
            formBody.name?.length > 0 &&
            //   nameRegEx.test(formBody.name) &&
            formBody.email?.length > 0 &&
            phoneRegex.test(formBody.phoneNumber) &&
            formBody.category !== null &&
            formBody.category !== "Select Category" &&
            formBody.serviceName !== null &&
            formBody.serviceName !== "Select Service" &&
            ((comingFromIntegration === true && formBody.serviceName === "Connectwise") ? formBody.connectWisDomainUrl?.length > 0 : true) &&
            ((comingFromIntegration === true && formBody.serviceName === "Connectwise") ? formBody.connectWiseDeployment?.length > 0 : true) &&
            formBody.subject.length > 0 &&
            formBody.message.length > 0
        ) {
            setEnableSubmit(true);
        } else {
            setEnableSubmit(false);
        }

        if (JSON.stringify(initFormBody) == JSON.stringify(formBody)) {
            setEnableClear(false)
        } else {
            setEnableClear(true);
        }
    }, [formBody, updatedProfileDetails, profileDetails]);



    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)} className="contact-us-drawer-outer">
                <div className='contact-us-drawer-main p-5'>
                    <Row>
                        <Col>
                            <h1>Contact Us</h1>
                        </Col>
                        <Col className='text-right'>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={() => setOpen(false)}
                                color="#6A6A6A"
                            />
                        </Col>
                    </Row>
                    <Tab.Container activeKey={tabName}>
                        <div className="contact-form-tab">
                            <Col sm={12} className="mt-3 tab-panels-navigation">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="Query" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3'
                                            onClick={() => { setTabName("Query") }}
                                        >
                                            Query
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Contact List" className='addons-core-gws-manage pl-6 pr-6 pt-3 pb-3'
                                            onClick={() => { setTabName("Contact List") }}
                                        >
                                            Contact List
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>

                            <Col lg={12} className="tabs-content-wrapper">
                                <Tab.Content>
                                    {/* Query Tab */}
                                    <Tab.Pane eventKey="Query">
                                        <div className='contact-us-drawer-query-main pt-2'>
                                            <form ref={formRef}>
                                                <div className="contact-form-height">
                                                    <Row className="mx-0">
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3" controlId="formFirstName">
                                                                <Form.Label>First Name <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    maxLength={49}
                                                                    value={updatedProfileDetails.firstName}
                                                                    onChange={
                                                                        (e) => {
                                                                            setUpdatedProfileDetails(
                                                                                {
                                                                                    ...updatedProfileDetails,
                                                                                    firstName: e.target.value
                                                                                }
                                                                            );
                                                                            setFormBody(
                                                                                {
                                                                                    ...formBody,
                                                                                    name: e.target.value + " " + updatedProfileDetails.lastName
                                                                                })

                                                                        }
                                                                    }
                                                                />
                                                                {firstNameError &&
                                                                    <span className='text-small red'>Please enter valid first name.</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3" controlId="formLastName">
                                                                <Form.Label>Last Name <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    maxLength={49}
                                                                    value={updatedProfileDetails.lastName}
                                                                    onChange={
                                                                        (e) => {
                                                                            setUpdatedProfileDetails(
                                                                                {
                                                                                    ...updatedProfileDetails,
                                                                                    lastName: e.target.value
                                                                                }
                                                                            );
                                                                            setFormBody(
                                                                                {
                                                                                    ...formBody,
                                                                                    name: updatedProfileDetails.firstName + " " + e.target.value
                                                                                })


                                                                        }
                                                                    }
                                                                />
                                                                {lastNameError &&
                                                                    <span className='text-small red'>Please enter valid last name.</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3" controlId="formPrimaryEmail">
                                                                <Form.Label>Primary Email<span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    value={updatedProfileDetails.email}
                                                                    maxLength={100}
                                                                    onChange={
                                                                        (e) => {
                                                                            setUpdatedProfileDetails({ ...updatedProfileDetails, email: e.target.value });
                                                                            setFormBody({ ...formBody, email: e.target.value });
                                                                            setEmailError(false);
                                                                        }
                                                                    }
                                                                />
                                                                {emailError &&
                                                                    <span className='text-small red'>Incorrect email id, please enter correct email id.</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3" controlId="phoneNumber">
                                                                <Form.Label >
                                                                    Phone Number <span className="red">*</span>
                                                                </Form.Label>
                                                                <Row>
                                                                    <Col>
                                                                        <div className="phone-number-wrapper">
                                                                            <label>+61</label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                className="phone-number form-control col"
                                                                                placeholder="xxxxxxxxxx"
                                                                                name="phoneNumber"
                                                                                value={updatedProfileDetails.phoneNumber}
                                                                                maxLength="15"
                                                                                onChange={(e) => {
                                                                                    const inputValue = e.target.value;
                                                                                    if (inputValue === '' || phoneOnChangeRegex.test(inputValue)) {
                                                                                        setUpdatedProfileDetails({
                                                                                            ...updatedProfileDetails,
                                                                                            phoneNumber: inputValue
                                                                                        })
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group
                                                                className="form-group mb-3"
                                                                controlId="category"
                                                            >
                                                                <Form.Label>Category <span className="red">*</span></Form.Label>

                                                                <NavDropdown title={selectCategory} id="cloud-category-dropdown"
                                                                    className={`custom-dropdown ${selectCategory === "Select Category" ? "" : "selected-color"}`}>
                                                                    {categoryData?.map((option) => {

                                                                        return (<NavDropdown.Item
                                                                            key={option.id}
                                                                            id={option.id}
                                                                            value={option.name}
                                                                            className={selectCategory === option.name ? "selected" : ""}
                                                                            onClick={(e) => {
                                                                                let category = e.target.getAttribute("value");
                                                                                setSelectCategory(category);

                                                                                setFormBody({
                                                                                    ...formBody,
                                                                                    category: option.name,
                                                                                })
                                                                            }}
                                                                        >
                                                                            {option.name}
                                                                        </NavDropdown.Item>);

                                                                    })}
                                                                </NavDropdown>
                                                            </Form.Group>
                                                            {categoryError &&
                                                                <span className='text-small red'>Please select one category.</span>
                                                            }
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Cloud Service <span className="red">*</span></Form.Label>

                                                                <NavDropdown title={selectService} id="cloud-service-dropdown"
                                                                    className={`custom-dropdown dropdown-menu-contact ${selectService === "Select Service" ? "" : "selected-color"}`}>
                                                                    {serviceData?.map((option) => {
                                                                        return (<NavDropdown.Item
                                                                            key={option.id}
                                                                            id={option.id}
                                                                            value={option.name}
                                                                            className={selectService === option.name ? "selected" : ""}
                                                                            onClick={(e) => {
                                                                                let service = e.target.getAttribute("value");
                                                                                setSelectService(service);
                                                                                setFormBody({
                                                                                    ...formBody,
                                                                                    serviceName: option.name
                                                                                })
                                                                            }}
                                                                        >
                                                                            {option.name}
                                                                        </NavDropdown.Item>);
                                                                    })}
                                                                </NavDropdown>
                                                            </Form.Group>
                                                            {serviceError &&
                                                                <span className='text-small red'>Please select one service.</span>
                                                            }
                                                        </Col>
                                                        {(comingFromIntegration == true && selectService == "Connectwise") &&
                                                            <>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>ConnectWise Domain Url <span className="red">*</span></Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            value={formBody.connectWisDomainUrl}
                                                                            onChange={
                                                                                (e) => {
                                                                                    setFormBody({ ...formBody, connectWisDomainUrl: e.target.value });

                                                                                }
                                                                            }
                                                                        />
                                                                        {subjectError &&
                                                                            <span className='text-small red'>Please enter ConnectWise Domain Url.</span>
                                                                        }
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>ConnectWise Deployment <span className="red">*</span></Form.Label>

                                                                        <Row className="radio-group-wrapper mx-0">
                                                                            <Form.Check
                                                                                className="col-6 cpointer"
                                                                                type="radio"
                                                                                id="connectwise-radio-premise"
                                                                                label="On Premise"
                                                                                name="connectwisegroup"
                                                                                value="On Premise"
                                                                                onChange={(e) => {

                                                                                    setFormBody({
                                                                                        ...formBody,
                                                                                        connectWiseDeployment: e.target.value
                                                                                    });
                                                                                }
                                                                                }
                                                                            />

                                                                            <Form.Check
                                                                                className="col-6 cpointer"
                                                                                type="radio"
                                                                                id="connectwise-radio-cloud"
                                                                                label="On Cloud"
                                                                                name="connectwisegroup"
                                                                                value="On Cloud"
                                                                                onChange={(e) => {

                                                                                    setFormBody({
                                                                                        ...formBody,
                                                                                        connectWiseDeployment: e.target.value
                                                                                    });
                                                                                }
                                                                                }
                                                                            />
                                                                        </Row>
                                                                        {subjectError &&
                                                                            <span className='text-small red'>Please enter valid subject.</span>
                                                                        }
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                        }
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Subject <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={formBody.subject}
                                                                    onChange={
                                                                        (e) => {
                                                                            setFormBody({ ...formBody, subject: e.target.value });

                                                                        }
                                                                    }
                                                                />
                                                                {subjectError &&
                                                                    <span className='text-small red'>Please enter valid subject.</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Reference ID</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={formBody.referenceId}
                                                                    maxLength={30}
                                                                    onChange={
                                                                        (e) => {
                                                                            setFormBody({ ...formBody, referenceId: e.target.value })
                                                                        }
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Form.Group className="form-group mb-3" controlId="comments">
                                                                <Form.Label>Message <span className="red">*</span></Form.Label>
                                                                <Form.Control
                                                                    as="textarea" rows={5}
                                                                    value={comments}
                                                                    placeholder=""
                                                                    onChange={handleChange}
                                                                />

                                                                <Row>
                                                                    <Col className='text-left'>
                                                                        {commentsError &&
                                                                            <span className='text-small red'>Please enter valid comments.</span>
                                                                        }

                                                                        {
                                                                            comments.length > 2000 &&

                                                                            <span className='text-small red'>Comment length should not exceed 2000 charecters.</span>
                                                                        }

                                                                    </Col>
                                                                    <Col className='text-right'><span className="text-small text-right w-100 d-block mt-1">({comments.length}/2000)</span></Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Row className="contact-form-buttons mx-0">
                                                    {formSubmitting == false &&
                                                        <Col className={"text-right btn-wrapper-outer right-overlay-btn px-0"}>
                                                            {!enableClear &&
                                                                <Button className="mx-2 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light"
                                                                    disabled
                                                                >
                                                                    Clear
                                                                </Button>
                                                            }
                                                            {enableClear &&
                                                                <Button className="mx-2 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light"
                                                                    onClick={resetForm}
                                                                >
                                                                    Clear
                                                                </Button>
                                                            }

                                                            {enableSubmit &&
                                                                <Button className="mx-2 btn btn-lg"
                                                                    onClick={SubmitContactForm}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            }

                                                            {!enableSubmit &&
                                                                <Button
                                                                    className="btn btn-border-light btn-lg mx-2"
                                                                    variant="light"
                                                                    disabled
                                                                >
                                                                    Submit
                                                                </Button>
                                                            }
                                                        </Col>
                                                    }

                                                    {formSubmitting == true &&
                                                        <Col className={"my-3 text-right btn-wrapper-outer right-overlay-btn px-0"}>
                                                            <Button className="mx-2 btn-border-light w-md-auto col-xs-12"
                                                                variant="light"
                                                                disabled
                                                            >
                                                                Clear
                                                            </Button>

                                                            <Button
                                                                className="btn btn-border-light btn-lg mx-2"
                                                                variant="light"
                                                                disabled
                                                            >
                                                                Submitting...
                                                            </Button>

                                                        </Col>
                                                    }
                                                </Row>
                                            </form>
                                        </div>
                                    </Tab.Pane>

                                    {/* Contact List Tab */}
                                    <Tab.Pane eventKey="Contact List">
                                        <div className='contact-us-drawer-query-main'>
                                            <div className='contact-list-content' dangerouslySetInnerHTML={{ __html: contactList }}>

                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>

                        </div>
                    </Tab.Container>

                </div>
            </Drawer>
        </>

    )
}

export default ContactUsDrawer