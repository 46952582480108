import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";

const AzureFilterBox = (props) => {
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const { setIsAutoRefreshEnabled } = props;

    // Function to update selected statuses
    const handleStatusChange = (id) => {
        setSelectedStatuses((prevSelectedStatuses) => {
            if (prevSelectedStatuses.includes(id)) {
                return prevSelectedStatuses.filter((statusId) => statusId !== id);
            } else {
                return [...prevSelectedStatuses, id];
            }
        });
    };

    // Function to handle "All Statuses" checkbox
    const handleAllStatusesChange = (isChecked) => {
        setSelectedStatuses(isChecked ? props.statusData.map((status) => status.id) : []);
    };

    const closeFilterBox = () => {
        props.setShowFilterBox(false);
        // Re-enable auto-refresh if no filters are active or if filters were just cleared
        if (selectedStatuses.length === 0) {
            props.setIsAutoRefreshEnabled(true);
        }
    };

    const clearFilters = () => {
        props.setEmptyUserFilters(false);
        props.clearExistingData();
        setSelectedStatuses([]);
        props.setPageNo(1);
        props.GetTableData(1, props.pageSize, props.searchValueTable, []);
        props.setFilterFlag(false);
        props.setStatus([]);
        props.setIsAutoRefreshEnabled(true); // Re-enable auto-refresh when filters are cleared
    };

    useEffect(() => {
        setSelectedStatuses(props.status || []);
    }, [props.status]);

    return (
        <>
            <div className="filter-box filter-box-single">
                <Container>
                    <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                        <h2>Filters</h2>
                        <XLg size={16} onClick={closeFilterBox} />
                    </Col>
                    <Row className="mb-3 mobile-filter-row">
                        <Col className="filter-column">
                            <h3 className="mb-3">Status</h3>
                            <div>
                                <div className="filter-options">
                                    {props.statusData && props.statusData.length > 0 && (
                                        <>
                                            <Form.Check
                                                className="mb-2"
                                                type="checkbox"
                                                id={`status-all`}
                                                label="All Statuses"
                                                checked={selectedStatuses.length === props.statusData.length}
                                                onChange={(e) => handleAllStatusesChange(e.target.checked)}
                                            />
                                            {props.statusData.map((statusItem) => (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={statusItem.id}
                                                    type="checkbox"
                                                    id={`status-${statusItem.id}`}
                                                    label={statusItem.name}
                                                    checked={selectedStatuses.includes(statusItem.id)}
                                                    onChange={() => handleStatusChange(statusItem.id)}
                                                />
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="actions-row">
                        <Col className="d-flex justify-content-end">
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilters}
                                disabled={selectedStatuses.length === 0 && (!props.status || props.status.length === 0)}
                                
                            >
                                Clear
                            </Button>
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setStatus(selectedStatuses);
                                    props.clearExistingData();
                                    props.setShowFilterBox(false);
                                    props.setPageNo(1);
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, selectedStatuses, true);
                                    props.setFilterFlag(true);
                                    props.setFilterApplied(true);
                                    props.setEmptyUserFilters(true);
                                    props.setIsAutoRefreshEnabled(false); // Disable auto-refresh when filters are applied
                                }}
                                disabled={selectedStatuses.length === 0}
                            >
                                Apply
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AzureFilterBox