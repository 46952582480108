import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import styles from './ibmServiceManagement.module.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { GET_SERVICES, GET_CUSTOMERS_LIST, IBM_SERVICE_MANAGEMENT_ACCOUNT_DETAILS, IBM_RESOURCE_GROUP, IBM_RESOURCE_LIST } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Form from "react-bootstrap/Form";
import { Container, Button, Row, Col } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import serviceLogo from '../../../../../Assets/Images/Illustrations/ibm.svg';
import SearchDropdown from "../../../../../GlobalComponents/SearchDropdown";
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import EmptyServiceProvision from '../../../../../Assets/Images/Illustrations/EmptyServiceProvision.svg'
import Eye from '../../../../../GlobalComponents/Eye';
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif"

const IBMServiceManagement = ({ Logout }) => {

    const location = useLocation();                                                        // use to access data from other page data
    const navigate = useNavigate();                                                        // to use navigate function from react router dom
    const [searchIconFlag, setSearchIconFlag] = useState(false);                           // to open and close search box in resource group
    const [searchValueTable, setSearchValueTable] = useState(null);                        // storing searching value in the search input box in resource group
    const [searchIconListFlag, setSearchIconListFlag] = useState(false);                   // to open and close search box in resource list
    const [searchValueListTable, setSearchValueListTable] = useState(null);                // storing searching value in the search input box in resource list
    const searchInputRef = useRef();                                                       // Search Input Box resource group
    const searchInputResourceListRef = useRef();                                           // Search Input Box resource list 

    //Resource list tables
    const [columnsVirtualGuests, setColumnsVirtualGuests] = useState([]);
    const [tableVirtualGuests, setTableVirtualGuests] = useState([]);
    let ColumnNameVirtualGuests = ["Host Name", "Configuration", "IP Address", "FQDN", "Status"];

    const [columnsBareMetalInstances, setColumnsBareMetalInstances] = useState([]);
    const [tableBareMetalInstances, setTableBareMetalInstances] = useState([]);
    let ColumnNameBareMetalInstance = ["FQDN", "Configuration", "Billing Type"];

    const [columnsDedicatedHosts, setColumnsDedicatedHosts] = useState([]);
    const [tableDedicatedHosts, setTableDedicatedHosts] = useState([]);
    let ColumnNameDedicatedHosts = ["Name", "Capacity", "Disk Capacity"];

    const [columnsLoadBalancers, setColumnsLoadBalancers] = useState([]);
    const [tableLoadBalancers, setTableLoadBalancers] = useState([]);
    let ColumnNameLoadBalancers = ["Name", "Load Balancing Method", "Virtual IP Address", "Connection Limit", "Connection Type"];

    const [columnsNetworkGateways, setColumnsNetworkGateways] = useState([]);
    const [tableNetworkGateways, setTableNetworkGateways] = useState([]);
    let ColumnNameNetworkGateways = ["Name", "Private Vlan", "Private IP Address", "Public Vlan", "Public IP Address"];

    const [columnsNetworkStorage, setColumnsNetworkStorage] = useState([]);
    const [tableNetworkStorage, setTableNetworkStorage] = useState([]);
    let ColumnNameNetworkStorage = ["FQDN", "Capacity", "NAS Type"];

    const [columnsNetworkVlans, setColumnsNetworkVlans] = useState([]);
    const [tableNetworkVlans, setTableNetworkVlans] = useState([]);
    let ColumnNameNetworkVlans = ["Name", "Primary Subnet ID", "Public Vlan"];

    const [columnsPublicNetworkVlans, setColumnsPublicNetworkVlans] = useState([]);
    const [tablePublicNetworkVlans, setTablePublicNetworkVlans] = useState([]);
    let ColumnNamePublicNetworkVlans = ["Name", "Primary Subnet ID", "Public Vlan"];

    const [columnsRouters, setColumnsRouters] = useState([]);
    const [tableRouters, setTableRouters] = useState([]);
    let ColumnNameRouters = ["Network Management IP Address", "FQDN", "Domain", "Configuration"];

    const [columnsSubnets, setColumnsSubnets] = useState([]);
    const [tableSubnets, setTableSubnets] = useState([]);
    let ColumnNameSubnets = ["Name", "Gateway", "Subnet Type", "Netmask", "Total IP Addresses", "Usable IP Address Count"];

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                        // table active page number
    const [pageSize, setPageSize] = useState(5);                                    // table active page size
    const [totalRows, setTotalRows] = useState(0);                                  // table total count of data from api 
    const [columns, setColumns] = useState([]);                                     // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
    let ColumnName = ["Name", "Subscription ID", "Location"];                       // table headers used to make object of react data table component in resource group

    const [serverErrorUser, setServerErrorUser] = useState(false);                  // server error check state in table during api call to load data in resource group
    const [emptyUser, setEmptyUser] = useState(false);                              // empty table check state in table during api call to load data in resource group
    const [emptyUserSearch, setEmptyUserSearch] = useState(false);                  // empty search response check state in table during api call to load data in resource group

    const [emptyUserList, setEmptyUserList] = useState(false);                      // empty table in resource list check state in table during api call to load data
    const [emptyUserListSearch, setEmptyUserListSearch] = useState(false);          // empty search response in resource list check state in table during api call to load data
    const [serverErrorListUser, setServerErrorListUser] = useState(false);          // server error check state in resource list in table during api call to load data


    const [customerId, setCustomerId] = useState(location.state !== null ? location.state.customerId : "");
    const [serviceId, setServiceId] = useState(null);
    const [serviceData, setServiceData] = useState(null);                             // data in service dropdown
    const [customerData, setCustomerData] = useState([]);                             // data in status dropdown
    const [modalOpen, setModalOpen] = useState(false);                                // opens modal popup
    const [additionalDetails, setAdditionalDetails] = useState(null);                 // storing additionaldetails from api
    const [initialSubscriptionTableCall, setInitialSubscriptionTableCall]
        = useState(null);                                                           // initial loading of subscription table when service is provisioned
    const [loading, setLoading] = useState(false);                                    // loading state for resource group
    const [loadingList, setLoadingList] = useState(false);                            // loading state for resource list
    const [passwordType, setPasswordType] = useState(['password', '']);               // popup cloud details password

    // Search Value for Service Name
    const [serviceNameDefaultValue, setServiceNameDefaultValue] = useState("Select Service Name");
    const [serviceNameSearchValue, setServiceNameSearchValue] = useState();
    const [serviceName, setServiceName] = useState([]);
    const [serviceNameFiltered, setServiceNameFiltered] = useState(serviceName);
    const updatedServiceName = [];

    // Search Value for Customer Name
    const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState("Select Customer Name");
    const [customerNameSearchValue, setCustomerNameSearchValue] = useState();
    const [customerName, setCustomerName] = useState([]);
    const [customerNameFiltered, setCustomerNameFiltered] = useState(customerName);
    const updatedCustomerName = [];

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in Service Section 
    async function GetAllServices() {

        await api.get(GET_SERVICES, config)
            .then(resp => {
                let serviceArray = []
                resp.data.map((service) => {
                    if (service.serviceType == "ONLINE") {
                        serviceArray.push(service);
                    }
                })
                setServiceData(serviceArray);
                setServiceNameFiltered(serviceArray);
                for (let i in resp.data) {
                    if (resp.data[i].integrationCode === "softlayer") {
                        setServiceId(resp.data[i].id);
                        setServiceNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }
                for (let i in resp.data) {
                    if (!serviceName.includes(resp.data[i].name)) {
                        serviceName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Customer Section 
    async function GetAllCustomers() {
        await api.get(GET_CUSTOMERS_LIST, config)
            .then(resp => {
                setCustomerData(resp.data);
                setCustomerNameFiltered(resp.data);
                for (let i in resp.data) {
                    if (resp.data[i].id === customerId) {
                        setCustomerNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }
                for (let i in resp.data) {
                    if (!customerName.includes(resp.data[i].name)) {
                        customerName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // function to call all additional details of IBM
    async function GetAdditionalDetails() {
        await api.get(`${IBM_SERVICE_MANAGEMENT_ACCOUNT_DETAILS}?customerId=${customerId}`, config)
            .then(resp => {
                setAdditionalDetails(resp.data);
                if (resp.data.isProvision) {
                    setInitialSubscriptionTableCall(true);
                }
                else {
                    setInitialSubscriptionTableCall(false);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setInitialSubscriptionTableCall(false);
                }
            });
    };

    // Get Resource Group Data with Search Functionality
    async function GetTableData(page, newPerPage, search) {
        setLoading(true);
        (search === undefined || search === null) ? search = "" : search = search;
        await api.get(`${IBM_RESOURCE_GROUP}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&customerId=${customerId}`, config)
            .then(resp => {
                setLoading(false);
                if (resp.status == 204) {
                    if (search === "") {
                        setEmptyUser(true);
                    }
                    if (search != "") {
                        setEmptyUserSearch(true);
                    }
                }
                // handle success
                else {
                    setServerErrorUser(false);
                    setEmptyUser(false);
                    setEmptyUserSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Name": td.resourceGroupName,
                                    "Subscription ID": td.subscriptionId,
                                    "Location": td.location,
                                }
                            );
                    })
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Subscription ID" || td === "Name") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                                allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                }
            })
            .catch(error => {
                setLoading(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorUser(true);
                }
            });
    };

    // Get Resource list data with Search Functionality
    async function GetResourceListData(search) {
        setLoadingList(true);
        (search === undefined || search === null) ? search = "" : search = search;
        await api.get(`${IBM_RESOURCE_LIST}?customerId=${customerId}&searchText=${search}`, config)
            .then(resp => {
                setLoadingList(false);
                if (resp.status == 204) {
                    if (search === "") {
                        setEmptyUserList(true);
                    }
                    if (search != "") {
                        setEmptyUserListSearch(true);
                    }
                }
                // handle success
                else {
                    setServerErrorListUser(false);
                    setEmptyUserList(false);
                    setEmptyUserListSearch(false);
                    // console.log("resource list: ", resp.data.bareMetalInstances[0]);
                    //first:bareMetalInstances
                    if (resp.data.bareMetalInstances !== null && resp.data.bareMetalInstances.length > 0) {
                        // console.log(resp.data.bareMetalInstances);
                        let i = 0;
                        let f = [];
                        (resp.data.bareMetalInstances).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Billing Type": td.billingType,
                                        "Configuration": td.configuration,
                                        "FQDN": td.fullyQualifiedDomainName,
                                    }
                                )
                        });
                        setTableBareMetalInstances(f);
                        // console.log("data for bareMetalInstances  is: ", tableBareMetalInstances);
                        let d = [];

                        ColumnNameBareMetalInstance.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsBareMetalInstances(d);     // Setting Column Data
                        // console.log("columnsss: ", d)

                    }
                    //second:virtualGuests
                    if (resp.data.virtualGuests !== null && resp.data.virtualGuests.length > 0) {
                        // console.log("virtual guests: ", resp.data.virtualGuests);
                        let i = 0;
                        let f = [];
                        (resp.data.virtualGuests).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Host Name": td.hostname,
                                        "configuration": td.configuration,
                                        "IP Address": td.ipAddress,
                                        "FQDN": td.fullyQualifiedDomainName,
                                        "Status": td.status,
                                    }
                                )
                        });
                        setTableVirtualGuests(f);
                        // console.log("data is: ", tableVirtualGuests);
                        let d = [];

                        ColumnNameVirtualGuests.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsVirtualGuests(d);     // Setting Column Data
                        // console.log("columnsss for virtual guests: ", d)

                    }
                    //third:DedicatedHosts
                    if (resp.data.dedicatedHosts !== null && resp.data.dedicatedHosts.length > 0) {
                        // console.log(resp.data.dedicatedHosts);
                        let i = 0;
                        let f = [];
                        (resp.data.dedicatedHosts).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Capacity": td.capacity,
                                        "Disk Capacity": td.diskCapacity,
                                    }
                                )
                        });
                        setTableDedicatedHosts(f);
                        // console.log("data for tableDedicatedHosts is: ", tableDedicatedHosts);
                        let d = [];

                        ColumnNameDedicatedHosts.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsDedicatedHosts(d);     // Setting Column Data
                        // console.log("columnsss for DedicatedHosts: ", d)

                    }
                    //fourth:LoadBalancers
                    if (resp.data.loadBalancers !== null && resp.data.loadBalancers.length > 0) {
                        // console.log(resp.data.loadBalancers);
                        let i = 0;
                        let f = [];
                        (resp.data.loadBalancers).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Load Balancing Method": td.loadBalancingMethod,
                                        "Virtual IP Address": td.virtualIpAddress,
                                        "Connection Limit": td.connectionLimit,
                                        "Connection Type": td.connectionType,
                                    }
                                )
                        });
                        setTableLoadBalancers(f);
                        // console.log("data for tableLoadBalancers is: ", tableLoadBalancers);
                        let d = [];

                        ColumnNameLoadBalancers.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsLoadBalancers(d);     // Setting Column Data
                        // console.log("columnsss for LoadBalancers: ", d)

                    }
                    //fifth:NetworkGateways
                    if (resp.data.networkGateways !== null && resp.data.networkGateways.length > 0) {
                        // console.log(resp.data.networkGateways);
                        let i = 0;
                        let f = [];
                        (resp.data.networkGateways).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Public Vlan": td.publicVlan,
                                        "Public IP Address": td.publicIpAddress,
                                        "Private Vlan": td.privateVlan,
                                        "Private IP Address": td.privateIpAddress,
                                    }
                                )
                        });
                        setTableNetworkGateways(f);
                        // console.log("data is: ", tableNetworkGateways);
                        let d = [];

                        ColumnNameNetworkGateways.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsNetworkGateways(d);     // Setting Column Data
                        // console.log("columnsss for NetworkGateways: ", d)
                    }
                    // sixth:NetworkStorage
                    if (resp.data.networkStorage !== null && resp.data.networkStorage.length > 0) {
                        // console.log(resp.data.networkStorage);
                        let i = 0;
                        let f = [];

                        (resp.data.networkStorage).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "FQDN": td.fullyQualifiedDomainName,
                                        "Capacity": td.capacity,
                                        "NAS Type": td.nasType,
                                    }
                                )
                        });
                        setTableNetworkStorage(f);
                        // console.log("data for Network Storage is: ", tableNetworkStorage);
                        let d = [];

                        ColumnNameNetworkStorage.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsNetworkStorage(d);     // Setting Column Data
                        // console.log("columnsss for NetworkStorage: ", d)

                    }
                    //seventh:NetworkVlans
                    if (resp.data.networkVlans !== null && resp.data.networkVlans.length > 0) {
                        // console.log(resp.data.networkVlans);
                        let i = 0;
                        let f = [];
                        (resp.data.networkVlans).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Public Vlan": td.publicVlan,
                                        "Primary Subnet ID": td.primarySubnetId,
                                    }
                                )
                        });
                        setTableNetworkVlans(f);
                        // console.log("data for NetworkVlans is: ", tableNetworkVlans);
                        let d = [];

                        ColumnNameNetworkVlans.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsNetworkVlans(d);     // Setting Column Data
                        // console.log("columnsss for NetworkVlans: ", d)

                    }
                    // eight:PublicNetworkVlans
                    if (resp.data.publicNetworkVlans !== null && resp.data.publicNetworkVlans.length > 0) {
                        // console.log(resp.data.publicNetworkVlans);
                        let i = 0;
                        let f = [];
                        (resp.data.publicNetworkVlans).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Public Vlan": td.publicVlan,
                                        "Primary Subnet ID": td.primarySubnetId,
                                    }
                                )
                        });
                        setTablePublicNetworkVlans(f);
                        // console.log("data for PublicNetworkVlans is: ", tablePublicNetworkVlans);
                        let d = [];

                        ColumnNamePublicNetworkVlans.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsPublicNetworkVlans(d);     // Setting Column Data
                        // console.log("columnsss for PublicNetworkVlans: ", d)

                    }
                    //ninth:Routers
                    if (resp.data.routers !== null && resp.data.routers.length > 0) {
                        // console.log(resp.data.routers);
                        let i = 0;
                        let f = [];
                        (resp.data.routers).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "FQDN": td.fullyQualifiedDomainName,
                                        "Network Management IP Address": td.networkManagementIpAddress,
                                        "Domain": td.domain,
                                        "Configuration": td.configuration,
                                    }
                                )
                        });
                        setTableRouters(f);
                        // console.log("data for Routers is: ", tableRouters);
                        let d = [];

                        ColumnNameRouters.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsRouters(d);     // Setting Column Data
                        // console.log("columnsss for Routers: ", d)

                    }
                    // tenth:Subnets
                    if (resp.data.subnets !== null && resp.data.subnets.length > 0) {
                        // console.log(resp.data.subnets);
                        let i = 0;
                        let f = [];
                        (resp.data.subnets).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Gateway": td.gateway,
                                        "Subnet Type": td.subnetType,
                                        "Total IP Addresses": td.totalIpAddresses,
                                        "Netmask": td.netmask,
                                        "Usable IP Address Count": td.usableIpAddressCount,
                                    }
                                )
                        });
                        setTableSubnets(f);
                        // console.log("data for Subnets is: ", tableSubnets);
                        let d = [];

                        ColumnNameSubnets.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsSubnets(d);     // Setting Column Data

                    }
                    if (resp.data.subnets == null && resp.data.routers == null && resp.data.publicNetworkVlans == null && resp.data.networkVlans == null && resp.data.networkStorage == null && resp.data.networkGateways == null &&
                        resp.data.loadBalancers == null && resp.data.dedicatedHosts == null && resp.data.virtualGuests == null && resp.data.bareMetalInstances == null) {
                        emptyUserList(true);
                    }
                }
            })
            .catch(error => {
                setLoadingList(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorListUser(true);
                }
            });
    };


    // On search field in resource group value we trigger this function having react debugging after every 2000 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                setPageNo(1);
                GetTableData(1, pageSize, searchValueTable);
            }, 2000);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // On search field in resource list value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueListTable !== undefined && searchValueListTable !== null) {
            const getData = setTimeout(() => {
                GetResourceListData(searchValueListTable);
            }, 2000);
            return () => clearTimeout(getData);
        }
    }, [searchValueListTable])

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
    };

    // Use Effect to get all the details in dropdown and screen
    useEffect(() => {
        GetAdditionalDetails();
        GetAllServices();
        GetAllCustomers();
    }, [customerId]);

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        if (initialSubscriptionTableCall) {
            GetTableData(pageNo, pageSize, searchValueTable);
            GetResourceListData(searchValueListTable);
        }
    }, [initialSubscriptionTableCall])

    // Renders when service ID Changes
    useEffect(() => {
        if (serviceId) {
            let url = "";
            let integrationCode = "";
            serviceData && serviceData.map((data) => {
                if (data.id == serviceId) {
                    integrationCode = data.integrationCode
                }
            })
            switch (integrationCode) {
                case "softlayer": // IBM Cloud
                    navigate("/management-console/ibm", { state: { customerId: customerId } });
                    break;

                case "microsoftazure": // Microsoft CSP - Azure
                    navigate("/management-console/azure", { state: { customerId: customerId } });
                    break;

                case "cloudcare": // Avast Cloud Care
                    navigate("/management-console/avast", { state: { customerId: customerId } });
                    break;

                case "acronis1": //  Acronis Cyber Cloud
                    navigate("/management-console/acronis", { state: { customerId: customerId } });
                    break;

                case "googleworkspace": // Google Workspace
                    navigate("/management-console/gws", { state: { customerId: customerId } });
                    break;

                case "GCP":  // Google Cloud Platform
                    navigate("/management-console/gcp", { state: { customerId: customerId } });
                    break;

                default: // Microsoft CSP
                    navigate("/management-console/csp", { state: { customerId: customerId } });
                    break;
            }
        }
    }, [serviceId]);

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    //Scroll to top on opening of page
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        searchInputResourceListRef.current && searchInputResourceListRef.current.focus();
    }, [searchIconListFlag]);

    return (
        <div className='main-content'>
            <div className='container-fluid'>
                <Popup
                    open={modalOpen}
                    onClose={() => { setModalOpen(false); }}
                    className="custom-modal custom-modal-md custom-modal-ticket-details"
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header">
                                <h2>Cloud Portal Details</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={close}
                                />
                            </div>
                            <Container>

                                {additionalDetails && <Row>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Cloud Service</label>
                                        <h3 className="mb-3">IBM Cloud</h3>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Customer</label>
                                        <p><strong>{additionalDetails.customerName}</strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Portal</label>
                                        <p className='service-management-acronis-portal-link long-text'>
                                            <a className="long-text" href={additionalDetails.serviceUrl}>
                                                {additionalDetails.serviceUrl}
                                                <span className='external-link cpointer ml-2'></span>
                                            </a>
                                        </p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Start Date</label>
                                        <p><strong>
                                            {new Date(additionalDetails.startDate).toLocaleDateString('en-GB', {
                                                day: 'numeric',
                                                month: 'numeric',
                                                year: 'numeric',
                                            }).split('/').join('-')}
                                        </strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium long-text">User Name</label>
                                        <p><strong>{additionalDetails.userName}</strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Initial Password</label>
                                        <span className='d-flex password-toggle'>
                                            <input type={passwordType[0]} value={additionalDetails.password} readOnly className={`plain-text text-strong ${styles['password-ibm']}`} />
                                            <Eye setPasswordType={setPasswordType} passwordType={passwordType} />
                                        </span>
                                    </Col>
                                </Row>}
                            </Container>
                        </div>
                    )}
                </Popup>
                <Row>
                    <Col md={6} className="justify-content-between d-flex align-items-center">
                        <label className="cpointer" onClick={() => navigate('/management-console')}>
                            <span className="back">&nbsp;</span>&nbsp;<strong><span className={`${styles['service-management-heading']}`}>IBM Cloud - Service Management</span></strong>
                        </label>
                    </Col>
                    <Col md={6} className="text-right">
                        <img className={`${styles['gcp-service-management-logo']}`} src={serviceLogo} />
                    </Col>
                </Row>
                <div className='service-management-azure-header mt-5'>
                    <Row className='mb-3'>
                        <Col md={3} className='mb-3'>
                            <SearchDropdown
                                dropdownLabel="Cloud Service"
                                formGroupId="serviceNameId"
                                placeholder="Enter Service Name"
                                selectDefaultValue={serviceNameDefaultValue}
                                setSelectDefaultValue={setServiceNameDefaultValue}
                                selectOptions={serviceData}
                                selectOptionsFiltered={serviceNameFiltered}
                                setSelectOptionsFiltered={setServiceNameFiltered}
                                selectSearchValue={serviceNameSearchValue}
                                setSelectSearchValue={setServiceNameSearchValue}
                                updatedOptions={updatedServiceName}
                                setOptionId={setServiceId}
                                setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                            />
                        </Col>
                        <Col md={3} className='mb-3'>
                            <SearchDropdown
                                dropdownLabel="Customer Name"
                                formGroupId="customerNameId"
                                placeholder="Enter Customer Name"
                                selectDefaultValue={customerNameDefaultValue}
                                setSelectDefaultValue={setCustomerNameDefaultValue}
                                setOptionId={setCustomerId}
                                selectOptions={customerData}
                                selectOptionsFiltered={customerNameFiltered}
                                setSelectOptionsFiltered={setCustomerNameFiltered}
                                selectSearchValue={customerNameSearchValue}
                                setSelectSearchValue={setCustomerNameSearchValue}
                                updatedOptions={updatedCustomerName}
                                setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                            />
                        </Col>

                        <Col md={6} className='text-right'>
                            <div class="d-flex justify-content-between justify-content-md-end align-items-center h-100">
                                {initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="light" onClick={() => setModalOpen(true)}>Cloud Portal Details</Button>}
                                {!initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="secondary" disabled >Cloud Portal Details</Button>}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="service-management-azure-body user-management-console-main-div">
                    <div className="mgmt-console-table-row">
                        <div className="mt-3 mb-2 d-flex">
                            <Col className={`p-0 ${styles['user-management-table']}`}>
                                <div className={`${styles['user-management-table-inner']} ${styles['table-details']} details-box`}>
                                    <div className="my-0 table-heading align-items-center py-0 row">

                                        <Col md={12}>
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                {!(!loading && serverErrorUser && !loadingList && serverErrorListUser) && initialSubscriptionTableCall &&
                                                    <Col className={`px-0 ${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                                        <h5 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Resource Group</h5>
                                                    </Col>}
                                                {!serverErrorUser && !emptyUser && initialSubscriptionTableCall && <Col className="px-0 d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                    {!loading && !searchIconFlag &&
                                                        <span className="mx-3">&nbsp;</span>
                                                    }
                                                    {!loading && !searchIconFlag &&
                                                        <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                    }
                                                    {!loading && searchIconFlag &&
                                                        <InputGroup className="search-input-box search-input-box-xl">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search for Name/Subscription ID/Location"
                                                                aria-label="Search for Name/Subscription ID/Location"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                                ref={searchInputRef}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    }
                                                </Col>}
                                            </Row>
                                        </Col>

                                    </div>
                                    <Container fluid className="mb-5 p-0">
                                        <Row>
                                            <Col>
                                                <div className={`table-responsive ${styles['ibm-service-table']}`}>
                                                    {!loading && serverErrorUser && !loadingList && serverErrorListUser &&
                                                        <div className={styles["no-data-ibm"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-management-table-svg" />
                                                            <h2 className="mt-4 mb-3">Facing issues in reaching server, Try again later!</h2>
                                                        </div>
                                                    }
                                                    {!loading && serverErrorUser && !serverErrorListUser && !loadingList &&
                                                        <div className={styles["no-data-ibm-error-line"]}>
                                                            <p className="mt-4 mb-3 mx-6">Facing issues in reaching server, Try again later!</p>
                                                        </div>
                                                    }
                                                    {!loading && emptyUserSearch &&
                                                        <div className={styles["no-data-ibm"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Matching Resource Group Found.</h2>
                                                        </div>
                                                    }
                                                    {!initialSubscriptionTableCall && initialSubscriptionTableCall != null &&
                                                        <div className={styles["no-data-ibm"]}>
                                                            <img src={EmptyServiceProvision} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">Selected Cloud Service is not provisioned</h2>
                                                            <p className="mb-3">No Subscriptions are currently available for this cloud service.</p>
                                                            <Button className="btn btn-lg btn-primary px-3">Provision Cloud Service</Button>
                                                        </div>
                                                    }
                                                    {!loading && emptyUser && !loadingList && !emptyUserList &&
                                                        <div className={styles["no-data-ibm-error-line"]}>
                                                            <p className="mt-4 mb-3 mx-4">No Resource Group Found in this Cloud Service.</p>
                                                        </div>
                                                    }
                                                    {!loading && emptyUser && !loadingList && emptyUserList &&
                                                        <div className={styles["no-data-ibm"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Subscription Found in this Cloud Service.</h2>
                                                        </div>
                                                    }

                                                    {loading && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource Group...</p></div>}
                                                    {!loading && !serverErrorUser && !emptyUser && !emptyUserSearch && initialSubscriptionTableCall &&
                                                        <DataTable
                                                            columns={columns}
                                                            data={table}
                                                            theme="solarized"
                                                            customStyles={customStyles}
                                                            persistTableHead={false}
                                                            noDataComponent={loading && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource Group...</p></div>}
                                                            width="100%"
                                                            fixedHeaderScrollHeight="60vh"
                                                            fixedHeader
                                                        />}</div>
                                            </Col>
                                        </Row>
                                    </Container>

                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`my-0 table-heading align-items-center row`}>
                        {<Col className="px-1 mb-1">
                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                {initialSubscriptionTableCall &&
                                    <Col className={`pl-1 ${searchIconListFlag ? "hide-in-mobile" : ""}`}>
                                        {!(!loading && emptyUser && !loadingList && emptyUserList) && !(!loading && serverErrorUser && !loadingList && serverErrorListUser) && <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Resource List</h5>}
                                    </Col>}
                                {!serverErrorListUser && !emptyUser && initialSubscriptionTableCall && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                    {!loadingList && !searchIconListFlag && emptyUserList &&
                                        <span className="mx-3">&nbsp;</span>
                                    }
                                    {!loadingList && !searchIconListFlag && !emptyUserList &&
                                        <span className="search mx-3 cpointer" onClick={() => { setSearchIconListFlag(!searchIconListFlag); }}>&nbsp;</span>
                                    }
                                    {!loadingList && searchIconListFlag &&
                                        <InputGroup className="search-input-box search-input-box-xl mr-0">
                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                <span className="searchgrey cpointer"
                                                >
                                                    &nbsp;
                                                </span>
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder="Search for Name/Location"
                                                aria-label="Search for Name/Location"
                                                aria-describedby="basic-addon2"
                                                className="search-input-box-input"
                                                value={searchValueListTable}
                                                onChange={(e) => setSearchValueListTable(e.target.value)}
                                                ref={searchInputResourceListRef}
                                            />
                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                onClick={() => {
                                                    setSearchValueListTable("");
                                                    setSearchIconListFlag(!searchIconListFlag);
                                                }}>
                                                <span className="closebtn">
                                                    &nbsp;
                                                </span>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    }
                                </Col>}
                            </Row>
                        </Col>}
                    </div>
                    <Container fluid className="mb-5 p-0">
                        <Row>
                            <Col>
                                <div className={`table-responsive ${styles['ibm-service-table']}`}>
                                    {loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource Group...</p></div>}
                                    {!loadingList && serverErrorListUser && !loading && !serverErrorUser &&
                                        <div className={styles["no-data-ibm-error-line"]}>
                                            <p className="mt-6 mb-3 mx-6">Facing issues in reaching server, Try again later!</p>
                                        </div>
                                    }
                                    {!loadingList && emptyUserListSearch &&
                                        <div className={styles["no-data-ibm"]}>
                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                            <h2 className="mt-4 mb-3">No Matching Resource List Found.</h2>
                                        </div>
                                    }
                                    {!loadingList && emptyUserList && !loading && !emptyUser &&
                                        <div className={styles["no-data-ibm-error-line"]}>
                                            <p className="mt-6 mb-3 mx-6">No Resource List Found.</p>
                                        </div>
                                    }

                                    {/* first */}
                                    {tableBareMetalInstances.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Bare Metal Instances</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsBareMetalInstances}
                                                    data={tableBareMetalInstances}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}
                                    {/* second */}
                                    {tableNetworkVlans.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Network Vlans</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsNetworkVlans}
                                                    data={tableNetworkVlans}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}
                                    {/* third */}
                                    {tableDedicatedHosts.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Dedicated Hosts</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsDedicatedHosts}
                                                    data={tableDedicatedHosts}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}
                                    {/* fourth */}
                                    {tablePublicNetworkVlans.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Public Network Vlans</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsPublicNetworkVlans}
                                                    data={tablePublicNetworkVlans}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}
                                    {/* fifth */}
                                    {tableNetworkStorage.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Network Storage</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsNetworkStorage}
                                                    data={tableNetworkStorage}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader
                                                />}
                                        </div>}
                                    {/* sixth */}
                                    {tableRouters.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Routers</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsRouters}
                                                    data={tableRouters}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}

                                    {/* seventh: */}
                                    {tableNetworkGateways.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Network Gateways</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsNetworkGateways}
                                                    data={tableNetworkGateways}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}

                                    {/* eight */}
                                    {tableLoadBalancers.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Load Balancers</h2>
                                            </Col>

                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsLoadBalancers}
                                                    data={tableLoadBalancers}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}

                                    {/* ninth */}
                                    {tableVirtualGuests.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Virtual Guests</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsVirtualGuests}
                                                    data={tableVirtualGuests}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}
                                    {/* tenth */}
                                    {tableSubnets.length > 0 && !loadingList &&
                                        <div className={`my-0 align-items-center details-box ${styles['details-box']}`}>
                                            <Col>
                                                <h2 className="py-3 px-3 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Subnets</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsSubnets}
                                                    data={tableSubnets}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader
                                                />}
                                        </div>} </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='my-4'></div>
            </div>
        </div>
    )
}
export default IBMServiceManagement