import React, { useState, useEffect } from 'react'
import { Button, Container } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { api } from "../../../../../../Utils/API";
import 'reactjs-popup/dist/index.css';
import { CUSTOMER_UNBILLED_USAGE_REPORT, UPDATE_CUSTOMER_UNBILLED_USAGE_REPORT } from '../../../../../../Utils/GlobalConstants';
import 'reactjs-popup/dist/index.css';
import './styles.scss';
import { XLg, Check2 } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import Toast from "../../../../../../GlobalComponents/Toast";

const ExpandableTable = ({ data }) => {
    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                                       // table active page number
    const [pageSize, setPageSize] = useState(5);                                                   // table active page size
    const [totalRows, setTotalRows] = useState(0);                                                 // table total count of data from api 
    const [table, setTable] = useState([]);                                                        // data state used to display data in react data table component
    let ColumnName = ["Customer", "Estimated Unbilled Amount", "Alert Threshold", "Actions"];      // Define columns for the expanded table

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [updateAlertThresholdModalFlag, setUpdateAlertThresholdModalFlag] = useState(false);       //Updating Alert Threshold modal
    const [showTableLoading, setShowTableLoading] = useState("Loading your Customers Report . . . ");
    const [editingRowId, setEditingRowId] = useState(0);                                             //to check particular row for updatIng alert threshold value
    const [editedValue, setEditedValue] = useState();                                                //Update alert threshold new value
    const [unbilledUsageDataId, setUnbilledUsageDataId] = useState();                                // value of unbilled usage data id

    const [isEditing, setIsEditing] = useState(false);                                                      // for check button


    let d = [];
    ColumnName.map((td) => {
        if (td === "Alert Threshold") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                // selector: row => row.td,
                cell: (row) => {
                    return editingRowId == row["Unbilled UsageData Id"] ? (
                        <div className='d-flex justify-content-between'>
                            <div>
                                <input
                                    style={{
                                        maxWidth: '50px'
                                    }}
                                    type="number"
                                    min="0"
                                    value={editedValue}
                                    // onChange={(e) => setEditedValue(e.target.value)}
                                    onChange={(e) => {
                                        setEditedValue(e.target.value);
                                        if ((e.target.value) == (row["Alert Threshold"].slice(1, row["Alert Threshold"].length - 3))) {
                                            setIsEditing(false);
                                            // console.log("hekllo green")
                                        }
                                        else {
                                            setIsEditing(true);
                                            // console.log("hekllo grey")
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.code === 'Minus') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                            {isEditing ? (
                                <div className='mx-1' onClick={() => {
                                    // console.log("td for green button", row["Unbilled UsageData Id"])
                                    setUnbilledUsageDataId(row["Unbilled UsageData Id"]);
                                    saveAlertThresholdValue(row);
                                    setUpdateAlertThresholdModalFlag(true);
                                    // console.log(row["Unbilled UsageData Id"], "Unbilled UsageData Id");
                                    // console.log(editedValue);
                                }}>
                                    <Check2 size={24} color="green" />
                                </div>) :
                                <div onClick={() => {
                                    // console.log("td for grey button", row["Unbilled UsageData Id"])
                                }}>
                                    <Check2 size={24} color="grey" />

                                </div>
                            }
                            <div className='mx-1' onClick={() => handleEditclose(row)}><span className="closebtn">&nbsp;</span></div>
                        </div>
                    )
                        : (
                            <div className="alert-threshold-cell">
                                <div className="text-wrap">{row[`${td}`]}</div>  {/* Display the alertThreshold value */}
                            </div>
                        )
                },
                left: true,
                allowOverflow: true,
            })
        }
        else if (td === "Actions") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                cell: row => <span onClick={() => handleEditClick(row)} className="editpencil">&nbsp;</span>,
                center: true,
                allowOverflow: true,
            })
        }
        else if (td === "Customer") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
                grow: 3,
            })
        }
        else {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                // sortable: true,
                // allowOverflow: true,
            })
        }

    });

    //save alert threshold updated value
    const saveAlertThresholdValue = (rowId) => {
        // console.log('Saved value :', editedValue, rowId);
        setEditedValue(editedValue);
    };
    //close alert threshold input box with button
    const handleEditclose = (rowId) => {
        setEditingRowId();
        setEditedValue();
    };

    //click on edit actions button
    const handleEditClick = (rowId) => {
        // console.log('clicked for edit', rowId);
        setEditedValue(Number(rowId["Alert Threshold"].slice(1)));
        setEditingRowId(rowId["Unbilled UsageData Id"]);
    };


    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Update Customer Alert threshold value
    async function UpdateCustomerAlertThreshold(unbilledUsageDataId, alertThreshold) {
        // Define the request body for the PUT API
        const requestBody = {
            "unbilledUsageDataId": unbilledUsageDataId.toString(),
            "alertThreshold": alertThreshold
        };
        // console.log("unbilled:", unbilledUsageDataId);
        // console.log("alertThreshold:", alertThreshold);
        await api.put(UPDATE_CUSTOMER_UNBILLED_USAGE_REPORT, requestBody, config)
            .then(resp => {
                // console.log("Response of updation: ", resp.data);
                // handle success
                GetTableData(data.ServiceId, pageNo, pageSize);
                // console.log("Response of updation: ", resp.data);
                setToastMessage("Alert Threshold Value Updated Successfully");
                setToastSuccess(true);
                setTimeout(() => {
                    setToastSuccess(false);
                }, 2000);
                setEditingRowId();
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    // // console.log("Error 401");
                    // Logout();
                }
                else {
                    // console.log(error);
                    setToastMessage("Alert Threshold Value could not be updated at the moment, try again later.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
            });
    }

    // Get Expanded Table Data with Pagination Functionality
    async function GetTableData(serviceId, page, newPerPage) {
        await api.get(`${CUSTOMER_UNBILLED_USAGE_REPORT}?serviceId=${serviceId}&pageNo=${page}&pageSize=${newPerPage}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setShowTableLoading("No Reports Found")
                }
                // handle success
                else {
                    setShowTableLoading("Loading your Customers Report . . . ");
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Customer": td.customerName,
                                    "Estimated Unbilled Amount": `AUD ${td.estimatedUnbilledAmount}`,
                                    "Alert Threshold": `AUD ${td.alertThreshold}`,
                                    "Unbilled UsageData Id": td.unbilledUsageDataId,
                                }
                            );
                    })
                    // console.log("Test F", f);
                    setTable(f);         // Setting Table Data
                }
            }
            )
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    // Logout();
                }
                else {
                    // console.log(error);
                }

            });
    };

    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                textAlign: 'center',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(data.ServiceId, pageNo, pageSize);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(data.ServiceId, page, newPerPage);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        GetTableData(data.ServiceId, pageNo, pageSize);
    }, [])

    useEffect(() => {
        GetTableData(data.ServiceId, pageNo, pageSize);
    }, [])

    return (
        <div className='expandable-table-iaas-usage-report'>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

            <Popup
                className="custom-modal custom-modal-md"
                open={updateAlertThresholdModalFlag}
                onClose={() => setUpdateAlertThresholdModalFlag(false)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Message</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className="p-5">
                            <p className='pt-0 px-3 pb-5 mb-0'>
                                <strong>Are you sure you want to change threshold value</strong>
                            </p>
                            <div className="actions">
                                <div className="d-flex justify-content-center pb-3 pt-2">
                                    <Button
                                        className="btn btn-lg mx-3 btn-border-light"
                                        variant="light"
                                        onClick={() => {
                                            // console.log("modal closed ");
                                            close();
                                        }}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        className="btn btn-md"
                                        onClick={() => {
                                            UpdateCustomerAlertThreshold(unbilledUsageDataId, editedValue);
                                            close();
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                )}
            </Popup>
            {/* <h4 className='px-3 py-3'>{totalRows} Customers Found</h4> */}

            <DataTable
                columns={d}
                data={table}
                theme="solarized"
                customStyles={customStyles}
                // striped={true}
                noDataComponent={<div className="loading-unbilled-usage"><p>{showTableLoading}</p></div>}
                width="100%"
                persistTableHead={false}
                fixedHeaderScrollHeight="60vh"
                // progressPending={loading}
                fixedHeader
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={pageSize}
                paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                onChangePage={(pageNo) => handlePageChange(pageNo, totalRows)}
                onChangeRowsPerPage={handlePerRowsChange}
            />
        </div>
    );
};

export default ExpandableTable;