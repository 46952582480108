import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Nav, NavDropdown, Row, Tab } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './summary.module.scss'
import { DASHBOARD_VENDOR_REVENUE_DOWNLOAD_REPORT, GET_CUSTOMERS_LIST, GET_SERVICES_ALL, GET_STATUS_LIST, DASHBOARD_VENDOR_REVENUE, CLOUD_SUMMARY_LISTING_TABLE } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import { useLocation } from 'react-router-dom';
import { createTheme } from 'react-data-table-component';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import SummaryFilterBox from './LocalComponents/FilterBox';
import Toast from '../../../../../../../GlobalComponents/Toast';
import { BarChart } from '@mui/x-charts';
import LazyTable from './LocalComponents/LazyTable';

const Summary = ({ Logout, setSummaryPopup, summaryPopup, tabName, setTabName, filterType, setFilterType, invoiceDateFrom, setInvoiceDateFrom, invoiceDateTo, setInvoiceDateTo }) => {

    const date = new Date();                                                    // for js date time function
    const location = useLocation();
    const [barChartData, setBarChartData] = useState(null)
    const [barChartDataSeries, setBarChartDataSeries] = useState([])            // for x-axisdata in api

    const searchParams = new URLSearchParams(location.search);
    const [searchIconFlag, setSearchIconFlag] = useState(false);                // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(searchParams.get('searchValueTable') || null);         // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                         // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                   // showing filter box
    const searchInputRef = useRef();
    const [status, setStatus] = useState(null);                                  // getTableData() Status 
    const [pageNo, setPageNo] = useState(1);                                    // table active page number
    const [pageSize, setPageSize] = useState(15);                                // table active page size
    const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    let ColumnName = ["Summary No.", "Cloud Service",                              // table headers used to make object of react data table component headers
        "Summary Date", "Invoice Amount",
        "Credit Note Amount", "Balance Amount"];
    const [initialLoading, setInitialLoading] = useState(true);                 // loader for table

    // Get Table Data Function Parameters
    const [serviceId, setServiceId] = useState(null);                                   // getTableData() service id
    const [sortCol, setSortCol] = useState("summaryNumber");                            // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("DESC");                                     // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending
    const [customerId, setCustomerId] = useState(searchParams.get('customerId') || null);                                 // getTableData() customer id

    const [isDescInvoiceDate, setIsDescInvoiceDate] = useState(true);                   // sort state check for invoice date column
    const [serviceData, setServiceData] = useState(null);                             // data in service dropdown
    const [statusData, setStatusData] = useState(null);                               // data in status dropdown
    const [customerData, setCustomerData] = useState(null);                               // data in status dropdown

    const [serverErrorCloudInvoice, setServerErrorCloudInvoice] = useState(false);      // server error check state in table during api call to load data
    const [emptyCloudInvoice, setEmptyCloudInvoice] = useState(false);                  // empty table check state in table during api call to load data
    const [emptyCloudInvoiceFilters, setEmptyCloudInvoiceFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCloudInvoiceSearch, setEmptyCloudInvoiceSearch] = useState(false);      // empty search response check state in table during api call to load data

    // filters Usestate
    const [invoiceServiceLastUsedDate, setInvoiceServiceLastUsedDate]
        = useState("default");                                                        // service dropdown saved data after applying filters
    const [invoiceDateLastUsedDate, setInvoiceDateLastUsedDate]
        = useState("default");                                                        // invoice date dropdown saved data after applying filters

    // if custom was selected in due date and invoice date in filter box
    const [invoiceDateCustomFromLast, setInvoiceDateCustomFromLast]
        = useState(date.toISOString().slice(0, 10));                                     // invoice from date saved data after applying filter having custom selection
    const [invoiceDateCustomToLast, setInvoiceDateCustomToLast]
        = useState(date.toISOString().slice(0, 10));                                     // invoice to date saved data after applying filter having custom selection

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                        // usestate to scroll to top when applied filter or search when already scrolled
    const [infinityLoading, setInfinityLoading] = useState(false);                      // loading state while table loads for lazy loading
    const [tableFilterApplied, setTableFilterApplied] = useState(false);                // table filter is apllied or not

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    async function GetAllServices() {
        await api.get(`${GET_SERVICES_ALL}`, config)
            .then(resp => {
                setServiceData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401 || error.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(GET_STATUS_LIST, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401 || error.response.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(GET_CUSTOMERS_LIST, config)
            .then(resp => {
                setCustomerData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401 || error.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };
    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?duration=${filterType}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401 || error.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Get Table Data with Filters, Pagination, Search and Sort Functionality
    async function GetTableData(page, newPerPage, search, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, customerId, status, isFilter, appendData) {
        setInitialLoading(false);
        (search === undefined || search === null || search === "null") ? search = "" : search = search;
        (serviceId === undefined || serviceId === null || serviceId === "null") ? serviceId = "" : serviceId = serviceId;
        (invoiceDateFrom === undefined || invoiceDateFrom === "undefined/undefined/" || invoiceDateFrom === null || invoiceDateFrom === "null") ? invoiceDateFrom = "" : invoiceDateFrom = invoiceDateFrom;
        (invoiceDateTo === undefined || invoiceDateTo === "undefined/undefined/" || invoiceDateTo === null || invoiceDateTo === "null") ? invoiceDateTo = "" : invoiceDateTo = invoiceDateTo;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;
        console.log("vidhii", isFilter)
        await api.get(`${CLOUD_SUMMARY_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&serviceId=${serviceId}&fromDate=${invoiceDateFrom}&toDate=${invoiceDateTo}&sortCol=${sortCol}&sortDir=${sortDir}`, config)
            .then(resp => {
                setInitialLoading(true);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    if (search === "" && serviceId === "") {
                        setEmptyCloudInvoice(true);
                    }
                    else if (serviceId != "" && isFilter) {
                        setEmptyCloudInvoiceFilters(true);
                    }
                    else if (search != "" && !isFilter) {
                        setEmptyCloudInvoiceSearch(true);
                    }
                }
                // handle success
                else {
                    setServerErrorCloudInvoice(false);
                    setEmptyCloudInvoice(false);
                    setEmptyCloudInvoiceFilters(false);
                    setEmptyCloudInvoiceSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Summary No.": td.summaryNumber,
                                    "Cloud Service": `${td.serviceName}`,
                                    "Summary Date": td.summaryDate,
                                    "Invoice Amount": td.invoiceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.invoiceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.invoiceAmount)).toFixed(2))}`,
                                    "Credit Note Amount": td.creditNoteAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.creditNoteAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.creditNoteAmount)).toFixed(2))}`,
                                    "Balance Amount": td.balanceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.balanceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.balanceAmount)).toFixed(2))}`,
                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Invoice Amount" || td === "Balance Amount" || td === "Credit Note Amount") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                            })
                        }
                        else if (td === "Summary No.") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescInvoiceDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceDate(true); setSortCol("summaryNumber"); }}></span></>}
                                    {isDescInvoiceDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescInvoiceDate(false); setSortCol("summaryNumber"); }}></span></>}
                                    {isDescInvoiceDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceDate(true); setSortCol("summaryNumber"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Cloud Service") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401 || error.response.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorCloudInvoice(true);
                }

            });
    };

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                // textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, customerId, status);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, customerId, status);
    };

    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
        let date = new Date();
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (eventKey === "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            setInvoiceDateFrom("01/01/" + date.getFullYear());
            setInvoiceDateTo(fromDate);
        }
        else {
            setInvoiceDateFrom(`01/01/${date.getFullYear() - 1}`);
            setInvoiceDateTo(`31/12/${date.getFullYear() - 1}`);
        }
    };

    // Function to call vendor revenue contribution data
    async function GetAllVendorRevenueContribution() {
        await api.get(`${DASHBOARD_VENDOR_REVENUE}?duration=${filterType}`, config)
            .then(resp => {
                if (resp.data.graphData.length > 5) {
                    let localData = [];
                    let addArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    let i = 1;
                    resp.data.graphData.map((data) => {
                        if (i <= 5) {
                            localData.push(data);
                        }
                        else {
                            addArray = addArray.map((dt, idx) => {
                                return dt + data.data[idx];
                            })
                        }
                        i++;
                    })
                    localData.push(
                        { data: addArray, stack: 'A', label: 'Others' }
                    )
                    // console.log("Local Data vendor:", localData, addArray, i);
                    setBarChartData(localData);
                }
                else {
                    setBarChartData(resp.data.graphData);
                }

                setBarChartDataSeries(resp.data.xAxisData);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401 || error.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    //useEffect for calling get api on initial load
    useEffect(() => {
        GetAllVendorRevenueContribution();
        setPageNo(1);
        setScrollToDivTop(true);
        if (tableFilterApplied)
            GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, customerId, status, true);
        else
            GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, customerId, status, false);
    }, [filterType])

    //on tab change move make scroll to top true
    useEffect(() => {
        setScrollToDivTop(true);
        setPageNo(1);
    }, [tabName])

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, customerId, status);
    }, [isDescInvoiceDate])

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, customerId, status, false, true))();
            return newPageNumber;
        });
    }

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setTableFilterApplied(false);
                    GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, customerId, status);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, sortCol, sortDir, customerId, status);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // Use Effect to get all the services in filter service dropdown
    useEffect(() => {
        GetAllServices();
        GetAllStatuses();
        GetAllCustomers();
    }, []);

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xxl"
                open={summaryPopup}
                onClose={() => { setSummaryPopup(false); setServerErrorCloudInvoice(false); setEmptyCloudInvoice(false); setEmptyCloudInvoiceFilters(false); setEmptyCloudInvoiceSearch(false); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Vendor Revenue Contribution</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <>
                            <div className='my-4 height-dashboard-charts'>
                                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

                                <Container fluid>
                                    <Tab.Container id="main-tab-bar" defaultActiveKey="Summary">
                                        <Tab.Container activeKey={tabName}>
                                            <Row>
                                                <Col md={9}>
                                                    <Nav variant="pills" defaultActiveKey={null}>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Summary" className='dashboard-tabs  pl-6 pr-6 pt-3 pb-3'
                                                                onClick={() => { setTabName("Summary") }}
                                                            >
                                                                Summary</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Details" className={'dashboard-tabs pl-6 pr-6 pt-3 pb-3 d-none-mobile'}
                                                                onClick={() => { setTabName("Details") }}
                                                            >
                                                                Details</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col md={3}> <div className='text-right'>
                                                    <Button
                                                        variant="light"
                                                        className="mr-1 px-4 btn-border-light w-md-auto col-xs-12"
                                                        onClick={(e) => { DownloadAction(DASHBOARD_VENDOR_REVENUE_DOWNLOAD_REPORT, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_vendor-revenue-contribution.csv`, "csv") }}
                                                    >
                                                        Download Report
                                                    </Button>
                                                </div></Col>

                                            </Row>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="Summary">
                                                    <div>
                                                        <div>
                                                            <Row>
                                                                <Col md={10}></Col>
                                                                <Col md={2}>
                                                                    <div className='service-management-csp-header mt-3 ml-5'>
                                                                        <Form.Group >
                                                                            <NavDropdown title={filterType} id="cloud-service-dropdown" className={`servcies-dropdown dropdown-specialclass-fortwo ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setFilterType)}>
                                                                                <NavDropdown.Item
                                                                                    eventKey="Last Year"
                                                                                    className={filterType === "Last Year" ? "selected" : ""}
                                                                                >Last Year</NavDropdown.Item>
                                                                                <NavDropdown.Item
                                                                                    eventKey="This Year"
                                                                                    className={filterType === "This Year" ? "selected" : ""}
                                                                                >This Year</NavDropdown.Item> </NavDropdown>

                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className={`my-4 ${styles['bar-chart-height']}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                            {barChartData && <BarChart
                                                                series={barChartData}
                                                                grid={{ horizontal: true, vertical: true }}
                                                                sx={{
                                                                    [`.MuiChartsLegend-series *`]: {
                                                                        fontSize: `0.75rem !important`
                                                                    }
                                                                    ,
                                                                    [`.MuiChartsLegend-mark`]: {
                                                                        height: '12px',
                                                                        width: '12px',
                                                                        borderRadius: '50%',
                                                                    },

                                                                }}
                                                                slotProps={{
                                                                    legend: {
                                                                        direction: 'row',
                                                                        position: { vertical: 'bottom', horizontal: 'middle' },
                                                                        padding: 0,
                                                                        labelStyle: {
                                                                            fill: '#6a6a6a'
                                                                        }
                                                                    },
                                                                    popper: {
                                                                        sx: {
                                                                            zIndex: 99999,
                                                                        },
                                                                    },
                                                                }}
                                                                colors={['#3599DA', '#F9A932', "#77BA1D", "#E11F13", "#5A63E7", "#FF53BA"]}
                                                                xAxis={[{
                                                                    data: barChartDataSeries,
                                                                    scaleType: 'band',
                                                                    tickPlacement: 'middle',
                                                                    categoryGapRatio: 0.6,
                                                                }]}
                                                               
                                                                yAxis={[
                                                                    {
                                                                    tickNumber: 5,
                                                                    valueFormatter: (value) => value > 1000 ? `$${(value / 1000).toLocaleString()}K` : `$${value}`,
                                                                    }
                                                                     
                                                                  ]}
                                                               
                                                            margin={{ top: 10, bottom: 100, left: 50, right: 50 }}
                                                            />}
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Details">
                                                    <div className='my-4 position-relative'>
                                                        <Row >
                                                            <Col md={3}>
                                                                <h2 className='mx-7'>Summaries {!emptyCloudInvoice && !emptyCloudInvoiceFilters && !emptyCloudInvoiceSearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h2>
                                                            </Col>
                                                            {/* desktop and tab search and filter */}
                                                            <Col className="px-1 d-none d-md-block mb-2" md={7}>
                                                                <Row className={`justify-content-end align-items-center mobile-padding-search-open-sub  ${styles['search-tablet-view']}`}>
                                                                    {!serverErrorCloudInvoice && !emptyCloudInvoice && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                                        {!searchIconFlag &&
                                                                            <span className="mx-3">&nbsp;</span>
                                                                        }
                                                                        {!searchIconFlag && !emptyCloudInvoiceFilters &&
                                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                                        }
                                                                        {searchIconFlag && !emptyCloudInvoiceFilters &&
                                                                            <InputGroup className="search-input-box search-input-box-xl">
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                                    <span className="searchgrey cpointer"
                                                                                    >
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                                <Form.Control
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    aria-describedby="basic-addon2"
                                                                                    className="search-input-box-input"
                                                                                    value={searchValueTable}
                                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                                    ref={searchInputRef}
                                                                                />
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                                    onClick={() => {
                                                                                        setSearchValueTable("");
                                                                                        setSearchIconFlag(!searchIconFlag);
                                                                                    }}>
                                                                                    <span className="closebtn">
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        }

                                                                        {!filterFlag && !emptyCloudInvoiceSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                            setShowFilterBox(!showFilterBox);
                                                                        }}></span>}
                                                                        {initialLoading && filterFlag && !emptyCloudInvoiceSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                                    </Col>}
                                                                    {
                                                                        !emptyCloudInvoiceSearch && showFilterBox &&
                                                                        <SummaryFilterBox
                                                                            serviceId={serviceId} setServiceId={setServiceId} GetTableData={GetTableData} pageNo={pageNo}
                                                                            pageSize={pageSize} searchValueTable={searchValueTable} invoiceDateFrom={invoiceDateFrom} invoiceDateTo={invoiceDateTo}
                                                                            setInvoiceDateFrom={setInvoiceDateFrom} setInvoiceDateTo={setInvoiceDateTo} sortCol={sortCol} sortDir={sortDir}
                                                                            setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} invoiceServiceLastUsedDate={invoiceServiceLastUsedDate}
                                                                            setInvoiceServiceLastUsedDate={setInvoiceServiceLastUsedDate} invoiceDateLastUsedDate={invoiceDateLastUsedDate} status={status} setStatus={setStatus}
                                                                            setInvoiceDateLastUsedDate={setInvoiceDateLastUsedDate}
                                                                            invoiceDateCustomFromLast={invoiceDateCustomFromLast} setInvoiceDateCustomFromLast={setInvoiceDateCustomFromLast} invoiceDateCustomToLast={invoiceDateCustomToLast}
                                                                            setInvoiceDateCustomToLast={setInvoiceDateCustomToLast}
                                                                            customerId={customerId} setCustomerId={setCustomerId} setScrollToDivTop={setScrollToDivTop}
                                                                            serviceData={serviceData} statusData={statusData} setPageNo={setPageNo} customerData={customerData} setCustomerData={setCustomerData} setTableFilterApplied={setTableFilterApplied}
                                                                        />
                                                                    }
                                                                </Row>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Form.Group>
                                                                    <NavDropdown title={filterType} id="cloud-service-dropdown-2" className={`servcies-dropdown dropdown-specialclass-fortwo`} onSelect={createHandleSelect(setFilterType)}>
                                                                        <NavDropdown.Item
                                                                            eventKey="Last Year"
                                                                            className={filterType === "Last Year" ? "selected" : ""}
                                                                        >Last Year</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="This Year"
                                                                            className={filterType === "This Year" ? "selected" : ""}
                                                                        >This Year</NavDropdown.Item> </NavDropdown>
                                                                </Form.Group></Col>
                                                        </Row>
                                                        <div className={`mb-1 p-0 ${styles['user-management-table-inner']} ${styles['table-details']}`}>
                                                            <Row>
                                                                <Col>
                                                                    <div className={`table-responsive ${styles['customer-table']}`}>
                                                                        {initialLoading && serverErrorCloudInvoice &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCloudInvoiceSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Summaries found</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCloudInvoiceFilters && !emptyCloudInvoiceSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Summaries found</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCloudInvoice &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={RaiseTicketIllustration} className="empty-cloud-invoice-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Summaries found</h2>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !serverErrorCloudInvoice && !emptyCloudInvoiceFilters && !emptyCloudInvoice && !emptyCloudInvoiceSearch &&
                                                                            <LazyTable
                                                                                d={columns}
                                                                                table={table}
                                                                                customStyles={customStyles}
                                                                                loading={infinityLoading}
                                                                                pageNo={pageNo}
                                                                                totalRows={totalRows}
                                                                                handlePerRowsChange={handlePerRowsChange}
                                                                                handlePageChange={handlePageChange}
                                                                                styles={styles}
                                                                                handleLazyCalls={handleLazyCalls}
                                                                                serverErrorUser={serverErrorCloudInvoice}
                                                                                emptyUser={emptyCloudInvoice}
                                                                                emptyUserFilters={emptyCloudInvoiceFilters}
                                                                                emptyUserSearch={emptyCloudInvoiceSearch}
                                                                                setScrollToDivTop={setScrollToDivTop}
                                                                                scrollToDivTop={scrollToDivTop}
                                                                            />}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Row>
                                                            <Col md={10}>
                                                                <p className='mx-2 notes-confirmation'><a href={localStorage.getItem("b2b_navigation") + "Invoice-Payment/"} target='_blank'>Click here</a> to pay invoices.</p>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div className="btn-wrapper text-right">
                                                                    <Button
                                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                        variant="light"
                                                                        onClick={() => {
                                                                            close();
                                                                        }}
                                                                    >
                                                                        Close
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Tab.Container>

                                </Container>
                            </div>

                        </>
                    </div>)}
            </Popup>
        </div>
    )
}

export default Summary
