import React, { useState, useEffect, useRef } from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap';
import styles from './auditReport.module.scss'
import { createTheme } from 'react-data-table-component';
import { api } from '../../../../../../../Utils/API';
import Form from 'react-bootstrap/Form';
import 'reactjs-popup/dist/index.css';
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import RaiseTicketIllustration from '../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import { AUDIT_STATUS_TYPES, AUDIT_REPORTING_LISTING_TABLE, DOWNLOAD_AUDIT_REPORT, GET_CUSTOMERS_LIST, AUDIT_ACTION_TYPES, GET_AUDIT_DETAILS, AUDIT_REPORT_PLAN_SELECT_LIST } from '../../../../../../../Utils/GlobalConstants';
import AuditFilterBox from './LocalComponents/FilterBox';
import "reactjs-popup/dist/index.css";
import { XLg } from "react-bootstrap-icons";
import Toast from '../../../../../../../GlobalComponents/Toast';
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif"
import Popup from 'reactjs-popup';
import LazyTable from './LocalComponents/LazyTable';

const AuditReportManage = (props) => {
  const date = new Date();                                                    // for js date time function

  const [searchIconFlag, setSearchIconFlag] = useState(false);                // to open and close search box
  const [searchValueTable, setSearchValueTable] = useState(null);             // storing searching value in the search input box
  const [filterFlag, setFilterFlag] = useState(false);                        // to show active and deactive filter states 
  const [showFilterBox, setShowFilterBox] = useState(false);                  // showing filter box
  const searchInputRef = useRef();                                            // Search Input Box

  // Table Parameters
  const [pageNo, setPageNo] = useState(1);                                    // table active page number
  const [pageSize, setPageSize] = useState(15);                                // table active page size
  const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
  const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
  const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
  let auditReportColumnName = ["Date", "Customer", "Plan", "Addition Name", "Agreement", "Action type", "Status", "Info"]; // table headers used to make object of react data table component headers

  const [initialLoading, setInitialLoading] = useState(true);                 // loader for table
  const [status, setStatus] = useState([]);                                 // getTableData() status
  const [customerCode, setCustomerCode] = useState("");                       // getTableData() customer code 

  const [customerName, setCustomerName] = useState("");                       // Customer Name
  const [agreementId, setAgreementId] = useState("");                         // Aggrement Id 
  const [actionTypeId, setActionTypeId] = useState("");                       // Action Type Id

  const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
  const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data
  const [emptyCustomerFilters, setEmptyCustomerFilters] = useState(false);    // empty filter response check state in table during api call to load data
  const [emptyAuditReportSearch, setEmptyAuditReportSearch] = useState(false);      // empty search response check state in table during api call to load data

  // Get Table Data Function Parameters
  const [dateFrom, setDateFrom] = useState(null);                             // getTableData() from date
  const [dateTo, setDateTo] = useState(null);                                 // getTableData()  to date
  const [sortCol, setSortCol] = useState("");                     // getTableData() sorting column name
  const [sortDir, setSortDir] = useState("ASC");                              // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending

  //lazy loading
  const [scrollToDivTop, setScrollToDivTop] = useState(false);
  const [infinityLoading, setInfinityLoading] = useState(false);                     // loading state while table loads for lazy loading

  // Filter Props
  const [customerData, setCustomerData] = useState(null);                             // data in customer dropdown in filter box
  const [customerId, setCustomerId] = useState(null);                                 // getTableData() customer id
  const [planId, setPlanId] = useState("");                                   // getTableData() service id
  const [auditDateLastUsedDate, setAuditDateLastUsedDate] = useState("default");        // Plan Data
  const [auditDateFrom, setAuditDateFrom] = useState(null);                       // getTableData() audit from date
  const [auditDateTo, setAuditDateTo] = useState(null);                           // getTableData() audit to date
  const [statusData, setStatusData] = useState(null);                               // data in status dropdown
  const [planData, setPlanData] = useState(null);                                 // Plan Data
  const [actionTypeData, setActionTypeData] = useState(null);
  const [auditDateCustomFromLast, setAuditDateCustomFromLast] = useState(date.toISOString().slice(0, 10));  // audit from date saved data after applying filter having custom selection
  const [auditDateCustomToLast, setAuditDateCustomToLast] = useState(date.toISOString().slice(0, 10));  // audit to date saved data after applying filter having custom selection

  // Use State for Toasts
  const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
  const [toastError, setToastError] = useState(false);                        // toast message displaying error message
  const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

  const [isAuditAdditionalDetailsPopupOpen, setisAuditAdditionalDetailsPopupOpen] = useState(false);                        // Subscription Details Popup Box
  const [auditDetailIsLoadingFlag, setAuditDetailIsLoadingFlag] = useState(false)                       // Subscription is Loading Popup
  const [auditDetails, setAuditDetails] = useState(null);
  const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);               // loading state for processing button

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Header Authorization for Download CSV API having response type as blob
  const configBlob = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
    responseType: 'blob',
  };

  // Download File using Js blobs
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    if (fileType === "application/csv") {
      a.href = window.URL.createObjectURL(data)
    }
    else {
      a.href = window.URL.createObjectURL(blob)
    }
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  // Download CSV File 
  async function DownloadAction(id, url, name, fileType) {
    setConfirmLoadingFlag(true);
    let configuration;
    if (fileType === "csv") {
      configuration = configBlob;
    }
    else {
      configuration = config;
    }

    let auditActionTypeId = (actionTypeId === undefined || actionTypeId === null) ? "" : actionTypeId;
    let auditPlanId = (planId === undefined || planId === null) ? "" : planId;
    let auditDateStartFrom = (auditDateFrom === undefined || auditDateFrom === null) ? "" : auditDateFrom;
    let auditDateEndTo = (auditDateTo === undefined || auditDateTo === null) ? "" : auditDateTo;
    let auditAgreementId = (agreementId === undefined || agreementId === null) ? "" : agreementId;
    let auditStatus = (status === undefined || status === null) ? "" : status;
    let auditSortCol = (sortCol === undefined || sortCol === null) ? "" : sortCol;
    let auditCustomerId = (customerId === undefined || customerId === null) ? "" : customerId;
    let auditSearchValueTable = (searchValueTable === undefined || searchValueTable === null) ? "" : searchValueTable;
    let auditSortDir = (sortDir === undefined || sortDir === null) ? "" : sortDir;

    await api.get(`${url}?customerName=${auditCustomerId}&searchTerm=${auditSearchValueTable}&agreementId=${auditAgreementId}&actionTypeId=${auditActionTypeId}&status=${auditStatus}&startDate=${auditDateStartFrom}&endDate=${auditDateEndTo}&planId=${auditPlanId}&pageNo=${pageNo}&pageSize=${pageSize}&sortCol=${auditSortCol}&sortDir=${auditSortDir}` + id, configuration)
      .then(resp => {
        // handle success
        if (resp.status === 204) {
          setConfirmLoadingFlag(false);
          setToastMessage("No Data available to Download !");
          setToastError(true);
          setTimeout(() => {
            setToastError(false);
          }, 2000);
        }
        else {
          setConfirmLoadingFlag(false);
          downloadFile({
            data: (resp.data),
            fileName: name,
            fileType: `application/${fileType}`,
          });
        }
      })
      .catch(error => {
        setConfirmLoadingFlag(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };


  // Function to call all dropdown values in Status Section in Filter Box
  async function GetAllStatuses() {
    await api.get(AUDIT_STATUS_TYPES, config)
      .then(resp => {
        setStatusData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }

      });
  };


  // Function to call all dropdown values in Plan Section in Filter Box
  async function GetAllPlans() {
    await api.get(AUDIT_REPORT_PLAN_SELECT_LIST, config)
      .then(resp => {
        setPlanData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }

      });
  };

  // Function to call all dropdown values in Action Types Section in Filter Box
  async function GetAllActionTypes() {
    await api.get(AUDIT_ACTION_TYPES, config)
      .then(resp => {
        setActionTypeData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }

      });
  };

  // Get Table Data with Filters, Pagination, Search and Sort Functionality
  async function GetTableData(customerId, page, newPerPage, search, dateFrom, dateTo, sortCol, sortDir, status, planId, actionTypeId, appendData, isFilter) {
    setInitialLoading(true);
    (search === undefined || search === null) ? search = "" : search = search;
    (dateFrom === undefined || dateFrom === null) ? dateFrom = "" : dateFrom = dateFrom;
    (dateTo === undefined || dateTo === null) ? dateTo = "" : dateTo = dateTo;
    (status === undefined || status === null) ? status = "" : status = status;
    (planId === undefined || planId === null) ? planId = "" : planId = planId;
    (sortCol === undefined || sortCol === null) ? sortCol = "" : sortCol = sortCol;
    (customerId === undefined || customerId === null) ? customerId = "" : customerId = customerId;
    (actionTypeId === undefined || actionTypeId === null) ? actionTypeId = "" : actionTypeId = actionTypeId;
    isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

    await api.get(`${AUDIT_REPORTING_LISTING_TABLE}?customerName=${customerId}&searchTerm=${search}&agreementId=${agreementId}&actionTypeId=${actionTypeId}&status=${status}&startDate=${dateFrom}&endDate=${dateTo}&planId=${planId}&pageNo=${page}&pageSize=${newPerPage}`, config)
      .then(resp => {

        setInitialLoading(false);
        setInfinityLoading(false);

        if (resp.status == 204) {
          if (search == "" && dateFrom == "" && dateTo == "" && status == "" && customerId == "" && planId == "" && actionTypeId == "") {
            setEmptyCustomer(true);
          }
          else if ((dateFrom != "" || dateTo != "" || status != "" || customerId != "" || planId != "" || actionTypeId != "") && isFilter) {
            setEmptyCustomerFilters(true);
          }
          else if (search != ""  && !isFilter) {
            setEmptyAuditReportSearch(true);
          }
        }
        // handle success
        else {
          setServerErrorCustomer(false);
          setEmptyCustomer(false);
          setEmptyCustomerFilters(false);
          setEmptyAuditReportSearch(false);
          let f = [];
          setTotalRows(resp.data.totalCount);
          (resp.data.content).map((td) => {
            f.push
              (
                {
                  "Date": td.created,
                  "Customer": td.customerName,
                  "Plan": td.planName,
                  "Addition Name": td.additionName,
                  "Agreement": td.agreementName,
                  "Action type": td.actionTypeName,
                  "Status": td.status === "Success" ? (
                    <div className={`${styles['status-customers']}`}>
                      <span className="status-active"></span>
                      <div className="py-1 text-muted text-small">Success</div>
                    </div>
                  ) :
                    <div className={`${styles['status-customers']}`}>
                      <span className="status-failed"></span>
                      <div className="py-1 text-muted text-small">Failed</div>
                    </div>,
                  "Info": <span
                    className="infoIcon"
                    onClick={() => {
                      setisAuditAdditionalDetailsPopupOpen(true);
                      GetSubscriptionDetailsPopup(td.auditReportId);
                    }}
                  >
                    &nbsp;
                  </span>,
                }
              );
          })
          setTimeout(() => {
            if (appendData) {
              setTable(table => [...table, ...f])            // Setting Table Data
            } else {
              setTable(f)
            }
          }, 50);
          let d = [];
          auditReportColumnName.map((td) => {
            if (td === "Info") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
              })
            }
            else if (td === "Status") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="">{row[`${td}`]}</div>,
                center: true,
                grow: 0.7,
              })
            }
            else if (td === "Agreement") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 1.5
              })
            }
            else {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 1
              })
            }

          })
          setColumns(d);             // Setting Column Data
        }
      })
      .catch(error => {
        setInitialLoading(false);
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
          setServerErrorCustomer(true);
        }

      });
  };


  // Function to handle the lazy loading calls invoked from the Lazy Table
  const handleLazyCalls = async () => {
    setInfinityLoading(true);
    setPageNo((prev) => {
      const newPageNumber = prev + 1;
      (async () => await GetTableData(customerId, newPageNumber, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status, planId, actionTypeId, true))();

      return newPageNumber;
    });
  }

  // On search field value we trigger this function having react debugging after every 800 ms
  useEffect(() => {
    if (searchValueTable !== undefined && searchValueTable !== null) {
      const getData = setTimeout(() => {
        if (searchValueTable.length > 0) {
          setScrollToDivTop(true);
          setPageNo(1);
          GetTableData(customerId, 1, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status, planId, actionTypeId);
        } else {
          setScrollToDivTop(true);
          setPageNo(1);
          GetTableData(customerId, 1, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status, planId, actionTypeId);
        }
      }, 800);
      return () => clearTimeout(getData);
    }
  }, [searchValueTable]);

  // Function to call all dropdown values in Customer Section in Filter Box
  async function GetAllCustomers() {
    await api.get(GET_CUSTOMERS_LIST, config)
      .then(resp => {
        setCustomerData(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };


  // Function to add data in View Audit Report Details Popup
  async function GetSubscriptionDetailsPopup(auditId) {
    setAuditDetailIsLoadingFlag(true);
    await api
      .get(GET_AUDIT_DETAILS + auditId, config)
      .then((resp) => {
        setAuditDetails(resp.data);
        setAuditDetailIsLoadingFlag(false);
      })
      .catch((error) => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
          setAuditDetailIsLoadingFlag(false);
        }
      });
  }

  // React Data Table Custom Theme
  createTheme('solarized', {
    text: {
      primary: '#000000',
    },
    background: {
      default: '#FFFFFF',
    },
    divider: {
      default: '#d7dadf',
    },
    striped: {
      default: '#f5f5f5',
    },
  });

  // React Data Table Custom Styles
  const customStyles = {
    tableWrapper: {
      style: {
        minHeight: '300px'
      },
    },
    rows: {
      style: {
        minHeight: '60px',  // override the row height
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        // textAlign: 'center',
        fontSize: '0.9rem',
        fontWeight: '400',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '10px', // override the cell padding for head cells
        paddingRight: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#fafafa',
        fontWeight: '500',
        fontSize: '0.875rem',
        color: '#6A6A6A !important',
        // textTransform: 'capitalize',
        // textAlign: 'center',
      },
    },
  };

  // Function Triggers when Page Number is Changed by Customer
  const handlePageChange = (pageNo) => {
    GetTableData(customerId, pageNo, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status, planId, actionTypeId);
    setPageNo(pageNo);
  };

  // Function Triggers when Rows Per Page is Changed by Customer
  const handlePerRowsChange = async (newPerPage, page) => {
    setPageNo(page);
    setPageSize(newPerPage);
    GetTableData(customerId, page, newPerPage, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status, planId, actionTypeId);
  };

  // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
  useEffect(() => {
    GetTableData(customerName, pageNo, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status, planId, actionTypeId);
  }, []);


  // Search Box Focus on Open
  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();
  }, [searchIconFlag]);

  useEffect(() => {
    GetAllCustomers();
    GetAllStatuses();
    GetAllActionTypes();
    GetAllPlans();
  }, [])

  useEffect(() => {
    if (props.bulkMappingSuccessfull) {
      GetTableData(customerName, pageNo, pageSize, searchValueTable, dateFrom, dateTo, sortCol, sortDir, status, planId, actionTypeId);
      props.setBulkMappingSuccessfull(false);
    }
  }, [props])

  return (
    <>
      <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
      <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
      {/* Subscription Details Popup */}
      <Popup
        className="custom-modal"
        open={isAuditAdditionalDetailsPopupOpen}
        onClose={() => setisAuditAdditionalDetailsPopupOpen(false)}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            {!auditDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2>Audit Details</h2>
                  <XLg
                    size={18}
                    className="cpointer text-strong"
                    onClick={close}
                    color="#6A6A6A"
                  />
                </div>
                {auditDetails && <Container>
                  <Row>
                    <Col className="mb-3">
                      <label className="text-medium text-strong">
                        <strong> Plan:</strong>
                      </label>
                      <span>
                        <strong>
                          &nbsp;{auditDetails.planName}
                        </strong>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <label className="text-medium">
                        Service
                      </label>
                      <p className="mb-0 text-medium">
                        <strong>
                          {auditDetails.serviceName}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <label className="text-medium">
                        Subscription Id
                      </label>
                      <p className="mb-0 text-medium">
                        <strong>
                          {auditDetails.subscriptionId}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <label className="text-medium">
                        Connectwise Company Name
                      </label>
                      <p className="mb-0 text-medium">
                        <strong>
                          {auditDetails.customerCompanyName}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <label className="text-medium">
                        Additional Description
                      </label>
                      <p className="mb-0 text-medium">
                        <strong>
                          {auditDetails.additionName}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <label className="text-medium">
                        Message
                      </label>
                      <p className="mb-0 text-medium">
                        <strong>
                          {auditDetails.action}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                </Container>}
              </>
            }
            {
              auditDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2 className="mb-0">Audit Details</h2>
                  <XLg
                    size={18}
                    className="cpointer text-strong"
                    onClick={close}
                    color="#6A6A6A"
                  />
                </div>
                <Container>
                  <div className="empty-subscription-detail">
                    <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Audit Report Details ...</p>
                  </div>
                </Container>
              </>
            }
          </div>
        )}
      </Popup>

      <div className="main-content">
        <>
          <div className="customer-management-console-main-div mb-5">
            <div className="mgmt-console-table-row">
              <div className="mb-3">
                <Col className={`py-0 ${styles['customers-table']}`}>
                  <div className={`${styles['customers-table-inner']} ${styles['table-details']} details-box`}>
                    <div className="my-0 table-heading align-items-center row">
                      <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                        <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header">Audit Report{!emptyAuditReportSearch && !emptyCustomer && !emptyCustomerFilters && !initialLoading && <span className='px-2 text-muted text-large'>({totalRows})</span>}</h5>
                      </Col>
                      <Col className="my-1 d-md-none d-block text-right mr-1">
                        {/* Download csv file button */}
                        {!confirmLoadingFlag ? <Button onClick={(e) => { DownloadAction(customerCode, DOWNLOAD_AUDIT_REPORT, `Audit report_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}.csv`, "csv") }}>Download Report</Button>
                          : <Button
                            className="px-3 ml-1 btn-border-light w-md-auto col"
                            variant="light"
                            disabled
                          > Downloading ...</Button>}
                      </Col>

                      {/* desktop and tab search and filter */}
                      <Col className="px-1 d-none d-md-block" md={9}>
                        <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                          {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                            {!searchIconFlag &&
                              <span className="mx-3">&nbsp;</span>
                            }
                            {!searchIconFlag && !emptyCustomerFilters &&
                              <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                            }
                            {searchIconFlag && !emptyCustomerFilters &&
                              <InputGroup className="search-input-box search-input-box-xl">
                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                  <span className="searchgrey cpointer"
                                  >
                                    &nbsp;
                                  </span>
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Search"
                                  aria-label="Search"
                                  aria-describedby="basic-addon2"
                                  className="search-input-box-input"
                                  value={searchValueTable}
                                  onChange={(e) => setSearchValueTable(e.target.value)}
                                  ref={searchInputRef}
                                />
                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                  onClick={() => {
                                    setSearchValueTable("");
                                    setSearchIconFlag(!searchIconFlag);
                                  }}>
                                  <span className="closebtn">
                                    &nbsp;
                                  </span>
                                </InputGroup.Text>
                              </InputGroup>
                            }

                            {!filterFlag && !emptyAuditReportSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                              setShowFilterBox(!showFilterBox);
                            }}></span>}
                            {filterFlag && !emptyAuditReportSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                            {/* Download csv file button */}
                            {/* <Button className="ml-3 px-4 mr-2" onClick={(e) => { DownloadAction(customerCode, DOWNLOAD_AUDIT_REPORT, `Audit report_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}.csv`, "csv") }}>Download Report</Button> */}
                            {!confirmLoadingFlag ? <Button className="ml-3 px-4 mr-2" onClick={(e) => { DownloadAction(customerCode, DOWNLOAD_AUDIT_REPORT, `Audit report_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}.csv`, "csv") }}>Download Report</Button>
                              : <Button
                                className="px-4 ml-3 mr-2 btn-border-light"
                                variant="light"
                                disabled
                              > Downloading ...</Button>}
                            {/* <Button className="ml-3 px-4 mr-3" onClick={() => navigate('/my-customers/bulk-upload')}>Customer Bulk Upload</Button> */}

                          </Col>}
                        </Row>
                      </Col>

                      {/* mobile search and filter */}
                      <Col className="d-md-none d-block mt-2 px-3" md={12}>
                        <Row className="justify-content-start align-items-center mobile-padding-search-open-sub">
                          {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                            <InputGroup className="search-input-box search-input-box-xl">
                              <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                <span className="searchgrey cpointer"
                                >
                                  &nbsp;
                                </span>
                              </InputGroup.Text>
                              <Form.Control
                                placeholder="Search Organization, Full name, Email"
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                className="search-input-box-input"
                                value={searchValueTable}
                                onChange={(e) => setSearchValueTable(e.target.value)}
                              />
                              <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                onClick={() => {
                                  setSearchValueTable("");
                                  setSearchIconFlag(!searchIconFlag);
                                }}>
                                <span className="closebtn">
                                  &nbsp;
                                </span>
                              </InputGroup.Text>
                            </InputGroup>


                            {!filterFlag && !emptyAuditReportSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                              setShowFilterBox(!showFilterBox);
                            }}></span>}
                            {initialLoading && filterFlag && !emptyAuditReportSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                          </Col>}
                        </Row>
                      </Col>
                    </div>
                    {
                      !emptyAuditReportSearch && showFilterBox &&
                      <AuditFilterBox planId={planId} setPlanId={setPlanId} GetTableData={GetTableData} customerName={customerName} setCustomerName={setCustomerName} pageNo={pageNo}
                        pageSize={pageSize} searchValueTable={searchValueTable} auditDateFrom={auditDateFrom} auditDateTo={auditDateTo}
                        setAuditDateFrom={setAuditDateFrom} setAuditDateTo={setAuditDateTo}
                        sortCol={sortCol} sortDir={sortDir}
                        setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag}
                        auditDateLastUsedDate={auditDateLastUsedDate} status={status} setStatus={setStatus}
                        setAuditDateLastUsedDate={setAuditDateLastUsedDate}
                        auditDateCustomFromLast={auditDateCustomFromLast} setAuditDateCustomFromLast={setAuditDateCustomFromLast} auditDateCustomToLast={auditDateCustomToLast}
                        setAuditDateCustomToLast={setAuditDateCustomToLast}
                        customerId={customerId} setCustomerId={setCustomerId} customerData={customerData} setCustomerData={setCustomerData}
                        customerIsAuthenticated={props.customerIsAuthenticated} setCustomerIsAuthenticated={props.setAuthentication}
                        planData={planData} statusData={statusData} setPageNo={setPageNo}
                        actionTypeData={actionTypeData} setActionTypeData={setActionTypeData}
                        actionTypeId={actionTypeId} setActionTypeId={setActionTypeId} setScrollToDivTop={setScrollToDivTop}
                      />
                    }
                    <Container fluid className="mb-5 p-0">
                      <Row>
                        <Col>
                          <div className={`table-responsive ${styles['customers-history-table']}`}>
                            {!initialLoading && serverErrorCustomer &&
                              <div className={styles["no-data-customers"]}>
                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                              </div>
                            }
                            {!initialLoading && emptyCustomerFilters && !emptyAuditReportSearch &&
                              <div className={styles["no-data-customers"]}>
                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                <h2 className="mt-4 mb-3">No Data Found.</h2>
                              </div>
                            }
                            {!initialLoading && emptyAuditReportSearch &&
                              <div className={styles["no-data-customers"]}>
                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                <h2 className="mt-4 mb-3">No results found.</h2>
                              </div>
                            }

                            {!initialLoading && emptyCustomer &&
                              <div className={styles["no-data-customers"]}>
                                <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                <h2 className="mt-4 mb-3">No Data found.</h2>
                              </div>
                            }
                            {!serverErrorCustomer && !emptyCustomerFilters && !emptyCustomer && !emptyAuditReportSearch &&
                              <LazyTable
                                d={columns}
                                table={table}
                                customStyles={customStyles}
                                loading={infinityLoading}
                                pageNo={pageNo}
                                totalRows={totalRows}
                                handlePerRowsChange={handlePerRowsChange}
                                handlePageChange={handlePageChange}
                                styles={styles}
                                handleLazyCalls={handleLazyCalls}
                                serverErrorUser={serverErrorCustomer}
                                emptyUser={emptyCustomer}
                                emptyUserFilters={emptyCustomerFilters}
                                emptyUserSearch={emptyAuditReportSearch}
                                setScrollToDivTop={setScrollToDivTop}
                                scrollToDivTop={scrollToDivTop}
                              />}

                            {/* {initialLoading == true &&
                              <>
                                <div className={styles["loading-customers"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading...</p></div>
                              </>} */}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  )
}

export default AuditReportManage;
