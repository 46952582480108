import React from 'react'

const SendNotificationsTab = () => {
  return (
    <div>
      <h1 className='mt-5'>Send Notifications</h1>
    </div>
  )
}

export default SendNotificationsTab