import React, { useEffect, useRef, useState } from "react"
import DataTable from "react-data-table-component"
import loadingGif from "../../../../../../../Assets/Images/Animations/loading-management-console.gif";
import "./styles.scss";

const LazyTable = ({
  d,
  table,
  customStyles,
  loading,
  totalRows,
  Expandable,
  handlePerRowsChange,
  customerNameDefaultValue,
  GetAdditionalDetails,
  resellerId,
  handlePageChange,
  styles,
  handleLazyCalls,
  serverErrorUser,
  emptyUser,
  emptyUserFilters,
  emptyUserSearch,
  initialSubscriptionTableCall,
  setScrollToDivTop,
  scrollToDivTop,
}) => {
  const tableRef = useRef(null);
  const [currentRow, setCurrentRow] = useState(null);   // to set current row for expanding

  useEffect(() => {
    if (table && totalRows) {
      const _table = tableRef.current;
      const divInside = _table && _table.querySelector('div');
      let hasCalledLazyCalls = false;

      const handleScroll = () => {
        const buffer = 1;
        if ((divInside.scrollTop + divInside.clientHeight + buffer) > divInside.scrollHeight) {
          if (!hasCalledLazyCalls && totalRows > table.length) {
            hasCalledLazyCalls = true;
            handleLazyCalls();
          }
        }
      };

      divInside && divInside.addEventListener('scroll', handleScroll);

      return () => {
        divInside && divInside.removeEventListener('scroll', handleScroll);
      };
    }
  }, [table, totalRows]);

  useEffect(() => {
    const _table = tableRef.current;
    const divInside = _table && _table.querySelector('div');

    if (scrollToDivTop == true) {
      divInside.scrollTop = 0;
      setScrollToDivTop(false);
    }
  }, [scrollToDivTop])

  return (
    <div className="responsive-table" ref={tableRef}>
      {!serverErrorUser && !emptyUser && !emptyUserFilters && !emptyUserSearch && initialSubscriptionTableCall &&
        <DataTable
          columns={d}
          data={table}
          theme="solarized"
          customStyles={customStyles}
          noDataComponent={<div className={styles["no-data-user"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscriptions...</p></div>}
          width="100%"
          fixedHeaderScrollHeight="60vh"
          fixedHeader
          persistTableHead={false}
          onChangePage={(pageNo) => handlePageChange(pageNo, totalRows)}
          expandableRows
          expandableRowDisabled={(row) => !row["has Add On"]}  // Conditionally render based on isActive
          expandOnRowClicked={false}
          expandableRowExpanded={(row) => (row === currentRow)}
          onRowClicked={(row) => { if (row["has Add On"]) setCurrentRow(row) }}
          onRowExpandToggled={(bool, row) => setCurrentRow(row)}          // to ensure only one row is expanded 
          expandableRowsComponentProps={{ "customerNameDefaultValue": customerNameDefaultValue, "GetAdditionalDetails": GetAdditionalDetails,"resellerId": resellerId }}
          expandableRowsComponent={Expandable}
          onChangeRowsPerPage={handlePerRowsChange}
        />}
      <div className="infinity-loader-wrapper">
        {loading && <div className="text-center"><img className="management-console-loading-icon" src={loadingGif} /></div>}
      </div>
    </div>

  )
};

export default LazyTable;