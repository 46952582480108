import React, { useState, useEffect, useRef } from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import styles from './userManagement.module.scss'
import { createTheme } from 'react-data-table-component';
import { api } from "../../../Utils/API";
import Dropdown from "react-bootstrap/Dropdown";
import Form from 'react-bootstrap/Form';
import 'reactjs-popup/dist/index.css';
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import EmptyUser from '../../../Assets/Images/Illustrations/EmptyUser.svg'
import { UPDATE_SUB_ACCOUNT_STATUS, DELETE_SUB_ACCOUNT, SUB_ACCOUNTS, GET_ROLES_FILTER, GET_STATUS_FILTER, DOWNLOAD_SUBACCOUNTS, RESET_PASSWORD_SUBACCOUNT, RESEND_EMAIL_SUBACCOUNT } from '../../../Utils/GlobalConstants';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { XLg } from "react-bootstrap-icons";
import Toast from '../../../GlobalComponents/Toast';
import { useMsal } from '@azure/msal-react';
import UserManagementFilterBox from './LocalComponents/FilterBox';
import LazyTable from './LocalComponents/LazyTable';

const UserManagement = (props) => {
    const navigate = useNavigate();                                                 // to use navigate function from react router dom    
    const date = new Date();                                                        // for js date time function

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                        // table active page number
    const [pageSize, setPageSize] = useState(15);                                   // table active page size
    const [totalRows, setTotalRows] = useState(0);                                  // table total count of data from api 
    const [columns, setColumns] = useState([]);                                     // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
    let ColumnName = ["Name", "User Name", "Role", "Telephone", "Email ID",         // table headers used to make object of react data table component 
        "Status", "Actions"];
    const [initialLoading, setInitialLoading] = useState(true);                     // loader in table
    const [serverErrorUser, setServerErrorUser] = useState(false);                  // server error check state in table during api call to load data
    const [emptyUser, setEmptyUser] = useState(false);                              // empty table check state in table during api call to load data
    const [emptyUserSearch, setEmptyUserSearch] = useState(false);                  // empty search response check state in table during api call to load data
    const [deactiveStatusModalFlag, setDeactiveStatusModalFlag] = useState(false);  // popup when deactive is selected in actions
    const [activeStatusModalFlag, setActiveStatusModalFlag] = useState(false);      // popup when active is selected in actions
    const [deleteModalFlag, setDeleteModalFlag] = useState(false);                  // popup when delete is slected in actions
    const [passwordResetModalFlag, setPasswordResetModalFlag] = useState(false);    // popup when reset password is slected in actions
    const [resendEmailModalFlag, setResendEmailModalFlag] = useState(false);        // popup when resend email is slected in actions
    const [userStatusChange, setUserStatusChange] = useState();                     // storing id when status is changed ie. activate deactivate 
    const [userAccountDelete, setUserAccountDelete] = useState();                   // storing id when account is deleted
    const [userAccountPasswordReset, setUserAccountPasswordReset] = useState();     // storing id when clicked on reset password
    const [userAccountResendEmail, setUserAccountResendEmail] = useState();         // storing id when clicked on resend email
    const [status, setStatus] = useState("");                                       // for status checkbox filter 
    const [roles, setRoles] = useState("");                                         // roles checkbox filter
    const [resellerCode, setResellerCode] = useState(null);                         // displaying reseller code when downloading userlist
    const [searchIconFlag, setSearchIconFlag] = useState(false);                    // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(null);                 // storing searching value in the search input box
    const searchInputRef = useRef();                                                // Search Input Box

    //filter
    const [statusData, setStatusData] = useState(null);                             // storing data of status filter api
    const [rolesData, setRolesData] = useState(null);                               // storing data of roles filter api
    const [filterFlag, setFilterFlag] = useState(false);                            // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                      // showing filter box
    const [emptyFilters, setEmptyFilters] = useState(false);                        // empty filter response check state in table during api call to load data

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                        // toast message displaying success message
    const [toastError, setToastError] = useState(false);                            // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                           // toast message  
    const [firstTimeDataLoadFlag, setFirstTimeDataLoadFlag] = useState(null);       // to check the initial first load of data

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                    // for scrolling to the top in table   
    const [infinityLoading, setInfinityLoading] = useState(false);                  // loading state while table loads for lazy loading

    // Reset Password Username Save
    const [resetPasswordUsername, setResetPasswordUsername] = useState(null);                        // toast message displaying success message

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF pricing
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "pdf" || fileType === "zip") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(url, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 5000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: resellerCode + name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className={styles['threeDotAnchorTag']}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className={styles["threedots"]} />
        </a>
    ));

    // Reset Password
    async function ResetPassword(id) {
        let body = {
            "userName" : resetPasswordUsername
        }
        await api.put(`${RESET_PASSWORD_SUBACCOUNT}`,body, config)
            .then(resp => {
                // handle success
                setToastMessage("Email sent successfully.");
                setToastSuccess(true);
                GetTableData(pageNo, pageSize, searchValueTable, status, roles);
                setTimeout(() => {
                    setToastSuccess(false);
                }, 5000);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setToastMessage("There is some issue while submitting the request. Please try again later.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 5000);
                }
            });
    }

    // Resend Email
    async function ResendEmail(id) {
        await api.post(`${RESEND_EMAIL_SUBACCOUNT}${id}`, null, config)
            .then(resp => {
                // handle success
                setToastMessage("Email sent successfully.");
                setToastSuccess(true);
                setTimeout(() => {
                    setToastSuccess(false);
                }, 5000);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setToastMessage("There is some issue while submitting the request. Try again later.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 5000);
                }
            });
    }

    // Deletion of sub account
    async function UserDeleteAction(id) {
        await api.delete(`${DELETE_SUB_ACCOUNT}${id}`, config)
            .then(resp => {
                // handle success
                setToastMessage("User Deleted Successfully");
                setToastSuccess(true);
                setTimeout(() => {
                    GetTableData(pageNo, pageSize, searchValueTable, status, roles);
                    setToastSuccess(false);
                }, 7000);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setToastMessage("User could not be deleted at the moment, try again later!");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 5000);
                }
            });
    }

    //activation and deactivation of status
    async function UserStatusActivation(isActive, id) {
        const requestBody = {
            isActive: isActive,
            id: id,
        };

        await api.put(UPDATE_SUB_ACCOUNT_STATUS, requestBody, config)
            .then(resp => {
                // handle success              
                isActive ? setToastMessage("Subaccount activated successfully.") : setToastMessage("Subaccount deactivated successfully.");
                setToastSuccess(true);
                setTimeout(() => {
                    GetTableData(pageNo, pageSize, searchValueTable, status, roles);
                    setToastSuccess(false);
                }, 8000);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setToastMessage(error.response.data.errors);
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 5000);
                }
            });
    }
    // Get Table Data with Pagination and Search Functionality
    async function GetTableData(page, newPerPage, search, status, roles, appendData, isFilter) {
        firstTimeDataLoadFlag === null ? setInitialLoading(false) : console.log("");
        (search === undefined || search === null) ? search = "" : search = search;
        (status === undefined || status === null) ? status = "" : status = status;
        (roles === undefined || roles === null) ? roles = "" : roles = roles;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;
        await api.get(`${SUB_ACCOUNTS}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&status=${status}&roles=${roles}`, config)
            .then(resp => {
                firstTimeDataLoadFlag === null ? setInitialLoading(true) : console.log("");
                setInfinityLoading(false);

                if (resp.status == 204) {
                    if (search === "" && status === "" && roles === "") {
                        setEmptyUser(true);
                    } else if ((status != "" || roles != "") && isFilter) {
                        setEmptyFilters(true);
                    } else if (search != "" && !isFilter) {
                        setEmptyUserSearch(true);
                    }
                }
                // handle success
                else {
                    setFirstTimeDataLoadFlag("Loaded")
                    setServerErrorUser(false);
                    setEmptyUser(false);
                    setEmptyUserSearch(false);
                    setEmptyFilters(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    setResellerCode(resp.data.content[0].resellerCode);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Name": td.fullName,
                                    "User Name": td.userName,
                                    "Role": td.currentRole,
                                    "Telephone": td.phoneNumber != null ? `+61-${td.phoneNumber}` : td.phoneNumber,
                                    "Email ID": <span title={td.email}>{td.email}</span>,
                                    "id": td.id,
                                    "Status": td.isActive ? (
                                        <div className={`${styles['status-user-management']}`}>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) :
                                        <div className={`${styles['status-user-management']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Inactive</div>
                                        </div>,
                                    "Actions": (
                                        <Dropdown drop={"start"}>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                {(td.actions).map((action) => {
                                                    return (<Dropdown.Item onClick={(e) => {
                                                        if (action.id === "edit") {
                                                            navigate('/user-management/edit-user', { state: { id: td.id }, })
                                                        }
                                                        else if (action.id === "deactivate") {
                                                            setUserStatusChange(td.id); setDeactiveStatusModalFlag(true);
                                                        }
                                                        else if (action.id === "reactivate") {
                                                            setUserStatusChange(td.id); setActiveStatusModalFlag(true);
                                                        }
                                                        else if (action.id === "reset-password") {
                                                            setResetPasswordUsername(td.userName);setUserAccountPasswordReset(td.id); setPasswordResetModalFlag(true);
                                                        }
                                                        else if (action.id === "delete") {
                                                            setUserAccountDelete(td.id); setDeleteModalFlag(true);
                                                        }
                                                        else if (action.id === "clone") {
                                                            navigate('/user-management/clone-user', { state: { id: td.id }, })
                                                        }
                                                        else {
                                                            setUserAccountResendEmail(td.id); setResendEmailModalFlag(true);
                                                        }
                                                    }
                                                    } id={td.id}>{action.name}</Dropdown.Item>)
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ),
                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Actions" || td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Email ID") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                }
            })
            .catch(error => {
                setInitialLoading(true);
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setServerErrorUser(true);
                }

            });
    };

    // Function to call all dropdown values in status Section in Filter Box
    async function GetAllStatus() {
        await api.get(GET_STATUS_FILTER, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };


    // Function to call all dropdown values in roles Section in Filter Box
    async function GetAllRoles() {
        await api.get(GET_ROLES_FILTER, config)
            .then(resp => {
                setRolesData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
    };


    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, status, roles, true))();
            return newPageNumber;
        });
    }

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, status, roles);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, status, roles);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '350px'
            },
        },
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status, roles);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, status, roles);
    };

    // Function Triggers when Page is initially loaded by Customer
    useEffect(() => {
        localStorage.getItem("navigation_link") ?  localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link","/user-management");
        GetTableData(pageNo, pageSize, searchValueTable, status, roles);
        GetAllRoles();
        GetAllStatus();
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="main-content">
                <div className="user-management-console-main-div mb-5">
                    <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                    <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
                    {/* Deactivate popup */}
                    <Popup
                        className="custom-modal custom-modal-sm"
                        open={deactiveStatusModalFlag}
                        onClose={() => setDeactiveStatusModalFlag(false)}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header p-4 py-3">
                                    <h2 className="mb-0 px-3">Deactivate User</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong close-icon-popup-sm"
                                        onClick={close}
                                        color="#6A6A6A"
                                    />
                                </div>
                                <Container className="p-5">
                                    <div className='pt-0 px-3 pb-5 mb-0'>
                                        <p className='text-regular'><strong>Are you sure you want to deactivate the user?</strong></p>
                                        <span>Deactivating the user will not allow respective user to login to the portal
                                        </span></div>
                                    <div className="actions">
                                        <div className="d-flex justify-content-center pb-3 pt-2">
                                            <Button
                                                className="btn btn-lg mx-3 btn-border-light"
                                                variant="light"
                                                onClick={() => {
                                                    close();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn btn-md"
                                                onClick={() => {
                                                    UserStatusActivation(false, userStatusChange);
                                                    close();
                                                }}
                                            >
                                                Deactivate
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Popup>
                    {/* Activate popup */}
                    <Popup
                        className="custom-modal custom-modal-sm"
                        open={activeStatusModalFlag}
                        onClose={() => setActiveStatusModalFlag(false)}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header p-4 py-3">
                                    <h2 className="mb-0 px-3">Reactivate User</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong close-icon-popup-sm"
                                        onClick={close}
                                        color="#6A6A6A"
                                    />
                                </div>
                                <Container className="p-5">
                                    <div className='pt-0 px-3 pb-5 mb-0'>
                                        <p className='text-regular'><strong>Are you sure you want to reactivate the user?</strong></p>
                                        <span>Reactivating the user will allow respective user to login to the portal
                                        </span></div>
                                    <div className="actions">
                                        <div className="d-flex justify-content-center pb-3 pt-2">
                                            <Button
                                                className="btn btn-lg mx-3 btn-border-light"
                                                variant="light"
                                                onClick={() => {
                                                    close();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn btn-md"
                                                onClick={() => {
                                                    UserStatusActivation(true, userStatusChange);
                                                    close();
                                                }}
                                            >
                                                Reactivate
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Popup>
                    {/* Delete popup */}
                    <Popup
                        className="custom-modal custom-modal-sm"
                        open={deleteModalFlag}
                        onClose={() => setDeleteModalFlag(false)}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header p-4 py-3">
                                    <h2 className="mb-0 px-3">Delete User</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong close-icon-popup-sm"
                                        onClick={close}
                                        color="#6A6A6A"
                                    />
                                </div>
                                <Container className="p-5">
                                    <div className='pt-0 px-3 pb-5 mb-0'>
                                        <p className='text-regular'><strong>Are you sure you want to delete the user?</strong></p>
                                        <span>Deleting user will permanently remove access to the user. This action cannot be reverted
                                        </span></div>
                                    <div className="actions">
                                        <div className="d-flex justify-content-center pb-3 pt-2">
                                            <Button
                                                className="btn btn-lg mx-3 btn-border-light"
                                                variant="light"
                                                onClick={() => {
                                                    close();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn btn-md"
                                                onClick={() => {
                                                    UserDeleteAction(userAccountDelete);
                                                    close();
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Popup>
                    {/* Reset Password popup */}
                    <Popup
                        className="custom-modal custom-modal-sm"
                        open={passwordResetModalFlag}
                        onClose={() => {setPasswordResetModalFlag(false);setResetPasswordUsername(null);}}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header p-4 py-3">
                                    <h2 className="mb-0 px-3">Reset Password</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong close-icon-popup-sm"
                                        onClick={close}
                                        color="#6A6A6A"
                                    />
                                </div>
                                <Container className="p-5">
                                    <div className='pt-0 px-3 pb-5 mb-0'>
                                        <p className='text-regular'><strong>Are you sure you want to reset password?</strong></p>
                                        <span>Resetting password will send one time password to the user
                                        </span></div>
                                    <div className="actions">
                                        <div className="d-flex justify-content-center pb-3 pt-2">
                                            <Button
                                                className="btn btn-lg mx-3 btn-border-light"
                                                variant="light"
                                                onClick={() => {
                                                    close();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn btn-md px-4"
                                                onClick={() => {
                                                    ResetPassword(userAccountPasswordReset);
                                                    close();
                                                }}
                                            >
                                                Reset Password
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Popup>
                    {/* Resend Email popup */}
                    <Popup
                        className="custom-modal custom-modal-sm"
                        open={resendEmailModalFlag}
                        onClose={() => setResendEmailModalFlag(false)}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header p-4 py-3">
                                    <h2 className="mb-0 px-3">Resend Email</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong close-icon-popup-sm"
                                        onClick={close}
                                        color="#6A6A6A"
                                    />
                                </div>
                                <Container className="p-5">
                                    <div className='pt-0 px-3 pb-5 mb-0'>
                                        <p className='text-regular'><strong>Are you sure you want to resend email?</strong></p>
                                        <span>Email sent will contain temporary password.
                                        </span></div>
                                    <div className="actions">
                                        <div className="d-flex justify-content-center pb-3 pt-2">
                                            <Button
                                                className="btn btn-lg mx-3 btn-border-light"
                                                variant="light"
                                                onClick={() => {
                                                    close();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn btn-md px-4"
                                                onClick={() => {
                                                    ResendEmail(userAccountResendEmail);
                                                    close();
                                                }}
                                            >
                                                Resend Email
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Popup>

                    <div className="mgmt-console-table-row">
                        <div className="my-3 d-flex">
                            <Col className={`py-0 ${styles['user-management-table']}`}>
                                <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header">User Management</h5>
                                <div className={`${styles['user-management-table-inner']} ${styles['table-details']} details-box`}>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                            <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header">Users {!emptyFilters && !emptyUser && !emptyUserSearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h5>
                                        </Col>
                                        <Col>
                                            <Col className="my-1 d-md-none d-block text-right mr-1">
                                                <Row>
                                                    <Col md={6} sm={8}>
                                                        {resellerCode != null && <Button className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12 px-3"
                                                            variant="light" sx={{ mr: 1 }}
                                                            onClick={(e) => { DownloadAction(DOWNLOAD_SUBACCOUNTS, `_Subaccount User List_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, '0')}${date.getFullYear()}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}.csv`, "csv") }}
                                                        >
                                                            Download User List
                                                        </Button>}
                                                    </Col>

                                                </Row>

                                            </Col>
                                        </Col>

                                        {/* desktop and tab search and filter */}
                                        <Col md={8} className="px-1 d-none d-md-block">
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorUser && !emptyUser && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                    {!searchIconFlag &&
                                                        <span className="mx-3">&nbsp;</span>
                                                    }
                                                    {!searchIconFlag && !emptyFilters &&
                                                        <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                    }
                                                    {searchIconFlag && !emptyFilters &&
                                                        <InputGroup className="search-input-box search-input-box-large">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search user / email ID / user name"
                                                                aria-label="Search user / email ID / user name"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                                ref={searchInputRef}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    }
                                                    {!filterFlag && !emptyUserSearch && initialLoading && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                    }}></span>}
                                                    {initialLoading && filterFlag && !emptyUserSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                    <Button className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12 px-3"
                                                        variant="light" sx={{ mr: 1 }}
                                                        onClick={(e) => { DownloadAction(DOWNLOAD_SUBACCOUNTS, `_Subaccount User List_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, '0')}${date.getFullYear()}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}.csv`, "csv") }}
                                                    >
                                                        Download user list
                                                    </Button>
                                                    <Button className="ml-3 px-4 mr-2" onClick={() => navigate('/user-management/add-user')}>Add User</Button>

                                                </Col>}
                                            </Row>
                                        </Col>
                                        {/* mobile search and filter */}
                                        <Col className="px-3 d-block d-md-none" md={12}>
                                            <Row className="justify-content-start align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorUser && !emptyUser && <Col className="d-flex align-items-center justify-items-center justify-content-between position-relative">
                                                    {!emptyFilters &&
                                                        <InputGroup className="search-input-box mx-0">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer" >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search user / email ID / user name"
                                                                aria-label="Search user / email ID / user name"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                ref={searchInputRef}
                                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>}
                                                    {!filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                    }}></span>}
                                                    {initialLoading && filterFlag && !emptyUserSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                </Col>}
                                            </Row>
                                        </Col>
                                    </div>
                                    {!emptyUserSearch && showFilterBox &&
                                        <UserManagementFilterBox
                                            GetTableData={GetTableData} pageNo={pageNo} pageSize={pageSize} setPageNo={setPageNo} searchValueTable={searchValueTable}
                                            rolesData={rolesData} statusData={statusData} roles={roles} setRoles={setRoles} status={status}
                                            setStatus={setStatus} setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} setScrollToDivTop={setScrollToDivTop}
                                        />
                                    }
                                    <Container fluid className="mb-5 p-0">
                                        <Row>
                                            <Col>
                                                <div className={`table-responsive ${styles['user-history-table']}`}>
                                                    {initialLoading && serverErrorUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-management-table-svg" />
                                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyUserSearch &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Matching User Found.</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyFilters && !emptyUserSearch &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Data Found.</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyUser} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">No users added.</h2>
                                                            <h4>There are no users created yet, please click on add user to create a user</h4>
                                                            <Button className="ml-3 my-2 mt-4 px-4 mr-2" onClick={() => navigate('/user-management/add-user')}>Add User</Button>
                                                        </div>
                                                    }
                                                    {!serverErrorUser && !emptyUser && !emptyUserSearch && !emptyFilters &&
                                                        <LazyTable
                                                            d={columns}
                                                            table={table}
                                                            customStyles={customStyles}
                                                            loading={infinityLoading}
                                                            pageNo={pageNo}
                                                            totalRows={totalRows}
                                                            handlePerRowsChange={handlePerRowsChange}
                                                            handlePageChange={handlePageChange}
                                                            styles={styles}
                                                            handleLazyCalls={handleLazyCalls}
                                                            serverErrorUser={serverErrorUser}
                                                            emptyUser={emptyUser}
                                                            emptyUserFilters={emptyFilters}
                                                            emptyUserSearch={emptyUserSearch}
                                                            setScrollToDivTop={setScrollToDivTop}
                                                            scrollToDivTop={scrollToDivTop}
                                                        />}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserManagement