const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email Regex
const numberRegEx = /^[0-9]*$/; // Number Regex 
const websiteURLRegEx = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{1,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/; // Web Site URL Regex
const passwordRegEx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})"); // Regex Expression for Password
const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/; // Reggex for Domain Check
const alphanumericRegex = /^[a-zA-Z0-9]*$/; // Alpha Numeric RegEx
const phoneRegex = /^[0-9\s-]{8,15}$/; // Phone Number RegEx validation on submit
const nameRegEx =  /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/;
const phoneOnChangeRegex = /^[0-9\s-]+$/; // Phone Number RegEx validation on Change
const alphanumericRegexWithHyphen = /^[a-zA-Z0-9-]{0,50}$/; // Alpha Numeric RegEx with hyphen

export { emailRegEx, numberRegEx, websiteURLRegEx, passwordRegEx, domainRegex, alphanumericRegex, alphanumericRegexWithHyphen, phoneRegex, nameRegEx, phoneOnChangeRegex }