import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import styles from './azureServiceManagement.module.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { GET_SERVICES, GET_CUSTOMERS_LIST, AZURE_SERVICE_MANAGEMENT_ACCOUNT_DETAILS, GET_AZURE_SUBSCRIPTION_TABLE, AZURE_MONTHLY_FOREX, AZURE_RESOURCE_GROUP, AZURE_RESOURCE_LIST, AZURE_CHANGE_STATUS, GET_AZURE_ROLES, DELETE_AZURE_ROLES, AZURE_STATUS_TYPE } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Form from "react-bootstrap/Form";
import { Container, Button, Row, Col, Table, Nav, Tab } from 'react-bootstrap';
import Dropdown from "react-bootstrap/Dropdown";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import serviceLogo from '../../../../../Assets/Images/Illustrations/azure.svg';
import SearchDropdown from "../../../../../GlobalComponents/SearchDropdown";
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import AzureFilterBox from './LocalComponents/FilterBox';
import EmptyServiceSubscription from '../../../../../Assets/Images/Illustrations/EmptyServiceSubscription.svg'
import EmptyServiceProvision from '../../../../../Assets/Images/Illustrations/EmptyServiceProvision.svg';
import Toast from '../../../../../GlobalComponents/Toast';
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif"
import NoCustomer from '../../../../../Assets/Images/Illustrations/noCustomer.svg'
import GDAPRelationship from './LocalComponents/GDAPRelationship';
import CreateGDAP from './LocalComponents/CreateGDAP';

const AzureServiceManagement = (props) => {

    const alphanumericRegex = /^[a-zA-Z0-9-]{0,50}$/;                                // RegEx for PO Number

    const location = useLocation();                                                 // use to access data from other page data
    const navigate = useNavigate();                                                 // to use navigate function from react router dom   
    const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Manage") ? true : false);
    const [isCloudAdmin, setIsCloudAdmin] = useState(localStorage.getItem("curentRole") && localStorage.getItem("curentRole").toLowerCase().includes("cloud admin view") ? true : false);

    const [searchIconFlag, setSearchIconFlag] = useState(false);                    // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(null);                 // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(true);                            // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                      // showing filter box
    const [status, setStatus] = useState(["ACTIVE", "INACTIVE", "PENDING"]);                    // getTableData() status

    const [searchIconListFlag, setSearchIconListFlag] = useState(false);            // to open and close search box in resource list
    const [searchValueListTable, setSearchValueListTable] = useState(null);         // storing searching value in the search input box in resource list

    const [searchIconResourceGroupFlag, setSearchIconResourceGroupFlag]
        = useState(false);                                                        // to open and close search box in resource group
    const [searchValueResourceGroupTable, setSearchValueResourceGroupTable]
        = useState(null);                                                         // storing searching value in the search input box in resource group
    const searchInputRef = useRef();                                              // Search Input Box
    const [filterApplied, setFilterApplied] = useState(false);                    // filter in gettabledata

    //Resource list tables including header name
    const [columnsVirtualMachines, setColumnsVirtualMachines] = useState([]);
    const [tableVirtualMachines, setTableVirtualMachines] = useState([]);
    let ColumnNameVirtualMachines = ["Name", "Location", "VM Size", "OS"];

    const [columnsNetworkInterfaces, setColumnsNetworkInterfaces] = useState([]);
    const [tableNetworkInterfaces, setTableNetworkInterfaces] = useState([]);
    let ColumnNameNetworkInterfaces = ["Name", "Location", "Private IP Address", "MAC Address"];

    const [columnsDisks, setColumnsDisks] = useState([]);
    const [tableDisks, setTableDisks] = useState([]);
    let ColumnNameDisks = ["Name", "Location", "Disk Size GB", "Disk State"];

    const [columnsPublicIpAddresses, setColumnsPublicIpAddresses] = useState([]);
    const [tablePublicIpAddresses, setTablePublicIpAddresses] = useState([]);
    let ColumnNamePublicIpAddresses = ["Name", "Location", "IP Address", "Public IP Address Version"];

    const [columnsVirtualNetworks, setColumnsVirtualNetworks] = useState([]);
    const [tableVirtualNetworks, setTableVirtualNetworks] = useState([]);
    let ColumnNameVirtualNetworks = ["Name", "Location", "Address Prefix"];

    const [columnsNetworkWatchers, setColumnsNetworkWatchers] = useState([]);
    const [tableNetworkWatchers, setTableNetworkWatchers] = useState([]);
    let ColumnNameNetworkWatchers = ["Name", "Location", "Provisioning State"];

    const [columnsStorageAccounts, setColumnsStorageAccounts] = useState([]);
    const [tableStorageAccounts, setTableStorageAccounts] = useState([]);
    let ColumnNameStorageAccounts = ["Name", "Location", "Creation Date"];

    const [emptyUserList, setEmptyUserList] = useState(false);                      // empty table in resource list check state in table during api call to load data
    const [emptyUserListSearch, setEmptyUserListSearch] = useState(false);          // empty search response in resource list check state in table during api call to load data
    const [serverErrorListUser, setServerErrorListUser] = useState(false);          // server error check state in resource list in table during api call to load data
    const [loadingList, setLoadingList] = useState(false);                          // loading state for resource list

    //Resource Group
    const [columnsResourceGroup, setColumnsResourceGroup] = useState([]);
    const [tableResourceGroup, setTableResourceGroup] = useState([]);
    const [tableResourceGroupDuplicate, setTableResourceGroupDuplicate] = useState([]);
    let ColumnNameResourceGroup = ["Name", "Subscription ID", "Location"];          // headers for react data table 
    const [loadingResourceGroup, setLoadingResourceGroup] = useState(false);        // loading state for resource group
    const [serverErrorUserResourceGroup, setServerErrorUserResourceGroup]
        = useState(false);                                                         // server error check state in table during api call to load data in resource group
    const [emptyUserResourceGroup, setEmptyUserResourceGroup] = useState(false);   // empty table check state in table during api call to load data in resource group
    const [emptyUserSearchResourceGroup, setEmptyUserSearchResourceGroup]          // empty search response check state in table during api call to load data in resource group
        = useState(false);

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                        // table active page number
    const [pageSize, setPageSize] = useState(5);                                    // table active page size
    const [totalRows, setTotalRows] = useState(0);                                  // table total count of data from api 
    const [columns, setColumns] = useState([]);                                     // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
    let ColumnName = ["Plan", "Plan ID", "Subscription ID", "Status", "Actions"];   // table headers used to make object of react data table component
    let ColumnNameReadOnly = ["Plan", "Plan ID", "Subscription ID", "Status"];

    const [loading, setLoading] = useState(false);                                  // loading state for main table
    const [provisionRefreshIconFlag, setProvisionRefreshIconFlag]                   // refresh icon on the table when service is provisioned
        = useState(false);

    const [serverErrorUser, setServerErrorUser] = useState(false);                   // server error check state in table during api call to load data in main table
    const [emptyUser, setEmptyUser] = useState(false);                               // empty table check state in table during api call to load data in main table
    const [emptyUserSearch, setEmptyUserSearch] = useState(false);                   // empty search response check state in table during api call to load data in main table
    const [emptyUserFilters, setEmptyUserFilters] = useState(true);                 // empty filter response check state in table during api call to load data in main table
    const [customerId, setCustomerId]
        = useState(location.state !== null ? location.state.customerId : "");        // storing customer id 
    const [resellerId, setResellerId]
        = useState((location.state !== null && location.state !== "") ? location.state.resellerId : "");  // storing reseller id

    const [serviceId, setServiceId] = useState(null);                                // service name for dropdown at the top
    const [serviceData, setServiceData] = useState(null);                            // data in service dropdown
    const [customerData, setCustomerData] = useState([]);                            // data in customer dropdown
    const [modalOpen, setModalOpen] = useState(false);                               // opens modal popup
    const [additionalDetails, setAdditionalDetails] = useState(null);                // storing additionaldetails from api for popup
    const [monthlyForex, setMonthlyForex] = useState(null);                          //storing monthly forex value
    const [initialSubscriptionTableCall, setInitialSubscriptionTableCall]
        = useState(null);                                                            // initial loading of subscription table 
    const [roleManagementLoading, setRoleManagementLoading] = useState(false);

    // Search Value for Service Name
    const [serviceNameDefaultValue, setServiceNameDefaultValue] = useState("Select Service Name");
    const [serviceNameSearchValue, setServiceNameSearchValue] = useState();
    const [serviceName, setServiceName] = useState([]);
    const [serviceNameFiltered, setServiceNameFiltered] = useState(serviceName);
    const updatedServiceName = [];

    // Search Value for Customer Name
    const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState("Select Customer Name");
    const [customerNameSearchValue, setCustomerNameSearchValue] = useState();
    const [customerName, setCustomerName] = useState([]);
    const [customerNameFiltered, setCustomerNameFiltered] = useState(customerName);
    const updatedCustomerName = [];

    const [deactiveStatusModalFlag, setDeactiveStatusModalFlag]                 // deactive popup
        = useState(null);
    const [activeStatusModalFlag, setActiveStatusModalFlag]                     // activate popup
        = useState(null);
    const [roleManageModalFlag, setRoleManageModalFlag]                     // activate popup
        = useState(null);
    const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);
    const [statusData, setStatusData] = useState(null);                               // data in status dropdown

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    const [poNumber, setPoNumber] = useState("");                               // Order Confirmation PO Number
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);        // Processing after calling api
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);                      // Terms and condition checkbox popup

    // Selected Row for Actions
    const [selectedRowActivate, setSelectedRowActivate] = useState(null)                        // Selected row from subscription table to open in activate option
    const [selectedRowDeactivate, setSelectedRowDeactivate] = useState(null)                    // Selected row from subscription table to open in deactivate status option
    const [roleManagementData, setRoleManagementData] = useState(null);
    const [deleteRow, setDeleteRow] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const [emptyRoles, setEmptyRoles] = useState(false);
    const [emptyUsers, setEmptyUsers] = useState(false);

    let changeStatusConfirmationColumnName = ["Plan", "Plan ID", "Subscription ID"];
    let roleManagementColumnName = ["User Email", "Role", "Delete"];
    const [actionCompletedRefreshSubscriptionTable, setActionCompletedRefreshSubscriptionTable] = useState(false)
    const [statusRefreshChecker, setStatusRefreshChecker] = useState([]);
    const [infinityLoading, setInfinityLoading] = useState(false);
    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(false);
    const [isFilterOrSearchActive, setIsFilterOrSearchActive] = useState(false);
    const [isActionInProgress, setIsActionInProgress] = useState(false);
    const [hasPendingStatus, setHasPendingStatus] = useState(false);
    const [pagesWithPending, setPagesWithPending] = useState(new Set());
    const [loadedPages, setLoadedPages] = useState(new Set([1]));
    const [tableData, setTableData] = useState({});
    const [isSearching, setIsSearching] = useState(false);
    const [actionsSubscriptionId, setActionsSubscriptionId] = useState(location.state?.subscriptionId !== null ? location.state?.subscriptionId : null);

    // cloud portal details
    const [tabName, setTabName] = useState("Cloud Portal Details");                                          // Default Tab

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className={styles['threeDotAnchorTag']}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className={styles["threedots"]} />
        </a>
    ));

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetAllServices() {
        await api.get(GET_SERVICES, config)
            .then(resp => {
                let serviceArray = []
                resp.data.map((service) => {
                    if (service.serviceType == "ONLINE") {
                        serviceArray.push(service);
                    }
                })
                setServiceData(serviceArray);
                setServiceNameFiltered(serviceArray);
                for (let i in resp.data) {
                    if (resp.data[i].integrationCode === "microsoftazure") {
                        setServiceId(resp.data[i].id);
                        setServiceNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }
                for (let i in resp.data) {
                    if (!serviceName.includes(resp.data[i].name)) {
                        serviceName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(`${GET_CUSTOMERS_LIST}?requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setCustomerData(resp.data);
                setCustomerNameFiltered(resp.data);
                for (let i in resp.data) {
                    if (resp.data[i].id === customerId) {
                        setCustomerNameDefaultValue(resp.data[i].name)
                    }
                }
                for (let i in resp.data) {
                    if (!customerName.includes(resp.data[i].name)) {
                        customerName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(AZURE_STATUS_TYPE, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // function to call all account details for popup
    async function GetAdditionalDetails() {
        await api.get(`${AZURE_SERVICE_MANAGEMENT_ACCOUNT_DETAILS}?customerId=${customerId}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setAdditionalDetails(resp.data);
                if (resp.data.isProvision) {
                    setInitialSubscriptionTableCall(true);
                    setProvisionRefreshIconFlag(false);
                }
                else {
                    setInitialSubscriptionTableCall(false);
                    setProvisionRefreshIconFlag(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setInitialSubscriptionTableCall(false);
                }
            });
    };

    // function to call monthly forex
    async function GetMonthlyForex() {
        await api.get(`${AZURE_MONTHLY_FOREX}?customerId=${customerId}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setMonthlyForex(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Get Table Data with Pagination and Filter and Search Functionality
    async function GetTableData(page, newPerPage, search, status, appendData, isFilter) {
    setLoading(true);
    (search === undefined || search === null) ? search = "" : search = search;
    (status === undefined || status === null) ? status = "" : status = status;
    isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

    try {
        const resp = await api.get(`${GET_AZURE_SUBSCRIPTION_TABLE}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&status=${status}&customerId=${customerId}&requestedResellerCode=${resellerId}`, config);
        
        setLoading(false);
        setInfinityLoading(false);

        if (resp.status == 204) {
            handleEmptyResponse(search, status, isFilter);
        } else {
            const hasPending = resp.data.content.some(item => item.status.toUpperCase() === 'PENDING');
            updatePendingPages(page, hasPending);
            setHasPendingStatus(hasPending);

            setLoadedPages(prev => new Set(prev).add(page));
            resetErrorStates();
            setTotalRows(resp.data.totalCount);

            const formattedData = resp.data.content.map((td) => ({
                                    "Plan": td.planName,
                                    "Plan ID": td.planId,
                                    "Subscription ID": td.vendorSubscriptionId,
                                    "Status": (td.status.toLowerCase() === 'active') ? (
                                        <div className={`${styles['status-azure']}`}>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) : (td.status.toLowerCase() === 'inactive') ? (
                                        <div className={`${styles['status-azure']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Suspended</div>
                                        </div>
                                    ) : (td.status.toLowerCase() === 'pending') ? (
                                        <div className={`${styles['status-azure']}`}>
                                            <span className="status-pending"></span>
                                            <div className="py-1 text-muted text-small">Pending</div>
                                        </div>
                                    ) : (
                                        td.status
                                    ),
                                    "Actions": (<>
                                        {(td.status.toLowerCase() != "pending") ? <Dropdown drop={"start"} onToggle={handleDropdownToggle}>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                {(td.status.toLowerCase() === 'active') ? (
                                                    <>
                                                        <Dropdown.Item onClick={(e) => { setSelectedRowDeactivate(td); setDeactiveStatusModalFlag(true); setActionsSubscriptionId(td.vendorSubscriptionId);}} id="1">Suspend</Dropdown.Item>
                                                        <Dropdown.Item onClick={(e) => { setSelectedRow(td); GetUserRoles(td.subscriptionId, null); setRoleManageModalFlag(true); setActionsSubscriptionId(td.vendorSubscriptionId); }} id="1">Role Management</Dropdown.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Dropdown.Item onClick={(e) => { setSelectedRowActivate(td); setActiveStatusModalFlag(true); setActionsSubscriptionId(td.vendorSubscriptionId); }} id="1">Activate</Dropdown.Item>
                                                        <Dropdown.Item onClick={(e) => { setSelectedRow(td); GetUserRoles(td.subscriptionId, null); setRoleManageModalFlag(true); setActionsSubscriptionId(td.vendorSubscriptionId);}} id="1">Role Management</Dropdown.Item>
                                                    </>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown> : <span className={`${styles['threedots']} three-dots-icon-disabled`} />}</>
                                    ),
            }));

            const uniqueData = removeDuplicates(formattedData, 'subscriptionId');
            updateTableData(page, uniqueData, newPerPage, appendData);
            // setStatusRefreshChecker(statusArray);
                    let d = [];
                    (isreadOnly ? ColumnNameReadOnly : ColumnName).map((td) => {
                        if (td === "Actions") {
                            d.push({
                                id: `${td}`,
                                name: <div className='d-none-mobile'>{td}</div>,
                                selector: row => <div className="text-wrap d-none-mobile">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1
                            })
                        }
                    })
                    setColumns(d);     // Setting Column Data
                    setLoading(false);
        }
    } catch (error) {
        handleApiError(error);
    }
    }

    function handleDropdownToggle(isOpen) {
    setIsAutoRefreshEnabled(!isOpen);
    }

    function handleEmptyResponse(search, status, isFilter) {
    if (search === "" && (status === "" || status.length === 0)) {
        setShowFilterBox(false);
        setEmptyUser(true);
    } else if (status !== "" && isFilter) {
        setEmptyUserFilters(true);
    } else if (search !== "" && !isFilter) {
        setEmptyUserSearch(true);
    }
    }

    function handleApiError(error) {
    setLoading(false);
    setIsAutoRefreshEnabled(false);
    if (error?.status == 401 || error?.response?.status == 401) {
        props.Logout();
    } else {
        setServerErrorUser(true);
    }
    }

    function updatePendingPages(page, hasPending) {
    setPagesWithPending(prev => {
        const newSet = new Set(prev);
        if (hasPending) {
            newSet.add(page);
        } else {
            newSet.delete(page);
        }
        return newSet;
    });
    }

    function resetErrorStates() {
    setServerErrorUser(false);
    setEmptyUser(false);
    setEmptyUserSearch(false);
    setEmptyUserFilters(false);
    }

    function updateTableData(page, uniqueData, newPerPage, appendData) {
    setTableData(prevData => ({
        ...prevData,
        [page]: uniqueData
    }));

    setTable(prevTable => {
        let updatedTable;
        if (appendData) {
            updatedTable = [...prevTable, ...uniqueData];
        } else {
            updatedTable = [...prevTable];
            const startIndex = (page - 1) * newPerPage;
            const endIndex = startIndex + uniqueData.length;
            updatedTable.splice(startIndex, endIndex - startIndex, ...uniqueData);
        }
        return removeDuplicates(updatedTable, 'subscriptionId');
    });
    }

    function removeDuplicates(array, key) {
    return array.filter((item, index, self) =>
        index === self.findIndex((t) => t[key] === item[key])
    );
    }

    useEffect(() => {
   console.log("refreshDataEnter", isAutoRefreshEnabled , initialSubscriptionTableCall , !isFilterOrSearchActive , !isActionInProgress , hasPendingStatus, pagesWithPending.size > 0) 
    
    if (isAutoRefreshEnabled && initialSubscriptionTableCall && !isFilterOrSearchActive && !isActionInProgress && hasPendingStatus) {
        const intervalId = setInterval(() => {
            refreshData();
        }, 5000); // 5 seconds interval

        return () => clearInterval(intervalId);
    }
    }, [isAutoRefreshEnabled, initialSubscriptionTableCall, isFilterOrSearchActive, isActionInProgress, hasPendingStatus]);

    const refreshData = () => {
    GetAdditionalDetails();
    for (let page of pagesWithPending) {
        if (loadedPages.has(page)) {
            GetTableData(page, pageSize, searchValueTable, status, false, false);
        }
    }
    };

    const clearExistingData = () => {
         setTable([]);
         setTableData({});
         setTotalRows(0);
    };

    // Get Resource Group Data with Search Functionality
    async function GetResourceGroupData(search) {
        setLoadingResourceGroup(true);
        (search === undefined || search === null) ? search = "" : search = search;
        await api.get(`${AZURE_RESOURCE_GROUP}?searchText=${search}&customerId=${customerId}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setLoadingResourceGroup(false);
                if (resp.status == 204) {
                    if (search === "") {
                        setEmptyUserResourceGroup(true);
                    }
                    if (search != "") {
                        setEmptyUserSearchResourceGroup(true);
                    }
                }
                // handle success
                else {
                    setServerErrorUserResourceGroup(false);
                    setEmptyUserResourceGroup(false);
                    setEmptyUserSearchResourceGroup(false);
                    if (resp.data.content !== null && resp.data.content.length > 0) {
                        let i = 0;
                        let f = [];
                        (resp.data.content).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.resourceGroupName,
                                        "Subscription ID": td.vendorSubscriptionId,
                                        "Location": td.location,
                                    }
                                );
                        })
                        setTableResourceGroupDuplicate(f);
                        setTableResourceGroup(f);
                        // console.log("data for resourceGroup is: ", tableResourceGroup);
                        let d = [];
                        ColumnNameResourceGroup.map((td) => {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                // grow: 1.5,
                            })
                        });
                        setColumnsResourceGroup(d);     // Setting Column Data
                        // console.log("columnsss for resourceGroup: ", d)
                    }
                }
            })
            .catch(error => {
                setLoadingResourceGroup(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setServerErrorUserResourceGroup(true);
                }
            });
    };

    // Get Resource list data with Search Functionality
    async function GetResourceListData(search) {
        setLoadingList(true);
        (search === undefined || search === null) ? search = "" : search = search;
        await api.get(`${AZURE_RESOURCE_LIST}?customerId=${customerId}&searchText=${search}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setLoadingList(false);
                if (resp.status == 204) {
                    if (search === "") {
                        setEmptyUserList(true);
                    }
                    if (search != "") {
                        setEmptyUserListSearch(true);
                    }
                }
                // handle success
                else {
                    setServerErrorListUser(false);
                    setEmptyUserList(false);
                    setEmptyUserListSearch(false);
                    // console.log("resource list: ", resp.data.networkWatchers[0]);
                    //first:NetworkWatchers
                    if (resp.data.networkWatchers !== null && resp.data.networkWatchers.length > 0) {
                        // console.log(resp.data.networkWatchers);
                        let i = 0;
                        let f = [];
                        (resp.data.networkWatchers).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Location": td.location,
                                        "Provisioning State": td.provisioningState,
                                    }
                                )
                        });
                        setTableNetworkWatchers(f);
                        // console.log("data for NetworkWatchers  is: ", tableNetworkWatchers);
                        let d = [];
                        ColumnNameNetworkWatchers.map((td) => {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })
                        });
                        setColumnsNetworkWatchers(d);     // Setting Column Data
                        // console.log("columnsss: ", d)
                    }
                    //second:virtualMachines
                    if (resp.data.virtualMachines !== null && resp.data.virtualMachines.length > 0) {
                        // console.log("virtual Machines: ", resp.data.virtualMachines);
                        let i = 0;
                        let f = [];
                        (resp.data.virtualMachines).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Location": td.location,
                                        "VM Size": td.vmSize,
                                        "OS": td.os,
                                    }
                                )
                        });
                        setTableVirtualMachines(f);
                        // console.log("data is: ", tableVirtualMachines);
                        let d = [];
                        ColumnNameVirtualMachines.map((td) => {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })
                        });
                        setColumnsVirtualMachines(d);     // Setting Column Data
                        // console.log("columnsss for virtual Machines: ", d)
                    }
                    //third:NetworkInterfaces
                    if (resp.data.networkInterfaces !== null && resp.data.networkInterfaces.length > 0) {
                        // console.log(resp.data.networkInterfaces);
                        let i = 0;
                        let f = [];
                        (resp.data.networkInterfaces).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Location": td.location,
                                        "Private IP Address": td.privateIpAddress,
                                        "MAC Address": td.macAddress,
                                    }
                                )
                        });
                        setTableNetworkInterfaces(f);
                        // console.log("data for tableNetworkInterfaces is: ", tableNetworkInterfaces);
                        let d = [];
                        ColumnNameNetworkInterfaces.map((td) => {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })
                        });
                        setColumnsNetworkInterfaces(d);     // Setting Column Data
                        // console.log("columnsss for NetworkInterfaces: ", d)
                    }
                    //fourth:Disks
                    if (resp.data.disks !== null && resp.data.disks.length > 0) {
                        // console.log(resp.data.disks);
                        let i = 0;
                        let f = [];
                        (resp.data.disks).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Location": td.location,
                                        "Disk Size GB": td.diskSizeGB,
                                        "Disk State": td.diskState,
                                    }
                                )
                        });
                        setTableDisks(f);
                        // console.log("data for tableDisks is: ", tableDisks);
                        let d = [];
                        ColumnNameDisks.map((td) => {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })
                        });
                        setColumnsDisks(d);     // Setting Column Data
                        // console.log("columnsss for Disks: ", d)
                    }
                    //fifth:PublicIpAddresses
                    if (resp.data.publicIpAddresses !== null && resp.data.publicIpAddresses.length > 0) {
                        // console.log(resp.data.publicIpAddresses);
                        let i = 0;
                        let f = [];
                        (resp.data.publicIpAddresses).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Location": td.location,
                                        "IP Address": td.ipAddress,
                                        "Public IP Address Version": td.publicIPAddressVersion,
                                    }
                                )
                        });
                        setTablePublicIpAddresses(f);
                        // console.log("data is: ", tablePublicIpAddresses);
                        let d = [];
                        ColumnNamePublicIpAddresses.map((td) => {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })
                        });
                        setColumnsPublicIpAddresses(d);     // Setting Column Data
                        // console.log("columnsss for PublicIpAddresses: ", d)
                    }
                    // sixth:VirtualNetworks
                    if (resp.data.virtualNetworks !== null && resp.data.virtualNetworks.length > 0) {
                        // console.log(resp.data.virtualNetworks);
                        let i = 0;
                        let f = [];
                        (resp.data.virtualNetworks).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Location": td.location,
                                        "Address Prefix": td.addressPrefix,
                                    }
                                )
                        });
                        setTableVirtualNetworks(f);
                        // console.log("data for Network Storage is: ", tableVirtualNetworks);
                        let d = [];

                        ColumnNameVirtualNetworks.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsVirtualNetworks(d);     // Setting Column Data
                        // console.log("columnsss for VirtualNetworks: ", d)

                    }
                    //seventh:StorageAccounts
                    if (resp.data.storageAccounts !== null && resp.data.storageAccounts.length > 0) {
                        // console.log(resp.data.storageAccounts);
                        let i = 0;
                        let f = [];
                        (resp.data.storageAccounts).map((td) => {
                            i++
                            f.push
                                (
                                    {
                                        "Name": td.name,
                                        "Location": td.location,
                                        "Creation Date": td.creationDate,
                                    }
                                )
                        });
                        setTableStorageAccounts(f);
                        // console.log("data for StorageAccounts is: ", tableStorageAccounts);
                        let d = [];

                        ColumnNameStorageAccounts.map((td) => {

                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                            })

                        });
                        setColumnsStorageAccounts(d);     // Setting Column Data
                        // console.log("columnsss for StorageAccounts: ", d)

                    }
                    if (resp.data.storageAccounts == null && resp.data.virtualNetworks == null && resp.data.publicIpAddresses == null &&
                        resp.data.disks == null && resp.data.networkInterfaces == null && resp.data.virtualMachines == null && resp.data.networkWatchers == null) {
                        emptyUserList(true);
                    }
                }
            })
            .catch(error => {
                setLoadingList(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setServerErrorListUser(true);
                    // console.log(error);
                }
            });
    };

    // Activate Subscription
    async function ActivateSubscription() {
        setConfirmLoadingFlag(true);

        await api.post(AZURE_CHANGE_STATUS + parseInt(selectedRowActivate.subscriptionId) + "/activate", null, config)
            .then(resp => {
                if (resp.status = 200) {
                    setActiveStatusModalFlag(false);
                    setConfirmLoadingFlag(false);

                    setToastMessage("Subscription activated successfully");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setSelectedRowActivate(null);
                    setActionCompletedRefreshSubscriptionTable(true);
                    setIsActionInProgress(false);
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                setConfirmLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setToastMessage("Subscription activation failed. Please try again.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 5000);
                }
                 setIsActionInProgress(true);
                 setIsAutoRefreshEnabled(false);
            })
    }

    // Activate Subscription
    async function DeactivateSubscription() {
        setConfirmLoadingFlag(true);

        await api.post(AZURE_CHANGE_STATUS + parseInt(selectedRowDeactivate.subscriptionId) + "/suspend", null, config)
            .then(resp => {
                if (resp.status = 200) {
                    setDeactiveStatusModalFlag(false);
                    setConfirmLoadingFlag(false);
                    setToastMessage("Subscription suspended successfully");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setSelectedRowDeactivate(null);
                    setActionCompletedRefreshSubscriptionTable(true);
                    setIsActionInProgress(false);
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                setConfirmLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setToastMessage("Subscription suspension failed. Please try again.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 5000);
                    setIsActionInProgress(true);
                    setIsAutoRefreshEnabled(false);
                }
            })
    }

    // Get User Roles
    async function GetUserRoles(subscriptionId, resourceGroupName) {
        setRoleManagementLoading(true);
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        (resourceGroupName === undefined || resourceGroupName === null) ? resourceGroupName = "" : resourceGroupName = resourceGroupName;
        await api.get(`${GET_AZURE_ROLES}?subscriptionId=${subscriptionId}&resourceGroupName=${resourceGroupName}`, config)
            .then(resp => {
                if (resp.status = 200) {
                    setRoleManagementLoading(false);
                    let emptyRoles = [];
                    resp.data.content.map((role) => {
                        if (role.roleName.length > 0) {
                            emptyRoles.push(true);
                        }
                        else {
                            emptyRoles.push(false);
                        }
                    })
                    if (emptyRoles.includes(true)) {
                        setRoleManagementData(resp.data.content);
                        setEmptyRoles(false);
                    }
                    else {
                        setEmptyRoles(true);
                    }
                    setEmptyUser(false);
                }
                else {
                    setRoleManagementLoading(false);
                    setEmptyUser(true);
                    setEmptyRoles(false);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                setRoleManagementLoading(false);
                setEmptyUser(false);
                setEmptyRoles(false);
            })
    }

    // Delete User
    async function DeleteUser(data) {
        setConfirmLoadingFlag(true);
        let body = {
            "subscriptionId": data.subscriptionId,
            "resourceGroupName": data.resourceGroupName,
            "roleDefinitionId": data.roleDefinitionId,
            "roleAssignmentId": data.roleAssignmentId,
            "roleName": data.roleName,
            "emailId": data.emailId
        }
        await api.post(DELETE_AZURE_ROLES, body, config)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmLoadingFlag(false);
                    setToastMessage("Role deleted successfully");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    GetUserRoles(data.subscriptionId, null);
                    setIsActionInProgress(false);
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                setConfirmLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setToastMessage("We encountered an issue while deleting the role. Please try again later.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 5000);
                }
                setIsActionInProgress(true);
                setIsAutoRefreshEnabled(false);
            })
    }

    // Refreshing Subscription Table on Action Completion
    useEffect(() => {
        if (actionCompletedRefreshSubscriptionTable) {
            GetMonthlyForex();          
            const actionedItemIndex = table.findIndex(item => item["Subscription ID"] == actionsSubscriptionId);
            const calculatedPageNo  = Math.floor(actionedItemIndex / pageSize) + 1;
            GetTableData(calculatedPageNo, pageSize, searchValueTable, status);
            GetResourceGroupData(searchValueResourceGroupTable);
            GetResourceListData(searchValueListTable)
            setActionCompletedRefreshSubscriptionTable(false);
        }
    }, [actionCompletedRefreshSubscriptionTable])

    // On search field value we trigger this function having react debugging after every 2000 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                setPageNo(1);
                GetTableData(1, pageSize, searchValueTable, status);
                setIsAutoRefreshEnabled(searchValueTable.length === 0);
            }, 2000);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // On search field in resource list value we trigger this function having react debugging after every 2000 ms
    useEffect(() => {
        if (searchValueListTable !== undefined && searchValueListTable !== null) {
            const getData = setTimeout(() => {
                GetResourceListData(searchValueListTable);
            }, 2000);
            return () => clearTimeout(getData);
        }
    }, [searchValueListTable])

    // On search field in resource group value we trigger this function having react debugging after every 2000 ms
    useEffect(() => {
        if (searchValueResourceGroupTable !== undefined && searchValueResourceGroupTable !== null) {
            const getData = setTimeout(() => {
                // GetResourceGroupData(searchValueResourceGroupTable);
                if (searchValueResourceGroupTable.length > 0) {
                    let dataLocal = [];
                    tableResourceGroup.map((data) => {
                        if ((data.Name.toLowerCase()).includes(searchValueResourceGroupTable.toLowerCase()) || (data.Location.toLowerCase()).includes(searchValueResourceGroupTable.toLowerCase())) {
                            dataLocal.push(data);
                        }
                    })
                    if (dataLocal.length > 0) {
                        setEmptyUserSearchResourceGroup(false);
                        setTableResourceGroup(dataLocal);
                    }
                    else {
                        setEmptyUserSearchResourceGroup(true);
                    }
                }
                else {
                    setEmptyUserSearchResourceGroup(false);
                    setTableResourceGroup(tableResourceGroupDuplicate);
                }

            }, 1000);
            return () => clearTimeout(getData);
        }
    }, [searchValueResourceGroupTable])

    useEffect(() => {
        if (selectedRowActivate) {
            setActiveStatusModalFlag(true);
        }
    }, [selectedRowActivate])

    useEffect(() => {
        if (selectedRowDeactivate) {
            setDeactiveStatusModalFlag(true);
        }
    }, [selectedRowDeactivate])

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, status);
    };

    useEffect(() => {
        GetAdditionalDetails();
        GetAllServices();
        GetAllCustomers();
    }, [customerId]);

    // use effects when service is provisioned
    useEffect(() => {
        if (initialSubscriptionTableCall) {
            GetMonthlyForex();
            if (filterApplied)
                GetTableData(pageNo, pageSize, searchValueTable, status, true);
            else
                GetTableData(pageNo, pageSize, searchValueTable, status, false);

            GetResourceGroupData(searchValueResourceGroupTable);
            GetResourceListData(searchValueListTable)
        }
    }, [initialSubscriptionTableCall]);

    // Renders when service ID Changes
    useEffect(() => {
        if (serviceId) {
            let url = "";
            let integrationCode = "";
            serviceData && serviceData.map((data) => {
                if (data.id == serviceId) {
                    integrationCode = data.integrationCode
                }
            })
            switch (integrationCode) {
                case "softlayer": // IBM Cloud
                    navigate("/management-console/ibm", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "microsoftazure": // Microsoft CSP - Azure
                    navigate("/management-console/azure", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "cloudcare": // Avast Cloud Care
                    navigate("/management-console/avast", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "acronis1": //  Acronis Cyber Cloud
                    navigate("/management-console/acronis", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "googleworkspace": // Google Workspace
                    navigate("/management-console/gws", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "GCP":  // Google Cloud Platform
                    navigate("/management-console/gcp", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                default: // Microsoft CSP
                    navigate("/management-console/csp", { state: { customerId: customerId, resellerId: resellerId } });
                    break;
            }
        }
    }, [serviceId]);

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    //Scroll to top on opening of page
    useEffect(() => {
        window.scrollTo(0, 0);
        GetAllStatuses();
    }, [])

    useEffect(() => {
        // console.log("statusRefreshChecker", statusRefreshChecker);
        if (statusRefreshChecker.includes("PENDING")) {
            setTimeout(() => {
                GetTableData(pageNo, pageSize, searchValueTable, status);
            }, 5000);
        }
    }, [statusRefreshChecker])

    useEffect(() => {
        if (deleteRow) {
            setDeleteConfirmationPopup(true);
        }
    }, [deleteRow])

    return (
        <div className='main-content'>
            <div className='container-fluid'>
                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
                <Popup
                    className="custom-modal custom-modal-xl"
                    open={deactiveStatusModalFlag}
                    onClose={() => { setDeactiveStatusModalFlag(false); setSelectedRowDeactivate(null); }}
                    closeOnDocumentClick={!confirmLoadingFlag}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">

                            <div className="header">
                                <h2>Order Confirmation - Suspend Subscription</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                                />
                            </div>
                            <Container fluid>
                                <Row className="pb-3 pt-5">
                                    <Col lg={8}>
                                        <h3 className="mb-3">Customer Name: <span className="text-regular">{additionalDetails && additionalDetails.customerName}</span></h3>
                                    </Col>
                                    <Col lg={4}>
                                        <h3 className="mb-3">Cloud Service: <span className="text-regular">Microsoft CSP - Azure</span></h3>
                                    </Col>
                                </Row>
                                <Row>
                                    {selectedRowDeactivate &&
                                        <Table className={`${styles['gws-trial-to-paid-core-table']}`} responsive="md">
                                            <thead className="sticky-top">
                                                <tr>
                                                    {changeStatusConfirmationColumnName.map(th => {
                                                        return (<th>{th}</th>)
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedRowDeactivate &&
                                                    <tr>
                                                        <td>{selectedRowDeactivate.planName}</td>
                                                        <td>{selectedRowDeactivate.planId}</td>
                                                        <td>{selectedRowDeactivate.vendorSubscriptionId}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                </Row>
                                <Row className="py-3">
                                    <Col>
                                        <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="w-auto d-inline ml-3"
                                            id="inputPONumber"
                                            placeholder='Add PO Number'
                                            aria-describedby="inputPONumber"
                                            value={poNumber}
                                            maxlength="50"
                                            onChange={(e) => {
                                                alphanumericRegex.test(e.target.value)
                                                    ? setPoNumber(e.target.value)
                                                    : console.log("")
                                            }}
                                        />
                                        <Form.Text id="poNumber" />
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-4">
                                    <Col md={6}>
                                        <Form.Check // prettier-ignore
                                            type="checkbox"
                                            id="termsCheckBox"
                                            label=""
                                            disabled
                                            checked={isTermsChecked}
                                            className="d-inline-block w-auto"
                                        />
                                        <Form.Label className="d-inline-block  w-auto mb-0">
                                            I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                        </Form.Label>
                                    </Col>
                                    <Col md={6}>
                                        <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                            {!confirmLoadingFlag && isTermsChecked && <>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { close() }}>Cancel</Button>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => DeactivateSubscription()}>
                                                    Confirm
                                                </Button>
                                            </>
                                            }
                                            {!confirmLoadingFlag && !isTermsChecked &&
                                                <>
                                                    <Button
                                                        className="px-4 mx-2"
                                                        onClick={() => { setDeactiveStatusModalFlag(false); }}>Cancel
                                                    </Button>
                                                    <Button
                                                        variant="light"
                                                        className="px-4 mx-2"
                                                        disabled
                                                    >
                                                        Confirm
                                                    </Button>
                                                </>
                                            }
                                            {confirmLoadingFlag &&
                                                <>
                                                    <Button
                                                        variant="light"
                                                        disabled
                                                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                    >Cancel
                                                    </Button>
                                                    <Button
                                                        disabled
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                    >
                                                        Processing . . .
                                                    </Button>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )}
                </Popup>
                <Popup
                    className="custom-modal custom-modal-xl"
                    open={activeStatusModalFlag}
                    onClose={() => { setActiveStatusModalFlag(false); setSelectedRowActivate(null); }}
                    closeOnDocumentClick={!confirmLoadingFlag}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">

                            <div className="header">
                                <h2>Order Confirmation - Activate Subscription</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                                />
                            </div>
                            <Container fluid>
                                <Row className="pb-3 pt-5">
                                    <Col lg={8}>
                                        <h3 className="mb-3">Customer Name: <span className="text-regular">{additionalDetails && additionalDetails.customerName}</span></h3>
                                    </Col>
                                    <Col lg={4}>
                                        <h3 className="mb-3">Cloud Service: <span className="text-regular">Microsoft CSP - Azure</span></h3>
                                    </Col>
                                </Row>
                                <Row>
                                    {selectedRowActivate &&
                                        <Table className={`${styles['gws-trial-to-paid-core-table']}`} responsive="md">
                                            <thead className="sticky-top">
                                                <tr>
                                                    {changeStatusConfirmationColumnName.map(th => {
                                                        return (<th>{th}</th>)
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedRowActivate &&
                                                    <tr>
                                                        <td>{selectedRowActivate.planName}</td>
                                                        <td>{selectedRowActivate.planId}</td>
                                                        <td>{selectedRowActivate.vendorSubscriptionId}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                </Row>
                                <Row className="py-3">
                                    <Col>
                                        <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="w-auto d-inline ml-3"
                                            id="inputPONumber"
                                            placeholder='Add PO Number'
                                            aria-describedby="inputPONumber"
                                            value={poNumber}
                                            maxlength="25"
                                            onChange={(e) => {
                                                alphanumericRegex.test(e.target.value)
                                                    ? setPoNumber(e.target.value)
                                                    : console.log("")
                                            }}
                                        />
                                        <Form.Text id="poNumber" />

                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-4">
                                    <Col md={6}>
                                        <Form.Check // prettier-ignore
                                            type="checkbox"
                                            id="termsCheckBox"
                                            label=""
                                            disabled
                                            checked={isTermsChecked}
                                            className="d-inline-block w-auto"
                                        />
                                        <Form.Label className="d-inline-block  w-auto mb-0">
                                            I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                        </Form.Label>
                                    </Col>
                                    <Col md={6}>
                                        <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                            {!confirmLoadingFlag && isTermsChecked && <>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { close(); }}>Cancel</Button>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => ActivateSubscription()}>
                                                    Confirm
                                                </Button>
                                            </>
                                            }
                                            {!confirmLoadingFlag && !isTermsChecked &&
                                                <>
                                                    <Button
                                                        className="px-4 mx-2"
                                                        onClick={() => { setActiveStatusModalFlag(false); }}>Cancel
                                                    </Button>
                                                    <Button
                                                        variant="light"
                                                        className="px-4 mx-2"
                                                        disabled
                                                    >
                                                        Confirm
                                                    </Button>
                                                </>
                                            }
                                            {confirmLoadingFlag &&
                                                <>
                                                    <Button
                                                        variant="light"
                                                        disabled
                                                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                    >Cancel
                                                    </Button>
                                                    <Button
                                                        disabled
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                    >
                                                        Processing . . .
                                                    </Button>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )}
                </Popup>
                <Popup
                    open={modalOpen}
                    onClose={() => { setModalOpen(false); setTabName("Cloud Portal Details"); }}
                    className="custom-modal custom-modal-xxl"
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header">
                                <h2>Cloud Portal Details</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={close}
                                />
                            </div>
                            <div className='mx-4 my-3'>
                                <Tab.Container activeKey={tabName}>
                                    <Row>
                                        <Col sm={12}>
                                            <Nav variant="pills">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Cloud Portal Details" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3'
                                                        onClick={() => { setTabName("Cloud Portal Details") }}
                                                    >
                                                        Cloud Portal Details
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="GDAP Relationships" className='addons-core-gws-manage pl-6 pr-6 pt-3 pb-3'
                                                        onClick={() => { setTabName("GDAP Relationships") }}
                                                    >
                                                        GDAP Relationships
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {!isreadOnly && <Nav.Item>
                                                    <Nav.Link eventKey="Create GDAP" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3 d-none-mobile'
                                                        onClick={() => { setTabName("Create GDAP") }}
                                                    >
                                                        Create GDAP
                                                    </Nav.Link>
                                                </Nav.Item>}
                                            </Nav>
                                        </Col>
                                        <Col lg={12}>
                                            <Tab.Content>
                                                {/* Cloud Portal Details */}
                                                <Tab.Pane eventKey="Cloud Portal Details">
                                                    <div className={`mt-4 mx-1 ${styles['gdap-height']}`}>
                                                        {additionalDetails && <Row>
                                                            <Col md={12} className="mb-2">
                                                                <label className="text-medium">Cloud Service</label>
                                                                <p><strong>Microsoft CSP - Azure</strong></p>
                                                            </Col>
                                                            <Col md={12} className="mb-2">
                                                                <label className="text-medium">Customer</label>
                                                                <p><strong>{additionalDetails.customerName}</strong></p>
                                                            </Col>
                                                            <Col md={12} className="mb-2">
                                                                <label className="text-medium">User Name</label>
                                                                <p><strong>{additionalDetails.userName}</strong></p>
                                                            </Col>
                                                            <Col md={12} className="mb-2">
                                                                <label className="text-medium">Password</label>
                                                                <p><strong>{additionalDetails.password}</strong></p>
                                                            </Col>
                                                            <Col md={12} className="mb-2">
                                                                <label className="text-medium">Start Date</label>
                                                                <p><strong>{additionalDetails.startDate}</strong></p>
                                                            </Col>
                                                            <Col md={12} className="mb-2">
                                                                <label className="text-medium">Portal</label>
                                                                <p className='service-management-acronis-portal-link'>
                                                                    <a href={additionalDetails.serviceUrl} target="_blank">
                                                                        {additionalDetails.serviceUrl}<span className='external-link cpointer ml-2'></span>
                                                                    </a>
                                                                </p>
                                                            </Col>
                                                            {/* <Col md={12} className="mb-2">
                                                                <label className="text-medium">User Name</label>
                                                                <p><strong>{additionalDetails.userName}</strong></p>
                                                            </Col> */}
                                                        </Row>}
                                                    </div>
                                                </Tab.Pane>
                                                {/* GDAP Relationships */}
                                                <Tab.Pane eventKey="GDAP Relationships">
                                                    <GDAPRelationship vendorAccountId={additionalDetails?.vendorAccountId} tabName={tabName} Logout={props.Logout} />
                                                </Tab.Pane>

                                                {/* Create GDAP Tab */}
                                                <Tab.Pane eventKey="Create GDAP">
                                                    <CreateGDAP vendorAccountId={additionalDetails?.vendorAccountId} toastError={toastError} toastMessage={toastMessage} Logout={props.Logout}
                                                        toastSuccess={toastSuccess} setToastError={setToastError} setToastSuccess={setToastSuccess} setToastMessage={setToastMessage} setTabName={setTabName} />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container></div>

                        </div>
                    )}
                </Popup>
                <Popup
                    className="custom-modal custom-modal-xl"
                    open={roleManageModalFlag}
                    onClose={() => { setRoleManageModalFlag(false); setSelectedRowActivate(null); setRoleManagementData(null); }}
                    closeOnDocumentClick={!confirmLoadingFlag}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">

                            <div className="header">
                                <h2>Role Management</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                                />
                            </div>
                            <Container fluid>
                                <Row className="pb-3 pt-5">
                                    <Col lg={6}>
                                        <h3 className="mb-3">Plan Name: <span className="text-regular">{selectedRow && selectedRow.planName}</span></h3>
                                    </Col>
                                    <Col lg={6}>
                                        <h3 className="mb-3">Subscription ID: <span className="text-regular">{selectedRow && selectedRow.vendorSubscriptionId}</span></h3>
                                    </Col>
                                </Row>
                                <Row>
                                    {roleManagementData &&
                                        <Table className={`${styles['gws-trial-to-paid-core-table']}`} responsive="md">
                                            <thead className="sticky-top">
                                                <tr>
                                                    {roleManagementColumnName.map(th => {
                                                        return (<th>{th}</th>)
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {roleManagementData.map((roleItem) => {
                                                    return (<tr>
                                                        <td>{roleItem.emailId}</td>
                                                        <td>{roleItem.roleName}</td>
                                                        <td>{roleItem.roleName !== "Owner (Default User)" && <span className="trash cpointer" onClick={() => { if (!confirmLoadingFlag) { setDeleteRow(roleItem) } }}>&nbsp;</span>}</td>
                                                    </tr>)
                                                })
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    {
                                        !roleManagementData && emptyUsers &&
                                        <div className={`${styles["role-management-no-data-customers"]} mt-5 mb-5`}>
                                            <img src={NoCustomer} className="empty-customers-table-svg" />
                                            <h2 className="mt-4 mb-3">No Users Found</h2>
                                            <p className="mb-3">Please <a className='cpointer'>click here</a> to add users.</p>
                                        </div>
                                    }
                                    {
                                        !roleManagementData && emptyRoles &&
                                        <div className={`${styles["role-management-no-data-customers"]} mt-5 mb-5`}>
                                            <img src={NoCustomer} className="empty-customers-table-svg" />
                                            <h2 className="mt-4 mb-3">No roles assigned to the user</h2>
                                            <p className="mb-3">Please <a className='cpointer'>click here</a> to assign role to the user.</p>
                                        </div>
                                    }
                                    {roleManagementLoading &&
                                        <div className={`${styles["role-management-no-data-customers"]} mt-5 mb-5`}>
                                            <img className="management-console-loading-icon" src={loadingGif} />
                                            <p className="text-medium text-strong management-console-loading-text">Loading Users...</p>
                                        </div>
                                    }
                                </Row>
                                <Row className='mb-5 mt-2'>
                                    <Col md={10}>
                                        {(roleManagementData && !emptyUsers && !emptyRoles) && <>
                                            <p className='mb-1'><a href="https://docs.microsoft.com/en-us/azure/role-based-access-control/role-assignments-portal" target='_blank'>Modify azure role assignments</a></p>
                                            <p className='mb-1'><a href="https://docs.microsoft.com/en-us/azure/role-based-access-control/quickstart-assign-role-user-portal" target='_blank'>Grant a user access to azure resources</a></p>
                                        </>}
                                    </Col>
                                    <Col md={2} className=' d-flex align-items-center justify-content-center'>
                                        <Button
                                            className="px-4 mx-2"
                                            onClick={() => { close(); }}>Cancel
                                        </Button>
                                    </Col>
                                </Row>

                            </Container>
                        </div>
                    )}
                </Popup>
                <Popup
                    className="custom-modal custom-modal-sm"
                    open={deleteConfirmationPopup}
                    onClose={() => { setDeleteConfirmationPopup(false); setDeleteRow(null); }}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header p-4 py-3">
                                <h2 className="mb-0 px-3">Delete Roles</h2>
                                <XLg
                                    size={18}
                                    className="cpointer text-strong close-icon-popup-sm"
                                    onClick={close}
                                    color="#6A6A6A"
                                />
                            </div>
                            <Container className="p-5">
                                <p className='pt-0 px-3 pb-5 mb-0'>
                                    <strong>Are you sure you want to delete the status {deleteRow && deleteRow.role} role of the user ?</strong>
                                </p>
                                <div className="actions">
                                    <div className="d-flex justify-content-center pb-3 pt-2">
                                        <Button
                                            className="btn btn-lg mx-3 btn-border-light"
                                            variant="light"
                                            onClick={() => {
                                                close();

                                            }}
                                        >
                                            No
                                        </Button>
                                        <Button
                                            className="btn btn-md"
                                            onClick={() => {
                                                DeleteUser(deleteRow);
                                                close();
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    )}
                </Popup>
                <Row>
                    <Col md={6} className="justify-content-between d-flex align-items-center">
                        <label className="cpointer" onClick={() => navigate('/management-console' , { state: { resellerId: resellerId } })}>
                            <span className="back">&nbsp;</span>&nbsp;<strong><span className={`${styles['service-management-heading']}`}>
                                Azure - Service Management</span></strong>
                        </label>
                    </Col>
                    <Col md={6} className="text-right service-logo">
                        <img src={serviceLogo} />
                    </Col>
                </Row>
                <div className='service-management-azure-header mt-5'>
                    <Row className='mb-3'>

                        <Col md={3} className='mb-3'>
                            <SearchDropdown
                                dropdownLabel="Cloud Service"
                                formGroupId="serviceNameId"
                                placeholder="Enter Service Name"
                                selectDefaultValue={serviceNameDefaultValue}
                                setSelectDefaultValue={setServiceNameDefaultValue}
                                selectOptions={serviceData}
                                selectOptionsFiltered={serviceNameFiltered}
                                setSelectOptionsFiltered={setServiceNameFiltered}
                                selectSearchValue={serviceNameSearchValue}
                                setSelectSearchValue={setServiceNameSearchValue}
                                updatedOptions={updatedServiceName}
                                setOptionId={setServiceId}
                                setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                            />
                        </Col>

                        <Col md={3} className='mb-3'>
                            <SearchDropdown
                                dropdownLabel="Customer Name"
                                formGroupId="customerNameId"
                                placeholder="Enter Customer Name"
                                selectDefaultValue={customerNameDefaultValue}
                                setSelectDefaultValue={setCustomerNameDefaultValue}
                                setOptionId={setCustomerId}
                                selectOptions={customerData}
                                selectOptionsFiltered={customerNameFiltered}
                                setSelectOptionsFiltered={setCustomerNameFiltered}
                                selectSearchValue={customerNameSearchValue}
                                setSelectSearchValue={setCustomerNameSearchValue}
                                updatedOptions={updatedCustomerName}
                                setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                            />
                        </Col>


                        <Col md={6} className='text-right'>
                            <div class="d-flex justify-content-between justify-content-md-end align-items-center h-100">
                                <label class="pt-0 label-sm mr-3">Subscriptions
                                    <span class="circle-bg label-sm">
                                        <strong class="label-sm">
                                            {additionalDetails ? additionalDetails.subscriptionCount : 0}
                                        </strong>
                                    </span>
                                </label>

                                {initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="light" onClick={() => setModalOpen(true)}>Cloud Portal Details</Button>}
                                {!initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="secondary" disabled >Cloud Portal Details</Button>}
                            </div>
                        </Col>
                    </Row>
                    {initialSubscriptionTableCall ? (
                        <div>
                            <p>Forex Conversion Rate (USD to AUD):&nbsp;
                                <span className='text-primary'>
                                    {monthlyForex && monthlyForex.forexMonthly}
                                </span>
                            </p>
                        </div>
                    ) : null}
                </div>



                <div className="service-management-azure-body user-management-console-main-div mb-5">
                    <div className="mgmt-console-table-row">
                        <div className="my-3 d-flex">
                            <Col className={`p-0 ${styles['user-management-table']}`}>
                                <div className={`${styles['user-management-table-inner']} ${styles['table-details']} details-box`}>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                            <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Subscriptions {!emptyUser && !emptyUserFilters && !emptyUserSearch && !loading && <span className='text-muted pl-1 text-large'>({totalRows})</span>} 
                                            {/* {!loading && !provisionRefreshIconFlag && <span className='refresh ml-2' onClick={() => GetTableData(pageNo, pageSize, searchValueTable, status)}></span>} */}
                                            </h5>
                                        </Col>
                                       {((!resellerId || resellerId?.length==0) && !isCloudAdmin) && <Col className="my-1 d-md-none d-block text-right mr-1">
                                            <Button onClick={() => navigate("/cloud-provision", {
                                                state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                            })}>Buy Subscription</Button>
                                        </Col>}
                                        {/* Filter and Search Box for Desktop and Tablet */}
                                        <Col className="d-none d-md-block">
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                {!serverErrorUser && !emptyUser && initialSubscriptionTableCall &&
                                                    <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative pr-4">
                                                        {!loading && !searchIconFlag &&
                                                            <span className="mx-3">&nbsp;</span>
                                                        }
                                                        {!loading && !searchIconFlag && !emptyUserFilters &&
                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                        }
                                                        {!loading && searchIconFlag && !emptyUserFilters &&
                                                            <InputGroup className="search-input-box">
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                    <span className="searchgrey cpointer"
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    placeholder="Search Plan Name"
                                                                    aria-label="Search Plan Name"
                                                                    aria-describedby="basic-addon2"
                                                                    className="search-input-box-input"
                                                                    value={searchValueTable}
                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                    ref={searchInputRef}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                    onClick={() => {
                                                                        setSearchValueTable("");
                                                                        setSearchIconFlag(!searchIconFlag);
                                                                    }}>
                                                                    <span className="closebtn">
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        }
                                                        {!loading && !filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                        }}></span>}
                                                        {!loading && filterFlag && !emptyUserSearch &&
                                                            <span className="filter-black-active cpointer" 
                                                            onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                            if (!showFilterBox) {
                                                            setIsAutoRefreshEnabled(false);
                                                            } else {
                                                            // Only re-enable auto-refresh if no filters are active
                                                            if (!isFilterOrSearchActive) {
                                                            setIsAutoRefreshEnabled(true);
                                                            }
                                                            }
                                                            }}
                                                            ></span>}
                                                        {((!resellerId || resellerId?.length==0) && !isCloudAdmin) && <Button className="ml-3 px-4" onClick={() => navigate("/cloud-provision", {
                                                            state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                        })}>Buy Subscription</Button>}
                                                    </Col>}
                                            </Row>
                                        </Col>

                                        {/* Filter and Search Box for Mobile */}

                                        {!serverErrorUser && !emptyUser && initialSubscriptionTableCall &&
                                            <Col className="d-md-none d-block mt-2 px-3" md={12}>
                                                <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                    <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                        {!loading &&
                                                            <InputGroup className="search-input-box">
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                    <span className="searchgrey cpointer"
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    placeholder="Search Plan ID"
                                                                    aria-label="Search Plan ID"
                                                                    aria-describedby="basic-addon2"
                                                                    className="search-input-box-input"
                                                                    value={searchValueTable}
                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                    ref={searchInputRef}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                    onClick={() => {
                                                                        setSearchValueTable("");
                                                                        setSearchIconFlag(!searchIconFlag);
                                                                    }}>
                                                                    <span className="closebtn">
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        }

                                                        {!loading && !filterFlag && !emptyUserSearch &&
                                                            <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                setShowFilterBox(!showFilterBox);
                                                            }}></span>}

                                                        {!loading && filterFlag && !emptyUserSearch &&
                                                            <span className="filter-black-active cpointer" 
                                                            onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                            if (!showFilterBox) {
                                                            setIsAutoRefreshEnabled(false);
                                                            } else {
                                                            // Only re-enable auto-refresh if no filters are active
                                                            if (!isFilterOrSearchActive) {
                                                            setIsAutoRefreshEnabled(true);
                                                            }
                                                            }
                                                            }}
                                                            ></span>}
                                                    </Col>
                                                </Row>
                                            </Col>}
                                    </div>
                                    {
                                        !emptyUserSearch && showFilterBox &&
                                        <AzureFilterBox GetTableData={GetTableData} pageNo={pageNo} setPageNo={setPageNo} setEmptyUserFilters={setEmptyUserFilters}
                                            pageSize={pageSize} searchValueTable={searchValueTable} setShowFilterBox={setShowFilterBox}
                                            setFilterFlag={setFilterFlag} status={status} setStatus={setStatus} statusData={statusData}
                                            setFilterApplied={setFilterApplied} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled} clearExistingData={clearExistingData}
                                        />
                                    }

                                    <Container fluid className="mb-5 p-0">
                                        <Row>
                                            <Col>
                                                <div className={`table-responsive ${styles['azure-service-management-table']}`}>
                                                    {!loading && serverErrorUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-management-table-svg" />
                                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                        </div>
                                                    }
                                                    {!loading && emptyUserSearch && !emptyUserFilters && initialSubscriptionTableCall && !emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Matching Subscriptions Found.</h2>
                                                        </div>
                                                    }
                                                    {!initialSubscriptionTableCall && initialSubscriptionTableCall != null && !emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyServiceProvision} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">Selected Cloud Service is not provisioned</h2>
                                                            <p className="mb-3">No subscriptions are currently available for this cloud service.</p>
                                                            {((!resellerId || resellerId?.length==0) && !isCloudAdmin) && <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                                                navigate("/cloud-provision", {
                                                                    state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                                })
                                                            }}>Provision Cloud Service</Button>}
                                                        </div>
                                                    }
                                                    {!loading && emptyUserFilters && initialSubscriptionTableCall && !emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Data Found.</h2>
                                                        </div>
                                                    }
                                                    {!loading && emptyUser &&
                                                        <div className={styles["no-data-user"]}>
                                                            <img src={EmptyServiceSubscription} className="empty-customers-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Subscriptions in this Cloud Service.</h2>
                                                           {((!resellerId || resellerId?.length==0) && !isCloudAdmin) && <>
                                                            <p className="mb-3">Please click on Buy Subscriptions to Add Subscriptions</p>
                                                            <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                                                navigate("/cloud-provision", {
                                                                    state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                                })
                                                            }}>Buy Subscription</Button>
                                                            </>}
                                                        </div>
                                                    }
                                                    {
                                                        loading &&
                                                        <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading subscription...</p></div>
                                                    }
                                                    {!serverErrorUser && !emptyUser && !emptyUserFilters && !emptyUserSearch && initialSubscriptionTableCall && !loading &&
                                                        <DataTable
                                                            columns={columns}
                                                            data={table}
                                                            theme="solarized"
                                                            customStyles={customStyles}
                                                            persistTableHead={false}
                                                            overflowY={true}
                                                            noDataComponent={loading && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading subscription...</p></div>}
                                                            width="100%"
                                                            fixedHeaderScrollHeight="60vh"
                                                            // progressPending={loading}
                                                            progressComponent={<div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading subscription...</p></div>}
                                                            fixedHeader
                                                            // pagination
                                                            // paginationServer
                                                            // paginationTotalRows={totalRows}
                                                            // paginationPerPage={pageSize}
                                                            // paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                            onChangePage={(pageNo) => handlePageChange(pageNo, totalRows)}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                        />}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
                {!serverErrorUser && <div className={`my-0 table-heading align-items-center row`}>
                    <Container fluid className="mb-5 p-0">
                        <Row>
                            <Col>
                                <div className={`table-responsive ${styles['resourceList-resourceGroup-table']}`}>
                                    {/* Resource Group */}
                                    <div className="my-0 align-items-center details-box">
                                        <Row>
                                            <Col>
                                                {initialSubscriptionTableCall && !(!loadingResourceGroup && emptyUserResourceGroup && !loadingList && !emptyUserList) && <h2 className="py-3 px-4 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Resource Group</h2>}
                                            </Col>

                                            {
                                                <Col className="align-items-center col d-flex justify-content-end justify-items-center position-relative">
                                                    {!serverErrorUserResourceGroup && !emptyUserResourceGroup && initialSubscriptionTableCall && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                        {!loadingResourceGroup && !searchIconResourceGroupFlag &&
                                                            <span className="mx-3">&nbsp;</span>
                                                        }
                                                        {!loadingResourceGroup && !searchIconResourceGroupFlag &&
                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconResourceGroupFlag(!searchIconResourceGroupFlag); }}>&nbsp;</span>
                                                        }
                                                        {!loadingResourceGroup && searchIconResourceGroupFlag &&
                                                            <InputGroup className={`search-input-box ${styles['search-input-box-azure']}`}>
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                    <span className="searchgrey cpointer"
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    placeholder="Search for Name/Location"
                                                                    aria-label="Search for Name/Location"
                                                                    aria-describedby="basic-addon2"
                                                                    className={`search-input-box ${styles['search-input-box-azure']}`}
                                                                    value={searchValueResourceGroupTable}
                                                                    onChange={(e) => setSearchValueResourceGroupTable(e.target.value)}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                    onClick={() => {
                                                                        setSearchValueResourceGroupTable("");
                                                                        setSearchIconResourceGroupFlag(!searchIconResourceGroupFlag);
                                                                    }}>
                                                                    <span className={`closebtn ${styles['resoure-group-close-btn']}`}>
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        }
                                                    </Col>}
                                                </Col>}
                                        </Row>
                                        {loadingResourceGroup && <div className={styles["loading-resourcegroup"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource Group...</p></div>}
                                        {!loadingResourceGroup && serverErrorUserResourceGroup &&
                                            <div className={styles["no-data-ibm-error-line"]}>
                                                <p className="mb-3 mx-6 mt-4">Facing issues in reaching server, Try again later!</p>
                                            </div>
                                        }
                                        {!loadingResourceGroup && emptyUserSearchResourceGroup &&
                                            <div className={styles["no-data-ibm"]}>
                                                <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                <h2 className="mb-3">No Matching Resource Group Found.</h2>
                                            </div>
                                        }
                                        {!loadingResourceGroup && emptyUserResourceGroup &&
                                            <div className={styles["no-data-ibm-error-line"]}>
                                                <p className="mb-3 mx-6 mt-4">No Resource Group Found.</p>
                                            </div>
                                        }
                                        {tableResourceGroup.length > 0 && !loadingResourceGroup &&
                                            <div>
                                                {!serverErrorUserResourceGroup && !emptyUserResourceGroup && !emptyUserSearchResourceGroup && initialSubscriptionTableCall &&
                                                    <DataTable
                                                        columns={columnsResourceGroup}
                                                        data={tableResourceGroup}
                                                        theme="solarized"
                                                        customStyles={customStyles}
                                                        persistTableHead={false}
                                                        noDataComponent={loadingResourceGroup && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource Group...</p></div>}
                                                        width="100%"
                                                        fixedHeaderScrollHeight="60vh"
                                                        fixedHeader

                                                    />}
                                            </div>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>}
                {!serverErrorUser && <div>
                    <div className={`my-0 mt-6 table-heading align-items-center row`}>
                        {<Col className="px-1">
                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                {initialSubscriptionTableCall &&
                                    <Col className={`pl-1 ${searchIconListFlag ? "hide-in-mobile" : ""}`}>
                                        {!(!loadingResourceGroup && emptyUserResourceGroup && !loadingList && !emptyUserList) && <h5 className="px-4 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Resource List</h5>}
                                    </Col>}
                                {!serverErrorListUser && !emptyUser && initialSubscriptionTableCall && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                    {!loadingList && !searchIconListFlag && emptyUserList &&
                                        <span className="mx-3">&nbsp;</span>
                                    }
                                    {/* {!loadingList && !searchIconListFlag && !emptyUserList &&
                                        <span className="search mx-3 cpointer" onClick={() => { setSearchIconListFlag(!searchIconListFlag); }}>&nbsp;</span>
                                    } */}
                                    {/* {!loadingList && searchIconListFlag &&
                                        <InputGroup className={`search-input-box ${styles['search-input-box-azure']}`}>
                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                <span className="searchgrey cpointer"
                                                >
                                                    &nbsp;
                                                </span>
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder="Search for Name/Location"
                                                aria-label="Search for Name/Location"
                                                aria-describedby="basic-addon2"
                                                className={`search-input-box ${styles['search-input-box-azure']}`}
                                                value={searchValueListTable}
                                                onChange={(e) => setSearchValueListTable(e.target.value)}
                                            />
                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                onClick={() => {
                                                    setSearchValueListTable("");
                                                    setSearchIconListFlag(!searchIconListFlag);
                                                }}>
                                                <span className="closebtn">
                                                    &nbsp;
                                                </span>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    } */}
                                </Col>}
                            </Row>
                        </Col>}
                    </div>
                    <Container fluid className="mb-5 p-0">
                        <Row>
                            <Col>
                                <div className={`table-responsive ${styles['resourceList-resourceGroup-table']}`}>
                                    {loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                    {!loadingList && serverErrorListUser &&
                                        <div className={styles["no-data-ibm-error-line"]}>
                                            <p className="mt-6 mb-3 mx-6">Facing issues in reaching server, Try again later!</p>
                                        </div>
                                    }
                                    {!loadingList && emptyUserListSearch &&
                                        <div className={styles["no-data-ibm"]}>
                                            <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                            <h2 className="mt-4 mb-3">No Matching Resource List Found.</h2>
                                        </div>
                                    }
                                    {!loadingList && emptyUserList &&
                                        <div className={styles["no-data-ibm-error-line"]}>
                                            <p className="mt-6 mb-3 mx-6">No Resource List Found.</p>
                                        </div>
                                    }

                                    {/* first */}
                                    {tableVirtualMachines.length > 0 && !loadingList &&
                                        <div className="my-0 align-items-center details-box">
                                            <Col>
                                                <h2 className="py-3 px-4 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Virtual Machines</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsVirtualMachines}
                                                    data={tableVirtualMachines}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}
                                    {/* second */}
                                    {tableNetworkInterfaces.length > 0 && !loadingList &&
                                        <div className="my-2 align-items-center details-box">
                                            <Col>
                                                <h2 className="py-3 px-4 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Network Interfaces</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsNetworkInterfaces}
                                                    data={tableNetworkInterfaces}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader
                                                />}
                                        </div>}
                                    {/* third */}
                                    {tableDisks.length > 0 && !loadingList &&
                                        <div className="my-2 align-items-center details-box">
                                            <Col>
                                                <h2 className="py-3 px-4 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Disks</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsDisks}
                                                    data={tableDisks}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}
                                    {/* fourth */}
                                    {tablePublicIpAddresses.length > 0 && !loadingList &&
                                        <div className="my-2 align-items-center details-box">
                                            <Col>
                                                <h2 className="py-3 px-4 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Public IP Addresses</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsPublicIpAddresses}
                                                    data={tablePublicIpAddresses}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader

                                                />}
                                        </div>}
                                    {/* fifth */}
                                    {tableVirtualNetworks.length > 0 && !loadingList &&
                                        <div className="my-2 align-items-center details-box">
                                            <Col>
                                                <h2 className="py-3 px-4 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Virtual Networks</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsVirtualNetworks}
                                                    data={tableVirtualNetworks}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader
                                                />}
                                        </div>}
                                    {/* sixth */}
                                    {tableNetworkWatchers.length > 0 && !loadingList &&
                                        <div className="my-2 align-items-center details-box">
                                            <Col>
                                                <h2 className="py-3 px-4 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Network Watchers</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsNetworkWatchers}
                                                    data={tableNetworkWatchers}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader
                                                />}
                                        </div>}
                                    {/* seventh: */}
                                    {tableStorageAccounts.length > 0 && !loadingList &&
                                        <div className="my-2 align-items-center details-box">
                                            <Col>
                                                <h2 className="py-3 px-4 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">Storage Accounts</h2>
                                            </Col>
                                            {!serverErrorListUser && !emptyUserList && !emptyUserListSearch && initialSubscriptionTableCall &&
                                                <DataTable
                                                    columns={columnsStorageAccounts}
                                                    data={tableStorageAccounts}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    persistTableHead={false}
                                                    noDataComponent={loadingList && <div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Resource List...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    fixedHeader
                                                />}
                                        </div>}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>}
                <div className='my-4'></div>
            </div>
        </div>
    )
}
export default AzureServiceManagement