import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Accordion, AccordionContext, Button, Card, Col, Container, useAccordionButton, Row } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './GWSRenewalSettings.module.scss';
import DataTable from 'react-data-table-component';
import { api } from '../../../../../../../../Utils/API';
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif"
import { GOOGLE_WORKSPACE_RENEWAL_SETTINGS_GET, GOOGLE_WORKSPACE_RENEWAL_SETTINGS_POST } from '../../../../../../../../Utils/GlobalConstants';
import Table from 'react-bootstrap/Table';
import EmptyDataTableFilterSearch from '../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'


const GWSRenewalSettings = ({ subscriptionTableAPIData, subscriptionId, renewalSettingsActionPopupModalOpen, setRenewalSettingsActionActionPopupModalOpen,
    columnsSubscriptionTable, tableSubscriptionTable, setToastSuccess, setToastError, setToastMessage, setSubscriptionTableAPIData,Logout,
setIsActionInProgress,
    setIsAutoRefreshEnabled  }) => {
    const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false) // Subscription is Loading Popup
    const [loading, setLoading] = useState(false);
    const [defaultRenewalSettings, setDefaultRenewalSettings] = useState();
    const [renewalSettingsSelectedData, setRenewalSettingsSelectedData] = useState();
    const [confirmRenewalSettingsFlag, setConfirmRenewalSettingsFlag] = useState(false);
    const [confirmRenewalSettingsLoadingFlag, setConfirmRenewalSettingsLoadingFlag] = useState(false);
    const [emptyDataError, setEmptyDataError] = useState(false);                                                                  // to check empty data error-204
    const [serverError, setServerError] = useState(false);                                                                        // to check server error-400


    let DowngradeCoreColumnName = ["", "Renewal Plan", "Description"];
    const [renewalSettingsPlans, setRenewalSettingsPlans] = useState();
    //Stepper
    const steps = ['Select Plan', 'Select SKU', 'Add Quantity'];

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };
    //DOWNGRADE Core Action Tab
    //downgrade core step 1
    async function GetRenewaSettingsListing(subscriptionId) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        await api.get(GOOGLE_WORKSPACE_RENEWAL_SETTINGS_GET, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyDataError(true);
                }
                // handle success
                else {
                    setEmptyDataError(false);
                    setServerError(false);
                    setRenewalSettingsPlans(resp.data);
                }
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    //post api for upgrade confirmation
    async function GwsRenewalSettingsPost() {
        setConfirmRenewalSettingsLoadingFlag(true);
        const data = {
            "subscriptionId": parseInt(subscriptionId),
            "quantity": parseInt(subscriptionTableAPIData.quantity),
            "autoRenewalName": renewalSettingsSelectedData.id,
            "autoRenewalDescription": renewalSettingsSelectedData.name,
            "planName": subscriptionTableAPIData.planName,
            "offerName": subscriptionTableAPIData.billingTermId
        };
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(GOOGLE_WORKSPACE_RENEWAL_SETTINGS_POST, data, configFile)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmRenewalSettingsLoadingFlag(false);
                    setServerError(false);
                    setEmptyDataError(false);
                    setRenewalSettingsActionActionPopupModalOpen(false);
                    setToastMessage("Renewal setting updated  successfully. ");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setIsActionInProgress(false);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                setConfirmRenewalSettingsLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setToastMessage("We encountered an issue while updating the renewal setting. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
                setIsActionInProgress(true);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(false);

            })
    };

    // accordion
    function CustomToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = activeEventKey === eventKey;
        return (
            <span
                className='pt-1 align-content-center'
                onClick={decoratedOnClick}
            >{children}
                {isCurrentEventKey ? (
                    <span className='up-accordion' />
                ) : (
                    <span className='down-accordion' />
                )}
            </span>
        );
    }
    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                borderBottom: '0.5px solid #DDDDDD',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        }
    };

    useEffect(() => {
        GetRenewaSettingsListing();
    }, [])

    useEffect(() => {
        if (subscriptionTableAPIData) {
            setDefaultRenewalSettings(subscriptionTableAPIData.autoRenewalName)
        }
    }, [subscriptionTableAPIData])

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-md"
                open={confirmRenewalSettingsFlag}
                onClose={() => setConfirmRenewalSettingsFlag(false)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Renewal Settings</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className="p-5">
                            <p className={`pt-0 px-3 pb-5 mb-0 ${styles['renewal-setting-gws-confirmation-line']}`}>
                                <strong>{(`Are you sure you want to ${defaultRenewalSettings} ?`).replace("my", "your")}</strong>
                            </p>
                            <div className="actions">
                                <div className="d-flex justify-content-center pb-3 pt-2">
                                    <Button
                                        className="btn btn-lg mx-3 btn-border-light"
                                        variant="light"
                                        onClick={() => {
                                            close();
                                        }}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        className="btn btn-md"
                                        onClick={() => {
                                            GwsRenewalSettingsPost();
                                            close();
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                )}
            </Popup>
            <Popup
                className="custom-modal custom-modal-xl"
                open={renewalSettingsActionPopupModalOpen}
                onClose={() => { setRenewalSettingsActionActionPopupModalOpen(false); setServerError(false); setEmptyDataError(false); setSubscriptionTableAPIData(null); }}
                modal
                closeOnDocumentClick={!confirmRenewalSettingsLoadingFlag}
                nested
            >
                {(close) => (
                    <div className="modal">
                        {!subscriptionDetailIsLoadingFlag &&
                            <>
                                <div className="header">
                                    <h2>Renewal Settings</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong"
                                        onClick={() => { if (!confirmRenewalSettingsLoadingFlag) close() }}
                                        color="#6A6A6A"
                                    />
                                </div>
                                <Container className={`action-modal-height ${styles['modal-width']}`}>
                                    <Accordion defaultActiveKey="1">
                                        <Card className={`${styles['accordion-downgradeGWS']}`}>
                                            <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                                                <Col md={5} className="mt-2 mb-2">
                                                    <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                                                </Col>
                                                <Col md={5} className="mt-2 mb-2">
                                                    <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                                                </Col>
                                                <Col md={2} className='text-right'><CustomToggle className='text-left text-center' eventKey="0"></CustomToggle></Col>
                                            </Row>

                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <div className={`table-responsive  ${styles['gws-subsription-details-table']}`}>
                                                        {tableSubscriptionTable &&
                                                            <DataTable
                                                                columns={columnsSubscriptionTable}
                                                                data={tableSubscriptionTable}
                                                                theme="solarized"
                                                                customStyles={customStyles}
                                                                persistTableHead={false}
                                                                noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                                width="100%"
                                                                fixedHeaderScrollHeight="60vh"
                                                                progressPending={loading}
                                                                progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                                fixedHeader
                                                            />
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>

                                    <Box sx={{ width: '100%' }} >
                                        <React.Fragment>
                                            {serverError &&
                                                <div className={styles["no-data-cloud-invoice"]}>
                                                    <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                                    <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                </div>
                                            }
                                            {emptyDataError &&
                                                <div className={styles["no-data-cloud-invoice"]}>
                                                    <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                                    <h2 className="mt-4 mb-3">No Data Found</h2>
                                                </div>
                                            }
                                            <Typography sx={{ mt: 2, mb: 1 }}>
                                                {!serverError && !emptyDataError &&
                                                    <div className={`table-responsive ${styles['gws-downgrade-core']}`}>
                                                        {renewalSettingsPlans && defaultRenewalSettings &&
                                                            <Table className={`${styles['gws-renewal-settings-core-table']}`} responsive="md">
                                                                <thead className="sticky-top">
                                                                    <tr>
                                                                        {DowngradeCoreColumnName.map(th => {
                                                                            return (<th className={th === "Renewal Plan" ? `${styles['gws-renewal-settings-plan-min-width']}` : ""}>{th}</th>)
                                                                        })}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        renewalSettingsPlans.map((td) => {
                                                                            return (<tr>
                                                                                <td><input disabled={confirmRenewalSettingsLoadingFlag} type='radio' className='cpointer' id={td.id} checked={defaultRenewalSettings === (td.id)} onChange={() => { setDefaultRenewalSettings(td.id); setRenewalSettingsSelectedData(td); }} /></td>
                                                                                {defaultRenewalSettings === (td.id) ? <td className={`${styles['highlight-renewal-plan-text-gws']}`}>{td.id}</td> : <td>{td.id}</td>}
                                                                                <td>{td.name}</td>
                                                                            </tr>)
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        }
                                                        {!renewalSettingsPlans &&
                                                            <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>
                                                        }
                                                    </div>}
                                            </Typography>
                                            {/* buttons for downgrade */}
                                            {!serverError && !emptyDataError &&
                                                <div className='text-right pt-4'>
                                                    <React.Fragment>
                                                        {!confirmRenewalSettingsLoadingFlag && defaultRenewalSettings && (subscriptionTableAPIData.autoRenewalName !== defaultRenewalSettings) && <Button className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light" onClick={() => { setDefaultRenewalSettings(subscriptionTableAPIData.autoRenewalName) }} sx={{ mr: 1 }}>
                                                            Clear
                                                        </Button>}
                                                        {!confirmRenewalSettingsLoadingFlag && defaultRenewalSettings && (subscriptionTableAPIData.autoRenewalName === defaultRenewalSettings) && <Button className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled>
                                                            Clear
                                                        </Button>}
                                                        {confirmRenewalSettingsLoadingFlag && <Button className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled>
                                                            Clear
                                                        </Button>}
                                                        {!confirmRenewalSettingsLoadingFlag && defaultRenewalSettings && (subscriptionTableAPIData.autoRenewalName === defaultRenewalSettings) && <Button
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled
                                                        >
                                                            Proceed
                                                        </Button>}
                                                        {!confirmRenewalSettingsLoadingFlag && defaultRenewalSettings && (subscriptionTableAPIData.autoRenewalName !== defaultRenewalSettings) && <Button
                                                            onClick={() => setConfirmRenewalSettingsFlag(true)}
                                                        >
                                                            Proceed
                                                        </Button>}
                                                        {confirmRenewalSettingsLoadingFlag && defaultRenewalSettings && (subscriptionTableAPIData.autoRenewalName !== defaultRenewalSettings) && <Button
                                                            disabled
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                        >
                                                            Processing . . .
                                                        </Button>}
                                                    </React.Fragment>
                                                </div>}
                                        </React.Fragment>

                                    </Box>
                                </Container>
                            </>
                        }
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default GWSRenewalSettings
