import React from 'react'
import { Col, OverlayTrigger, Row, Table, Button } from 'react-bootstrap';
import styles from '../CSPProductDetail.module.scss';
import { Tooltip } from 'react-bootstrap';

const CoTermCloudProvsion = (props) => {
    return (
        <>
            <div className={`table-responsive col-12 my-3 ${styles['csp-popup-table']} ${styles['csp-popup-table-step1']}`}>
                <Table className={`table w-100 ${styles['csp-change-status-core-table']} ${styles['csp-change-status-core-table-step1']}`}>
                    <thead className="sticky-top">
                        <tr>
                            <th className='text-left'>Plans</th>
                            <th className='text-right'>Quantity</th>
                            {!props.isPrerpectual &&
                                <th>Commitment Term (Billing Frequency)</th>
                            }
                            <th className="text-right">Price (Ex GST)</th>
                            <th className="text-right">Sub-Total Price</th>
                            <th className="text-center">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(props.selectedPlan && props.selectedPlan.length > 0) &&
                            props.selectedPlan.map((td, index) => {
                                return (
                                    <>
                                        <tr key={index}>
                                            <td className='text-left'><span>{td.planName}</span></td>
                                            <td className="text-right">{td.quantity}</td>
                                            {!props.isPrerpectual && <td><span>{td.billingTerm}</span></td>}


                                            <td className="text-right"><span>${td.strPrice}</span></td>
                                            <td className="text-right">
                                                <span>

                                                    ${
                                                        parseFloat(td.quantity * parseFloat(td.price)).toFixed(2)
                                                    }

                                                </span>
                                            </td>
                                            <td className="text-center"><span className="trash" onClick={() => props.handleDeleteRow(index)}>&nbsp;</span></td>

                                        </tr>
                                    </>
                                )
                            })
                        }

                        {
                            props.selectedPlan.length < 1 &&
                            <tr className="no-bdr-row no-hover-row">
                                <td colSpan={5} className="text-center">
                                    <p className="mb-0 py-3">No plans in the list. Please go back to buy subscriptions.</p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>


            </div>
            <Row className="mt-2">
                <Col md={6}>
                    <p className="d-inline-block  w-auto mb-0">
                        <strong>Note:</strong> You can Coterminate your subscription with an existing non-trial online services NCE subscription or align the end date with the calendar month by choosing an appropriate end date depending on the term duration.
                    </p>

                </Col>

                <Col md={6}>
                    <div className="btn-wrapper text-right mb-1">
                        <Button
                            variant="light"
                            className="px-4 btn-border-light w-md-auto col-xs-12"
                            onClick={() => props.setCSPProductDetailsPopupModalOpen(false)}
                        >
                            Back
                        </Button>

                        {/* {props.selectedPlan.length > 0 ?
                            <Button
                                className="px-4 mx-2"
                                onClick={() => {
                                    props.setIsProvisionsReviewDone(true);
                                    props.setStep1Progress(false);
                                    props.setStep2Progress(true);
                                    props.setStep3Progress(false);

                                }}
                            >
                                Manage Co Term
                            </Button> :
                            <Button
                                className="px-4 mx-2"
                                variant="light"
                               disabled
                            >
                                Manage Co Term
                            </Button>

                        } */}
                        {props.selectedPlan.length > 0 ?
                            (
                                props.selectedPlan.filter(item => item.billingTerm !== "Trial").length === 0
                                    ?<Button
                                className="px-4 mx-2"
                                onClick={() => {
                                    const updatedPlans = props.selectedPlan.map(plan => ({
                                        planId: plan.planId,
                                        customTermEndDate: "",
                                        isAlignedDateFlag: false,
                                        alignedMonthEndDateFlag: false
                                    }));
                                    props.setAlignedDateArrayData(updatedPlans);
                                    props.setIsProvisionsReviewDone(true);
                                    props.setIsCoTermSelection(true);
                                    props.setStep1Progress(false);
                                    props.setStep2Progress(false);
                                    props.setStep3Progress(true);

                                }}
                            >
                                Proceed
                            </Button> : <Button
                                className="px-4 mx-2"
                                onClick={() => {
                                    props.setIsProvisionsReviewDone(true);
                                    props.setStep1Progress(false);
                                    props.setStep2Progress(true);
                                    props.setStep3Progress(false);

                                }}
                            >
                                Manage Co Term
                            </Button>) :
                            <Button
                                className="px-4 mx-2"
                                variant="light"
                               disabled
                            >
                                Manage Co Term
                            </Button>

                        }
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default CoTermCloudProvsion;