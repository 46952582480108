import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './monthlySummary.module.scss'
import { MONTHLY_SUMMARY_WIDGET_CUSTOMERS_DASHBOARD, MONTHLY_SUMMARY_WIDGET_REVENUE_DASHBOARD, MONTHLY_SUMMARY_WIDGET_SEATS_DASHBOARD } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';

const MonthlySummary = ({ Logout }) => {
  const [customerSummaryWidget, setCustomerSummaryWidget] = useState(null);   // usestate for customer summary
  const [revenueSummaryWidget, setRevenueSummaryWidget] = useState(null);     // usestate for Revenue summary
  const [seatSummaryWidget, setSeatSummaryWidget] = useState(null);           // usestate for seat summary

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to call customer summary data
  async function GetCustomerSummary() {
    await api.get(`${MONTHLY_SUMMARY_WIDGET_CUSTOMERS_DASHBOARD}`, config)
      .then(resp => {
        setCustomerSummaryWidget(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to call all Revenue count data
  async function GetRevenueSummary() {
    await api.get(`${MONTHLY_SUMMARY_WIDGET_REVENUE_DASHBOARD}`, config)
      .then(resp => {
        setRevenueSummaryWidget(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  // Function to call all seat and subscription count data
  async function GetSeatSummary() {
    await api.get(`${MONTHLY_SUMMARY_WIDGET_SEATS_DASHBOARD}`, config)
      .then(resp => {
        setSeatSummaryWidget(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        else {
        }
      });
  };

  useEffect(() => {
    GetCustomerSummary();
    GetRevenueSummary();
    GetSeatSummary();
  }, [])
  return (
    <div className={styles["monthly-summary"]}>
      <h3>Monthly Summary</h3>
      <Row>
        {customerSummaryWidget && <Col md={5} className={styles["monthly-summary-box"]}>
          <span className="text-strong">{customerSummaryWidget.widgetTitle}</span>

          {customerSummaryWidget.percentage != 0 ?
            <h1 className={customerSummaryWidget.isIncrease ? "text-success" : "text-danger"}><span className={customerSummaryWidget.isIncrease ? "dashboard-green-arrow mb-1" : "dashboard-red-arrow mb-1"}></span> {customerSummaryWidget.percentage}%</h1> :
            <h1 className='text-disabled'>
              &nbsp;- {customerSummaryWidget.percentage}%
            </h1>
          }
          <span>{`${Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format((parseInt(customerSummaryWidget.total)).toFixed(2))}`} {(customerSummaryWidget.widgetTitle)}</span>
        </Col>}
        {revenueSummaryWidget && <Col md={5} className={styles["monthly-summary-box"]}>
          <span className="text-strong">{revenueSummaryWidget.widgetTitle}</span>
          {(revenueSummaryWidget.percentage == "-" || revenueSummaryWidget.percentage == 0) ? <h1 className='text-disabled'>
            &nbsp;- 0%
          </h1> :
            <h1 className={revenueSummaryWidget.isIncrease ? "text-success" : "text-danger"}><span className={revenueSummaryWidget.isIncrease ? "dashboard-green-arrow mb-1" : "dashboard-red-arrow mb-1"}></span> {revenueSummaryWidget.percentage}%</h1>

          }
          <span>{parseFloat(revenueSummaryWidget.total) >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((parseFloat(revenueSummaryWidget.total)).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(parseFloat(revenueSummaryWidget.total))).toFixed(2))}`}</span>
        </Col>}
        {seatSummaryWidget && seatSummaryWidget.map((data) => {
          return (
            <Col md={5} className={styles["monthly-summary-box"]}>
              <span className="text-strong">{data.widgetTitle}</span>

              {(data.percentage == "-" || data.percentage == 0) ? <h1 className='text-disabled'>&nbsp;- 0%</h1> :
                <h1 className={data.isIncrease ? "text-success" : "text-danger"}><span className={data.isIncrease ? "dashboard-green-arrow mb-1" : "dashboard-red-arrow mb-1"}></span> {data.percentage}%</h1>
              }

              <span>{`${Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format((parseInt(data.total)).toFixed(2))}`} {(data.widgetTitle)}</span>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default MonthlySummary
