import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionContext, Button, Card, Col, Container, Form, Table, Row, useAccordionButton, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import styles from './CSPPurchaseAddOns.module.scss';
import DataTable, { createTheme } from 'react-data-table-component';
import { api } from '../../../../../../../../Utils/API';
import { CSP_PURCHASE_ADD_ONS_GET, CSP_PURCHASE_ADD_ONS_POST } from '../../../../../../../../Utils/GlobalConstants';
import failureImg from '../../../../../../../../Assets/Images/Illustrations/failure.svg';
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif";
import Coterm from './LocalComponents/coterm';

const CSPPurchaseAddOns = ({ subscriptionTableAPIData, subscriptionId, purchaseAddOnsActionPopupModalOpen, setPurchaseAddOnsActionPopupModalOpen,
  columnsSubscriptionTable, tableSubscriptionTable, GetTableData, minQuantity, maxQuantity, setToastSuccess, setToastError, setToastMessage, customerId, Logout,
  setIsActionInProgress,vendorAccountId,
    setIsAutoRefreshEnabled  }) => {

  const alphanumericRegex = /^[a-zA-Z0-9-]{0,50}$/; // RegEx for PO Number
  const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false) // Subscription is Loading Popup
  const [loading, setLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const [newQuantity, setNewQuantity] = useState(1);

  const [selectedAddOn, setSelectedAddOn] = useState();
  const [activeQtyCheckFlag, setActiveQtyCheckFlag] = useState(false);

  const [editQuantityStepTwo, setEditQuantityStepTwo] = useState(false);
  let editQuantityStepTwoColumnName = ["Plan", "Commitment Term (Billing Frequency)", "Unit Price", "Quantity", "Sub-Total Price", "Delete"];
  const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);
  const [editQuantityStepTwoErrorModalOpen, setEditQuantityStepTwoErrorModalOpen] = useState(false);

  const [isEditingQuantity, setIsEditingQuantity] = useState(false);
  let EditedColumnName = ["Plan", "SKU", "Commitment Term (Billing Frequency)", "RRP (Inc GST)", "Reseller Price (Ex GST)", "Quantity", "Sub-Total Price"];
  const [columnsEditQuantity, setColumnsEditQuantity] = useState([]);
  const [editTable, setEditTable] = useState([]);
  const [editQuantityTableStepTwo, setEditQuantityTableStepTwo] = useState([]);
  const [editQuantityColumnStepTwo, setEditQuantityColumnStepTwo] = useState([]);
  const [poNumber, setPONumber] = useState("");
  const [planId, setPlanId] = useState(null);
  const [addOnTable, setAddOnTable] = useState([])
  const [addonsCount, setAddOnsCount] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [selectedAddOnRows, setSelectedAddOnRows] = useState([]);
  const [noAddons, setNoAddons] = useState(false); // No Purchase addon available

  // Quantity Editor Block
  const [selectedPlan, setSelectedPlan] = useState([]);

  // Set Add on Plans
  const [addOnPlan, setAddOnPlan] = useState([]);

  const [resetData, setResetData] = useState(false);

  // Coterm steps
  const [intermediateCotermStep, setIntermediateCotermStep] = useState(false);
  const [isCoTermSelection, setIsCoTermSelection] = useState(false);
  const [alignedDateArrayData, setAlignedDateArrayData] = useState([]);

  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  const handleIncrease = (qty,maxQuantity) => {
    if (newQuantity <= maxQuantity) {
      setNewQuantity(parseInt(qty) + 1);
      setIsEditingQuantity(true);
    } else {
      setNewQuantity(qty);
    }
  }

  const handleDecrease = (qty,minQuantity) => {
    if (newQuantity >= minQuantity) {
      setNewQuantity(parseInt(qty) - 1);
      setIsEditingQuantity(true);
    } else {
      setNewQuantity(qty);
    }
  }

  // accordion
  function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <span
        className='pt-1 align-content-center'
        onClick={decoratedOnClick}
      >{children}
        {isCurrentEventKey ? (
          <span className='up-accordion' />
        ) : (
          <span className='down-accordion' />
        )}
      </span>
    );
  }

  //edit quantity save and close action core tab
  const handleEditQuantityClose = () => {
    setNewQuantity(subscriptionTableAPIData.quantity);
    setIsEditingQuantity(false);
  };

  // Delete Row
  const handleDeleteRow = (index) => {
    const updatedPlan = [...selectedPlan];
    // console.log("Plan Id", selectedPlan[index].planId);
    updatedPlan.splice(index, 1);
    setSelectedPlan(updatedPlan);
    // console.log(selectedPlan);

    const updatedPOConfirmationBody = [...alignedDateArrayData];
    updatedPOConfirmationBody.splice(index, 1);
    setAlignedDateArrayData(updatedPOConfirmationBody);

    setSelectedRowId(selectedRowId.filter(id => id != selectedPlan[index].planId));

    // Find the index of the object with planId = "68167"
    const indexToUpdate = addOnTable.findIndex(item => item.planId === selectedPlan[index].planId);

    // Make sure the index is valid before updating
    if (indexToUpdate !== -1) {
      // Create a copy of the addOnTable array
      const updatedProductDetails = [...addOnTable];

      // Update the quantity of the object at the specified index
      updatedProductDetails[indexToUpdate] = {
        ...updatedProductDetails[indexToUpdate],
        quantity: 0 // Update the quantity to 4
      };

      // Set the updated array using setaddOnTable
      setAddOnTable(updatedProductDetails);
    } else {
      console.error("Object not found with planId: 68167");
    }
  };

  // POST Updated ADD-ON Quantity
  async function updateAddOns() {
    setIsConfirming(true);
    let requestBody = {
      "subscriptionId": subscriptionId,
      "customerId": customerId,
      "poNumber": poNumber,
      "addonPlans": poConfirmationBody
      // selectedPlan
    };
    // console.log("requestBody:",requestBody)
    await api.post(CSP_PURCHASE_ADD_ONS_POST, requestBody, config)
      .then(resp => {
        if (resp.status = 200) {
          setToastMessage("Addon Purchased Successfully.");
          setToastSuccess(true);
          setSelectedAddOn("");
          setNewQuantity(null);
          setIsEditingQuantity(false);
          setTimeout(() => {
            setToastSuccess(false);
            setPurchaseAddOnsActionPopupModalOpen(false);
            setIsConfirming(false);
          }, 2000);

          setTimeout(() => {
            setEditQuantityStepTwo(false);
            setIsConfirming(false);
          }, 3000);
          setEditQuantityStepTwoErrorModalOpen(false);
          setIsActionInProgress(false);
          // console.log("auto refresh 1");
          setIsAutoRefreshEnabled(true);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
        setToastError(true);
        setToastMessage("Purchase addons failed.");
        setTimeout(() => {
          setToastError(false);
        }, 2000);
        setIsConfirming(false);
        setIsActionInProgress(true);
        // console.log("auto refresh 1");
        setIsAutoRefreshEnabled(false);
      });
  }

  const [poConfirmationBody, setPoConfirmationBody] = useState([]);

  useEffect(() => {

    let selectedFeilds = selectedPlan.map(({ planId, quantity }) => ({ planId, quantity }));

      // Create a map from selectedFeilds based on planId
      const mapSelectedFields = {};
      selectedFeilds.forEach(item => {
        mapSelectedFields[item.planId] = item.quantity;
      });

      // Combine the arrays
      const combinedArray = alignedDateArrayData.map(item => {
        return {
          ...item,
          quantity: mapSelectedFields[item.planId] !== undefined ? mapSelectedFields[item.planId] : null
        };
      });

      setPoConfirmationBody(combinedArray);

      // console.log(combinedArray);

  }, [selectedPlan, alignedDateArrayData, poNumber])

  // Update Edit Quantity - put request
  async function GetPurchaseAddonsSubscriptions() {
    await api.get(`${CSP_PURCHASE_ADD_ONS_GET}?subscriptionId=${subscriptionId}&customerId=${customerId}`, config)
      .then(resp => {
        if (resp.status == 200) {
          setNoAddons(false);
          setAddOnTable(resp.data.content);
          let f = [];
          (resp.data.content).map((td) => {

            f.push
              (
                {
                  "Plan": td.planName,
                  "SKU": td.planCode,
                  "Commitment Term (Billing Frequency)": td.billingTerm,
                  "RRP (Inc GST)": td.rrpInclusiveTax,
                  "Reseller Price (Ex GST)": td.price,
                  "Quantity": <div className='d-flex flex-wrap my-2 align-items-center'>
                    <input type='number' defaultValue={td.quantity} value={newQuantity} onChange={e => setNewQuantity(e.target.value)} />
                  </div>,
                  "Sub-Total Price": td.quantity * td.price,
                  "Min": td.minQuantity,
                  "Max": td.maxQuantity,
                }
              );
          })
          setTimeout(() => {
            setEditTable(f);         // Setting Table Data
          }, 50);
          let d = []
          EditedColumnName.map((td) => {
            if (td === "Sub-Total Price" || td === "RRP (Inc GST)" || td === "Reseller Price (Ex GST)") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                right: true,
                grow: 1.5,
              })
            }
            else if (td === "Commitment Term (Billing Frequency)") {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 3,
              })
            }
            else {
              d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2,
              })
            }
          })
          setColumnsEditQuantity(d);
        } else if (resp.status == 204) {
          setNoAddons(true);
        } else {
          setNoAddons(true);
        }
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
      })
  }

  //useEffect when edit quantity changes edit quantity-step2
  useEffect(() => {
    // Column Edited Quantity tab level step 2
    let f = [];
    selectedAddOnRows.map(td => {
      f.push
        (
          {
            "Plan": td.planName,
            "Plan Code": td.planCode,
            "Commitment Term (Billing Frequency)": td.billingTerm,
            "Unit Price": "$" + td.price,
            "Quantity": td.quantity,
            "Sub-Total Price": "$" + Math.round((((td.price) * parseInt(td.quantity)) + Number.EPSILON) * 100) / 100,
            "Delete": <span onClick={() => {
              setSelectedAddOnRows(selectedAddOnRows.filter(item => item.planId !== td.planId));
              setAddOnPlan(selectedAddOnRows.filter(item => item.planId !== td.planId));
              setSelectedRowId(selectedRowId.filter(planId => planId !== td.planId));
            }}>
              <span className="trash cpointer">&nbsp;</span>
            </span>,
            "plan Id": td.planId,
          }
        );
    })

    setTimeout(() => {
      setEditQuantityTableStepTwo(f);         // Setting Table Data
    }, 50);
    let d = []
    editQuantityStepTwoColumnName.map((td) => {
      if (td === "Unit Price" || td === "Quantity" || td === "Sub-Total Price") {
        d.push({
          id: `${td}`,
          name: <div>{td}</div>,
          selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
          right: true,
        })
      } else if (td === "Delete") {
        d.push({
          id: `${td}`,
          name: <div>{td}</div>,
          selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
          center: true,
        })
      } else if (td === "Commitment Term (Billing Frequency)") {
        d.push({
          id: `${td}`,
          name: <div>{td}</div>,
          selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
        })
      } else {
        d.push({
          id: `${td}`,
          name: <div>{td}</div>,
          selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
          left: true,
        })
      }
    })
    setEditQuantityColumnStepTwo(d);     // Setting Column Data  
  }, [selectedAddOnRows, editQuantityStepTwo]);


  useEffect(() => {

    let selectedFeilds = selectedPlan.map(({ planId, quantity }) => ({ planId, quantity }));

      // Create a map from selectedFeilds based on planId
      const mapSelectedFields = {};
      selectedFeilds.forEach(item => {
        mapSelectedFields[item.planId] = item.quantity;
      });

      // Combine the arrays
      const combinedArray = alignedDateArrayData.map(item => {
        return {
          ...item,
          quantity: mapSelectedFields[item.planId] !== undefined ? mapSelectedFields[item.planId] : null
        };
      });

      setPoConfirmationBody(combinedArray);

      // console.log(combinedArray);

  }, [selectedPlan, alignedDateArrayData, poNumber])

  useEffect(() => {
    GetPurchaseAddonsSubscriptions();
  }, [])

  // React Data Table Custom Theme
  createTheme('solarized', {
    text: {
      primary: '#000000',
    },
    background: {
      default: '#FFFFFF',
    },
    divider: {
      default: '#d7dadf',
    },
    striped: {
      default: '#f5f5f5',
    },
  });

  // Delete the selected Plans
  const resetTableData = () => {
    const updatedAddOnTable = addOnTable.map(obj => ({ ...obj, quantity: 0 }));
    setAddOnTable(updatedAddOnTable);
    setSelectedRowId([]);
    setSelectedPlan([]);
    setEditQuantityStepTwoErrorModalOpen(false);
    setAddOnsCount(0);

    setPONumber("");
    setNewQuantity(null);
    setIsEditingQuantity(false);
  }

  useEffect(() => {
    resetData === true && (
      <>
        {resetTableData()}
        {setResetData(false)}
        {setActiveQtyCheckFlag(false)}
        {setSelectedAddOn("")}
      </>
    )
  }, [resetData]);


  // React Data Table Custom Styles
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    cells: {
      style: {
        paddingLeft: '1rem', // override the cell padding for data cells
        paddingRight: '1rem',
        fontSize: '0.9rem',
        fontWeight: '400',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '1rem', // override the cell padding for head cells
        paddingRight: '1rem',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#fafafa',
        fontWeight: '500',
        fontSize: '0.875rem',
        color: '#6A6A6A !important',
        textTransform: 'capitalize',
      },
    },
    expanderButton: {
      style: {
        '&:hover:enabled': {
          cursor: 'pointer',
        },
        '&:disabled svg path': {
          color: '#999',
        },
        svg: {
          margin: 'auto',
        },
      },
    },
  };

  return (
    <>
      {/* Edit Quantity Error Modal */}
      <Popup
        open={editQuantityStepTwoErrorModalOpen}
        onClose={() => { setEditQuantityStepTwoErrorModalOpen(false); setIsCoTermSelection(false); setAlignedDateArrayData([]);
        }}
        className="custom-modal custom-modal-md custom-modal-ticket-details"
        modal
        nested
      >
        {(close) => (
          <div className="modal">

            <div className="header">
              <h2>Order Failed</h2>
              <XLg
                size={24}
                className="cpointer close-icon-popup"
                onClick={close}
              />
            </div>
            <Container fluid>
              <Row className="pb-3 pt-5">
                <Col md={12} className="text-center my-4">
                  <img src={failureImg} alt="Edit Quantity Failure" />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p className="red text-strong text-center">Order failed as the edit didn’t satisfy</p>
                </Col>

                <Col md={12}>
                  <p>The requested no. of seats exceeded the remaining limit of 300 seats allowed per subscription. If you wish to proceed further with change quantity request, please revise the quantity to available limit</p>
                </Col>
              </Row>

              <Row className="mt-2 mb-4">

                <Col md={12}>
                  <div className="btn-wrapper text-center">

                    <Button
                      className="px-4 mx-2"
                      onClick={() => { handleEditQuantityClose(); setEditQuantityStepTwoErrorModalOpen(false); }}>
                      Ok
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Popup>

      <Popup
        className="custom-modal custom-modal-xl"
        open={purchaseAddOnsActionPopupModalOpen}
        onClose={() => { setPurchaseAddOnsActionPopupModalOpen(false); setEditQuantityStepTwo(false); setNewQuantity(null); setIsEditingQuantity(false); }}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            {!subscriptionDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2>{editQuantityStepTwo ? "Order Confirmation - Purchase Addons" : "Purchase Addons"}</h2>
                  <XLg
                    size={18}
                    className="cpointer text-strong"
                    onClick={close}
                    color="#6A6A6A"
                  />
                </div>
                <Container className={`action-modal-height ${styles['edit-quantity-width']}`}>
                  <Accordion defaultActiveKey="1">
                    <Card className={`${styles['accordion-upgradeCSP']}`}>
                      <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                        <Col md={5} className="mt-2 mb-2">
                          <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan:</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                        </Col>
                        <Col md={6} className="mt-2 mb-2">
                          <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                        </Col>
                        <Col md={1} className='text-right'><CustomToggle className='text-left text-center' eventKey="0"></CustomToggle></Col>
                      </Row>

                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className={`table-responsive  ${styles['gws-subsription-details-table']}`}>
                            {tableSubscriptionTable &&
                              <DataTable
                                columns={columnsSubscriptionTable}
                                data={tableSubscriptionTable}
                                theme="solarized"
                                customStyles={customStyles}
                                persistTableHead={false}
                                noDataComponent={<div className={styles["loading-plans"]}><p><b>Loading...</b></p></div>}
                                width="100%"
                                fixedHeaderScrollHeight="31vh" progressPending={loading}
                                progressComponent={<div className={styles["loading-plans"]}><p><b>Loading...</b></p></div>}
                                fixedHeader
                              />
                            }
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  { !intermediateCotermStep && <>
                  {editQuantityStepTwo ? (
                    <div className={`${styles['csp-edit-quantity-table-order-confirmation']} mt-4`}>

                      <Table className={`table w-100 ${styles['csp-change-status-core-table']}`}>
                        <thead className="sticky-top">
                          <tr>
                            <th className={`${styles['big-width-reseller-price']}`}>Plan</th>
                            <th className={`${styles['big-width-reseller-price']}`}>Commitment Term (Billing Frequency)</th>
                            <th className={`text-right ${styles['big-width-rrp']}`}>RRP (Inc GST)</th>
                            <th className={`text-right ${styles['big-width-reseller-price']}`}>Reseller Price (Ex GST)</th>
                            <th className="text-center">Quantity</th>
                            <th className={`text-right`}>Sub-Total Price</th>
                            <th className="text-center">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(selectedPlan && selectedPlan.length > 0) &&
                            selectedPlan.map((td, index) => {
                              return (
                                <>
                                  <tr key={index}>
                                    <td className={`${styles['big-width-reseller-price']}`}>{td.planName}</td>
                                    <td>{td.billingTerm}</td>
                                    <td className="text-right">${td.rrp}</td>
                                    <td className="text-right">${td.price}</td>
                                    <td className="text-right">
                                      <div className={styles['qty-wrapper']}>
                                        {selectedAddOn != td.planId &&
                                          <div className={`${styles['show-qty-btn-wrapper']}`}>
                                            <label
                                              id={td.planId}
                                              className={`${styles['show-qty-btn']}`}
                                              onClick={(e) => {
                                                if (!activeQtyCheckFlag) {
                                                  e.preventDefault();
                                                  setPlanId(td.planId);
                                                  setNewQuantity(td.quantity == 0 ? 1 : td.quantity);
                                                  setSelectedAddOn(e.target.id);
                                                  setActiveQtyCheckFlag(true);
                                                }
                                              }
                                              }>{td.quantity}</label>

                                            <div className={`text-left ${styles['btn-wrapper']}`}>
                                              <button className={`${styles['plus']}`}
                                                id={td.planId}
                                                onClick={(e) => {
                                                  if (!activeQtyCheckFlag) {
                                                    e.preventDefault();
                                                    setPlanId(td.planId);
                                                    setNewQuantity(td.quantity == 0 ? 1 : td.quantity);
                                                    setSelectedAddOn(e.target.id);
                                                    setActiveQtyCheckFlag(true);
                                                  }
                                                }
                                                }
                                              >+</button>
                                              <button className={`${styles['minus']}`}
                                                id={td.planId}
                                                onClick={(e) => {
                                                  if (!activeQtyCheckFlag) {
                                                    e.preventDefault();
                                                    setPlanId(td.planId);
                                                    setNewQuantity(td.quantity == 0 ? 1 : td.quantity);
                                                    setSelectedAddOn(e.target.id);
                                                    setActiveQtyCheckFlag(true);
                                                  }
                                                }
                                                }>
                                                <span>&nbsp;</span>
                                              </button>
                                            </div>
                                          </div>
                                        }

                                        {(selectedAddOn == td.planId) &&
                                          <div className="d-flex flex-wrap  align-items-center justify-content-end">
                                            <>
                                              <div class="number-input">
                                                <input
                                                  type="number"
                                                  id={td.planId}
                                                  className="no-arrows"
                                                  defaultValue={td.quantity}
                                                  value={parseInt(newQuantity)}
                                                  min={td.minQuantity}
                                                  max={td.maxQuantity}
                                                  onChange={(e) => {
                                                    setNewQuantity(e.target.value);

                                                    if ((e.target.value) > td.maxQuantity) {
                                                      setNewQuantity(td.maxQuantity)
                                                    }

                                                    if ((e.target.value) < td.minQuantity) {
                                                      setNewQuantity(td.minQuantity)
                                                    }

                                                    if ((e.target.value) === td.quantity) {
                                                      setNewQuantity(td.quantity)
                                                    }

                                                  }}
                                                  onKeyDown={(e) => {
                                                    if (e.code === 'Minus') {
                                                      e.preventDefault();
                                                    }
                                                  }}
                                                />

                                                <div className="btn-wrapper">
                                                  {parseInt(newQuantity) < parseInt(td.maxQuantity) ?
                                                    <button className="plus" onClick={() => handleIncrease(parseInt(newQuantity),parseInt(td.maxQuantity))
                                                    }>+</button> :
                                                    <button className="plus disabled" disabled>+</button>
                                                  }

                                                  {parseInt(newQuantity) > td.minQuantity ?
                                                    <button className="minus" onClick={
                                                      () => handleDecrease(parseInt(newQuantity),parseInt(td.minQuantity))
                                                    }> <span>&nbsp;</span> </button>
                                                    :
                                                    <button className="minus disabled" disabled>
                                                      <span>&nbsp;</span>
                                                    </button>}

                                                </div>

                                              </div>
                                              {parseInt(newQuantity) !== parseInt(td.quantity) &&
                                                <div className='mx-1'
                                                  onClick={(e) => {
                                                    td.quantity = parseInt(newQuantity);
                                                    if (!selectedRowId.includes(td.planId)) {
                                                      setSelectedRowId([...selectedRowId, td.planId]);
                                                      setSelectedAddOnRows([...selectedAddOnRows, td]);
                                                      setSelectedPlan([
                                                        {
                                                          billingTerm: td.billingTerm,
                                                          category: td.category,
                                                          flavorDetailJson: td.flavorDetailJson,
                                                          flavorId: td.flavorId,
                                                          isNcePlan: td.isNcePlan,
                                                          isPerpetualSoftware: td.isPerpetualSoftware,
                                                          maxQuantity: td.maxQuantity,
                                                          minQuantity: td.minQuantity,
                                                          planId: td.planId,
                                                          planName: td.planName,
                                                          price: td.price,
                                                          quantity: td.quantity,
                                                          rrp: td.rrpInclusiveTax,
                                                          skuId: td.skuId,
                                                          total: td.quantity * td.price,
                                                          licenseAutoRenewal: td.licenseAutoRenewal
                                                        },
                                                        ...selectedPlan
                                                      ]);
                                                    }

                                                    setNewQuantity(td.quantity);
                                                    setPlanId(null);
                                                    setSelectedAddOn("");
                                                    setActiveQtyCheckFlag(!activeQtyCheckFlag);

                                                    // Update Quantity Corresponding in Main Table if we change here
                                                    const indexToUpdate = addOnTable.findIndex(item => item.planId === td.planId);
                                                    const updatedProductDetails = [...addOnTable];

                                                    // Update the quantity of the object at the specified index
                                                    updatedProductDetails[indexToUpdate] = {
                                                      ...updatedProductDetails[indexToUpdate],
                                                      quantity: newQuantity// Update the quantity to 4
                                                    };

                                                    setAddOnTable(updatedProductDetails);
                                                    // Update Quantity Corresponding in Main Table if we change here
                                                    const indexToUpdateSelectedPlan = selectedPlan.findIndex(item => item.planId === td.planId);
                                                    const updatedProductDetailsSelectedPlan = [...selectedPlan];

                                                    // Update the quantity of the object at the specified index
                                                    updatedProductDetailsSelectedPlan[indexToUpdateSelectedPlan] = {
                                                      ...updatedProductDetailsSelectedPlan[indexToUpdateSelectedPlan],
                                                      quantity: newQuantity// Update the quantity
                                                    };

                                                    setSelectedPlan(updatedProductDetailsSelectedPlan)
                                                    
                                                    // setAddOnTable(updatedProductDetails);

                                                    // setNewQuantity(td.quantity);
                                                    // setPlanId(null);
                                                    // setSelectedAddOn("");
                                                    // setActiveQtyCheckFlag(!activeQtyCheckFlag);

                                                    // // Update Quantity Corresponding in Main Table if we change here
                                                    // const indexToUpdate = addOnTable.findIndex(item => item.planId === td.planId);
                                                    // const updatedProductDetails = [...addOnTable];

                                                    // // Update the quantity of the object at the specified index
                                                    // updatedProductDetails[indexToUpdate] = {
                                                    //   ...updatedProductDetails[indexToUpdate],
                                                    //   quantity: newQuantity// Update the quantity to 4
                                                    // };

                                                    // setAddOnTable(updatedProductDetails);
                                                  }
                                                  }>
                                                  <span className="quantity-tick" />
                                                </div>
                                              }

                                              {parseInt(newQuantity) == td.quantity &&
                                                <div className='mx-1'>
                                                  <span className="tick-grey" />
                                                </div>
                                              }


                                              <div className='mx-1' onClick={(e) => {
                                                setNewQuantity(td.quantity);
                                                setPlanId(null);
                                                setSelectedAddOn("");
                                                setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                                // console.log(activeQtyCheckFlag);
                                              }
                                              }>
                                                <span className="quantity-cross">&nbsp;</span>
                                              </div>
                                            </>
                                          </div>

                                        }
                                      </div>
                                    </td>
                                    <td className="text-right">
                                        {
                                          (selectedAddOn == td.planId) ?
                                            "$" + Math.round((((td.price) * parseInt(newQuantity)) + Number.EPSILON) * 100) / 100 :
                                            "$" + Math.round((((td.price) * parseInt(td.quantity)) + Number.EPSILON) * 100) / 100
                                        }
                                    </td>
                                    <td className={`text-center ${styles['delete-column']}`}>
                                      <span className="trash" onClick={() => handleDeleteRow(index)}>&nbsp;</span>
                                    </td>
                                  </tr>
                                </>
                              )
                            })
                          }


                          {selectedPlan.length < 1 &&
                            <tr className="no-bdr-row no-hover-row">
                              <td colSpan={8} className="text-center">
                                <p className="mb-0 py-3">No plans in the list. Please go back to buy subscriptions.</p>
                              </td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                      {selectedPlan.length > 0 &&
                        <>
                          <p className='mt-4'><span className='text-strong'>Note :</span> Plan cost is provided under 'Sub - Total Price', final cost may vary based on vendor’s billing logic.</p>
                          <Row>
                            <Col>
                              <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                              <Form.Control
                                type="text"
                                className="w-auto d-inline ml-3"
                                id="inputPONumber"
                                aria-describedby="inputPONumber"
                                placeholder='Add PO Number'
                                value={poNumber}
                                maxlength="50"
                                onChange={(e) => {
                                  alphanumericRegex.test(e.target.value)
                                    ? setPONumber(e.target.value)
                                    : console.log("")
                                }}
                              />
                              <Form.Text id="poNumber" />
                            </Col>
                          </Row>
                        </>
                      }
                      <Row className="mt-2">

                        <Col md={6}>
                          {selectedPlan.length > 0 &&
                            <>
                              <Form.Check // prettier-ignore
                                type="checkbox"
                                id="termsCheckBox"
                                label=""
                                disabled
                                checked={isTermsChecked}
                                className="d-inline-block w-auto"
                              />
                              <p className="d-inline-block  w-auto mb-0">
                                I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                              </p>
                            </>
                          }
                        </Col>

                        {selectedPlan.length < 1 &&

                          <Col md={6}>
                            <div className="btn-wrapper text-right mb-1">

                              <Button
                                className="px-4 mx-2"
                                onClick={() => {
                                  setEditQuantityStepTwo(false);
                                  setPONumber("");
                                }}>Back
                              </Button>
                            </div>
                          </Col>
                        }

                        {selectedPlan.length > 0 &&
                          <Col md={6}>
                            <div className="btn-wrapper text-right mb-1">
                              {!isConfirming && 
                                <Button
                                  className="px-4 mx-2"
                                  onClick={() => {
                                    // setEditQuantityStepTwo(false);
                                    setIntermediateCotermStep(true);
                                    setPONumber("");
                                  }}>Back
                                </Button>
                              }
                              {isConfirming &&
                                <Button
                                  variant="light"
                                  className="px-4 mx-2"
                                  disabled
                                >
                                  Back
                                </Button>
                              }
                              {!isConfirming &&
                                <>
                                  {(isTermsChecked && selectedPlan.length > 0) && <>
                                    <Button
                                      className="px-4 mx-2"
                                      onClick={updateAddOns}>
                                      Confirm
                                    </Button>

                                  </>
                                  }
                                </>
                              }

                              {isConfirming &&
                                <>
                                  <Button
                                    variant="light"
                                    className="px-4 mx-2"
                                    disabled
                                  >
                                    Confirming...
                                  </Button>
                                </>
                              }

                              {!isTermsChecked &&
                                <>
                                  <Button
                                    variant="light"
                                    className="px-4 mx-2"
                                    disabled
                                  >
                                    Confirm
                                  </Button>
                                </>
                              }
                            </div>
                          </Col>
                        }
                      </Row>
                    </div>
                  ) : (
                    // Content when setEditQuantityStepTwo is false
                    <>

                      {!noAddons &&
                        <div className={`${styles["csp-purchase-addon-table-outer"]}`}>
                          {
                            addOnTable.length < 1 &&
                            <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans</p></div>
                          }
                          {(addOnTable && addOnTable.length > 0) &&

                            <div className={`table-responsive col-12 my-3 ${styles["csp-purchase-addon-table"]}`}>
                              <table className={`table ${styles['csp-purchase-addon-table']}`}>
                                <thead className="sticky-top">
                                  <th className={`${styles['big-width-reseller-price']}`}>Plan</th>
                                  <th>SKU</th>
                                  <th>Commitment Term (Billing Frequency)</th>
                                  <th className={`text-right ${styles['big-width-rrp']}`}>RRP (Inc GST)</th>
                                  <th className={`text-right ${styles['big-width-reseller-price']}`}>Reseller Price (Ex GST)</th>
                                  <th className="text-center">Quantity</th>
                                  <th className={`text-right ${styles['big-width-rrp']}`}>Sub-Total Price</th>
                                </thead>
                                <tbody>
                                  {
                                    addOnTable.map((td, index) => {
                                      return (
                                        <>
                                          <tr key={index} className={selectedRowId.includes(td.planId) ? styles["selected-row"] : ""}>
                                            <td><span>{td.planName}</span></td>
                                            <td><span>{td.planCode}</span></td>
                                            <td><span>{td.billingTerm}</span></td>
                                            <td className="text-right"><span>${td.rrpInclusiveTax}</span></td>
                                            <td className="text-right"><span>${td.price}</span></td>
                                            <td className="text-right">
                                              <div className={styles['qty-wrapper']}>
                                                {selectedAddOn != td.planId &&
                                                  <div className={`${styles['show-qty-btn-wrapper']}`}>
                                                    <label
                                                      id={td.planId}
                                                      className={`${styles['show-qty-btn']}`}
                                                      onClick={(e) => {
                                                        if (!activeQtyCheckFlag) {
                                                          e.preventDefault();
                                                          setPlanId(td.planId);
                                                          setNewQuantity(td.quantity == 0 ? 1 : td.quantity);
                                                          setSelectedAddOn(e.target.id);
                                                          setActiveQtyCheckFlag(true);
                                                        }
                                                      }
                                                      }>{td.quantity}</label>

                                                    <div className={`${styles['btn-wrapper']}`}>
                                                      <button className={`${styles['plus']}`}
                                                        id={td.planId}
                                                        onClick={(e) => {
                                                          if (!activeQtyCheckFlag) {
                                                            e.preventDefault();
                                                            setPlanId(td.planId);
                                                            setNewQuantity(td.quantity == 0 ? 1 : td.quantity);
                                                            setSelectedAddOn(e.target.id);
                                                            setActiveQtyCheckFlag(true);
                                                          }
                                                        }
                                                        }
                                                      >+</button>
                                                      <button className={`${styles['minus']}`} id={td.planId}
                                                        onClick={(e) => {
                                                          if (!activeQtyCheckFlag) {
                                                            e.preventDefault();
                                                            setPlanId(td.planId);
                                                            setNewQuantity(td.quantity == 0 ? 1 : td.quantity);
                                                            setSelectedAddOn(e.target.id);
                                                            setActiveQtyCheckFlag(true);
                                                          }
                                                        }
                                                        }>
                                                        <span>&nbsp;</span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                }

                                                {(selectedAddOn == td.planId) &&
                                                  <div className="d-flex flex-wrap align-items-center justify-content-end">
                                                    <>
                                                      <div class="number-input">
                                                        <input
                                                          type="number"
                                                          id={td.planId}
                                                          className="no-arrows"
                                                          defaultValue={td.quantity}
                                                          value={parseInt(newQuantity)}
                                                          min={td.minQuantity}
                                                          max={td.maxQuantity}
                                                          onChange={(e) => {
                                                            setNewQuantity(e.target.value);

                                                            if ((e.target.value) > td.maxQuantity) {
                                                              setNewQuantity(td.maxQuantity)
                                                            }

                                                            if ((e.target.value) < td.minQuantity) {
                                                              setNewQuantity(td.minQuantity)
                                                            }

                                                            if ((e.target.value) === td.quantity) {
                                                              setNewQuantity(td.quantity)
                                                            }

                                                          }}
                                                          onKeyDown={(e) => {
                                                            if (e.code === 'Minus') {
                                                              e.preventDefault();
                                                            }
                                                          }}
                                                        />

                                                        <div className="btn-wrapper">
                                                          {parseInt(newQuantity) < parseInt(td.maxQuantity) ?
                                                            <button className="plus" onClick={() => handleIncrease(parseInt(newQuantity),parseInt(td.maxQuantity))
                                                            }>+</button> :
                                                            <button className="plus disabled" disabled>+</button>
                                                          }

                                                          {parseInt(newQuantity) > td.minQuantity ?
                                                            <button className="minus" onClick={
                                                              () => handleDecrease(parseInt(newQuantity),parseInt(td.minQuantity))
                                                            }> <span>&nbsp;</span> </button>
                                                            :
                                                            <button className="minus disabled" disabled>
                                                              <span>&nbsp;</span>
                                                            </button>}

                                                        </div>

                                                      </div>
                                                      {parseInt(newQuantity) !== parseInt(td.quantity) &&
                                                        <div className='mx-1'
                                                          onClick={(e) => {
                                                            // td.quantity = parseInt(newQuantity);
                                                            // if (!selectedRowId.includes(td.planId)) {
                                                            //   setSelectedRowId([...selectedRowId, td.planId]);
                                                            //   setSelectedAddOnRows([...selectedAddOnRows, td]);
                                                            //   setSelectedPlan([
                                                            //     {
                                                            //       billingTerm: td.billingTerm,
                                                            //       category: td.category,
                                                            //       flavorDetailJson: td.flavorDetailJson,
                                                            //       flavorId: td.flavorId,
                                                            //       isNcePlan: td.isNcePlan,
                                                            //       isPerpetualSoftware: td.isPerpetualSoftware,
                                                            //       maxQuantity: td.maxQuantity,
                                                            //       minQuantity: td.minQuantity,
                                                            //       planId: td.planId,
                                                            //       planName: td.planName,
                                                            //       price: td.price,
                                                            //       quantity: td.quantity,
                                                            //       rrp: td.rrpInclusiveTax,
                                                            //       skuId: td.skuId,
                                                            //       total: td.quantity * td.price,
                                                            //       licenseAutoRenewal : td.licenseAutoRenewal
                                                            //     },
                                                            //     ...selectedPlan
                                                            //   ]);              
                                                            // }

                                                            // Assuming td is the object being processed and newQuantity is the new quantity value
                                                            td.quantity = parseInt(newQuantity);

                                                            const existingPlanIndex = selectedPlan.findIndex(plan => plan.planId === td.planId); // Find the index of the existing plan

                                                            if (existingPlanIndex !== -1) {
                                                              // If the plan ID exists in selectedPlan
                                                              const existingPlan = selectedPlan[existingPlanIndex];
                                                              if (existingPlan.quantity !== td.quantity) {
                                                                // Update the quantity only if it's different
                                                                const updatedPlan = {
                                                                  ...existingPlan,
                                                                  quantity: td.quantity,
                                                                  total: td.quantity * td.price // Recalculate total if needed
                                                                };

                                                                // Create a new array to update selectedPlan
                                                                const updatedSelectedPlan = [
                                                                  ...selectedPlan.slice(0, existingPlanIndex), // Plans before the existing one
                                                                  updatedPlan, // Updated existing plan
                                                                  ...selectedPlan.slice(existingPlanIndex + 1) // Plans after the existing one
                                                                ];

                                                                setSelectedPlan(updatedSelectedPlan); // Update the selected plans with the new state
                                                              }
                                                            } else {
                                                              // If plan ID is not in selectedRowId, add the new plan as before
                                                              if (!selectedRowId.includes(td.planId)) {
                                                                setSelectedRowId([...selectedRowId, td.planId]);
                                                                setSelectedAddOnRows([...selectedAddOnRows, td]);
                                                                setSelectedPlan([
                                                                  {
                                                                    billingTerm: td.billingTerm,
                                                                    category: td.category,
                                                                    flavorDetailJson: td.flavorDetailJson,
                                                                    flavorId: td.flavorId,
                                                                    isNcePlan: td.isNcePlan,
                                                                    isPerpetualSoftware: td.isPerpetualSoftware,
                                                                    maxQuantity: td.maxQuantity,
                                                                    minQuantity: td.minQuantity,
                                                                    planId: td.planId,
                                                                    planName: td.planName,
                                                                    price: td.price,
                                                                    quantity: td.quantity,
                                                                    rrp: td.rrpInclusiveTax,
                                                                    skuId: td.skuId,
                                                                    total: td.quantity * td.price,
                                                                    licenseAutoRenewal: td.licenseAutoRenewal
                                                                  },
                                                                  ...selectedPlan
                                                                ]);
                                                              }
                                                            }

                                                            setNewQuantity(td.quantity);
                                                            setPlanId(null);
                                                            setSelectedAddOn("");
                                                            setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                                            // console.log("selectedRowId", selectedRowId);

                                                            // Update Quantity Corresponding in Main Table if we change here
                                                            const indexToUpdate = addOnTable.findIndex(item => item.planId === td.planId);
                                                            const updatedProductDetails = [...addOnTable];

                                                            // Update the quantity of the object at the specified index
                                                            updatedProductDetails[indexToUpdate] = {
                                                              ...updatedProductDetails[indexToUpdate],
                                                              quantity: newQuantity// Update the quantity to 4
                                                            };

                                                            setAddOnTable(updatedProductDetails);
                                                          }
                                                          }>
                                                          <span className="quantity-tick" />
                                                        </div>
                                                      }

                                                      {parseInt(newQuantity) == td.quantity &&
                                                        <div className='mx-1'>
                                                          <span className="tick-grey" />
                                                        </div>
                                                      }

                                                      <div className='mx-1' onClick={(e) => {
                                                        setNewQuantity(td.quantity);
                                                        setPlanId(null);
                                                        setSelectedAddOn("");
                                                        setActiveQtyCheckFlag(!activeQtyCheckFlag);
                                                        // console.log(activeQtyCheckFlag);
                                                      }
                                                      }>
                                                        <span className="quantity-cross">&nbsp;</span>
                                                      </div>
                                                    </>
                                                  </div>

                                                }

                                              </div>

                                            </td>
                                            <td className="text-right">
                                              <span>
                                                {
                                                  (selectedAddOn == td.planId) ?
                                                    "$" + Math.round((((td.price) * parseInt(newQuantity)) + Number.EPSILON) * 100) / 100 :
                                                    "$" + Math.round((((td.price) * parseInt(td.quantity)) + Number.EPSILON) * 100) / 100
                                                }

                                              </span>
                                            </td>
                                          </tr>

                                        </>
                                      )
                                    })
                                  }

                                </tbody>
                              </table>

                            </div>
                          }
                          {
                            selectedRowId.length > 0 && !noAddons &&
                            <>
                              <div className={`table ${styles['csp-purchase-addon-count-row']}`}>
                                <div>
                                  <Row>
                                    <Col md={6} className="d-flex align-items-center">
                                      <p className="m-0 p-0 text-small">{selectedRowId.length} Add-on selected</p>
                                    </Col>
                                    <Col md={6}>
                                      <div className="btn-wrapper justify-content-end d-flex m-0 p-0">
                                        <Button
                                          variant="light"
                                          className="px-4 btn-border-light w-md-auto col-xs-12"
                                          onClick={() => { setResetData(true) }}>
                                          Cancel
                                        </Button>
                                        {!activeQtyCheckFlag &&
                                          <Button
                                            className="px-4 ml-5"
                                            onClick={() => {
                                            // setEditQuantityStepTwo(true)
                                            setIntermediateCotermStep(true);
                                            }}>
                                            Manage Co Term
                                          </Button>
                                        }
                                        {activeQtyCheckFlag &&
                                          <Button
                                            className="px-4 ml-5"
                                            variant="light"
                                            disabled
                                          >
                                            Proceed
                                          </Button>
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      }

                      {noAddons &&
                        <div className={`py-5 pb-5 ${styles["csp-purchase-addon-table-outer"]}`}>
                          <p className='text-center mb-0'>No plans in this list. Please go back to provision module to buy subscriptions.</p>
                        </div>
                      }
                    </>
                  )}
                  </>}
                {intermediateCotermStep &&
                  <>
                    <Coterm
                      selectedPlan={selectedPlan}
                      setIsCoTermSelection={setIsCoTermSelection}
                      customerId={customerId}
                      vendorAccountId={vendorAccountId}
                      setAlignedDateArrayData={setAlignedDateArrayData}
                      setStep1Progress={setEditQuantityStepTwo}
                      step1Progress={editQuantityStepTwo}
                      setIntermediateCotermStep={setIntermediateCotermStep}
                      poConfirmationBody={poConfirmationBody}
                    />
                    </>
                  }
                </Container>
              </>
            }
            {
              subscriptionDetailIsLoadingFlag &&
              <>
                <div className="header">
                  <h2 className="mb-0">Edit Quantity</h2>
                  <XLg
                    size={18}
                    className="cpointer text-strong"
                    onClick={close}
                    color="#6A6A6A"
                  />
                </div>
                <Container>
                  <div className="empty-subscription-detail">
                    <center><h2> Loading . . .</h2></center>
                  </div>
                </Container>
              </>
            }
          </div>
        )}
      </Popup>
    </>
  )
}

export default CSPPurchaseAddOns