import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";
import { GET_SERVICES, MANAGEMENT_CONSOLE_ACCOUNT_TYPES, MANAGEMENT_CONSOLE_STATUS_TYPES } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';


const ManagementConsoleFilterBox = (props) => {

    const [serviceData, setServiceData] = useState(null);                             // data in service dropdown
    const [statusData, setStatusData] = useState(null);                               // data in status dropdown
    const [accountTypeData, setAccountTypeData] = useState(null);                     // data in account type dropdown

    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedAccountTypes, setSelectedAccountTypes] = useState([]);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to update selected services
    const handleServiceChange = (integrationCode) => {
       setSelectedServices((prevSelectedServices) => {
            if (prevSelectedServices.includes(integrationCode)) {
                return prevSelectedServices.filter((code) => code !== integrationCode);
            } else {
                return [...prevSelectedServices, integrationCode];
            }

        });
    };

    // Function to update selected statuses
    const handleStatusChange = (id) => {
        setSelectedStatuses((prevSelectedStatuses) => {
            if (prevSelectedStatuses.includes(id)) {
                return prevSelectedStatuses.filter((statusId) => statusId !== id);
            } else {
                return [...prevSelectedStatuses, id];
            }
        });
    };

    // Function to update selected account types
    const handleAccountTypeChange = (id) => {
        setSelectedAccountTypes((prevSelectedAccountTypes) => {
            if (prevSelectedAccountTypes.includes(id)) {
                return prevSelectedAccountTypes.filter((typeId) => typeId !== id);
            } else {
                return [...prevSelectedAccountTypes, id];
            }
        });
    };

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetAllServices() {
        await api.get(GET_SERVICES, config)
            .then(resp => {
                let serviceDataLocal = [];
                resp.data.map((services) => {
                    if (services.serviceType === "ONLINE") {
                        serviceDataLocal.push(services);
                    }
                })
                setServiceData(serviceDataLocal);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(MANAGEMENT_CONSOLE_STATUS_TYPES, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
        // setStatusData(statusDataStatic)   // comment this once api are made
    };

    // Function to call all dropdown values in Account Type Section in Filter Box
    async function GetAllAccountType() {
        await api.get(MANAGEMENT_CONSOLE_ACCOUNT_TYPES, config)
            .then(resp => {
                setAccountTypeData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
        // setAccountTypeData(AccountTypeDataStatic) // comment this once api are made
    };

    // Function to handle "All Services" checkbox
    const handleAllServicesChange = (isChecked) => {
        setSelectedServices(isChecked ? serviceData.map((cloudService) => cloudService.integrationCode) : []);
    };

    // Function to handle "All Statuses" checkbox
    const handleAllStatusesChange = (isChecked) => {
        setSelectedStatuses(isChecked ? statusData.map((status) => status.id) : []);
    };

    // Function to handle "All Account Types" checkbox
    const handleAllAccountTypesChange = (isChecked) => {
        setSelectedAccountTypes(isChecked ? accountTypeData.map((account) => account.id) : []);
    };

    useEffect(() => {
        (props.serviceId != null || props.serviceData != undefined) ? setSelectedServices(props.serviceId) : setSelectedServices([]);
        (props.status != null || props.status != undefined) ? setSelectedStatuses(props.status) : setSelectedStatuses([]);
        (props.accountType != null || props.accountType != undefined) ? setSelectedAccountTypes(props.accountType) : setSelectedAccountTypes([]);
        GetAllServices();
        GetAllAccountType();
        GetAllStatuses();
    }, []);

    return (
        <>
            {(serviceData && statusData && accountTypeData) && <div className="filter-box">
                <Container>
                    <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                        <h2>Filters</h2>
                        <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                    </Col>


                    <Row className="mb-3 mobile-filter-row">
                        <Col className="filter-column">
                            <h3 className="mb-3">Cloud Service</h3>
                            <div>
                                <div className="filter-options">
                                    <>
                                        {serviceData && serviceData.length > 0 && <Form.Check
                                            className="mb-2"
                                            type="checkbox"
                                            id={`service-all`}
                                            label="All Services"
                                            checked={(selectedServices.length === serviceData.length)}
                                            onChange={(e) => handleAllServicesChange(e.target.checked)}
                                        />}
                                        {serviceData && serviceData.length > 0 &&
                                            serviceData.map((cloudService) => {
                                                return (
                                                    <Form.Check
                                                        className="mb-2"
                                                        key={cloudService.integrationCode}
                                                        type="checkbox"
                                                        id={`service-${cloudService.integrationCode}`}
                                                        label={cloudService.name}
                                                        checked={selectedServices.includes(cloudService.integrationCode)}
                                                        onChange={() => handleServiceChange(cloudService.integrationCode)}
                                                    />
                                                );
                                            })}
                                    </>
                                </div>
                            </div>

                        </Col>
                        <Col className="filter-column">
                            <h3 className="mb-3">Status</h3>
                            <div>
                                <div className="filter-options">
                                    <>
                                        {statusData && statusData.length > 0 && <Form.Check
                                            className="mb-2"
                                            type="checkbox"
                                            id={`status-all`}
                                            label="All Statuses"
                                            checked={(selectedStatuses.length === statusData.length)}
                                            onChange={(e) => handleAllStatusesChange(e.target.checked)}
                                        />}
                                        {statusData && statusData.length > 0 &&
                                            statusData.map((statusItem) => {
                                                return (
                                                    <Form.Check
                                                        className="mb-2"
                                                        key={statusItem.id}
                                                        type="checkbox"
                                                        id={`status-${statusItem.id}`}
                                                        label={statusItem.name}
                                                        checked={selectedStatuses.includes(statusItem.id)}
                                                        onChange={() => handleStatusChange(statusItem.id)}
                                                    />
                                                );
                                            })}
                                    </>
                                </div>
                            </div>

                        </Col>
                        <Col className="filter-column">
                            <h3 className="mb-3">Account Type</h3>
                            <div>
                                <div className="filter-options">
                                    <>
                                        {accountTypeData && accountTypeData.length > 0 && <Form.Check
                                            className="mb-2"
                                            type="checkbox"
                                            id={`account-all`}
                                            label="All Account Types"
                                            checked={(selectedAccountTypes.length === accountTypeData.length)}
                                            onChange={(e) => handleAllAccountTypesChange(e.target.checked)}
                                        />}
                                        {accountTypeData && accountTypeData.length > 0 &&
                                            accountTypeData.map((account) => {
                                                return (
                                                    <Form.Check
                                                        className="mb-2"
                                                        key={account.id}
                                                        type="checkbox"
                                                        id={`account-${account.id}`}
                                                        label={account.name}
                                                        checked={selectedAccountTypes.includes(account.id)}
                                                        onChange={() => handleAccountTypeChange(account.id)}
                                                    />
                                                );
                                            })}
                                    </>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="actions-row">
                        <Col className="d-flex justify-content-end">
                            {((selectedServices.length > 0 || selectedStatuses.length > 0 || selectedAccountTypes.length > 0) || (((props.serviceId && props.serviceId.length > 0) || (props.status && props.status.length > 0) || (props.accountType && props.accountType.length) > 0)))
                                ? <Button
                                    className="btn btn-lg mx-3 btn-border-light"
                                    variant="light"
                                    onClick={() => {
                                        setSelectedServices([]);
                                        setSelectedStatuses([]);
                                        setSelectedAccountTypes([]);
                                        props.setPageNo(1);
                                        props.GetCloudAccounts(1, props.pageSize, [], [], [], props.searchValueTable);
                                        props.setFilterFlag(false);
                                        props.setServiceId();
                                        props.setAccountType();
                                        props.setStatus();
                                    }}
                                >
                                    Clear
                                </Button> :
                                <Button
                                    className="btn btn-lg"
                                    variant="light"
                                    disabled
                                >
                                    Clear
                                </Button>}
                            {(props.status || props.accountType || props.serviceId) ||
                                (
                                    (selectedServices.length > 0 || selectedStatuses.length > 0 || selectedAccountTypes.length > 0)
                                    &&
                                    (selectedServices.length !== serviceData.length ||
                                        selectedStatuses.length !== statusData.length ||
                                        selectedAccountTypes.length !== accountTypeData.length)) ? (
                                <Button
                                    className="btn btn-lg"
                                    variant="primary"
                                    onClick={() => {
                                        // console.log('Selected Services:', selectedServices);
                                        // console.log('Selected Statuses:', selectedStatuses);
                                        // console.log('Selected Account Types:', selectedAccountTypes);
                                        props.setServiceId(selectedServices);
                                        props.setAccountType(selectedAccountTypes);
                                        props.setStatus(selectedStatuses);
                                        props.setShowFilterBox(false);
                                        props.setPageNo(1);
                                        props.GetCloudAccounts(1, props.pageSize, selectedStatuses, selectedServices, selectedAccountTypes, props.searchValueTable);
                                        props.setFilterFlag(true);
                                    }}
                                >
                                    Apply
                                </Button>
                            ) : (

                                <Button
                                    className="btn btn-lg ml-3"
                                    variant="light"
                                    disabled
                                >
                                    Apply
                                </Button>
                            )
                            }
                        </Col>
                    </Row>
                </Container>
            </div>}
        </>
    )
}

export default ManagementConsoleFilterBox