import React, { useState, useEffect } from 'react'
import { cmsApi } from '../../../Utils/API';
import { LANDING_PAGE_CMS_DATA } from '../../../Utils/GlobalConstants';
import './styles.scss'

const TermsAndConditions = ({Logout}) => {

  const [termsAndConditionsData, setTermsAndConditionsData] = useState([]);

  // Function for calling Terms And Condition data from Orchid Core CMS
  async function GetTermsAndConditionData() {
    const data = `{
          termsAndConditionsType {
            htmlContent {
              html
            }
          }
        }`;

    // Defining Header Configuration
    const config = {
      headers: {
        "Content-Type": "application/graphql",
        Accept: "application/graphql",
      },
    };

    // Axios Post Request
    await cmsApi
      .post(LANDING_PAGE_CMS_DATA, data, config)
      .then((resp) => {
        // handle success
        // console.log(resp.data.data.termsAndConditionsType[0].htmlContent.html);
        setTermsAndConditionsData(
          resp.data.data.termsAndConditionsType[0].htmlContent.html
        );
      })
      .catch((error) => {
        if (error?.status == 401 || error?.response?.status == 401) {
          Logout();
        }
      });
  }

  // UseEffect Defination to call the get TermsAndCondition items function from CMS
  useEffect(() => {
    GetTermsAndConditionData();
  }, []);

  return (
    <div className='container-fluid mb-6 cloud-terms-and-condition mt-4' dangerouslySetInnerHTML={{ __html: termsAndConditionsData }}>
    </div>
  )
}

export default TermsAndConditions
