import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionContext, Button, Card, Col, Container, Form, OverlayTrigger, Row, Table, Tooltip, useAccordionButton } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './GWSChangePaymentPlan.module.scss';
import DataTable from 'react-data-table-component';
import { api } from '../../../../../../../../Utils/API';
import { GOOGLE_WORKSPACE_CHANGE_PAYMENT_PLAN, GOOGLE_WORKSPACE_CHANGE_PAYMENT_PLAN_POST } from '../../../../../../../../Utils/GlobalConstants';
import CustomNumberSimpleInput from '../../../../../../../../GlobalComponents/CustomNumberSimpleInput';
import EmptyDataTableFilterSearch from '../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif"


const GWSChangePaymentPlan = ({ subscriptionTableAPIData, subscriptionId, planName, changePaymentPlanActionPopupModalOpen, setChangePaymentPlanActionPopupModalOpen,
    columnsSubscriptionTable, tableSubscriptionTable, setToastSuccess, setToastError, setToastMessage, setActionCompletedRefreshSubscriptionTable, setUpBillingActionFlag, setSubscriptionTableAPIData, Logout,
setIsActionInProgress,
    setIsAutoRefreshEnabled  }) => {

    const alphanumericRegex = /^[a-zA-Z0-9-]{0,50}$/; // RegEx for PO Number

    const [loading, setLoading] = useState(false);                                                   //loading state while loading table data
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);                                      //order confirmation terms and condition tick check

    let ChangePaymentPlanColumnNameStep1 = ["", "Plan", "Billing Term",
        "RRP (Inc GST)", "Reseller Price (Ex GST)",];                                            //stepper step 1 data columns
    const [coreChangePaymentPlanDetails, setCoreChangePaymentPlanDetails] = useState();              //data containing all details of step 1 in stepper 
    let ChangePaymentPlanColumnNameStep2 = ["Plan", "Billing Term",                //stepper step 2 data columns
        "RRP (Inc GST)", "Reseller Price (Ex GST)", "Quantity", "Sub-Total Price"];
    const [changePaymentPlanCoreTableStep2, setChangePaymentPlanCoreTableStep2] = useState([]);      // react data table for stepper step 2
    const [changePaymentPlanCoreColumnsStep2, setChangePaymentPlanCoreColumnsStep2] = useState([]);  // columns for react data table stepper step 2
    let ChangePaymentPlanColumnNameStep3 = ["Plan", "Billing Term",                // stepper step 3 columns
        "Unit Price","Renewal Price (Ex GST)*", "Quantity", "Sub-Total Price"];
    const [changePaymentPlanCoreTableStep3, setChangePaymentPlanCoreTableStep3] = useState([]);      // react data table for stepper step 3
    const [changePaymentPlanCoreColumnsStep3, setChangePaymentPlanCoreColumnsStep3] = useState([]);  // columns for react data table stepper step 3
    const [coreChangePaymentPlanNewQuantity, setCoreChangePaymentPlanNewQuantity] = useState(null);      // new edit quantity change
    const [poNumber, setPoNumber] = useState("");                                                    // storing PO number in order confirmation
    const [defaultChangePaymentPlan, setDefaultChangePaymentPlan] = useState();                      //stepper step1 which contain selected data
    const [allSelectedDetails, setAllSelectedDetails] = useState([]);                                // data stored in step 1
    const [confirmChangePaymentPlanLoadingFlag, setConfirmChangePaymentPlanLoadingFlag]              // change payment loading state
        = useState(false);
    const [emptyDataError, setEmptyDataError] = useState(false);                                     // to check empty data error-204
    const [serverError, setServerError] = useState(false);                                           // to check server error-400

    //Stepper
    const steps = ['Select SKU', 'Add Quantity'];                                                    // all steps of stepper
    const [activeStep, setActiveStep] = useState(0);                                                 // active step in stepper

    //buttons for change payment stepper
    const [buttonCheckstep1, setButtonCheckstep1] = useState(false);
    const [buttonCheckstep2, setButtonCheckstep2] = useState(false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    //change payment plan core step 1
    async function GetChangePaymentPlanDetails(subscriptionId, planName) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        (planName === undefined || planName === null) ? planName = "" : planName = planName;
        await api.get(`${GOOGLE_WORKSPACE_CHANGE_PAYMENT_PLAN}?subscriptionId=${subscriptionId}&planName=${planName}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyDataError(true);
                }
                // handle success
                else {
                    setEmptyDataError(false);
                    setServerError(false);
                    setCoreChangePaymentPlanDetails(resp.data.purchasableOfferDetails);
                }
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    //change payment plan core step 2
    function CoreChangePaymentPlanStep2() {
        if (subscriptionTableAPIData) {
            // Column Edited Quantity
            let f = [];
            f.push
                (
                    {
                        "RRP (Inc GST)": `$${allSelectedDetails.rrpInclusiveTax}`,
                        "Reseller Price (Ex GST)": `$${allSelectedDetails.price}`,
                        "Billing Term": allSelectedDetails.billingCommitmentTerm,
                        "Plan": allSelectedDetails.planName,
                        "Quantity":
                            <div className='d-flex flex-wrap my-2'>
                                <CustomNumberSimpleInput
                                    defaultValue={coreChangePaymentPlanNewQuantity ? coreChangePaymentPlanNewQuantity : allSelectedDetails.defaultQuantity}
                                    min={allSelectedDetails.minValue}
                                    max={allSelectedDetails.maxValue}
                                    setUpdatedQuanity={setCoreChangePaymentPlanNewQuantity}
                                />
                            </div>
                        ,
                        "Sub-Total Price": `$${coreChangePaymentPlanNewQuantity ? ((allSelectedDetails.price) * coreChangePaymentPlanNewQuantity).toFixed(2) : ((allSelectedDetails.price)) * subscriptionTableAPIData.quantity}`
                    }
                );
            setChangePaymentPlanCoreTableStep2(f);         // Setting Table Data      
            let d = []
            ChangePaymentPlanColumnNameStep2.map((td) => {
                if (td === "Sub-Total Price") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        grow: 3,
                        allowOverflow: true,
                    })
                }
                else if (td === "Billing Term") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                            >
                                <span className="infoIcon-support-table"></span>
                            </OverlayTrigger></div>,
                        selector: row => <div className="text-wrap">
                            {row[`${td}`]}</div>,
                        left: true,
                        grow: 3,
                        allowOverflow: true,
                    })
                }
                else if (td === "Reseller Price (Ex GST)") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        grow: 3.5,
                        allowOverflow: true,
                    })
                }
                else if (td === "RRP (Inc GST)") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        grow: 2.5,
                        allowOverflow: true,
                    })
                }
                else {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        grow: 3,
                        allowOverflow: true,
                    })
                }
            })
            setChangePaymentPlanCoreColumnsStep2(d);
        }
    }
    //order confirmation table last step for change payment plan core 
    function CoreChangePaymentPlanOrderConfirmation() {
        if (subscriptionTableAPIData) {
            // Column change payment plan step 3
            let f = [];
            f.push
                (
                    {
                        "Unit Price": `$${allSelectedDetails.price}`,
                        "Plan": allSelectedDetails.planName,
                        "Renewal Price (Ex GST)*":`$${allSelectedDetails.rrpExclusiveTax}`,
                        "Quantity": coreChangePaymentPlanNewQuantity,
                        "Billing Term": allSelectedDetails.billingCommitmentTerm,
                        "Sub-Total Price": `$${coreChangePaymentPlanNewQuantity ? ((allSelectedDetails.price) * coreChangePaymentPlanNewQuantity).toFixed(2) : ((allSelectedDetails.price)) * subscriptionTableAPIData.quantity}`
                    }
                );
            setTimeout(() => {
                setChangePaymentPlanCoreTableStep3(f);         // Setting Table Data
            }, 50);
            let d = []
            ChangePaymentPlanColumnNameStep3.map((td) => {
                if (td === "Unit Price" || td === "Sub-Total Price" || td === "Renewal Price (Ex GST)*") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        allowOverflow: true,
                    })
                }
                else if (td === "Quantity") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        grow: 1,
                        allowOverflow: true,
                    })
                }
                else if (td === "Billing Term") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                            >
                                <span className="infoIcon-support-table ml-1"></span>
                            </OverlayTrigger></div>,
                        selector: row => <div className="text-wrap">
                            {row[`${td}`]}</div>,
                        left: true,
                        grow: 1.5,
                        allowOverflow: true,
                    })
                }
                else {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        allowOverflow: true,
                    })
                }
            })
            setChangePaymentPlanCoreColumnsStep3(d);     // Setting Column Data  
        }
    }

    //post api for change payment confirmation
    async function CoreOrderChangePaymentPlanConfirmation() {
        setConfirmChangePaymentPlanLoadingFlag(true);

        const data = {
            "offerName": allSelectedDetails.billingFrequency,
            "offerSku": allSelectedDetails.offerSku,
            "paymentPlan": allSelectedDetails.paymentPlan,
            "planName": allSelectedDetails.planName,
            "poNumber": poNumber,
            "quantity": parseInt(coreChangePaymentPlanNewQuantity),
            "skuName": allSelectedDetails.skuName,
            "subscriptionId": parseInt(subscriptionId)
        };
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(GOOGLE_WORKSPACE_CHANGE_PAYMENT_PLAN_POST, data, configFile)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmChangePaymentPlanLoadingFlag(false);
                    setChangePaymentPlanActionPopupModalOpen(false);

                    setToastMessage("Payment plan updated successfully.");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setActionCompletedRefreshSubscriptionTable(true);
                    setIsActionInProgress(false);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setConfirmChangePaymentPlanLoadingFlag(false);
                setToastMessage("We encountered an issue while updating the payment plan. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
                setIsActionInProgress(true);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(false);

            })
    };

    // Stepper
    //last step back button
    const handleBackOrderConfirmation = () => {
        setButtonCheckstep2(false);
        setPoNumber("");

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCoreChangePaymentPlanNewQuantity(allSelectedDetails.defaultQuantity);
    };

    const handleNextStep1 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        CoreChangePaymentPlanStep2();
    };
    const handleNextStep2 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonCheckstep2(true);
        CoreChangePaymentPlanOrderConfirmation();
    };

    // Toggle the state so Table changes to clearSelectedRows are triggered
    const handleClearRows = () => {
        setDefaultChangePaymentPlan();
        setButtonCheckstep1(false);
        setButtonCheckstep2(false);
    }

    // accordion
    function CustomToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = activeEventKey === eventKey;
        return (
            <span
                className='pt-1 align-content-center'
                onClick={decoratedOnClick}
            >{children}
                {isCurrentEventKey ? (
                    <span className='up-accordion' />
                ) : (
                    <span className='down-accordion' />
                )}
            </span>
        );
    }
    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                borderBottom: '0.5px solid #DDDDDD',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    // React Data Table Custom Styles for change payment plan
    const customStylesChangePaymentPlan = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
                borderBottom: '0.5px solid #DDDDDD',
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa'
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                wordBreak: 'normal',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    useEffect(() => {
        GetChangePaymentPlanDetails(subscriptionId, planName);
    }, [subscriptionId])

    useEffect(() => {
        CoreChangePaymentPlanStep2();
    }, [coreChangePaymentPlanNewQuantity])

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xl"
                open={changePaymentPlanActionPopupModalOpen}
                onClose={() => { setChangePaymentPlanActionPopupModalOpen(false); setServerError(false); setEmptyDataError(false); setSubscriptionTableAPIData(null); 
                    setIsActionInProgress(true);
                    console.log("auto refresh 3");
                    setIsAutoRefreshEnabled(false);
                }}
                closeOnDocumentClick={!(confirmChangePaymentPlanLoadingFlag || buttonCheckstep2 || buttonCheckstep1)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header">
                            {!setUpBillingActionFlag ? <h2>{buttonCheckstep2 ? "Order Confirmation - Change Payment Plan" : "Change Payment Plan"}</h2> : <h2>{buttonCheckstep2 ? "Order Confirmation - Setup Billing" : "Setup Billing"}</h2>}
                            <XLg
                                size={18}
                                className="cpointer text-strong"
                                onClick={() => { if (!confirmChangePaymentPlanLoadingFlag) close();
                                    setIsActionInProgress(true);
                    console.log("auto refresh 3");
                    setIsAutoRefreshEnabled(false);
                                 }}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className={`upgrade-modal-height ${styles['modal-width']}`}>
                            <Accordion defaultActiveKey="1">
                                <Card className={`${styles['accordion-changePaymentPlanGWS']}`}>
                                    <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                                        <Col md={6} className="mt-2 mb-2">
                                            <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                                        </Col>
                                        <Col md={5} className="mt-2 mb-2">
                                            <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}> Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                                        </Col>
                                        <Col md={1} className='text-right'><CustomToggle className='text-left text-center' eventKey="0"></CustomToggle></Col>
                                    </Row>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div className={`table-responsive  ${styles['gws-subsription-details-table']}`}>
                                                {tableSubscriptionTable &&
                                                    <DataTable
                                                        columns={columnsSubscriptionTable}
                                                        data={tableSubscriptionTable}
                                                        theme="solarized"
                                                        customStyles={customStyles}
                                                        persistTableHead={false}
                                                        noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                        width="100%"
                                                        fixedHeaderScrollHeight="60vh"
                                                        progressPending={loading}
                                                        progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                        fixedHeader
                                                    />
                                                }
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                            <Box sx={{ width: '100%' }} className='pt-6'>
                                <Row >
                                    <Col md={3}></Col>
                                    <Col md={6}
                                        className={`${styles['button-color-manage-gws']}`}>
                                        {activeStep !== steps.length && (
                                            <Stepper activeStep={activeStep}>
                                                {steps.map((label, index) => {
                                                    const stepProps = {};
                                                    const labelProps = {};
                                                    return (
                                                        <Step key={label} {...stepProps}>
                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        )}
                                    </Col>
                                    <Col md={3}></Col>
                                </Row>
                                {buttonCheckstep2 && <div className={`${styles['gws-changePaymentPlanGWS-core-step3']}`}>
                                    {changePaymentPlanCoreTableStep3 &&
                                        <DataTable
                                            columns={changePaymentPlanCoreColumnsStep3}
                                            data={changePaymentPlanCoreTableStep3}
                                            theme="solarized"
                                            customStyles={customStyles}
                                            persistTableHead={false}
                                            noDataComponent={<div className={styles["loading-plans"]}><p><b>Loading Plans. . .</b></p></div>}
                                            width="100%"
                                            fixedHeaderScrollHeight="31vh"
                                            progressPending={loading}
                                            progressComponent={<div className={styles["loading-plans"]}><p><b>Loading Plans. . .</b></p></div>}
                                            fixedHeader
                                        />
                                    }
                                     <p className='mt-4'><span className='text-strong'>Note* :</span> Renewal price is based on current pricing. This will change if new prices are published before renewal by Google.</p>
                                    <Row>
                                        <Col>
                                            <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="w-auto d-inline ml-3"
                                                id="inputPONumber"
                                                maxlength="50"
                                                placeholder='Add PO Number'
                                                aria-describedby="inputPONumber"
                                                value={poNumber}
                                                onChange={(e) => {
                                                    alphanumericRegex.test(e.target.value)
                                                        ? setPoNumber(e.target.value)
                                                        : console.log("")
                                                }}
                                            />
                                            <Form.Text id="poNumber" />

                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6}>
                                            <Form.Check // prettier-ignore
                                                type="checkbox"
                                                id="termsCheckBox"
                                                label=""
                                                disabled
                                                checked={isTermsChecked}
                                                className="d-inline-block w-auto"
                                            />
                                            <p className="d-inline-block  w-auto mb-0">
                                                I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                            </p>

                                        </Col>
                                        <Col md={6}>
                                            <div className="btn-wrapper text-right d-md-block d-none">
                                                {isTermsChecked && <>
                                                    {!confirmChangePaymentPlanLoadingFlag && <Button
                                                        className="px-4 mx-2"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBackOrderConfirmation}
                                                    >Back</Button>}
                                                    {!confirmChangePaymentPlanLoadingFlag && <Button
                                                        className="px-4 mx-2"
                                                        onClick={() => {
                                                            CoreOrderChangePaymentPlanConfirmation();
                                                        }}
                                                    >
                                                        Confirm
                                                    </Button>}
                                                    {confirmChangePaymentPlanLoadingFlag && <Button
                                                        className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled
                                                    >Back</Button>}
                                                    {confirmChangePaymentPlanLoadingFlag &&
                                                        <Button
                                                            disabled
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                        >
                                                            Processing . . .
                                                        </Button>}
                                                </>
                                                }
                                                {!isTermsChecked &&
                                                    <>
                                                        <Button
                                                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled={activeStep === 0}
                                                            onClick={handleBackOrderConfirmation}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Back
                                                        </Button>

                                                        <Button
                                                            variant="light"
                                                            className="px-4 mx-2"
                                                            disabled
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>}
                                <React.Fragment>
                                    {serverError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                        </div>
                                    }
                                    {emptyDataError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">No Data Found</h2>
                                        </div>
                                    }
                                    {activeStep === 0 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        {!serverError && !emptyDataError &&
                                            <div className={`table-responsive pt-3 ${styles['gws-changePaymentPlanGWS-core']}`}>
                                                {!coreChangePaymentPlanDetails &&
                                                    <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                                {coreChangePaymentPlanDetails &&
                                                    <Table responsive="md">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                {ChangePaymentPlanColumnNameStep1.map(th => {
                                                                    return (<th className={(th === "RRP (Inc GST)" || th === "Reseller Price (Ex GST)") ? "text-right" : ""}>{
                                                                        th === "Billing Term" ? <>{th}
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}

                                                                            >
                                                                                <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                                                            </OverlayTrigger></> : th}</th>)
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                coreChangePaymentPlanDetails.map((td) => {
                                                                    return (<tr>
                                                                        <td><input type='radio'
                                                                            id={td.offerSku}
                                                                            name="upgradeOptionsStep2"
                                                                            checked={defaultChangePaymentPlan === (td.offerSku)}
                                                                            onChange={() => {
                                                                                setDefaultChangePaymentPlan(td.offerSku);
                                                                                setButtonCheckstep1(true);
                                                                                setAllSelectedDetails(td);
                                                                            }}
                                                                        />
                                                                        </td>
                                                                        <td>{td.planName}</td>
                                                                        <td>{td.billingCommitmentTerm}</td>
                                                                        <td className='text-right'>${td.rrpInclusiveTax}</td>
                                                                        <td className='text-right'>${td.price}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>}
                                    </Typography>}
                                    {activeStep === 1 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        <div className={`table-responsive pt-3 ${styles['gws-changePaymentPlanGWS-core-step2']}`}>
                                            {changePaymentPlanCoreTableStep2 &&
                                                <DataTable
                                                    columns={changePaymentPlanCoreColumnsStep2}
                                                    data={changePaymentPlanCoreTableStep2}
                                                    theme="solarized"
                                                    customStyles={customStylesChangePaymentPlan}
                                                    persistTableHead={false}
                                                    noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="31vh" progressPending={loading}
                                                    progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                                    fixedHeader
                                                />
                                            }
                                        </div>
                                    </Typography>}
                                    {/* buttons for Change payment plan for stepper */}
                                    {!serverError && !emptyDataError &&
                                        <div className='text-right pt-4'>
                                            {activeStep === 0 &&
                                                <React.Fragment>
                                                    {buttonCheckstep1 && <Button
                                                        onClick={handleClearRows}
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light" sx={{ mr: 1 }}>
                                                        Clear
                                                    </Button>}
                                                    {!buttonCheckstep1 && <Button className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled>
                                                        Clear
                                                    </Button>}
                                                    {!buttonCheckstep1 && <Button onClick={handleNextStep1}
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Next
                                                    </Button>}
                                                    {buttonCheckstep1 && <Button onClick={handleNextStep1}
                                                    >
                                                        Next
                                                    </Button>}
                                                </React.Fragment>
                                            }
                                            {activeStep === 1 &&
                                                <React.Fragment>
                                                    <Button
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        sx={{ mr: 1 }}
                                                    > Back  </Button>
                                                    {<Button onClick={handleNextStep2}
                                                    >
                                                        Proceed
                                                    </Button>}
                                                </React.Fragment>
                                            }
                                        </div>}
                                </React.Fragment>

                            </Box>
                        </Container>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default GWSChangePaymentPlan
