import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { GET_CONNECTWISE_AGREEMENT_ADDITION } from '../../../../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../../../../Utils/API';
import styles from './AdditionSelection.module.scss';
import loadingGif from "../../../../../../../../../../Assets/Images/Animations/loading-management-console.gif"

const AdditionSelection = ({ setActiveStep, additionData, setAdditionData, defaultAdditionSelected, setDefaultAdditionSelected, setAdditionDescription, setConfirmationCheck, agreementId, additionName, setAdditionName, setAdditionId, defaultAdditionName, defaultAgreementName, defaultCompanyName, companyName, agreementName, Logout }) => {
    const [searchValueTable, setSearchValueTable] = useState(null);                         // storing searching value in the search input box
    const [searchIconFlag, setSearchIconFlag] = useState(false);                            // to open and close search box
    const searchInputRef = useRef();                                                        // Search Input Box

    let AdditionColumnName = ["", "Addition Names", "Addition Description", "Is Mapped"];   // Columns of Addition table 
    const [emptyData, setEmptyData] = useState(false);                                      // empty data in table during api call to load data
    const [loadingState, setLoadingState] = useState(false);                                // loading state
    const [defaultAdditionData, setDefaultAdditionData] = useState(additionData);                                // loading state


    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // get api for addition mapping data
    async function GetConnectwiseAdditionData(additionName) {
        setLoadingState(true);
        (additionName === undefined || additionName === null) ? additionName = "" : additionName = additionName;
        await api.get(`${GET_CONNECTWISE_AGREEMENT_ADDITION}${agreementId}/connectwise-additions?integrationId=${"1"}&addtionName=${additionName}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setLoadingState(false);
                    setEmptyData(true);
                }

                else {
                    setLoadingState(false);
                    setEmptyData(false);
                    setAdditionData(resp.data);
                    setDefaultAdditionData(resp.data);
                }

            })
            .catch(error => {
                setLoadingState(false);
                setEmptyData(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            if (searchValueTable === "") {
                setAdditionData(defaultAdditionData);
            }
            else {
                const getData = setTimeout(() => {
                    let localData = [];
                    additionData.map(item => {
                        if (item.additionName.toLowerCase().includes(searchValueTable.toLowerCase())) {
                            localData.push(item);
                        }
                    })
                    setAdditionData(localData);
                }, 800);
                return () => clearTimeout(getData);
            }
        }
    }, [searchValueTable])

    useEffect(() => {
        if (agreementId)
            GetConnectwiseAdditionData();
        if (defaultAgreementName == agreementName) {
            setAdditionName(defaultAdditionName);
        }
        else {
            setAdditionName(null);
            setDefaultAdditionSelected(null);
        }
    }, []);

    useEffect(() => {
        if (additionData) {
            if (additionData.length === 0)
                setEmptyData(true);
            else
                setEmptyData(false);
        }
    }, [additionData]);

    return (
        <div sx={{ mt: 2 }}>
            <Row className='mb-3'>
                <Col md={8}> <h3 className="mb-5 px-3 mt-2 notes-confirmation">Additions</h3></Col>
                <Col md={4}>
                    <div className="text-right">
                        <InputGroup className="search-input-box search-input-box-xl ">
                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                <span className="searchgrey cpointer"
                                >
                                    &nbsp;
                                </span>
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                className="search-input-box-input"
                                value={searchValueTable}
                                onChange={(e) => setSearchValueTable(e.target.value)}
                                ref={searchInputRef}
                            />
                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                onClick={() => {
                                    setSearchValueTable("");
                                    setSearchIconFlag(!searchIconFlag);
                                    setAdditionData(defaultAdditionData);
                                }}
                            >
                                <span className="closebtn">
                                    &nbsp;
                                </span>
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            {/* loading State */}
            {loadingState &&
                <div className={styles["loading-customers"]}>
                    <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                </div>}

            {!emptyData && !loadingState &&
                <div className={styles["table-height-adjustment"]}>
                    <Table responsive="md">
                        <thead className="sticky-top">
                            <tr>
                                {AdditionColumnName.map(th => {
                                    return (<th>{th}</th>)
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                additionData && additionData.map((item) => {
                                    return (
                                        <tr key={item.additionId}>
                                            <td><input type='radio'
                                                style={{ verticalAlign: 'bottom' }}
                                                id={item.additionId}
                                                name="additionData"
                                                disabled={item.isMapped}
                                                defaultChecked={defaultAdditionSelected == (item.additionId)}
                                                onChange={() => {
                                                    setDefaultAdditionSelected(item.additionId);
                                                    setAdditionName(item.additionName);
                                                    setAdditionDescription(item.additionDescription);
                                                    setAdditionId(item.additionId);
                                                }}
                                            />
                                            </td>
                                            <td>{item.additionName}</td>
                                            <td>{item.additionDescription}</td>
                                            <td>{!item.isMapped ? "No" : "Yes"}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>}
            {/* empty search result */}
            {emptyData && !loadingState &&
                <div className={styles["no-data-cloud-invoice"]}>
                    <h3 className="mt-4 mb-3">No Data Found</h3>
                </div>
            }
            <Row className='px-3 mt-3'>
                <Col md={6}>
                    <div className='text-small mb-5 mt-3'>
                        <span className='text-strong'>Addition name : </span>
                        {additionName}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="btn-wrapper text-right">
                        <Button
                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                            variant="light"
                            onClick={() => {
                                setActiveStep(1);

                            }}
                        >
                            Back
                        </Button>
                        {!emptyData && companyName && agreementName && additionName && (companyName !== defaultCompanyName || agreementName !== defaultAgreementName || additionName !== defaultAdditionName) ?
                            <Button
                                className="px-4 mx-2"
                                onClick={() => {
                                    setConfirmationCheck(true);
                                }}
                            >Proceed</Button> : <Button
                                className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                variant="light"
                                disabled  >Proceed</Button>}
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default AdditionSelection
