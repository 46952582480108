import React, { useState, useEffect } from 'react'
import styles from './managementConsoleWidgets.module.scss'
import { Col, Row } from 'react-bootstrap'
import { MANAGEMENT_CONSOLE_WIDGET } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';

const ManagementConsoleWidgets = (props) => {

  const [managementConsoleWidget, setManagementConsoleWidget] = useState(null);
  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  // Function to call all Customer count data
  async function GetManagementConsoleWidget() {
    await api.get(`${MANAGEMENT_CONSOLE_WIDGET}?requestedResellerCode=${props.requestedResellerCode}`, config)
      .then(resp => {
        setManagementConsoleWidget(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          props.Logout();
        }
        else {
        }
      });
  };

  useEffect(() => {
    GetManagementConsoleWidget();
  }, [])

  useEffect(() => {
    if(props.requestedResellerCode != null && props.requestedResellerCode != "")
    GetManagementConsoleWidget()
  }, [props.requestedResellerCode])

  return (
    <Row className='my-3'>
      {managementConsoleWidget && managementConsoleWidget.map((widget) => {
        return (
          <Col lg={2} md={5} xs={5} className={`${styles["widget-body"]} px-3 mx-2 mr-4`}>
            <span>{widget.widgetTitle}</span>
            <h3 className={widget.widgetTitle === "Active Subscriptions" ? styles["widget-body-green"] : (widget.widgetTitle === "Trial Subscriptions" ? styles["widget-body-default"] : (widget.widgetTitle === "Expiring Subscriptions" ? styles["widget-body-yellow"] : styles["widget-body-green"]))}>{`${Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format((parseInt(widget.count)).toFixed(2))}`}</h3>
          </Col>
        )
      })}
    </Row>
  )
}

export default ManagementConsoleWidgets
