import React , {useState,useEffect} from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './businessContact.module.scss'
import { CUSTOMERS_BUSINESS_CONTACT_DETAILS } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import { useLocation } from "react-router-dom";

const BusinessContact = ({ Logout }) => {

  const location = useLocation();   
  const [customerCode, setCustomerCode] = useState(location.state !== null ? location.state.customerCode : "");  // used for api
  const [businessContactDetails, setBusinessContactDetails] = useState(null);                                    // all the other details for business contact
  const [businessContactNameInitials, setBusinessContactNameInitials] = useState(null);                          // intials (name)

    // Header Authorization for General API's
    const config = {
      headers: {
        "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
      },
    };
  
    // Function to call Business contact api
    async function GetBusinessContact() {
      await api.get(`${CUSTOMERS_BUSINESS_CONTACT_DETAILS}/${customerCode}`, config)
        .then(resp => {
          let initials = "";
          setBusinessContactDetails(resp.data); 
          initials = resp.data.customerName.split(" ")[0].slice(0,1) + resp.data.customerName.split(" ")[1].slice(0,1);
          setBusinessContactNameInitials(initials.toUpperCase());
        })
        .catch(error => {
          if (error?.status == 401 || error?.response?.status == 401) {
            Logout();
          }
          else {
          }
        });
    };

  useEffect(() => {
    GetBusinessContact()
  }, [])

  return (
    <div className={`${styles["business-contact"]} mt-4`} >
        {businessContactDetails && <Row>
            <Col md={3} className={`${styles["customer-profile-outer"]}`}>
                <span className={`${styles["customer-profile"]}`}>{businessContactNameInitials ? businessContactNameInitials : ""}</span>
            </Col>
            <Col md={9}>
                <h3>Business Contact</h3>
                <p className={`mb-0 py-1`}><span className="text-strong">{businessContactDetails.customerName}</span></p>
                <p className={`mb-0 py-1`}><span className="text-strong">+61{businessContactDetails.phoneNumber}</span></p>
                <p className={`mb-0 py-1`}><span className="text-strong">{businessContactDetails.email}</span></p>
            </Col>
        </Row>}
    </div>
  )
}

export default BusinessContact
