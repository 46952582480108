import React from 'react'
import { useState , useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import styles from './notifications.module.scss'
import { XLg } from 'react-bootstrap-icons'
import { DELETE_ALL_NOTIFICATIONS, DELETE_NOTIFICATION, DELETE_NOTIFICATIONS_DATE, GET_ALL_NOTIFICATIONS, READ_NOTIFICATION } from '../../../Utils/GlobalConstants'
import { api } from '../../../Utils/API'
import Toast from '../../../GlobalComponents/Toast'
import EmptyNotification from '../../../Assets/Images/Icons/no-notifications.svg';

const Notifications = ({setHookRecentNotifications,hookNotifications,setHookNotifications}) => {
    const [notifications, setNotifications] = useState(null)

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [emptyNotification, setEmptyNotification] = useState(false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };
    
        // Function to call account details (profile details)
        async function GetNotifications() {
            await api.get(`${GET_ALL_NOTIFICATIONS}?pageNo=${1}&pageSize=${500}`,config)
                .then(resp => {
                    if(resp.status==200){
                        setEmptyNotification(false);
                       setNotifications(resp.data); 
                    }
                    else{
                        setEmptyNotification(true);
                    }
    
                })
                .catch(error => {
                    if (error?.status == 401 || error?.response?.status == 401) {
                        // Logout();
                    }
                    else {
                        setEmptyNotification(true);
                    }
    
                });
        };


       //   Delete Notifications
       async function DeleteNotification(notificationId) {
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(DELETE_NOTIFICATION+notificationId,null, configFile)
            .then(resp => {
                if (resp.status == 200) {
                    setToastSuccess(true);
                    setToastMessage("Notifications deleted successfully.");
                    setTimeout(() => {
                        GetNotifications();
                        setHookRecentNotifications(true);
                    }, 2000);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 3000);
                }
            })
            .catch(error => {
                setToastError(true);
                setToastMessage("We encountered an issue while deleting the notification. Please try again later.");
                setTimeout(() => {
                    setToastError(false);
                }, 3000);

            })
    };

        //   Delete Notifications by Date
        async function DeleteNotificationByDate(date) {
            const configFile = {
                headers: {
                    "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                    "Content-Type": "application/json",
                },
            };
            await api.post(`${DELETE_NOTIFICATIONS_DATE}?date=${date}`,null, configFile)
                .then(resp => {
                    if (resp.status == 200) {
                        setToastSuccess(true);
                        setToastMessage("Notifications deleted successfully.");
                        setTimeout(() => {
                            GetNotifications();
                            setHookRecentNotifications(true);
                        }, 2000);
                        setTimeout(() => {
                            setToastSuccess(false);
                        }, 3000);
                    }
                })
                .catch(error => {
                    setToastError(true);
                    setToastMessage("We encountered an issue while deleting the notification. Please try again later.");
                    setTimeout(() => {
                        setToastError(false);
                    }, 3000);
    
                })
        };


           //   Delete Notifications
           async function ClearAllNotification() {
            const configFile = {
                headers: {
                    "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                    "Content-Type": "application/json",
                },
            };
            await api.post(DELETE_ALL_NOTIFICATIONS,null, configFile)
                .then(resp => {
                    if (resp.status == 200) {
                        setToastSuccess(true);
                        setToastMessage("All Notifications deleted successfully.");
                        setTimeout(() => {
                            GetNotifications();
                            setHookRecentNotifications(true);
                        }, 2000);
                        setTimeout(() => {
                            setToastSuccess(false);
                        }, 3000);
                    }
                })
                .catch(error => {
                    setToastError(true);
                    setToastMessage("We encountered an issue while deleting the notification. Please try again later.");
                    setTimeout(() => {
                        setToastError(false);
                    }, 3000);
    
                })
        };

    //   Delete Notifications
    async function ReadNotification(notificationId) {
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(READ_NOTIFICATION+notificationId,null, configFile)
            .then(resp => {
                if (resp.status == 200) {
                    GetNotifications();
                    setHookRecentNotifications(true);
                }
            })
            .catch(error => {
                setToastError(true);
                setToastMessage("Some issue in reading notification, please try again after some time!");
                setTimeout(() => {
                    setToastError(false);
                }, 3000);

            })
    };

    useEffect(() => {
        if(selectedMessage){
            ReadNotification(selectedMessage);
            setTimeout(() => {
                setSelectedMessage(null);
            }, 1500);
        }
    }, [selectedMessage])

    useEffect(() => {
        localStorage.getItem("navigation_link") ?  localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link","/notifications");
        GetNotifications();
    }, [])

    useEffect(() => {
        if(hookNotifications===true){
            GetNotifications();
            setHookNotifications(false);
        }    
    }, [hookNotifications])

    return (
        <Container fluid className='main-content'>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
           {!emptyNotification && notifications && <Row className='mb-3'>
                <Col>
                    <h1>Notification Centre</h1>
                </Col>
                <Col className='text-right' >
                    <Button className="mx-2 btn-border-light w-md-auto col-xs-12" variant="light" onClick={()=> ClearAllNotification() }>
                        Clear All
                    </Button>
                </Col>
            </Row>}
            <div>
                {
                  !emptyNotification && notifications && notifications.map((item)=>{
                    return(
                        <div className={`${styles['notification-div']} py-3 mb-5`}>
                            <Row className='px-4 pb-2'>
                                <Col md={11}>
                                    <h3>{item.date}</h3>
                                </Col>
                                <Col md={1} className='text-right'>  
                                    <XLg
                                        size={16}
                                        className="cpointer close-icon-popup"
                                        onClick={()=>DeleteNotificationByDate(item.date)}
                                    />
                                </Col>
                            </Row>
                            {
                                item.notifications.length>0 && item.notifications.map((subItem)=>{
                                    return(
                                      <>
                                        <Row className={selectedMessage==subItem.notificationId ? 'notification-main-body-focus px-4 py-3 cpointer' : 'px-4 py-3 cpointer' } onClick={()=>{
                                            if(!(subItem.isRead)){
                                                setSelectedMessage(subItem.notificationId);
                                            }}
                                        }>
                                            <Col md={1} xs={2}>
                                                {
                                                    !(subItem.isRead) ? 
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="10"
                                                        height="10"
                                                        viewBox="0 0 9 9"
                                                        fill="none"
                                                    >
                                                        <circle cx="4.5" cy="4.8689" r="4" fill='#3599DA' />
                                                    </svg>  : <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="10"
                                                        height="10"
                                                        viewBox="0 0 9 9"
                                                        fill="none"
                                                    >
                                                        <circle cx="4.5" cy="4.8689" r="4" fill='#fff' />
                                                    </svg>
                                                }
                                                {subItem.notificationType==="COMPLETED" ? <span className="status-paid ml-1 notification-page-icon-card"></span> : (
                                                    subItem.notificationType==="FAILED" ? <span className="status-failed ml-1 notification-page-icon-card"></span> : (
                                                        <span className="status-inprogress ml-1 notification-page-icon-card"></span>
                                                    )
                                                )}
                                            </Col>
                                            <Col md={10} xs={8} className='notification-centre-description'>
                                                <p className={subItem.isRead ? 'mb-0' : 'mb-0 text-strong'}>{subItem.notificationDescription}</p>    
                                                <span className='notification-created-at text-small text-strong'>{subItem.createdAt}</span>
                                            </Col>
                                            <Col md={1} xs={2} className='text-right'>  
                                                <XLg
                                                    size={16}
                                                    className="cpointer close-icon-popup"
                                                    onClick={()=>{DeleteNotification(subItem.notificationId)}}
                                                />
                                            </Col>
                                        </Row>
                                        <hr className='my-0 mb-0 provision-left-bar-hr' />
                                      </>  
                                    )
                                })
                            }
                        </div>
                    )
                  })  
                }
            </div>
            {
                emptyNotification && <div className={`${styles['notifications-no-data']}`}>
                        <img src={EmptyNotification} alt="Notifications Empty Logo" />
                        <h2 className='text-strong mb-0'>No notifications found</h2>
                      </div>
            }
        </Container>
    )
}

export default Notifications
