import React , {useState,useEffect} from 'react'
import './styles.scss';
import { Carousel } from 'react-bootstrap';
import { api } from '../../Utils/API';
import { GET_ANNOUNCEMENTS } from '../../Utils/GlobalConstants';

const Announcement = ({ Logout }) => {
    const [announcementData, setAnnouncementData] = useState([]);               // Announcement data
    const [announcementDataEmpty, setAnnouncementDataEmpty] = useState(false);  // Empty announcement flag

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to get Announcement Data
    async function GetAnnouncements() {
        await api.get(GET_ANNOUNCEMENTS, config)
            .then(resp => {
                if(resp.status==200){
                    setAnnouncementDataEmpty(false);
                    setAnnouncementData(resp.data); 
                }
                else{
                    setAnnouncementDataEmpty(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                   Logout();
                }
                else {
                    // console.log(error);
                }

            });
    };

    useEffect(() => {
        GetAnnouncements();
    }, [])


  return (
    <div className="announcement-body-main">
        <h2 className='mb-4'>Announcements</h2>
        <div className="announcements-body">
            {!announcementDataEmpty && announcementData && announcementData.length>0 && <Carousel 
                className='caroseul-announcement py-4 pt-2'
                touch 
                fade 
                data-bs-theme="dark"
            > 
        {
            announcementData && announcementData.length>0 &&  announcementData.map((data)=>{
                return(
                    <Carousel.Item className='carosel-announcement' dangerouslySetInnerHTML={{ __html: data }}> 
                    </Carousel.Item> 
                )
                
            })
        }</Carousel> }
        { announcementDataEmpty && 
            <p className='text-center py-10'>No Announcements</p>
        }
        </div>
        
    </div>
  )
}

export default Announcement
