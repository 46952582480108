import React, { useState, useEffect } from 'react'
import { Button, Row, Col } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import styles from "./AgreementMappingFilterBox.module.scss";

const AgreementMappingFilterBox = (props) => {
    const [selectedServices, setSelectedServices] = useState([]);                // all the selected value in services
    const [selectedBillingTerms, setSelectedBillingTerms] = useState([]);        // all the selected value in billing terms
    const [searchPlanValue, setSearchPlanValue] = useState("");                  // Searched Value from Plan
    const [searchCustomerValue, setSearchCustomerValue] = useState("");          // Searched Value from Customer
    const [searchCompanyValue, setSearchCompanyValue] = useState("");            // Searched Value from Company
    const [searchAgreementValue, setSearchAgreementValue] = useState("");        // Searched Value from Agreement

    // Function to handle "All Commitment Term" checkbox
    const handleAllBillingTermsChange = (isChecked) => {
        setSelectedBillingTerms(isChecked ? props.billingTermData.map((billingTerm) => billingTerm.id) : []);
    };

    // Function to update selected billing term
    const handleBillingTermsChange = (id) => {
        setSelectedBillingTerms((prevSelectedBillingTerms) => {
            if (prevSelectedBillingTerms.includes(id)) {
                return prevSelectedBillingTerms.filter((typeId) => typeId !== id);
            } else {
                return [...prevSelectedBillingTerms, id];
            }
        });
    };

    // Function to handle "All Services" checkbox
    const handleAllServicesChange = (isChecked) => {
        setSelectedServices(isChecked ? props.serviceData.map((services) => services.id) : []);
    };

    // Function to update selected service
    const handleServicesChange = (id) => {
        setSelectedServices((prevSelectedServices) => {
            if (prevSelectedServices.includes(id)) {
                return prevSelectedServices.filter((serviceId) => serviceId !== id);
            } else {
                return [...prevSelectedServices, id];
            }
        });
    };

    const clearFilterSearchPlan = () => { // Clear Filter Box Search Input
        setSearchPlanValue("");
    }

    const clearFilterSearchCustomer = () => { // Clear Filter Box Search Input
        setSearchCustomerValue("");
    }

    const clearFilterSearchCompany = () => { // Clear Filter Box Search Input
        setSearchCompanyValue("");
    }

    const clearFilterSearchAgreement = () => { // Clear Filter Box Search Input
        setSearchAgreementValue("");
    }

    // when clear button is clicked
    const clearFilterBox = () => {
        if (props.serviceId && props.serviceId !== null) {
            const element = document.getElementById(props.serviceId);
            if (element) {
                element.checked = false;
            }
        };
        if (props.companyId && props.companyId !== null) {
            const element = document.getElementById(props.companyId);
            if (element) {
                element.checked = false;
            }
        };

        if (props.agreementId && props.agreementId !== null) {
            const element = document.getElementById(props.agreementId);
            if (element) {
                element.checked = false;
            }
        };

        if (props.planName && props.planName !== null) {
            const element = document.getElementById(props.planName);
            if (element) {
                element.checked = false;
            }
        };

        if (props.customerId && props.customerId !== null) {
            const element = document.getElementById(props.customerId);
            if (element) {
                element.checked = false;
            }
        };


        if (props.billingPeriodId && props.billingPeriodId !== null) {
            const element = document.getElementById(props.billingPeriodId);
            if (element) {
                element.checked = false;
            }
        };

        props.setScrollToDivTop(true);
        setSelectedBillingTerms([]);
        setSelectedServices([]);
        props.setPlanName(null);
        props.setAgreementId();
        props.setCustomerId(null);
        props.setCompanyId(null);
        props.setBillingPeriodId(null);
        props.setServiceId(null);
        props.GetTableData(props.pageNo, props.pageSize, props.searchValueTable, null, null, [], [], props.mapFilter, null, null);
        props.setFilterFlag(false);
    }

    useEffect(() => {
        (props.billingPeriodId != null || props.billingPeriodId != undefined) ? setSelectedBillingTerms(props.billingPeriodId) : setSelectedBillingTerms([]);
        (props.serviceId != null || props.serviceId != undefined) ? setSelectedServices(props.serviceId) : setSelectedServices([]);
    }, []);

    return (
        <div className={`filter-box filter-box-xl integration-filters ${styles['filter-integration']}`}>
            <>
                <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                    <h2>Filters</h2>
                    <XLg size={16} onClick={() => props.setShowFilterBox(false)} />
                </Col>
                <Row className={`mb-3 mobile-filter-row ${styles['filter-agreement-mobile']}`}>

                    <Col className="filter-column filter-column-height">
                        <h3 className="mb-3">Customer Name</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchCustomerValue}
                                    onChange={(e) => setSearchCustomerValue(e.target.value)}
                                />
                                <InputGroup.Text class="clear-search" onClick={clearFilterSearchCustomer}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">
                                {
                                    props.customerData && searchCustomerValue.length > 0 &&
                                    props.customerData.filter(option => (option.name).toLowerCase().includes(searchCustomerValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check // prettier-ignore
                                                    key={filteredOption.id}
                                                    type={'radio'}
                                                    id={filteredOption.id}
                                                    label={filteredOption.name}
                                                    name="customerGroup"
                                                    value={filteredOption.id}
                                                    aria-label={filteredOption.id}
                                                    defaultChecked={filteredOption.id == props.customerId ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setCustomerId(null) : props.setCustomerId(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })
                                }
                                {
                                    props.customerData && searchCustomerValue.length === 0 &&
                                    props.customerData.slice(0, 100).filter(option => (option.name).toLowerCase().includes(searchCustomerValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check // prettier-ignore
                                                    key={filteredOption.id}
                                                    type={'radio'}
                                                    id={filteredOption.id}
                                                    label={filteredOption.name}
                                                    name="customerGroup"
                                                    value={filteredOption.id}
                                                    aria-label={filteredOption.id}
                                                    defaultChecked={filteredOption.id == props.customerId ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setCustomerId(null) : props.setCustomerId(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })
                                }
                                {
                                    !props.customerData && <>Loading...</>
                                }
                            </div>
                        </div>
                    </Col>


                    <Col className={`filter-column ${styles['filter-column-height']}`}>
                        <h3 className="mb-3">Services</h3>
                        <div>
                            <div className="filter-options">
                                <>
                                    {props.serviceData && props.serviceData.length > 0 && <Form.Check
                                        className="mb-2"
                                        type="checkbox"
                                        id={`services-all`}
                                        label="All Services"
                                        checked={(selectedServices.length === props.serviceData.length)}
                                        onChange={(e) => handleAllServicesChange(e.target.checked)}
                                    />}
                                    {props.serviceData && props.serviceData.length > 0 &&
                                        props.serviceData.map((serviceItem) => {
                                            return (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={serviceItem.id}
                                                    type="checkbox"
                                                    id={`service-${serviceItem.id}`}
                                                    label={serviceItem.name}
                                                    checked={selectedServices.includes(serviceItem.id)}
                                                    onChange={() => handleServicesChange(serviceItem.id)}
                                                />
                                            );
                                        })}
                                </>
                            </div>
                        </div>

                    </Col>

                    <Col className={`filter-column ${styles['filter-column-height']}`}>
                        <h3 className="mb-3">Plans</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchPlanValue}
                                    onChange={(e) => setSearchPlanValue(e.target.value)}
                                />
                                <InputGroup.Text className="clear-search" onClick={clearFilterSearchPlan}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">

                                {
                                    props.planData && searchPlanValue.length > 0 &&
                                    props.planData.filter(option => (option.planName).toLowerCase().includes(searchPlanValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check
                                                    key={filteredOption.productPlanId}
                                                    type='radio'
                                                    id={filteredOption.planName}
                                                    label={filteredOption.planName}
                                                    name="plansGroup"
                                                    value={filteredOption.planName}
                                                    aria-label={filteredOption.productPlanId}
                                                    defaultChecked={filteredOption.planName === props.planName ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setPlanName(null) : props.setPlanName(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })

                                }

                                {
                                    props.planData && searchPlanValue.length === 0 &&
                                    props.planData.slice(0, 100).filter(option => (option.planName).toLowerCase().includes(searchPlanValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check
                                                    key={filteredOption.productPlanId}
                                                    type='radio'
                                                    id={filteredOption.planName}
                                                    label={filteredOption.planName}
                                                    name="plansGroup"
                                                    value={filteredOption.planName}
                                                    aria-label={filteredOption.productPlanId}
                                                    defaultChecked={filteredOption.planName === props.planName ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setPlanName(null) : props.setPlanName(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })

                                }

                                {
                                    !props.planData && <p>Loading...</p>
                                }
                            </div>
                        </div>
                    </Col>

                    <Col className="filter-column" >
                        <h3 className="mb-3">Commitment Term (Billing Frequency)</h3>
                        <div>
                            <div className="filter-options filter-options-extra-height">
                                <>
                                    {props.billingTermData && props.billingTermData.length > 0 && <Form.Check
                                        className="mb-2"
                                        type="checkbox"
                                        id={`billingTerm-all`}
                                        label="All Commitment Term"
                                        checked={(selectedBillingTerms.length === props.billingTermData.length)}
                                        onChange={(e) => handleAllBillingTermsChange(e.target.checked)}
                                    />}
                                    {props.billingTermData && props.billingTermData.length > 0 &&
                                        props.billingTermData.map((billingTermItem) => {
                                            return (
                                                <Form.Check
                                                    className="mb-2"
                                                    key={billingTermItem.id}
                                                    type="checkbox"
                                                    id={`billingTerm-${billingTermItem.id}`}
                                                    label={billingTermItem.name}
                                                    checked={selectedBillingTerms.includes(billingTermItem.id)}
                                                    onChange={() => handleBillingTermsChange(billingTermItem.id)}
                                                />
                                            );
                                        })}
                                </>
                            </div>
                        </div>

                    </Col>

                    <Col className={`filter-column ${styles['filter-column-height']}`}>
                        <h3 className="mb-3">Company</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchCompanyValue}
                                    onChange={(e) => setSearchCompanyValue(e.target.value)}
                                />
                                <InputGroup.Text className="clear-search" onClick={clearFilterSearchCompany}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">
                                {
                                    props.companyData &&
                                    props.companyData.filter(option => (option.name).toLowerCase().includes(searchCompanyValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check
                                                    key={filteredOption.id}
                                                    type='radio'
                                                    id={filteredOption.id}
                                                    label={filteredOption.name}
                                                    name="companyGroup"
                                                    value={filteredOption.id}
                                                    aria-label={filteredOption.id}
                                                    defaultChecked={filteredOption.id == props.companyId ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setCompanyId("") : props.setCompanyId(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })
                                }
                                {
                                    !props.companyData && <>Loading...</>
                                }

                            </div>
                        </div>
                    </Col>

                    <Col className={`filter-column ${styles['filter-column-height']}`}>
                        <h3 className="mb-3">Agreement</h3>
                        <div>
                            <InputGroup className="search-input-group mb-3">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search"
                                    value={searchAgreementValue}
                                    onChange={(e) => setSearchAgreementValue(e.target.value)}
                                />
                                <InputGroup.Text className="clear-search" onClick={clearFilterSearchAgreement}><span>&nbsp;</span></InputGroup.Text>
                            </InputGroup>
                            <div className="filter-options">
                                {
                                    props.agreementData &&
                                    props.agreementData.filter(option => (option.name).toLowerCase().includes(searchAgreementValue.toLowerCase())).map((filteredOption) => {
                                        return (
                                            <>
                                                <Form.Check
                                                    key={filteredOption.id}
                                                    type='radio'
                                                    id={filteredOption.id}
                                                    label={filteredOption.name}
                                                    name="agreementGroup"
                                                    value={filteredOption.id}
                                                    aria-label={filteredOption.id}
                                                    defaultChecked={filteredOption.id == props.agreementId ? true : false}
                                                    onChange={(e) => {
                                                        (e.target.value === "default") ? props.setAgreementId("") : props.setAgreementId(e.target.id);
                                                    }
                                                    }
                                                />
                                            </>
                                        )
                                    })
                                }
                                {
                                    !props.agreementData && <>Loading...</>
                                }

                            </div>
                        </div>
                    </Col>

                </Row>
                <Row className="actions-row">
                    <Col className="d-flex justify-content-end">
                        {(!props.planName && !props.customerId && !props.serviceId && !props.billingPeriodId && !props.companyId && selectedServices.length == 0 && selectedBillingTerms.length == 0 && !props.agreementId) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>
                        }
                        {(props.planName || props.customerId || props.serviceId || props.billingPeriodId || props.companyId || selectedServices.length > 0 || selectedBillingTerms.length > 0 || props.agreementId) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={clearFilterBox}>
                                Clear
                            </Button>
                        }
                        {(props.planName || props.customerId || props.serviceId || props.billingPeriodId || props.companyId || selectedServices.length > 0 || selectedBillingTerms.length > 0 || props.agreementId) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                                    props.setScrollToDivTop(true);
                                    props.setBillingPeriodId(selectedBillingTerms);
                                    props.setServiceId(selectedServices);
                                    props.setPageNo(1);
                                    props.GetTableData(1, props.pageSize, props.searchValueTable, props.customerId, props.planName, selectedServices, selectedBillingTerms, props.mapFilter, props.companyId, props.agreementId,false, true);
                                    props.setShowFilterBox(false);
                                    props.setFilterFlag(true);
                                }
                                }
                            >
                                Apply
                            </Button>
                        }
                        {(!props.planName && !props.customerId && !props.serviceId && !props.billingPeriodId && !props.companyId && selectedServices.length == 0 && selectedBillingTerms.length == 0 && !props.agreementId) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>
                        }
                    </Col>
                </Row>
            </>
        </div >
    )
}

export default AgreementMappingFilterBox;