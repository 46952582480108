import React from 'react'
import { Row, Col, Button, Accordion, Form } from "react-bootstrap";
import styles from '../../CSPCustomerPrerequisite.module.scss';
import { phoneOnChangeRegex } from '../../../../../../../../../GlobalComponents/RegExPatterns';

const MCAAgreement = (props) => {

    const minMCAAgreementDate = "2015-01-01";

    const handleKeyDown = (e) => {
        e.preventDefault();
    };

    const handlePaste = (e) => {
        e.preventDefault();
    };

    return (
        <>
            {/* MCA Agreement*/}
            <Accordion.Item className={`mt-5 ${props.domainCheckBlockErrors || (props.mcaErrorMessage?.length > 0) ? "accordionErrors" : ""} ${props.stepsCompletedCheck.isDomainCheckCompleted ? "" : "disabled"}`} eventKey="mcaDetails">
                <Accordion.Header>
                    <h3 className={`${styles['accordion-header-wrapper']}`}>
                        <label>
                            <span className={`${styles['accordion-number']}`}>2</span>
                        </label>
                        <label className="pl-2">
                            <span className={`${styles['accordion-name']}`}>MCA Agreement</span>
                            <span className="mt-2">&nbsp;</span>
                        </label>
                    </h3>
                </Accordion.Header>

                {props.stepsCompletedCheck.isDomainCheckCompleted &&

                    <Accordion.Body>
                        <div className={`${styles['accordion-content']}`}>
                            <Row>
                                <Col md={8}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="mcaFirstName">
                                                <Form.Label>First name <span className="red">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="firstName"
                                                    maxLength={30}
                                                    value={props.mcaAgreementDetails.firstName}
                                                    placeholder=""
                                                    readOnly={props.submittingMCA}
                                                    onChange={(e) => {
                                                        if (e.target.value === '' || /^[a-zA-Z\s]*$/.test(e.target.value)) {

                                                            props.setMCAAgreementDetails({
                                                                ...props.mcaAgreementDetails,
                                                                firstName: e.target.value
                                                            });

                                                        }
                                                    }}
                                                />
                                                {props.mcaAgreementDetails.firstName == "" || /^[a-zA-Z\s]*$/.test(props.mcaAgreementDetails.firstName) ? "" : <span className="red text-small">Enter first name</span>}
                                            </Form.Group>

                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="mcaLastName">
                                                <Form.Label>Last name <span className="red">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="lastName"
                                                    value={props.mcaAgreementDetails.lastName}
                                                    placeholder=""
                                                    readOnly={props.submittingMCA}
                                                    maxLength={30}
                                                    onChange={(e) => {
                                                        if (e.target.value === '' || /^[a-zA-Z\s]*$/.test(e.target.value)) {

                                                            props.setMCAAgreementDetails({
                                                                ...props.mcaAgreementDetails,
                                                                lastName: e.target.value
                                                            });

                                                        }
                                                    }}
                                                />
                                                {props.mcaAgreementDetails.lastName == "" || /^[a-zA-Z\s]*$/.test(props.mcaAgreementDetails.lastName) ? "" : <span className="red text-small">Enter last name</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="mcaFirstName">
                                                <Form.Label>Email ID <span className="red">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    value={props.mcaAgreementDetails.email}
                                                    placeholder=""
                                                    readOnly={props.submittingMCA}
                                                    onChange={(e) => {
                                                        props.setMCAAgreementDetails({
                                                            ...props.mcaAgreementDetails,
                                                            email: e.target.value
                                                        });

                                                        props.setMCAFormErrors({
                                                            ...props.mcaFormErrors,
                                                            emailError: false
                                                        })
                                                    }}
                                                />
                                                {props.mcaFormErrors.emailError == true &&
                                                    <span className="red text-small">Enter valid email</span>
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="phoneNumber">
                                                <Form.Label >
                                                    Phone Number
                                                </Form.Label>
                                                <Row>
                                                    <Col>
                                                        <div className="phone-number-wrapper">
                                                            <label>+61</label>
                                                            <Form.Control
                                                                type="text"
                                                                className="phone-number form-control col"
                                                                placeholder="xxxxxxxxxx"
                                                                name="phoneNumber"
                                                                value={props.mcaAgreementDetails.phoneNumber}
                                                                readOnly={props.submittingMCA}
                                                                maxLength="15"
                                                                onChange={(e) => {
                                                                    const inputValue = e.target.value;
                                                                    if (inputValue === '' || phoneOnChangeRegex.test(inputValue)) {
                                                                        props.setMCAAgreementDetails({
                                                                            ...props.mcaAgreementDetails,
                                                                            phoneNumber: inputValue
                                                                        })
                                                                    }
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="mcaFirstName">
                                                <Form.Label>MCA Terms Accepted on (as per UTC Time) <span className="red">*</span></Form.Label>

                                                <Form.Control
                                                    type="date"
                                                    onKeyDown={handleKeyDown}
                                                    onPaste={handlePaste}
                                                    max={new Date().toISOString().split('T')[0]}
                                                    min={minMCAAgreementDate}
                                                    readOnly={props.submittingMCA}
                                                    onChange={(e) => {
                                                        props.setMCAAgreementDetails({
                                                            ...props.mcaAgreementDetails,
                                                            mcaAcceptanceDate: e.target.value.split('-')
                                                        });
                                                        props.setValidDate(true);
                                                    }
                                                    }
                                                />
                                                {props.validDate == false && <span className="red text-small">Select acceptance date</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <p><a href={props.mcaDownloadUri} target='_blank'
                                                className="link-text">Click here</a> to view Microsoft Customer Agreement</p>
                                            <p>You must provide the confirmation of customer’s acceptance of MCA to provision the subscriptions</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-right">
                                    <div className="button-wrapper">
                                        {props.submittingMCA == false &&
                                            <>
                                                {props.isDomainType.isDomainTransition ?
                                                    <Button
                                                        className="btn btn-lg btn-border-light px-4"
                                                        variant="light"
                                                        onClick={() => props.setActiveAccordion(["transitionDomain"])}
                                                    >
                                                        Back
                                                    </Button> :
                                                    <Button
                                                        className="btn btn-lg btn-border-light px-4"
                                                        variant="light"
                                                        onClick={() => props.setActiveAccordion(["domainCheck"])}
                                                    >
                                                        Back
                                                    </Button>
                                                }
                                            </>
                                        }

                                        {props.submittingMCA == true &&
                                            <>
                                                <Button
                                                    className="btn btn-lg btn-border-light px-4"
                                                    variant="light"
                                                    disabled
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    className="btn btn-lg ml-2 px-4"
                                                    variant="light"
                                                    disabled
                                                >
                                                    Submitting...
                                                </Button>
                                            </>
                                        }
                                        {props.submittingMCA == false &&
                                            <>
                                                {props.ismcaFormHasNoErros == true ?
                                                    <Button
                                                        className="btn btn-lg ml-2 px-4"
                                                        onClick={(e) => {
                                                            props.submitMCAForm();
                                                        }
                                                        }
                                                    >
                                                        Submit
                                                    </Button>

                                                    :

                                                    <Button
                                                        className="btn btn-lg ml-2 px-4"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Submit
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Accordion.Body>
                }
            </Accordion.Item>
            {props.submittingMCA == false && props.mcaErrorMessageFlag == true && props.mcaErrorMessage?.length == 0 ?
                <p className='red text-small mt-3'>Update failed. MCA details not saved. Please retry the process.</p>
                :  <p className='red text-small mt-3'>{props.mcaErrorMessage}</p>
            }
        </>
    );
}

export default MCAAgreement;