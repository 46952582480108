import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../Utils/API";
import { CUSTOMER_LISTING_TABLE, PROVISIONING_GET_SERVICES } from '../../../../Utils/GlobalConstants';
import { useEffect, useState } from "react";

import {  useMsal } from '@azure/msal-react';
import CustomerDetails from "./Details/Details";
import Prerequisite from "./Prerequisites";
import CustomerSummary from "./CustomerSummary";

const EditCustomer = (props) => {
    const location = useLocation();                                                                     // get Location
    const navigate = useNavigate();                                                                     // to use navigate function
    const [tabName, setTabName] = useState((location.state?.tabName) ? (location.state?.tabName) : "Customer Summary");                                      // Default Tab
    const [customerId, setCustomerId] = useState(location.state?.customerCode ? location.state?.customerCode : "");     // Get Customer ID
    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);                              // server error check state in details during api 
    const [serviceId, setServiceId] = useState(location.state?.serviceId ? location.state?.serviceId : null);
    const [selectedOption, setSelectedOption] = useState(location.state?.selectedOption ? location.state.selectedOption : "Select");
    const [integrationCode, setIntegrationCode] = useState(location.state?.integrationCode ? location.state.integrationCode : null);
    const [comingFromProvision, setIscomingFromProvision] = useState(location.state?.comingFromProvision ? location.state.comingFromProvision : false);
    const { instance, inProgress } = useMsal(); // for logging purpose

    const [isDisabledPrerequisite, setIsDisabledPrerequisite] = useState(localStorage.getItem("disabled_array").includes("Prerequisite") ? true : false);
    const [cspServiceId, setCSPServiceId] = useState(null);
    const [gwsServiceId, setGWSServiceId] = useState(null);

    // Customer Details All Fields 
    const [customerDetails, setCustomerDetails] = useState({

        "organization": "",

        "businessContactDetails": {
            "email": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": ""
        },
        "mcaDetails": {
            "mcaEmail": "",
            "mcaFirstName": "",
            "mcaIPAddress": "",
            "mcaLastName": "",
            "mcaPhone": "",
            "mcaAgreementDate": "",
            "mcaDomainFlag": true
        }
    });


    const [backButtonDetails, setBackButtonDetails] = useState(null);                              // server error check state in details during api 

    

    // Header Authorization for General API's
    const config = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in Service Section 
    async function GetAllServices() {
        await api.get(PROVISIONING_GET_SERVICES, config)
            .then(resp => {

                const microsoftService = resp.data.find(service => service.integrationCode === "microsoftsaas");
                const googleServiceId = resp.data.find(service => service.integrationCode == "googleworkspace");

                setCSPServiceId(microsoftService ? microsoftService.id : null);
                setGWSServiceId(googleServiceId ? googleServiceId.id : null);


            })
            .catch(error => {

                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                } 

            });
    };

    // Get Customer Details Based on Customer ID
    async function getCustomerDetails() {
        await api.get(CUSTOMER_LISTING_TABLE + "/" + customerId, config)
            .then(resp => {
                if (resp.status === 200) {

                    setCustomerDetails(resp.data);
                    setBackButtonDetails(resp.data.itContactDetails);
                } else {
                    setServerErrorCustomer(false);
                }

            })
            .catch(error => {

                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setServerErrorCustomer(true);

                }

            });
    }

    // useEffect to Load Customer Details On Initial Loading
    useEffect(() => {
        GetAllServices();
        getCustomerDetails();
    }, []);

    return (
        <div className="main-content">

            <Container fluid>
                <Row>
                    <Col className="justify-content-between d-flex align-items-center">
                        <label className="cpointer" onClick={() => navigate('/my-customers')}>
                            <span className="back">&nbsp;</span>&nbsp;{backButtonDetails && backButtonDetails.firstName.length > 0 && <strong>
                                {customerDetails.organization} ({backButtonDetails.firstName} {backButtonDetails.lastName})
                            </strong>}
                        </label>

                    </Col>
                </Row>
                <Tab.Container activeKey={tabName}>
                    <Row>
                        <Col sm={12} className="mt-3">
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="Customer Summary" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3'
                                        onClick={() => { setTabName("Customer Summary") }}
                                    >
                                        Customer Summary
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Customer Details" className='addons-core-gws-manage pl-6 pr-6 pt-3 pb-3'
                                        onClick={() => { setTabName("Customer Details") }}
                                    >
                                        Customer Details
                                    </Nav.Link>
                                </Nav.Item>
                                {!isDisabledPrerequisite && <Nav.Item>
                                    <Nav.Link eventKey="Cloud Requirements" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3'
                                        onClick={() => { setTabName("Cloud Requirements") }}
                                    >
                                        Cloud Requirements
                                    </Nav.Link>
                                </Nav.Item>}
                            </Nav>
                        </Col>
                        <Col lg={12}>
                            <Tab.Content>
                                {/* Customer Summary */}
                                <Tab.Pane eventKey="Customer Summary">
                                    <CustomerSummary customerCode={customerId} setOpen={props.setOpen} Logout={props.Logout} />
                                </Tab.Pane>
                                {/* Customer Tab */}
                                <Tab.Pane eventKey="Customer Details">
                                    <CustomerDetails Logout={props.Logout} />
                                </Tab.Pane>

                                {/* Audit Report Tab */}
                                <Tab.Pane eventKey="Cloud Requirements">
                                    <Prerequisite
                                        customerDetails={customerDetails}
                                        customerId={customerId}
                                        serviceId={serviceId}
                                        selectedOption={selectedOption}
                                        integrationCode={integrationCode}
                                        comingFromProvision={comingFromProvision}
                                        cspServiceId={cspServiceId}
                                        gwsServiceId={gwsServiceId}
                                        setActiveLocalKey={props.setActiveLocalKey}
                                        toggleDrawer={props.toggleDrawer}
                                        setSelectService={props.setSelectService}
                                        setSelectCategory={props.setSelectCategory}
                                        Logout={props.Logout}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

        </div>
    );
}

export default EditCustomer;