import React, { useState, useEffect, useRef } from 'react'
import { Button, Container, Row, Col, Form, NavDropdown } from 'react-bootstrap';
import { emailRegEx, phoneOnChangeRegex, phoneRegex } from '../../../GlobalComponents/RegExPatterns';
import { PUBLIC_CONTACT_FORM } from '../../../Utils/GlobalConstants';
import { api } from '../../../Utils/API';
import Toast from '../../../GlobalComponents/Toast';
import UnautorisedNavbar from '../../../GlobalComponents/UnautorisedNavBar';

const PublicContactUs = ({ contactUsRef,customerIsAuthenticated,cookieAccepted }) => {

    const formRef = useRef();

    const [enableSubmit, setEnableSubmit] = useState(false);
    const [enableClear, setEnableClear] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [categoryData, setCategoryData] = useState([
        {
            "id": "Cloud Services Enquiry",
            "name": "Cloud Services Enquiry"
        },
        {
            "id": "General Enquiries",
            "name": "General Enquiries"
        },
        {
            "id": "Strategic Partnership",
            "name": "Strategic Partnership"
        }
    ]);

    const [selectCategory, setSelectCategory] = useState("Select Category");
    const [selectService, setSelectService] = useState("")

    const initFormBody = {
        name: "",
        email: "",
        phoneNumber: null,
        category: null,
        serviceName: null,
        subject: "",
        referenceId: "",
        message: "",
    };

    const [formBody, setFormBody] = useState(initFormBody);



    // Form Errors 
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [categoryError, setCategoryError] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);
    const [commentsError, setCommentError] = useState(false);
    const [isErrorMessage, setIsErrorMessage] = useState("");

    useEffect(() => {

        // console.log(initFormBody);
        // console.log(formBody);

        if (
            formBody.name?.length > 0 &&
            formBody.email?.length > 0 &&
            phoneRegex.test(formBody.phoneNumber) &&
            formBody.category !== null &&
            formBody.category !== "Select Category" &&
            formBody.serviceName !== null &&
            // formBody.serviceName !== "Select Service" &&
            formBody.subject.length > 0 &&
            formBody.message.length > 0
        ) {
            setEnableSubmit(true);
        } else {
            setEnableSubmit(false);
        }

        if (JSON.stringify(initFormBody) == JSON.stringify(formBody)) {
            setEnableClear(false)
        } else {
            setEnableClear(true);
        }

    }, [formBody]);

    const validateFormErrors = () => {

        if (!emailRegEx.test(formBody.email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }

        if (!phoneRegex.test(formBody.phoneNumber)) {
            setPhoneNumberError(true)
        } else {
            setPhoneNumberError(false);
        }

        if (selectCategory == "Select Category") {
            setCategoryError(true);
        } else {
            setCategoryError(false);
        }

        if (formBody.subject == "") {
            setServiceError(true);
        } else {
            setServiceError(false);
        }

        if (formBody.subject.length < 1) {
            setSubjectError(true)
        } else {
            setSubjectError(false)
        }

        if (formBody.message.length < 1) {
            setCommentError(true);
        } else {
            setCommentError(false);
        }
    }

    // Post Agreement Mapping
    async function SubmitContactForm() {
        validateFormErrors();

        if (
            !nameError &&
            !emailError &&
            emailRegEx.test(formBody.email) &&
            !phoneNumberError &&
            !categoryError &&
            !serviceError &&
            !subjectError &&
            !commentsError
        ) {
            setFormSubmitting(true);

            await api.post(PUBLIC_CONTACT_FORM, formBody)
                .then(resp => {
                    // console.log("Response : ", resp.request.status);
                    if (resp.request.status == 200) {


                        // console.log("Response : ", resp);
                        setToastSuccess(true);
                        setToastMessage("Contact Form submitted successfully!");

                        setTimeout(() => {
                            setToastSuccess(false);
                            setToastMessage("");
                        }, 3000);

                        setTimeout(() => {
                            resetForm();
                            setFormSubmitting(false);
                            setFormBody(initFormBody);
                        }, 4000);
                    }
                })
                .catch(error => {
                    setToastError(true);
                    setToastMessage("Contact Form not submitted!");

                    setTimeout(() => {
                        setToastError(false);
                        setToastMessage("");
                    }, 3000);



                    if (error?.status == 401 || error?.response?.status == 401) {
                        // // console.log("Error 401");
                        // Logout();
                    }
                    else {
                        // console.log("Error : ", error);
                        setIsErrorMessage(error.response.data.errors);

                    }

                    setFormSubmitting(false);
                });
        }
    };

    const resetForm = () => {
        setFormBody(initFormBody);
        setSelectCategory("Select Category");
        setSelectService("");
        setNameError(false);
        setEmailError(false);
        setPhoneNumberError(false);
        setCategoryError(false);
        setServiceError(false)
        setSubjectError(false);
        setCommentError(false);

        if (formRef.current) {
            formRef.current.reset();
        }
    }


    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
            {!customerIsAuthenticated && <UnautorisedNavbar cookieAccepted={cookieAccepted}/>}

            <Container className="vendor-contact-us-block" ref={contactUsRef} id="contactUs" fluid>
                <Container fluid>
                    <Row className="py-3">
                        <h1 className="py-3 text-extra-large">Contact Us</h1>
                        <Col md={8}>
                            <form ref={formRef}>
                                <div>
                                    <Row className="mx-0">
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="formFirstName">
                                                <Form.Label>Name <span className="red">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    maxLength={100}
                                                    value={formBody.name}
                                                    onChange={(e) => {
                                                        setFormBody({
                                                            ...formBody,
                                                            name: e.target.value
                                                        })
                                                    }
                                                    }
                                                />
                                                {nameError &&
                                                    <span className='text-small red'>Please enter valid first name.</span>
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formPrimaryEmail">
                                                <Form.Label>Primary Email<span className="red">*</span></Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder=""
                                                    value={formBody.email}
                                                    maxLength={100}
                                                    onChange={(e) => {
                                                        setEmailError(false);
                                                        setFormBody({
                                                            ...formBody,
                                                            email: e.target.value
                                                        })
                                                    }
                                                    }
                                                />
                                                {emailError &&
                                                    <span className='text-small red'>Incorrect email id, please enter correct email id.</span>
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="phoneNumber">
                                                <Form.Label >
                                                    Phone Number <span className="red">*</span>
                                                </Form.Label>
                                                <Row>
                                                    <Col>
                                                        <div className="phone-number-wrapper">
                                                            <label>+61</label>
                                                            <Form.Control
                                                                type="text"
                                                                className="phone-number form-control col"
                                                                placeholder="xxxxxxxxxx"
                                                                name="phoneNumber"
                                                                value={formBody.phoneNumber}
                                                                maxLength="15"
                                                                onChange={(e) => {
                                                                    const inputValue = e.target.value;
                                                                    if (phoneOnChangeRegex.test(inputValue)) {
                                                                        setFormBody({
                                                                            ...formBody,
                                                                            phoneNumber: e.target.value
                                                                        })
                                                                    }
                                                                }
                                                                }
                                                            />
                                                            {phoneNumberError &&
                                                                <span className='text-small red'>Please enter valid phone number</span>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group
                                                className="form-group mb-3"
                                                controlId="category"
                                            >
                                                <Form.Label>Category <span className="red">*</span></Form.Label>

                                                <NavDropdown title={selectCategory} id="cloud-category-dropdown"
                                                    className={`custom-dropdown ${selectCategory === "Select Category" ? "" : "selected-color"}`}>
                                                    {categoryData && categoryData.map((option) => {

                                                        return (
                                                            <NavDropdown.Item
                                                                key={option.id}
                                                                id={option.id}
                                                                value={option.name}
                                                                className=""
                                                                onClick={(e) => {
                                                                    let category = e.target.getAttribute("value");
                                                                    setSelectCategory(category);

                                                                    setFormBody({
                                                                        ...formBody,
                                                                        category: option.name,
                                                                    })
                                                                }}
                                                            >
                                                                {option.name}
                                                            </NavDropdown.Item>);

                                                    })}
                                                </NavDropdown>
                                                {categoryError &&
                                                    <span className='text-small red'>Please select one category.</span>
                                                }
                                            </Form.Group>

                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Cloud Service <span className="red">*</span></Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    className="form-control col"
                                                    placeholder=""
                                                    name="service"
                                                    value={formBody.serviceName}
                                                    maxLength="30"
                                                    onChange={(e) => {
                                                        setFormBody({
                                                            ...formBody,
                                                            serviceName: e.target.value
                                                        })
                                                    }
                                                    }
                                                />
                                                {serviceError &&
                                                    <span className='text-small red'>Please select one service.</span>
                                                }
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Subject <span className="red">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    value={formBody.subject}
                                                    onChange={(e) => {
                                                        setFormBody({
                                                            ...formBody,
                                                            subject: e.target.value
                                                        })
                                                    }
                                                    }
                                                />
                                                {subjectError &&
                                                    <span className='text-small red'>Please enter valid subject.</span>
                                                }
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Reference ID</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    value={formBody.referenceId}
                                                    maxLength={30}
                                                    onChange={(e) => {
                                                        setFormBody({
                                                            ...formBody,
                                                            referenceId: e.target.value
                                                        })
                                                    }
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="form-group mb-3" controlId="comments">
                                                <Form.Label>Message <span className="red">*</span></Form.Label>
                                                <Form.Control
                                                    as="textarea" rows={5}
                                                    value={formBody.message}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 2000) {
                                                            setFormBody({
                                                                ...formBody,
                                                                message: e.target.value
                                                            })
                                                        }
                                                    }
                                                    }
                                                />

                                                <Row>
                                                    <Col className='text-left'>
                                                        {commentsError &&
                                                            <span className='text-small red'>Please enter valid comments.</span>
                                                        }
                                                        {formBody.message.length > 2000 &&
                                                            <span className='text-small red'>Comment length should not exceed 2000 charecters.</span>
                                                        }
                                                    </Col>
                                                    <Col className='text-right'><span className="text-small text-right w-100 d-block mt-1">({formBody.message?.length}/2000)</span></Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>

                                    <Col className={"my-3 text-right btn-wrapper-outer right-overlay-btn"}>

                                        {
                                            formSubmitting &&
                                            <>
                                                <Button className="mx-2 btn-border-light w-md-auto col-xs-12"
                                                    variant="light"
                                                    disabled
                                                >
                                                    Clear
                                                </Button>

                                                <Button
                                                    className="btn btn-border-light btn-lg mx-2"
                                                    variant="light"
                                                    disabled
                                                >
                                                    Submitting...
                                                </Button>
                                            </>
                                        }

                                        {!formSubmitting &&
                                            <>

                                                {enableClear ?
                                                    <Button className="mx-2 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        onClick={resetForm}
                                                    >
                                                        Clear
                                                    </Button>
                                                    :
                                                    <Button className="mx-2 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Clear
                                                    </Button>

                                                }


                                                {enableSubmit ?
                                                    <Button className="mx-2 btn btn-lg"
                                                        onClick={SubmitContactForm}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        className="btn btn-border-light btn-lg mx-2"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Submit
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default PublicContactUs;