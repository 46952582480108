import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Nav, NavDropdown, Row, Tab } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './summary.module.scss'
import { CUSTOMER_DOWNLOAD_CSV_SUMMARY, CUSTOMER_LISTING_TABLE, DASHBOARD_NEW_CUSTOMERS, GET_CUSTOMERS_LIST, GET_CUSTOMER_STATUS_LIST } from '../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../Utils/API';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTheme } from 'react-data-table-component';
import EmptyDataTableFilterSearch from '../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import SummaryFilterBox from './LocalComponents/FilterBox';
import Toast from '../../../../../../../GlobalComponents/Toast';
import { BarChart } from '@mui/x-charts';
import LazyTable from './LocalComponents/LazyTable';
 
const Summary = ({ Logout, setSummaryPopup, summaryPopup, tabName, setTabName, setFilterType, filterType, fromDate, setFromDate, toDate, setToDate }) => {
    const location = useLocation();
    const date = new Date();                                                    // for js date time function
    const navigate = useNavigate();                                             // to use navigate function from react router dom    

    const searchParams = new URLSearchParams(location.search);
    const [searchIconFlag, setSearchIconFlag] = useState(false);                        // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(searchParams.get('searchValueTable') || null);                     // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                                // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                          // showing filter box
    const searchInputRef = useRef();
    const [status, setStatus] = useState(null);                               // getTableData() Status 
    const [barChartData, setBarChartData] = useState([{ data: [0], stack: 'A', label: 'New Customers' }])
    const [barChartDataSeries, setBarChartDataSeries] = useState([])            // for x-axisdata in api
    const [customerId, setCustomerId] = useState(null);                                    // table active page number
    const [customerData, setCustomerData] = useState(null);                         // data in customer dropdown
    const [statusData, setStatusData] = useState(null);                             // data in status dropdown

    const [pageNo, setPageNo] = useState(1);                                             // table active page number
    const [pageSize, setPageSize] = useState(15);                                         // table active page size
    const [totalRows, setTotalRows] = useState(0);                                       // table total count of data from api 
    const [columns, setColumns] = useState([]);                                         // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                             // data state used to display data in react data table component
    let ColumnName = ["Organization", "Full Name",                              // table headers used to make object of react data table component headers
        "Customer ID", "Email", "Creation Date", "Status", "Change Status"];
    let ColumnNameNoAction = ["Organization", "Full Name",                              // table headers used to make object of react data table component headers
        "Customer ID", "Email", "Creation Date", "Status"];
    const [initialLoading, setInitialLoading] = useState(true);                         // loader for table
    const [emptyCustomerFilters, setEmptyCustomerFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCustomerSearch, setEmptyCustomerSearch] = useState(false);      // empty search response check state in table during api call to load data
    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
    const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data
    const [dateLastUsedDate, setDateLastUsedDate] = useState("default");        // date dropdown saved data after applying filters
    const [statusLastUsed, setStatusLastUsed] = useState("default");            // status dropdown saved data after applying filters  

    // if custom was selected in date in filter box
    const [dateCustomFromLast, setDateCustomFromLast] =
        useState(date.toISOString().slice(0, 10));            // from date saved data after applying filter having custom selection
    const [dateCustomToLast, setDateCustomToLast] =
        useState(date.toISOString().slice(0, 10));            // to date saved data after applying filter having custom selection

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                        // usestate to scroll to top when applied filter or search when already scrolled
    const [infinityLoading, setInfinityLoading] = useState(false);                      // loading state while table loads for lazy loading
    const [tableFilterApplied, setTableFilterApplied] = useState(false);                // table filter is apllied or not

    const [isDisabledCustomers, setIsDisabledCustomers] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Customers") ? true : false);
    const [isReadCustomers, setIsReadCustomers] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Customers") ? true : false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?fromData=${fromDate}&toDate=${toDate}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all Customer data
    async function GetAllNewCustomersCount() {
        await api.get(`${DASHBOARD_NEW_CUSTOMERS}?filterType=${filterType}`, config)
            .then(resp => {
                let localData = [];
                if (resp.data.graphData) {
                    localData.push(resp.data.graphData)
                }
                setBarChartData(localData);
                setBarChartDataSeries(resp.data.xAxisData)
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(GET_CUSTOMERS_LIST, config)
            .then(resp => {
                setCustomerData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(GET_CUSTOMER_STATUS_LIST, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Get Table Data with Filters, Pagination, Search  Functionality
    async function GetTableData(page, newPerPage, search, status, fromDate, toDate, customerId, isFilter, appendData) {
        setInitialLoading(false);
        (search === undefined || search === null || search === "null") ? search = "" : search = search;
        (status === undefined || status === null || status === "null") ? status = "" : status = status;
        (fromDate === undefined || fromDate === null || fromDate === "null") ? fromDate = "" : fromDate = fromDate;
        (toDate === undefined || toDate === null || toDate === "null") ? toDate = "" : toDate = toDate;
        (customerId === undefined || customerId === null || customerId === "null") ? customerId = "" : customerId = customerId;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        await api.get(`${CUSTOMER_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&status=${status}&customerCode=${customerId}&fromDate=${fromDate}&toDate=${toDate}&sortCol=createdDate&sortDir=DESC`, config)
            .then(resp => {
                setInitialLoading(true);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    if (search === "" && status === "" && customerId === "") {
                        setEmptyCustomer(true);
                    }
                    else if ((status != "" || customerId != "") && isFilter) {
                        setEmptyCustomerFilters(true);
                    }
                    else if (search != "" && !isFilter) {
                        setEmptyCustomerSearch(true);
                    }
                }
                // handle success
                else {
                    setServerErrorCustomer(false);
                    setEmptyCustomer(false);
                    setEmptyCustomerFilters(false);
                    setEmptyCustomerSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Organization": td.organizationName,
                                    "Full Name": td.firstName + " " + td.lastName,
                                    "Email": td.email,
                                    "Creation Date": td.createdDate,
                                    "Status": td.isActive ? (
                                        <div className={`${styles['status-customers']}`}>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) :
                                        <div className={`${styles['status-customers']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Deactive</div>
                                        </div>,
                                    "Customer ID": td.customerCode,
                                    "Change Status": <span className='dashboard-change-status cpointer'
                                        onClick={() => {
                                            // console.log("Email :", td.email);
                                            navigate('/my-customers', { state: { searchData: `${td.email}` } })
                                        }}></span>
                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ((!isDisabledCustomers && !isReadCustomers) ? ColumnName : ColumnNameNoAction).map((td) => {
                        if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div className='text-center'>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Change Status") {
                            d.push({
                                id: `${td}`,
                                name: <div className='text-center'>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })

                        }
                        else if (td === "Email" || td === "Customer ID") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 2.7,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1.2,
                            })
                        }
                    })
                    setColumns(d);       // Setting Column Data
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorCustomer(true);
                }
            });
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status, fromDate, toDate, customerId);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, pageNo) => {
        setPageNo(pageNo);
        setPageSize(newPerPage);
        GetTableData(pageNo, newPerPage, searchValueTable, status, fromDate, toDate, customerId);
    };

    // function for dropdown data
    const createHandleSelect = (setSelectedItem) => (eventKey) => {
        setSelectedItem(eventKey);
        let date = new Date();
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (eventKey === "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            // console.log("Event Key :", eventKey, fromDate)
            setFromDate("01/01/" + date.getFullYear());
            setToDate(fromDate);
        }
        else {
            // console.log("Event Key : ", eventKey, `01/01/${date.getFullYear() - 1}`, `31/12/${date.getFullYear() - 1}`);
            setFromDate(`01/01/${date.getFullYear() - 1}`);
            setToDate(`31/12/${date.getFullYear() - 1}`);
        }
    };

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                // textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, status, fromDate, toDate, customerId, false, true))();
            return newPageNumber;
        });
    }

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setTableFilterApplied(false);
                    GetTableData(1, pageSize, searchValueTable, status, fromDate, toDate, customerId);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    GetTableData(1, pageSize, searchValueTable, status, fromDate, toDate, customerId);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    //on tab change move make scroll to top true
    useEffect(() => {
        setScrollToDivTop(true);
        setPageNo(1);
    }, [tabName])

    // Function to trigger getTable data function 
    useEffect(() => {
        GetAllNewCustomersCount();
        GetAllStatuses();
        GetAllCustomers();
        GetTableData(pageNo, pageSize, searchValueTable, status, fromDate, toDate, customerId);
    }, [])

    //   useEffect for calling get api on filter change
    useEffect(() => {
        GetAllNewCustomersCount();
        setScrollToDivTop(true);
        setPageNo(1);
        if (tableFilterApplied)
            GetTableData(1, pageSize, searchValueTable, status, fromDate, toDate, customerId, true);
        else
            GetTableData(1, pageSize, searchValueTable, status, fromDate, toDate, customerId, false);
   
        let todayDate = `${String(date.getDate()).padStart(2, '0')}`
        let todayMonth = `${String(date.getMonth() + 1).padStart(2, "0")}`
        let todayYear = `${date.getFullYear()}`
        if (filterType == "This Year") {
            let fromDate = String(todayDate) + "/" + String(todayMonth) + "/" + String(todayYear);
            setFromDate("01/01/" + date.getFullYear());
            setToDate(fromDate);
        }
        else {
            setFromDate(`01/01/${date.getFullYear() - 1}`);
            setToDate(`31/12/${date.getFullYear() - 1}`);
        }
    }, [filterType])

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xxl"
                open={summaryPopup}
                onClose={() => { setSummaryPopup(false); setServerErrorCustomer(false); setEmptyCustomer(false); setEmptyCustomerFilters(false); setEmptyCustomerSearch(false); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">New Customers</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <>
                            <div className='my-4 height-dashboard-charts'>
                                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

                                <Container fluid>
                                    <Tab.Container id="main-tab-bar" defaultActiveKey="Summary">
                                        <Tab.Container activeKey={tabName}>
                                            <Row>
                                                <Col md={9}>
                                                    <Nav variant="pills">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Summary" className='dashboard-tabs  pl-6 pr-6 pt-3 pb-3'
                                                                onClick={() => { setTabName("Summary") }}
                                                            >
                                                                Summary</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Details" className={'dashboard-tabs pl-6 pr-6 pt-3 pb-3 d-none-mobile'}
                                                                onClick={() => { setTabName("Details") }}
                                                            >
                                                                Details</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col md={3}> <div className='text-right'>
                                                    <Button
                                                        variant="light"
                                                        className="mr-1 px-4 btn-border-light w-md-auto col-xs-12"
                                                        onClick={(e) => { DownloadAction(CUSTOMER_DOWNLOAD_CSV_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_customer.csv`, "csv") }}
                                                    >
                                                        Download Report
                                                    </Button>
                                                </div></Col>

                                            </Row>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="Summary">
                                                    <div>
                                                        <Row>
                                                            <Col md={10}></Col>
                                                            <Col md={2}>
                                                                <div className='service-management-csp-header mt-3 ml-5'>
                                                                    <Form.Group >
                                                                        <NavDropdown title={filterType} id="cloud-service-dropdown" className={`servcies-dropdown dropdown-specialclass-fortwo ${styles['dropdown-disabled']}`} onSelect={createHandleSelect(setFilterType)}>
                                                                            <NavDropdown.Item
                                                                                eventKey="Last Year"
                                                                                className={filterType === "Last Year" ? "selected" : ""}
                                                                            >Last Year</NavDropdown.Item>
                                                                            <NavDropdown.Item
                                                                                eventKey="This Year"
                                                                                className={filterType === "This Year" ? "selected" : ""}
                                                                            >This Year</NavDropdown.Item></NavDropdown>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className={`${styles['bar-chart-height']}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {barChartData && <BarChart
                                                            series={barChartData}
                                                            grid={{ horizontal: true, vertical: true }}
                                                            labelRotation={45}
                                                            slotProps={{
                                                                legend: {
                                                                    direction: 'row',
                                                                    position: { vertical: 'bottom', horizontal: 'middle' },
                                                                    padding: 0,
                                                                    labelStyle: {
                                                                        fill: '#6a6a6a'
                                                                    }
                                                                },
                                                                popper: {
                                                                    sx: {
                                                                        zIndex: 99999,
                                                                    },
                                                                },
                                                            }}
                                                            colors={['#3599DA', '#F9A932']}
                                                            xAxis={[{
                                                                data: barChartDataSeries,
                                                                scaleType: 'band',
                                                                tickPlacement: 'middle',
                                                                categoryGapRatio: 0.6,
                                                                tickLabelPlacement: 'middle',
                                                            }]}
                                                            yAxis={[{
                                                                tickNumber: 5,
                                                                tickMinStep: 1,
                                                                // valueFormatter: (value) => `$ ${(value / 1000).toLocaleString()}K`,
                                                            }]}
                                                            // loading
                                                            margin={{ top: 20, bottom: 60, left: 50, right: 50 }}
                                                        />}
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Details">
                                                    <div className='my-4 position-relative'>
                                                        <Row>
                                                            <Col lg={3} md={4} >
                                                                <h2 className='mx-7'>Customers {!emptyCustomer && !emptyCustomerFilters && !emptyCustomerSearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h2>
                                                            </Col>
                                                            {/* desktop and tab search and filter */}
                                                            <Col className="px-1 d-none d-md-block mb-2" lg={7} md={6}>
                                                                <Row className={`justify-content-end align-items-center mobile-padding-search-open-sub  ${styles['search-tablet-view']}`}>
                                                                    {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                                        {!searchIconFlag &&
                                                                            <span className="mx-3">&nbsp;</span>
                                                                        }
                                                                        {!searchIconFlag && !emptyCustomerFilters &&
                                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                                        }
                                                                        {searchIconFlag && !emptyCustomerFilters &&
                                                                            <InputGroup className="search-input-box search-input-box-xl">
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                                    <span className="searchgrey cpointer"
                                                                                    >
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                                <Form.Control
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    aria-describedby="basic-addon2"
                                                                                    className="search-input-box-input"
                                                                                    value={searchValueTable}
                                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                                    ref={searchInputRef}
                                                                                />
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                                    onClick={() => {
                                                                                        setSearchValueTable("");
                                                                                        setSearchIconFlag(!searchIconFlag);
                                                                                    }}>
                                                                                    <span className="closebtn">
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        }

                                                                        {!filterFlag && !emptyCustomerSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                            setShowFilterBox(!showFilterBox);
                                                                        }}></span>}
                                                                        {initialLoading && filterFlag && !emptyCustomerSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}

                                                                    </Col>}
                                                                    {
                                                                        !emptyCustomerSearch && showFilterBox &&
                                                                        <SummaryFilterBox GetTableData={GetTableData} pageNo={pageNo} pageSize={pageSize} setPageNo={setPageNo} searchValueTable={searchValueTable} fromDate={fromDate} toDate={toDate}
                                                                            setFromDate={setFromDate} setToDate={setToDate} customerId={customerId} setCustomerId={setCustomerId} customerData={customerData} statusData={statusData}
                                                                            setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} dateLastUsedDate={dateLastUsedDate} status={status} setStatus={setStatus}
                                                                            setDateLastUsedDate={setDateLastUsedDate} dateCustomFromLast={dateCustomFromLast} setDateCustomFromLast={setDateCustomFromLast} dateCustomToLast={dateCustomToLast}
                                                                            setDateCustomToLast={setDateCustomToLast} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed} setScrollToDivTop={setScrollToDivTop}
                                                                            setTableFilterApplied={setTableFilterApplied}
                                                                        />
                                                                    }
                                                                </Row>
                                                            </Col>
                                                            <Col md={2} lg={2}>
                                                                <Form.Group >
                                                                    <NavDropdown title={filterType} id="cloud-service-dropdown" className={`servcies-dropdown dropdown-specialclass-fortwo`} onSelect={createHandleSelect(setFilterType)}>
                                                                        <NavDropdown.Item
                                                                            eventKey="Last Year"
                                                                            className={filterType === "Last Year" ? "selected" : ""}
                                                                        >Last Year</NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            eventKey="This Year"
                                                                            className={filterType === "This Year" ? "selected" : ""}
                                                                        >This Year</NavDropdown.Item> </NavDropdown>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <div className={`mb-1 p-0 ${styles['user-management-table-inner']} ${styles['table-details']}`}>
                                                            <Row>
                                                                <Col>
                                                                    <div className={`table-responsive ${styles['customer-table']}`}>
                                                                        {initialLoading && serverErrorCustomer &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Matching Customer Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerFilters && !emptyCustomerSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Data Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomer &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Customer Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !serverErrorCustomer && !emptyCustomerFilters && !emptyCustomer && !emptyCustomerSearch &&
                                                                            <LazyTable
                                                                                d={columns}
                                                                                table={table}
                                                                                customStyles={customStyles}
                                                                                loading={infinityLoading}
                                                                                pageNo={pageNo}
                                                                                totalRows={totalRows}
                                                                                handlePerRowsChange={handlePerRowsChange}
                                                                                handlePageChange={handlePageChange}
                                                                                styles={styles}
                                                                                handleLazyCalls={handleLazyCalls}
                                                                                serverErrorUser={serverErrorCustomer}
                                                                                emptyUser={emptyCustomer}
                                                                                emptyUserFilters={emptyCustomerFilters}
                                                                                emptyUserSearch={emptyCustomerSearch}
                                                                                setScrollToDivTop={setScrollToDivTop}
                                                                                scrollToDivTop={scrollToDivTop}
                                                                            />}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="btn-wrapper text-right">
                                                            <Button
                                                                className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                                variant="light"
                                                                onClick={() => {
                                                                    close();
                                                                }}
                                                            >
                                                                Close
                                                            </Button>
                                                            {!isDisabledCustomers && <Button
                                                                className="px-4 mx-2"
                                                                onClick={() => {
                                                                    navigate('/my-customers')
                                                                }}
                                                            >View Customers</Button>}
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Tab.Container>

                                </Container>
                            </div>

                        </>
                    </div>)}
            </Popup>
        </div>
    )
}

export default Summary
