import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup,Row, Table } from 'react-bootstrap';
import styles from './CompanySelection.module.scss';
import { GET_CONNECTWISE_COMPANY } from '../../../../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../../../../Utils/API';
import loadingGif from "../../../../../../../../../../Assets/Images/Animations/loading-management-console.gif"

const CompanySelection = ({ setDefaultCompanySelected,setEditActionPopup, defaultCompanySelected, setActiveStep, activeStep, companyName, setCompanyName, buttonCheckstep1, setButtonCheckstep1, setCompanyId, companyData, setCompanyData, Logout }) => {
    let CompanyColumnName = ["", "Company Name", "Company ID", "Types", "Status"];      // Columns of Company table 
    const [searchValueTable, setSearchValueTable] = useState(null);                     // storing searching value in the search input box
    const [searchIconFlag, setSearchIconFlag] = useState(false);                        // to open and close search box
    const searchInputRef = useRef();                                                    // Search Input Box
    const [emptySearch, setEmptySearch] = useState(false);                              // empty search response check state in table during api call to load data
    const [loadingState, setLoadingState] = useState(false);                            // loading state

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // get api for company mapping data
    async function GetConnectwiseCompanyData(companyName) {
        setLoadingState(true);
        (companyName === undefined || companyName === null) ? companyName = "" : companyName = companyName;
        await api.get(`${GET_CONNECTWISE_COMPANY}?companyName=${companyName}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setLoadingState(false);
                    setEmptySearch(true);
                }

                else {
                    setLoadingState(false);
                    setEmptySearch(false);
                    setCompanyData(resp.data);
                }

            })
            .catch(error => {
                setLoadingState(false);
                setEmptySearch(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                GetConnectwiseCompanyData(searchValueTable)
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    useEffect(() => {
        if (companyName)
            GetConnectwiseCompanyData();
    }, []);

    return (
        <div sx={{ mt: 2, mb: 0 }}>
            <Row className='mb-3'>
                <Col md={8}> <h3 className="mb-5 px-3 mt-2 notes-confirmation">Companies</h3></Col>
                <Col md={4}>
                    <div className="text-right">
                        <InputGroup className="search-input-box search-input-box-xl ">
                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                <span className="searchgrey cpointer"
                                >
                                    &nbsp;
                                </span>
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                className="search-input-box-input"
                                value={searchValueTable}
                                onChange={(e) => setSearchValueTable(e.target.value)}
                                ref={searchInputRef}
                            />
                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                onClick={() => {
                                    setSearchValueTable("");
                                    setSearchIconFlag(!searchIconFlag);
                                }}
                            >
                                <span className="closebtn">
                                    &nbsp;
                                </span>
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            {/* loading state */}
            {loadingState &&
                <div className={styles["loading-customers"]}>
                    <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading ...</p>
                </div>}

            {/* when data is there */}
            {!emptySearch && !loadingState &&
                <div className={styles["table-height-adjustment"]}>
                    <Table responsive="md" >
                        <thead className="sticky-top">
                            <tr>
                                {CompanyColumnName.map(th => {
                                    return (<th className={(th === "Status") ? "text-center" : ""}>{th}</th>)
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                companyData && companyData
                                .filter(item => item.status === "Active")
                                .map((item) => {
                                    return (
                                        <tr key={item.companyId}>
                                            <td><input type='radio'
                                                style={{ verticalAlign: 'bottom' }}
                                                id={item.companyId}
                                                name="companyData"
                                                defaultChecked={defaultCompanySelected == (item.companyId)}
                                                onChange={() => {
                                                    setDefaultCompanySelected(item.companyId);
                                                    setButtonCheckstep1(true);
                                                    setCompanyName(item.companyName);
                                                    setCompanyId(item.companyId);
                                                }}
                                            />
                                            </td>
                                            <td>{item.companyName}</td>
                                            <td>{item.companyId}</td>
                                            <td>{item.types}</td>
                                            <td className='text-center'>{item.status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            }
            {/* empty search result */}
            {emptySearch && !loadingState &&
                <div className={styles["no-data-cloud-invoice"]}>
                    <h3 className="mt-4 mb-3">No Data Found</h3>
                </div>
            }
            <Row className='px-3 pb-0 mt-3'>
                <Col md={6}>
                    <div className='text-small mb-5 mt-3'>
                        <span className='text-strong'>Company name : </span>
                        {companyName}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="btn-wrapper text-right">
                        {(!emptySearch) ? <>
                            <Button
                                className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                variant="light"
                                onClick={() => {
                                    setDefaultCompanySelected(null);
                                    setButtonCheckstep1(false);
                                    setCompanyName("");
                                    setEditActionPopup(false);
                                }}
                                sx={{ mr: 1 }} > Cancel </Button>
                            <Button
                                className="px-4 mx-2"
                                onClick={() => {
                                    setActiveStep(1);
                                }}>Proceed</Button></>
                            : <>
                                <Button
                                className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                variant="light"
                                onClick={() => {
                                    setDefaultCompanySelected(null);
                                    setButtonCheckstep1(false);
                                    setCompanyName("");
                                    setEditActionPopup(false);
                                }}
                                sx={{ mr: 1 }} > Cancel </Button>
                                <Button
                                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                    variant="light"
                                    disabled >Proceed</Button></>}
                    </div></Col>
            </Row>

        </div>
    )
}

export default CompanySelection
