import React, { useState, useEffect } from 'react'
import styles from './recentCustomers.module.scss'
import { Form, NavDropdown, Table } from 'react-bootstrap';
import { DASHBOARD_RECENT_CUSTOMERS } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import { useNavigate } from 'react-router-dom';

const RecentCustomers = ({ Logout }) => {
    const navigate = useNavigate()                                             // to use navigate function from react router dom  
    const [data, setData] = useState([])                                     // for storing recent customers data

    const [isDisabledCustomers, setIsDisabledCustomers] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Customers") ? true : false);
    const [isDisabledManage, setIsDisabledManage] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Manage") ? true : false);


    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call Recent customers data
    async function GetRecentCustomers() {
        await api.get(`${DASHBOARD_RECENT_CUSTOMERS}`, config)
            .then(resp => {
                if (resp.status == 200) {
                    setData(resp.data);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    //useEffect for calling get api on initial load
    useEffect(() => {
        GetRecentCustomers();
    }, [])

    return (
        <div className={styles["recent-customers"]}>
            <Form.Group
                className={`form-group col-md-12 col-lg-11 ml-3 mb-0 download-btn mb-3 mt-2 ${styles['left-recent-customers']}`}
                controlId="downloadId"
            >
                <NavDropdown title="Recent Customers" id="cloud-service-dropdown"
                    className={`servcies-dropdown`}
                >
                    <NavDropdown.Item
                        key="RecentCustomers"
                        id="RecentCustomers"
                        value="Recent Customers"
                        className=""
                    >
                        Recent Customers
                    </NavDropdown.Item>
                </NavDropdown>
            </Form.Group>
            <div className={styles["recent-customers-data"]}>
                {data.length > 0 &&
                    <Table>
                        <thead>
                            <tr>
                                <th>Customers</th>
                                {<th className={(isDisabledCustomers && isDisabledManage) ? "d-none" : "text-center"}>Actions</th>}
                            </tr>
                        </thead>
                        <tbody className={styles["recent-customers-tbody"]}>
                            {data.map((td) => {
                                return (
                                    <>
                                        <tr>
                                            <td className={`${styles['customername-td']}`}>
                                                <span title={td.customerName} className={`${styles['big-customer-name']}`}>{td.customerName}</span>
                                                </td>
                                            {<td className={`text-center ${styles['recent-customers-actions']}`}>
                                                {!isDisabledCustomers && <span className={`dashboard-actions-icon1 mr-3 cpointer ${styles['disable-button']}`} onClick={() => { navigate("/my-customers/edit-customer", { state: { customerCode: td.customerCode, customerName: (td.customerName.split(" "))[0] }, }); }} id={td.customerCode}>
                                                </span>}
                                                {!isDisabledManage && <span className="dashboard-actions-icon2 cpointer" onClick={() => { navigate('/management-console', { state: { searchData: `${(td.customerName.split(" "))[0]}` } }) }} id={td.customerCode}>
                                                </span>}
                                            </td>}
                                        </tr>
                                    </>
                                )
                            })
                            }
                        </tbody>
                    </Table>
                }
                {data.length < 1 &&
                    <div className={styles["recent-customers-loading-state"]}>
                        <p>No data to display</p>
                    </div>

                }
            </div>
        </div>
    )
}

export default RecentCustomers