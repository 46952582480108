import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, NavDropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { createTheme } from 'react-data-table-component';
import { useLocation } from 'react-router-dom';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import RaiseTicketIllustration from '../../../../../Assets/Images/Illustrations/raiseticketillustration.svg';
import { api } from '../../../../../Utils/API';
import { DASHBOARD_SUBSCRIPTIONS_PLAN_LIST, GET_CUSTOMERS_LIST, GET_SERVICES, RENEWALSETTINGS_DURATION, RENEWALSETTINGS_TABLE } from '../../../../../Utils/GlobalConstants';
import SummaryFilterBox from './FilterBox';
import LazyTable from './LazyTable';
import styles from './renewalSettingsTab.module.scss';
import AccessDenied from '../../../../../GlobalComponents/EmptyStates/AccessDenied';

const RenewalSettingsTab = ({ tabName, Logout, onStateChange
 }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [searchIconFlag, setSearchIconFlag] = useState(false);                // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(searchParams.get('searchValueTable') || null);         // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                         // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                   // showing filter box
    const searchInputRef = useRef();
    const [status, setStatus] = useState(null);                                  // getTableData() Status 
    const [fromDate, setFromDate] = useState(null);                             // getTableData() summary from date
    const [toDate, setToDate] = useState(null);                                 // getTableData() summary to date
    const [pageNo, setPageNo] = useState(1);                                    // table active page number
    const [planName, setPlanName] = useState(null);                                    // table active page number
    const [autoRenew, setAutoRenew] = useState(null);                                    // table active page number
    const [sortCol, setSortCol] = useState("");                                    // table active page number
    const [sortDir, setSortDir] = useState("");                                    // table active page number
    const [isDescStartDate, setIsDescStartDate] = useState(null);               // sort state check for summary number column
    const [isDescEndDate, setIsDescEndDate] = useState(null);               // sort state check for summary number column
    const [serviceData, setServiceData] = useState(null);                                    // table active page number

    const [pageSize, setPageSize] = useState(15);                                // table active page size
    const [totalRows, setTotalRows] = useState(0);                              // table total count of data from api 
    const [columns, setColumns] = useState([]);                                 // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                     // data state used to display data in react data table component
    let ColumnName = ["Customer", "Service",                              // table headers used to make object of react data table component headers
        "Plan", "Billing Term", "Quantity", "Start Date", "End Date", "Auto Renew", "Period",
        "Days Remaining", "Renewal Settings"];
    let ColumnNameNoAction = ["Customer", "Service",                              // table headers used to make object of react data table component headers
        "Plan", "Billing Term", "Quantity", "Start Date", "End Date", "Auto Renew", "Period",
        "Days Remaining"];
    const [initialLoading, setInitialLoading] = useState(true);                 // loader for table
    const [emptyCustomerFilters, setEmptyCustomerFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCustomerSearch, setEmptyCustomerSearch] = useState(false);      // empty search response check state in table during api call to load data
    const [serverErrorCustomer, setServerErrorCustomer] = useState(false);      // server error check state in table during api call to load data
    const [emptyCustomer, setEmptyCustomer] = useState(false);                  // empty table check state in table during api call to load data
    const [dateLastUsedDate, setDateLastUsedDate] = useState("default");        // date dropdown saved data after applying filters
    const [statusLastUsed, setStatusLastUsed] = useState("default");            // status dropdown saved data after applying filters  
    const [autoRenewData, setAutoRenewData] = useState(null);                   // for displaying total number in center of piechart
    const [planData, setPlanData] = useState(null);                             // for displaying total number in center of piechart
    const [customerData, setCustomerData] = useState(null);                     // data in customer dropdown
    const [customerId, setCustomerId] = useState(null);                         // data in customer id dropdown

    // if custom was selected in date in filter box
    // Calculate tomorrow's date
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const tomorrow = today.toISOString().slice(0, 10);

    // Initialize states with tomorrow's date
    const [dateCustomFromLast, setDateCustomFromLast] = useState(tomorrow);
    const [dateCustomToLast, setDateCustomToLast] = useState(tomorrow);

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                    // toast message displaying success message
    const [toastError, setToastError] = useState(false);                        // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                       // toast message usestate

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                        // usestate to scroll to top when applied filter or search when already scrolled
    const [infinityLoading, setInfinityLoading] = useState(false);                      // loading state while table loads for lazy loading
    const [tableFilterApplied, setTableFilterApplied] = useState(false);                // table filter is apllied or not

    const [isDisabledRenewalSettings, setIsRenewalSettings] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Subscriptions Renewal") ? true : false);
    const [isReadRenewalSettings, setIsReadRenewalSettings] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Subscriptions Renewal") ? true : false);

    const [selectedItem2, setSelectedItem2] = useState('Total'); // State for column 2 dropdown (type)
    const [selectedItem3, setSelectedItem3] = useState('Microsoft CSP'); // State for column 3 dropdwon (service)
    // const [integrationCode, setIntegrationCode] = useState("microsoftsaas"); 
    const [integrationCode, setIntegrationCode] = useState(""); 
    // const [serviceId, setServiceId] = useState("18");  
    const [serviceId, setServiceId] = useState(""); 
    const [durationOptions, setDurationOptions] = useState([]);
    const [selectedDuration, setSelectedDuration] = useState(''); // Default value
    const [serviceOptions, setServiceOptions] = useState([]);
    const [selectedService, setSelectedService] = useState('Microsoft CSP'); // Default value
    const [selectedCustomer, setSelectedCustomer] = useState('All'); // For customer dropdown
    const [customerOptions, setCustomerOptions] = useState([]); // For storing customer options
    const [customerSearchValue, setCustomerSearchValue] = useState('');
    const [customerOptionsFiltered, setCustomerOptionsFiltered] = useState([]);
    const customerSearchBoxRef = useRef();
    const isFirstSortEffect = useRef(true);
    const isFirstServiceEffect = useRef(true);


    useEffect(() => {
    if (selectedDuration) {
        console.log("Selected Duration (from effect):", selectedDuration);
        GetTableData(1, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId, true);
    }
    }, [selectedDuration]);

    useEffect(() => {
        if (onStateChange) {
            onStateChange({
                selectedItem2,
                integrationCode,
                serviceId
            });
        }
    }, [selectedItem2, integrationCode, serviceId]);


    // Add to fetch duration options
    async function GetDurationOptions() {
    await api.get(`${RENEWALSETTINGS_DURATION}`, config)
        .then(resp => {
            setDurationOptions(resp.data);
            // Set default selection to first option if available
            if (resp.data && resp.data.length > 0) {
                setSelectedDuration(resp.data[0].id);
            }
        })
        .catch(error => {
            if (error?.status == 401 || error?.response?.status == 401) {
                Logout();
            }
        });
    }

    const handleCustomerSearch = (e) => {
    setCustomerSearchValue(e.target.value);
    if (e.target.value.length > 2) {
        const updatedOptions = [];
        customerOptions.filter(option => {
            if (option.name) {
                if ((option.name).toLowerCase().includes(e.target.value.toLowerCase())) {
                    updatedOptions.push(option);
                }
            }
        });

        if (updatedOptions.length > 0) {
            setCustomerOptionsFiltered(updatedOptions);
        } else {
            setCustomerOptionsFiltered([{ "id": "no-data", "name": "No data available" }]);
        }
    } else {
        setCustomerOptionsFiltered(customerOptions);
    }
    };

    const clearCustomerSearchBox = () => {
    setCustomerSearchValue('');
    setCustomerOptionsFiltered(customerOptions);
    };

    async function GetAllCustomers() {
    await api.get(GET_CUSTOMERS_LIST, config)
        .then(resp => {
            const formattedOptions = [
                { id: 'All', name: 'All Customers' },
                ...resp.data.map(customer => ({
                    id: customer.id,
                    name: customer.name
                }))
            ];
            setCustomerData(resp.data);
            setCustomerOptions(formattedOptions);
            setCustomerOptionsFiltered(formattedOptions);
            if (!selectedCustomer) {
                setSelectedCustomer('All');
            }
        })
        .catch(error => {
            if (error?.status == 401 || error?.response?.status == 401) {
                Logout();
            }
        });
    }

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Header Authorization for Download CSV API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/csv") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "csv") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url}?serviceId=${serviceId}&type=${selectedItem2}&integrationCode=${integrationCode}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Get Table Data with Filters, Pagination, Search  Functionality
    async function GetTableData(page, newPerPage, search, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId, isFilter, appendData) {
        setInitialLoading(false);
        (search === undefined || search === null || search === "null") ? search = "" : search = search;
        (status === undefined || status === null || status === "null") ? status = "" : status = status;
        (fromDate === undefined || fromDate === "undefined/undefined/" || fromDate === null || fromDate === "null") ? fromDate = "" : fromDate = fromDate;
        (toDate === undefined || toDate === "undefined/undefined/" || toDate === null || toDate === "null") ? toDate = "" : toDate = toDate;
        (planName === undefined || planName === null || planName === "null") ? planName = "" : planName = planName;
        (customerId === undefined || customerId === null || customerId === "null") ? customerId = "" : customerId = customerId;
        (autoRenew === undefined || autoRenew === null || autoRenew === "null") ? autoRenew = "" : autoRenew = autoRenew;
        (sortCol === undefined || sortCol === null || sortCol === "null") ? sortCol = "" : sortCol = sortCol;
        (sortDir === undefined || sortDir === null || sortDir === "null") ? sortDir = "" : sortDir = sortDir;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        const durationParam = selectedDuration || '';
        await api.get(`${RENEWALSETTINGS_TABLE}?IntegrationCode=${integrationCode}&ServiceId=${serviceId}&CustomerCode=${customerId}&PageNo=${page}&PageSize=${newPerPage}&SearchText=${search}&SortCol=${sortCol}&SortDir=${sortDir}&Duration=${durationParam}`, config)
            .then(resp => {
                setInitialLoading(true);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    // if (search === "" && fromDate === "" && toDate === "" && planName === "" && customerId === "") {
                        setEmptyCustomer(true);
                    // }
                    // else if ((fromDate != "" || toDate != "" || planName != "" || customerId != "") && isFilter) {
                        setEmptyCustomerFilters(true);
                    // }
                    // else if (search != "" && !isFilter) {
                        setEmptyCustomerSearch(true);
                    // }

                }
                // handle success
                else {
                    setServerErrorCustomer(false);
                    setEmptyCustomer(false);
                    setEmptyCustomerFilters(false);
                    setEmptyCustomerSearch(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Customer": td.customerName,
                                    "Service": td.serviceName,
                                    "Plan": <>{td.planName}
                                        <div title={td.vendorSubscriptionId} className={`py-1 ${styles['csp-vendor-id']}`}>{td.vendorSubscriptionId}</div>
                                    </>,
                                    "Billing Term": td.billingTerm,
                                    "Quantity": td.quantity,
                                    "Start Date": td.startDate,
                                    "End Date": td.endDate,
                                    "Auto Renew": td.autoRenew,
                                    "Period": td.period,
                                    "Days Remaining": td.remainingDays,
                                    "Renewal Settings": <span className='renewal-change-status cpointer'
                                        // onClick={() => {
                                        //     if (td.serviceName == "Google Workspace") {
                                        //         if (td.isAddonPlan) {
                                        //             navigate("/management-console/gws", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.basePlanName, isRenewalSettings: true } })
                                        //         }
                                        //         else {
                                        //             navigate("/management-console/gws", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.planName, isRenewalSettings: true } })
                                        //         }
                                        //     }
                                        //     else {
                                        //         if (td.isAddonPlan) {
                                        //             navigate("/management-console/csp", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.basePlanName, isRenewalSettings: true } })
                                        //         }
                                        //         else {
                                        //             navigate("/management-console/csp", { state: { customerId: td.customerCode, subscriptionId: td.subscriptionId, planName: td.planName, isRenewalSettings: true } })
                                        //         }
                                        //     }
                                        // }}
                                        ></span>

                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ((!isReadRenewalSettings) ? ColumnName : ColumnNameNoAction).map((td) => {
                        if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Start Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescStartDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); setIsDescEndDate(null); }}></span></>}
                                    {isDescStartDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescStartDate(false); setSortCol("sortStartDate"); }}></span></>}
                                    {isDescStartDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortStartDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "End Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescEndDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescEndDate(true); setSortCol("sortEndDate"); setIsDescStartDate(null); }}></span></>}
                                    {isDescEndDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescEndDate(false); setSortCol("sortEndDate"); }}></span></>}
                                    {isDescEndDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescEndDate(true); setSortCol("sortEndDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Renewal Settings") {
                            d.push({
                                id: `${td}`,
                                name: <div className='text-center d-none-mobile'>{td}</div>,
                                selector: row => <div className="text-wrap d-none-mobile">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })

                        }else if(td === "Auto Renew"){
                             d.push({
                                id: `${td}`,
                                name: <div className='text-center'>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Billing Term") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}<OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                                >
                                    <span className="infoIcon-support-table"></span>
                                </OverlayTrigger>
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Quantity") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumns(d);       // Setting Column Data
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerErrorCustomer(true);
                }
            });
    };

    async function GetAllServices(selectedServiceName) {
    try {
        const resp = await api.get(`${GET_SERVICES}`, config);

        // Filter and format service options for dropdown
        const formattedServices = resp.data
            .filter(service => service.serviceType === "ONLINE")
            .map(service => ({
                id: service.name,
                name: service.name
            }));

        setServiceOptions(formattedServices);

        // Handle service selection
        if (selectedServiceName) {
            resp.data.map((service) => {
                if (service.name === selectedServiceName) {
                    setServiceId(service.id);
                    setIntegrationCode(service.integrationCode);
                    setSelectedService(selectedServiceName);
                }
            });
        } else if (formattedServices.length > 0) {
            // If no service is selected, use the first available service
            const defaultService = formattedServices[0].name;
            resp.data.map((service) => {
                if (service.name === defaultService) {
                    setServiceId(service.id);
                    setIntegrationCode(service.integrationCode);
                    setSelectedService(defaultService);
                }
            });
        }
    } catch (error) {
        if (error?.status == 401 || error?.response?.status == 401) {
            Logout();
        }
    }
    }

    // Function to call all dropdown values in Plans Section in Filter Box
    async function GetAllPlans() {
        await api.get(`${DASHBOARD_SUBSCRIPTIONS_PLAN_LIST}?integrationCode=${integrationCode}`, config)
            .then(resp => {
                setPlanData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        console.log("handlePageChange");
        GetTableData(pageNo, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, pageNo) => {
        setPageNo(pageNo);
        setPageSize(newPerPage);
        console.log("handlePerRowsChange");
        GetTableData(pageNo, newPerPage, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
useEffect(() => {
    if (!isFirstSortEffect.current) { // Only run if it's not the first render
        setPageNo(1);
        console.log("isDescEndDate function call");
        GetTableData(
            1, 
            pageSize, 
            searchValueTable, 
            status, 
            planName, 
            autoRenew, 
            fromDate, 
            toDate, 
            sortCol, 
            sortDir, 
            serviceId, 
            customerId
        );
    } else {
        isFirstSortEffect.current = false; // Set to false after first render
    }
}, [isDescEndDate, isDescStartDate]);


    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',  // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
            },
        },
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        console.log("handle lazy calls");
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId, false, true))();
            return newPageNumber;
        });
    }
    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setTableFilterApplied(false);
                    console.log("serach value table call");
                    GetTableData(1, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    console.log("serach value table call");
                    GetTableData(1, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])

    // Function to trigger getTable data function 
    useEffect(() => {
        // GetTableData(pageNo, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId);
        GetAllServices();
        GetAllCustomers();
        GetDurationOptions();
    }, [])

    //on tab change move make scroll to top true
    useEffect(() => {
        setScrollToDivTop(true);
        setPageNo(1);
    }, [tabName])

    // Define a function to handle selecting a service from the dropdown
    const handleSelectService = (selectedServiceName) => {
        GetAllServices(selectedServiceName);
    };

useEffect(() => {
    // GetAllPlans();
    setScrollToDivTop(true);
    setPageNo(1);

    if (tableFilterApplied) {
        console.log("entering if");
        GetTableData(1, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId, true);
    } else {
        if (!isFirstServiceEffect.current) {
            console.log("entering else");
            GetTableData(1, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId, false);
        } else {
            isFirstServiceEffect.current = false;
        }
    }
   }, [selectedService, integrationCode, serviceId]);

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    return (
        <>
            <div className="main-content">
                {/* <h2 className="mb-0 px-3">Subscriptions Renewal</h2> */}
                {!isDisabledRenewalSettings && <div className="customer-management-console-main-div mb-5">
                <div className="mgmt-console-table-row">
                <div className="mb-3 d-flex">
                <Col className={`py-0  ${styles['order-history-table']}`}>
                    <div className={`${styles['user-management-table-inner']} ${styles['table-details']} details-box`}>
                        <div className='my-4 position-relative'>
                            <Container fluid>
                            <Row>
                                <Col md={4} lg={3} className='my-4 d-flex align-items-center' >
                                    <h2>Subscriptions {!emptyCustomer && !emptyCustomerFilters && !emptyCustomerSearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h2>
                                </Col>

                                {/* Desktop Search and Filter lg={5}*/}
                                <Col className="px-1 d-none d-md-block mb-2 my-4"  md={3} >
                                                                {/* <Row className={`justify-content-end align-items-center mobile-padding-search-open-sub  ${styles['search-tablet-view']}`}>
                                                                    {!serverErrorCustomer && !emptyCustomer && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                                        {!searchIconFlag &&
                                                                            <span className="mx-3">&nbsp;</span>
                                                                        }
                                                                        {!searchIconFlag && !emptyCustomerFilters &&
                                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                                        }
                                                                        {searchIconFlag && !emptyCustomerFilters &&
                                                                            <InputGroup className="search-input-box search-input-box-xl">
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                                    <span className="searchgrey cpointer"
                                                                                    >
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                                <Form.Control
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    aria-describedby="basic-addon2"
                                                                                    className="search-input-box-input"
                                                                                    value={searchValueTable}
                                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                                    ref={searchInputRef}
                                                                                />
                                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                                    onClick={() => {
                                                                                        setSearchValueTable("");
                                                                                        setSearchIconFlag(!searchIconFlag);
                                                                                    }}>
                                                                                    <span className="closebtn">
                                                                                        &nbsp;
                                                                                    </span>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                        }
                                                                       {!filterFlag && !emptyCustomerSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                            setShowFilterBox(!showFilterBox);
                                                                        }}></span>}
                                                                        {initialLoading && filterFlag && !emptyCustomerSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                                    </Col>}
                                                                    {
                                                                        !emptyCustomerSearch && showFilterBox &&
                                                                        <SummaryFilterBox GetTableData={GetTableData} pageNo={pageNo} pageSize={pageSize} searchValueTable={searchValueTable} fromDate={fromDate} toDate={toDate}
                                                                            setFromDate={setFromDate} setToDate={setToDate} planData={planData} autoRenew={autoRenew} autoRenewData={autoRenewData}
                                                                            serviceData={serviceData} planName={planName} setPlanName={setPlanName} serviceId={serviceId} setServiceId={setServiceId}
                                                                            customerId={customerId} setCustomerId={setCustomerId} customerData={customerData} setPageNo={setPageNo} sortCol={sortCol} sortDir={sortDir}
                                                                            setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} dateLastUsedDate={dateLastUsedDate} setDateLastUsedDate={setDateLastUsedDate} status={status} setStatus={setStatus}
                                                                            dateCustomFromLast={dateCustomFromLast} setDateCustomFromLast={setDateCustomFromLast} dateCustomToLast={dateCustomToLast}
                                                                            setDateCustomToLast={setDateCustomToLast} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed} setScrollToDivTop={setScrollToDivTop} setTableFilterApplied={setTableFilterApplied}
                                                                        />
                                                                    }
                                                                </Row> */}
                                </Col>

                                {/* Mobile Search and Filter px-1 */}
<Col className="d-block d-md-none" md={12}>
    <Row className="justify-content-start align-items-center mobile-padding-search-open-sub">
        {!serverErrorCustomer && !emptyCustomer && 
            <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                {/* {!emptyCustomerFilters &&
                    <InputGroup className="search-input-box">
                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                            <span className="searchgrey cpointer">&nbsp;</span>
                        </InputGroup.Text>
                        <Form.Control
                            placeholder="Search Plan Name"
                            aria-label="Search Plan Name"
                            aria-describedby="basic-addon2"
                            className="search-input-box-input"
                            value={searchValueTable}
                            onChange={(e) => setSearchValueTable(e.target.value)}
                            ref={searchInputRef}
                        />
                        <InputGroup.Text 
                            id="basic-addon2" 
                            className="search-input-box-btn search-input-box-close"
                            onClick={() => {
                                setSearchValueTable("");
                                setSearchIconFlag(!searchIconFlag);
                            }}
                        >
                            <span className="closebtn">&nbsp;</span>
                        </InputGroup.Text>
                    </InputGroup>
                }
                {!filterFlag && !emptyCustomerSearch && 
                    <span 
                        className="filter-black cpointer" 
                        id="filterblock" 
                        onClick={() => {
                            setShowFilterBox(!showFilterBox);
                        }}
                    />
                }
                {initialLoading && filterFlag && !emptyCustomerSearch && 
                    <span 
                        className="filter-black-active cpointer" 
                        onClick={() => { 
                            setShowFilterBox(!showFilterBox);
                        }}
                    />
                } */}
            </Col>
        }
    </Row> 
    {/* Filter Box for Mobile */}
    {!emptyCustomerSearch && showFilterBox &&
        <div className={styles['mobile-filter-box']}>
            <SummaryFilterBox 
                GetTableData={GetTableData} 
                pageNo={pageNo} 
                pageSize={pageSize} 
                searchValueTable={searchValueTable} 
                fromDate={fromDate} 
                toDate={toDate}
                setFromDate={setFromDate} 
                setToDate={setToDate} 
                planData={planData} 
                autoRenew={autoRenew} 
                autoRenewData={autoRenewData}
                serviceData={serviceData} 
                planName={planName} 
                setPlanName={setPlanName} 
                serviceId={serviceId} 
                setServiceId={setServiceId}
                customerId={customerId} 
                setCustomerId={setCustomerId} 
                customerData={customerData} 
                setPageNo={setPageNo} 
                sortCol={sortCol} 
                sortDir={sortDir}
                setShowFilterBox={setShowFilterBox} 
                setFilterFlag={setFilterFlag} 
                dateLastUsedDate={dateLastUsedDate} 
                setDateLastUsedDate={setDateLastUsedDate} 
                status={status} 
                setStatus={setStatus}
                dateCustomFromLast={dateCustomFromLast} 
                setDateCustomFromLast={setDateCustomFromLast} 
                dateCustomToLast={dateCustomToLast}
                setDateCustomToLast={setDateCustomToLast} 
                statusLastUsed={statusLastUsed} 
                setStatusLastUsed={setStatusLastUsed} 
                setScrollToDivTop={setScrollToDivTop} 
                setTableFilterApplied={setTableFilterApplied}
                isMobile={true}
            />
        </div>
    }
</Col>


                                {/* Service Type and Service Selection Dropdowns */}
                                {!serverErrorCustomer &&
                                    <>
        <Col xs={12} md={2} lg={2} className='my-4'>
        <Form.Group>
        <NavDropdown 
        title={selectedCustomer === 'All' ? 'All Customers' : customerOptions.find(c => c.id === selectedCustomer)?.name || 'Select Customer'}
        id="customer-dropdown"
        className="servcies-dropdown dropdown-specialclass-fortwo"
        onToggle={clearCustomerSearchBox}
        >
        <Form.Control
            type="text"
            value={customerSearchValue}
            ref={customerSearchBoxRef}
            placeholder="Search customer"
            maxLength="50"
            onChange={handleCustomerSearch}
            className="mx-2 my-2 w-auto"
        />
        <div className="dynamic-options-block">
            {(customerOptionsFiltered.length > 0 ? customerOptionsFiltered : customerOptions).map(option => (
                <NavDropdown.Item 
                    key={option.id}
                    eventKey={option.id}
                    className={selectedCustomer === option.id ? "selected" : ""}
                    onClick={() => {
                        setSelectedCustomer(option.id);
                        setCustomerId(option.id === 'All' ? null : option.id);
                        GetTableData(1, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, option.id === 'All' ? null : option.id, true);
                    }}
                >
                    {option.name}
                </NavDropdown.Item>
            ))}
        </div>
        </NavDropdown>
        </Form.Group>
        </Col>
        <Col xs={12} md={2} lg={2} className='my-4'>
        <Form.Group>
    <NavDropdown 
        className="servcies-dropdown dropdown-specialclass-fortwo"
        title={selectedService}
        id="cloud-service-dropdown"
        onSelect={handleSelectService}
        value={selectedService}
    >
        <NavDropdown.Item 
            eventKey="Microsoft CSP"
            className={selectedService === "Microsoft CSP" ? "selected" : ""}
        >
            Microsoft CSP
        </NavDropdown.Item>
        <NavDropdown.Item 
            eventKey="Google Workspace"
            className={selectedService === "Google Workspace" ? "selected" : ""}
        >
            Google Workspace
        </NavDropdown.Item>
    </NavDropdown>
</Form.Group>
        </Col>
       <Col xs={12} md={2} lg={2} className='my-4'>
    <Form.Group>
        <NavDropdown 
            title={durationOptions.find(opt => opt.id === selectedDuration)?.name || 'Select Duration'}
            // title={selectedDuration} 
            id="duration-dropdown"
            className="servcies-dropdown dropdown-specialclass-fortwo"
            onSelect={(eventKey) => {
                setSelectedDuration(eventKey);
                GetTableData(1, pageSize, searchValueTable, status, planName, autoRenew, fromDate, toDate, sortCol, sortDir, serviceId, customerId, true);
            }}
        >
            {durationOptions.map(option => (
                <NavDropdown.Item 
                    key={option.id}
                    eventKey={option.id}
                    className={selectedDuration === option.id ? "selected" : ""}
                >
                    {option.name}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    </Form.Group>
</Col>
                                    </>
                                }
                            </Row>
                            </Container>
                            {/* Table Section */}
                            <Row>
                                <Col>
                                    <div className={`table-responsive ${styles['customer-table']}`}>
                                         {initialLoading && serverErrorCustomer &&
                                                                        <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Matching Subscription Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomerFilters && !emptyCustomerSearch &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={EmptyDataTableFilterSearch} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Subscription Found.</h2>
                                                                            </div>
                                                                        }
                                                                        {initialLoading && emptyCustomer &&
                                                                            <div className={styles["no-data-customers"]}>
                                                                                <img src={RaiseTicketIllustration} className="empty-customers-table-svg" />
                                                                                <h2 className="mt-4 mb-3">No Subscription Found.</h2>
                                                                            </div>
                                                                        }

                                        {/* Data Table */}
                                        {!serverErrorCustomer && !emptyCustomerFilters && !emptyCustomer && !emptyCustomerSearch &&
                                            <LazyTable
                                                d={columns}
                                                table={table}
                                                customStyles={customStyles}
                                                loading={infinityLoading}
                                                pageNo={pageNo}
                                                totalRows={totalRows}
                                                handlePerRowsChange={handlePerRowsChange}
                                                handlePageChange={handlePageChange}
                                                styles={styles}
                                                handleLazyCalls={handleLazyCalls}
                                                serverErrorUser={serverErrorCustomer}
                                                emptyUser={emptyCustomer}
                                                emptyUserFilters={emptyCustomerFilters}
                                                emptyUserSearch={emptyCustomerSearch}
                                                setScrollToDivTop={setScrollToDivTop}
                                                scrollToDivTop={scrollToDivTop}
                                            />
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                </div>
                </div>
                </div>}
                {
                  isDisabledRenewalSettings && <AccessDenied />
                }
            </div>
        </>
    )
}

export default RenewalSettingsTab